import React from "react";
import gql from "graphql-tag";
import AdminTable, {
  AdminTableColumn,
  AdminTableRow,
} from "~/components/AdminTable";
import { useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { Bold, H3, Text } from "~/components/Typography";
import styles from "../AdminCustomerListView/index.module.scss";
import AdminPagination from "~/components/AdminPagination";
import LoadingWrapper from "~/components/LoadingWrapper";
import TextInput from "~/components/TextInput";
import Button from "~/components/Button";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { useFieldState, useFormApi } from "informed";
import Modal from "~/components/Modal";

enum Field {
  PolicyID = "AdminActionListView-PolicyID",
  Email = "AdminActionListView-Email",
  ActionName = "AdminActionListView-ActionName",
}

type ActionParam = {
  name: string;
  value: string;
};

type Action = {
  actionId: string;
  policyId?: string;
  actionName: string;
  actionParams: ActionParam[];
  adminEmail: string;
  createdAt: Date;
  success: boolean;
};

type GetActionList = {
  adminActionListView: {
    actions: Action[];
    count?: number;
  };
};

const GET_ACTION_LIST = gql`
  query adminActionListView(
    $policyId: String
    $actionName: String
    $adminEmail: String
    $sortField: String
    $sortDesc: Boolean
    $page: Float
    $perPage: Float
    $getDocumentCount: Boolean
  ) {
    adminActionListView(
      policyId: $policyId
      actionName: $actionName
      adminEmail: $adminEmail
      sortField: $sortField
      sortDesc: $sortDesc
      page: $page
      perPage: $perPage
      getDocumentCount: $getDocumentCount
    ) {
      actions {
        actionId
        policyId
        actionName
        actionParams {
          name
          value
        }
        adminEmail
        createdAt
        success
      }
      count
    }
  }
`;

const AdminActionListView = () => {
  const location = useLocation();

  const formApi = useFormApi();
  const policyIdState = useFieldState(Field.PolicyID);
  const emailState = useFieldState(Field.Email);
  const actionNameState = useFieldState(Field.ActionName);

  const getParam = (param: string) => {
    return new URLSearchParams(location.search).get(param) ?? undefined;
  };

  const [selectedAction, setSelectedAction] = React.useState<
    Action | undefined
  >(undefined);

  const [options, setOptions] = React.useState<{
    email: string | undefined;
    actionName: string | undefined;
    policyId: string | undefined;
    sortField: string | undefined;
    sortDesc: boolean;
    selectedPage: number;
    perPage: number;
    itemCountIncrement: number;
  }>({
    email: getParam("email"),
    actionName: getParam("actionName"),
    policyId: getParam("policyId"),
    sortField: getParam("sortField") ?? "createdAt",
    sortDesc: getParam("sortDesc") === "false" ? false : true,
    selectedPage: 1,
    perPage: 25,
    itemCountIncrement: 1,
  });

  useMountEffect(() => {
    formApi.setValue(Field.PolicyID, getParam("policyId") ?? "");
    formApi.setValue(Field.ActionName, getParam("actionName") ?? "");
    formApi.setValue(Field.Email, getParam("email") ?? "");
  });

  const [tableColumns, setTableColumns] = React.useState<AdminTableColumn[]>(
    []
  );

  const [tableRows, setTableRows] = React.useState<AdminTableRow[]>([]);

  const [
    lastItemCountIncrement,
    setLastItemCountIncrement,
  ] = React.useState<number>(0);

  const [itemCount, setItemCount] = React.useState<number>(0);

  const onChangePerPage = (newPerPage: number) => {
    setOptions((options) => ({
      ...options,
      perPage: newPerPage,
      selectedPage: 1,
    }));
  };

  const onChangePageSelected = (newPageSelected: number) => {
    setOptions((options) => ({
      ...options,
      selectedPage: newPageSelected,
    }));
  };

  const getDateString = (date: Date) => {
    return date.toLocaleDateString();
  };

  const handleChangeSort = (sort: string) => {
    if (sort === options.sortField) {
      setOptions((options) => ({
        ...options,
        sortDesc: !options.sortDesc,
        selectedPage: 1,
      }));
    } else {
      setOptions((options) => ({
        ...options,
        sortField: sort,
        sortDesc: false,
        selectedPage: 1,
      }));
    }
  };

  const updateSearch = () => {
    setOptions((options) => ({
      ...options,
      email: emailState.value ? String(emailState.value) : undefined,
      actionName: actionNameState.value
        ? String(actionNameState.value)
        : undefined,
      policyId: policyIdState.value ? String(policyIdState.value) : undefined,
      itemCountIncrement: (options.itemCountIncrement += 1),
      selectedPage: 1,
    }));
  };

  const [getActionList, { loading, error, data }] = useLazyQuery<GetActionList>(
    GET_ACTION_LIST,
    {
      fetchPolicy: "no-cache",
    }
  );

  const openActionModal = (actionId: string) => {
    setSelectedAction(
      data?.adminActionListView?.actions.find((action) => {
        return action.actionId === actionId;
      })
    );
  };

  React.useEffect(() => {
    const newTableColumns: AdminTableColumn[] = [
      {
        displayName: "ACTION ID",
      },
      {
        displayName: "ACTION NAME",
        sortData: {
          sortable: true,
          sortField: "actionName",
          sortBy: options.sortField === "actionName",
          sortDesc: !!options.sortDesc,
        },
      },
      {
        displayName: "ADMIN EMAIL",
        sortData: {
          sortable: true,
          sortField: "adminEmail",
          sortBy: options.sortField === "adminEmail",
          sortDesc: !!options.sortDesc,
        },
      },
      {
        displayName: "POLICY ID",
        sortData: {
          sortable: true,
          sortField: "policyId",
          sortBy: options.sortField === "policyId",
          sortDesc: !!options.sortDesc,
        },
      },
      {
        displayName: "CREATED DATE",
        sortData: {
          sortable: true,
          sortField: "createdAt",
          sortBy: options.sortField === "createdAt",
          sortDesc: !!options.sortDesc,
        },
      },
      {
        displayName: "SUCCESSFUL",
      },
    ];

    setTableColumns(newTableColumns);

    getActionList({
      variables: {
        email: options.email,
        actionName: options.actionName,
        policyId: options.policyId,
        sortField: options.sortField,
        sortDesc: options.sortDesc,
        page: options.selectedPage,
        perPage: options.perPage,
        getDocumentCount: options.itemCountIncrement > lastItemCountIncrement,
      },
    });

    if (options.itemCountIncrement > lastItemCountIncrement) {
      setLastItemCountIncrement(options.itemCountIncrement);
    }

    const params = {
      ...(options.email && {
        email: options.email,
      }),
      ...(options.actionName && {
        actionName: options.actionName,
      }),
      ...(options.policyId && {
        policyId: options.policyId,
      }),
      sortField: options.sortField,
      sortDesc: options.sortDesc ? "true" : "false",
    };

    const newUrl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?${Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&")}`;

    window.history.replaceState({ path: newUrl }, "", newUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  React.useEffect(() => {
    if (data) {
      if (
        data.adminActionListView.count !== undefined &&
        data.adminActionListView.count !== null
      ) {
        setItemCount(data.adminActionListView.count);
      }

      const newTableRows: AdminTableRow[] = [];

      if (data.adminActionListView.actions) {
        data.adminActionListView.actions.forEach((action, index) => {
          const cells: React.ReactElement[] = [
            <Text key={`cell-actionId-${index}`}>{action.actionId}</Text>,
            <Text key={`cell-actionName-${index}`}>{action.actionName}</Text>,
            <Text key={`cell-adminEmail-${index}`}>{action.adminEmail}</Text>,
            <Text key={`cell-policyId-${index}`}>{action.policyId}</Text>,
            <Text key={`cell-createdAt-${index}`}>
              {getDateString(new Date(action.createdAt))}
            </Text>,
            <Text key={`cell-success-${index}`}>
              {action.success ? "Yes" : "No"}
            </Text>,
          ];

          newTableRows.push({
            onClickRowValue: action.actionId,
            cells,
          });
        });
      }

      setTableRows(newTableRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={styles.Content}>
      <div className={styles.HeaderContainer}>
        <H3>Admin Action List</H3>
      </div>

      <div className={styles.SearchParams}>
        <TextInput
          className={styles.SearchInput}
          type="text"
          label="Email"
          placeholder="admin-account@almi.com"
          field={Field.Email}
          initialValue={options.email}
        />

        <TextInput
          className={styles.SearchInput}
          type="text"
          label="Action Name"
          field={Field.ActionName}
          initialValue={options.actionName}
          placeholder="Action Name"
        />

        <TextInput
          className={styles.SearchInput}
          type="text"
          label="Policy ID"
          field={Field.PolicyID}
          initialValue={options.policyId}
          placeholder="Policy ID"
        />

        <Button
          className={styles.SearchButton}
          size={"xsmall"}
          onClick={updateSearch}
        >
          Update
        </Button>
      </div>

      {loading && <LoadingWrapper loading />}

      {!loading && !error && data && (
        <>
          <AdminTable
            columns={tableColumns}
            rows={tableRows}
            onClickRow={openActionModal}
            onChangeSortBy={handleChangeSort}
          />

          <AdminPagination
            itemCount={itemCount}
            perPage={options.perPage}
            pageSelected={options.selectedPage}
            siblingsShown={4}
            onPerPageChange={onChangePerPage}
            onPageSelectedChange={onChangePageSelected}
          />
        </>
      )}

      {selectedAction && (
        <Modal
          title={selectedAction?.actionId}
          isOpen={selectedAction !== undefined}
          shouldOverlayCloseOnClick
          onClose={() => {
            setSelectedAction(undefined);
          }}
          id="selected-admin-action-modal"
        >
          <div style={{ margin: "20px" }}>
            <Text>
              <Bold>Action Name:</Bold> {selectedAction.actionName}
            </Text>

            <Text>
              <Bold>Admin Email: </Bold> {selectedAction.adminEmail}
            </Text>

            <Text>
              <Bold>Policy ID: </Bold> {selectedAction.policyId}
            </Text>

            <Text>
              <Bold>
                Created Date:{" "}
                {getDateString(new Date(selectedAction.createdAt))}
              </Bold>
            </Text>

            <Text>
              <Bold>Success:</Bold> {selectedAction.success ? "Yes" : "No"}
            </Text>

            {selectedAction.actionParams.map((param) => (
              <>
                <Text>
                  <Bold>{param.name}: </Bold> {param.value}
                </Text>
              </>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AdminActionListView;
