/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useMemo, useState } from "react";
import LoadingWrapper from "~/components/LoadingWrapper";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useHistory, useParams } from "react-router-dom";

import CarImg from "~/assets/images/illustration-motor.png";
import TruckImg from "~/assets/images/illustration-truck.png";
import BikeImg from "~/assets/images/illustration-bike.png";
import HomeImg from "~/assets/images/illustration-home.png";
import { H3, H4, H5, H6, Link, Text } from "~/components/Typography";
import { Select, Option } from "informed";
import { useAuth0Context, UserRoleType } from "~/contexts/Auth0Context";
import { ToolOnCloseProps, ToolProps } from "..";
import AdminCancelPolicy from "../PolicyTools/AdminCancelPolicy";
import AdminRegenerateContract from "../PolicyTools/AdminRegenerateContract";
import AdminRegenerateCoverNote from "../PolicyTools/AdminRegenerateCoverNote";
import AdminUpdatePolicyPremium from "../PolicyTools/AdminUpdatePolicyPremium";
import Modal from "~/components/Modal";
import AdminManualSinglePayment from "../PolicyTools/AdminManualSinglePayment";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import styles from "./index.module.scss";
import { formatCurrency } from "~/helpers/currency";
import { getTypeOfCoverage } from "~/helpers/getCoverageData";
import { useCustomFieldApi } from "~/helpers/hooks/useCustomFieldApi";
import AdminDownloadDocument from "../AdminDownloadDocument";
import Button from "~/components/Button";
import Status from "~/components/Status";
import { ADMIN_CUSTOMER_DETAILS_URL } from "~/routes/index.constant";
import AdminRegisterPhysicalPayment from "../PolicyTools/AdminRegisterPhysicalPayment";
import AdminMarkFirstPaymentSuccessful from "../PolicyTools/AdminMarkFirstPaymentSuccessful";
import AdminRegisterManualRefund from "../PolicyTools/AdminRegisterManualRefund";
import AdminUpdateWebstarPolicies from "../PolicyTools/AdminUpdateWebstarPolicies";
import AdminForceDocumentSync from "../PolicyTools/AdminForceDocumentSync";
import { Renewal } from "../../../../types";
import AdminInsertRenewalData from "../PolicyTools/AdminInsertRenewalData";
import AdminProcessRenewal from "../PolicyTools/AdminProcessRenewal";
import {
  PortalChecklistHomeInput,
  PortalChecklistMotorInput,
} from "~/contexts/ChecklistProvider";
import { format } from "date-fns";
import { ArrowDown, ArrowUp } from "~/components/Icon/icons";

const GET_POLICY_DETAILS = gql`
  query adminGetPolicyDetails($policyId: String!) {
    adminGetPolicyDetails(policyId: $policyId) {
      policyId
      webstarId
      customerId
      policyStatus
      policyType
      coverageType
      policyProducer
      coverageStartDate
      coverageEndDate
      purchaseDate
      originalInception
      annualPremium
      vehicle {
        year
        make
        model
        value
        engineSize
        type
        isSportsCar
        isEngineModified
        isLeftSide
        isVehicleOwned
        isElectric
        registration
        engineNumber
        chassisNumber
        preciseEngineSize
        windscreenCoverageAmount
      }
      home {
        buildingType
        heightInStories
        age
        address
        isCommercial
        constructionType
        isFloodRisk
        isCoastal
        isHighDensity
        isGoodState
        isConflagurationZone
        value
        contentsValue
        allRiskCoverage
        allRiskValue
        isAllRiskWorldwide
        isExistingMotorCustomer
      }
      scheduledPayments {
        paymentDate
        amount
        status
        lastModified
        paymentRecordId
      }
      finalCardNumbers
      cardHolderName
      notes
      renewal {
        almiApproved
        clientApproved
        underwriterApproved
        premium
      }
      renewalHistory {
        startDate
        endDate
        premium
        scheduledPayments {
          paymentDate
          amount
          status
          lastModified
          paymentRecordId
        }
      }
      portalChecklist {
        agreedToDeclaration
        submitted
        documents {
          documentType
          fileName
          context
        }

        motor {
          additionalDrivers {
            name
            age
            yearsDrivingExperience
            occupation
            licenseNumber
            licenseDateOfIssue
            licenseType
          }
          healthConditions
          prosecutionsPastFiveYears
          refusedInsurance
          employerName
          employerAddress
          employerCountryCode
          employerPhone
          previousProvider
          isFinanced
        }

        home {
          storeys
          homeBuilt
          mortgageLender
          goodState
          surroundingCommercial
          leftWithNoInhabitant
          reclaimedLand
          levelledLand
          otherInsurance
          insuranceHistory
          insuranceHistoryReason
        }
      }
    }
  }
`;

const TOGGLE_RENEWAL = gql`
  mutation toggleAlmiAllowRenewal(
    $policyId: String!
    $allowPolicyRenewal: Boolean!
  ) {
    toggleAlmiAllowRenewal(
      policyId: $policyId
      allowPolicyRenewal: $allowPolicyRenewal
    )
  }
`;

const PAYMENT_RECORDS_LIST = gql`
  query adminPaymentRecordListView($policyId: String!) {
    adminPaymentRecordListView(policyId: $policyId) {
      records {
        externalId
        policyId
        creditCardTokenId
        amount
        currency
        status
        receiptNumber
      }
    }
  }
`;

type PolicyTool = {
  displayName: string;
  element: React.FC<ToolProps>;
  allowedRoles: UserRoleType[];
};

type ScheduledPayment = {
  paymentDate: Date;
  amount: string;
  status: string;
  lastModified: Date;
  paymentRecordId: string;
};

type RenewalHistory = {
  startDate: Date;
  endDate: Date;
  premium: string;
  scheduledPayments: ScheduledPayment[];
};

type ChecklistDocument = {
  documentType: string;
  fileName?: string;
  context?: string;
  isOptional?: boolean;
};

type PortalChecklist = {
  submitted?: boolean;
  agreedToDeclaration?: boolean;
  documents: ChecklistDocument[];
  motor: PortalChecklistMotorInput;
  home: PortalChecklistHomeInput;
};

type PolicyDetails = {
  policyId: string;
  webstarId: string;
  customerId: string;
  policyStatus: string;
  policyType: string;
  coverageType: string;
  policyProducer: string;
  coverageStartDate: Date;
  coverageEndDate: Date;
  originalInception: Date;
  annualPremium: string;
  vehicle: {
    year: number;
    make: string;
    model: string;
    value: string;
    engineSize: string;
    type: string;
    isSportsCar: boolean;
    isEngineModified: boolean;
    isLeftSide: boolean;
    isVehicleOwned: boolean;
    isElectric: boolean;
    registration?: string;
    engineNumber?: string;
    chassisNumber?: string;
    preciseEngineSize?: string;
    windscreenCoverageAmount?: string;
  };
  home: {
    buildingType: string;
    heightInStories?: number;
    age?: number;
    address: string;
    isCommerical: boolean;
    constructionType: string;
    isFloodRisk: boolean;
    isCoastal: boolean;
    isHighDensity: boolean;
    isGoodState: boolean;
    isConflagurationZone?: boolean;
    value: number;
    contentsValue: number;
    allRiskCoverage: boolean;
    allRiskValue?: number;
    isAllRiskWorldwide?: boolean;
    isExistingMotorCustomer?: boolean;
  };
  finalCardNumbers?: string;
  cardHolderName?: string;
  notes?: string;
  scheduledPayments: ScheduledPayment[];
  renewal?: Renewal;
  portalChecklist?: PortalChecklist;
  renewalHistory?: RenewalHistory[];
};

type GetPolicyDetails = {
  adminGetPolicyDetails: PolicyDetails;
};

enum PaymentRecordStatus {
  /** Has been created but has not been used yet.*/
  created = "created",
  /** Started processing a payment (we did not receive a response from FAC yet).*/
  started = "started",
  /** Payment was declined by FAC. */
  declined = "declined",
  /** Payment errored out with FAC. */
  error = "error",
  /** Payment was captured successfully. */
  captured = "captured",
  /** Original payment was successful, but the transaction was cancelled. */
  reversed = "reversed",
  /** This payment is a refund stub. Most likely created manually by an admin for records. */
  refunded = "refunded",
}

type AdminListPaymentRecord = {
  externalId: string;
  policyId: string;
  creditCardTokenId?: string;
  amount: string;
  currency: string;
  status: PaymentRecordStatus;
  receiptNumber?: string;
};

type PaymentRecordsList = {
  records: AdminListPaymentRecord[];
};

type GetPaymentRecordsList = {
  adminPaymentRecordListView: PaymentRecordsList;
};

const policyToolsFieldName = "AdminPolicyDetailView-PolicyTools";

const getBooleanString = (bool?: boolean) => {
  if (bool === undefined) {
    return "N/A";
  } else if (bool) {
    return "Yes";
  } else {
    return "No";
  }
};

const getNullableString = (str?: string) => {
  if (!str) {
    return "N/A";
  } else {
    return str;
  }
};

const AdminPolicyDetailView = () => {
  //States
  const [policy, setPolicy] = useState<PolicyDetails | undefined>(undefined);
  const [paymentRecords, setPaymentRecords] = useState<
    PaymentRecordsList | undefined
  >(undefined);

  const [selectedTool, setSelectedTool] = useState<PolicyTool | undefined>(
    undefined
  );
  const [successMessage, setSuccessMessage] = useState<string | undefined>(
    undefined
  );

  const [showPolicyDetailsCard, setShowPolicyDetailsCard] = useState<boolean>(
    false
  );
  const [showDocumentsCard, setShowDocumentsCard] = useState<boolean>(false);
  const [showPaymentsCard, setShowPaymentsCard] = useState<boolean>(false);
  const [showCardNumberCard, setShowCardNumberCard] = useState<boolean>(false);
  const [
    showPortalChecklistCard,
    setShowPortalChecklistCard,
  ] = useState<boolean>(false);
  const [showPaymentRecordsCard, setShowPaymentRecordsCard] = useState<boolean>(
    false
  );
  const [showRenewalHistoryCard, setShowRenewalHistoryCard] = useState<boolean>(
    false
  );

  //Contexts
  const auth0Context = useAuth0Context();
  const history = useHistory();

  //Constants
  const policyToolsFieldState = useCustomFieldState(policyToolsFieldName);
  const policyToolsFieldApi = useCustomFieldApi(policyToolsFieldName);

  const policyTools: PolicyTool[] = [
    {
      displayName: "Cancel Policy",
      element: AdminCancelPolicy,
      allowedRoles: [UserRoleType.admin, UserRoleType.cxo],
    },
    {
      displayName: "Register Physical Payment",
      element: AdminRegisterPhysicalPayment,
      allowedRoles: [UserRoleType.admin, UserRoleType.cxo],
    },
    {
      displayName: "Register Manual Refund",
      element: AdminRegisterManualRefund,
      allowedRoles: [UserRoleType.admin, UserRoleType.cxo],
    },
    {
      displayName: "Charge Single Payment",
      element: AdminManualSinglePayment,
      allowedRoles: [UserRoleType.admin, UserRoleType.cxo],
    },
    {
      displayName: "Mark First Payment Successful",
      element: AdminMarkFirstPaymentSuccessful,
      allowedRoles: [UserRoleType.admin],
    },
    {
      displayName: "Regenerate Contract",
      element: AdminRegenerateContract,
      allowedRoles: [UserRoleType.admin, UserRoleType.cxo],
    },
    {
      displayName: "Regenerate Cover Note",
      element: AdminRegenerateCoverNote,
      allowedRoles: [UserRoleType.admin, UserRoleType.cxo],
    },
    {
      displayName: "Update Premium",
      element: AdminUpdatePolicyPremium,
      allowedRoles: [UserRoleType.admin, UserRoleType.cxo],
    },
    {
      displayName: "Update Webstar",
      element: AdminUpdateWebstarPolicies,
      allowedRoles: [UserRoleType.admin],
    },
    {
      displayName: "Force Document Sync",
      element: AdminForceDocumentSync,
      allowedRoles: [UserRoleType.admin, UserRoleType.cxo],
    },
    {
      displayName: "Insert Renewal Data",
      element: AdminInsertRenewalData,
      allowedRoles: [UserRoleType.admin, UserRoleType.cxo],
    },
    {
      displayName: "Process Renewal",
      element: AdminProcessRenewal,
      allowedRoles: [UserRoleType.admin],
    },
  ];

  const params: {
    policyId: string | undefined;
  } = useParams();

  //Queries
  const { loading, data, error, refetch } = useQuery<GetPolicyDetails>(
    GET_POLICY_DETAILS,
    {
      variables: {
        policyId: params.policyId,
      },
      fetchPolicy: "no-cache",
      skip: !params.policyId,
    }
  );

  const {
    data: paymentRecordsData,
    refetch: paymentRecordsRefetch,
  } = useQuery<GetPaymentRecordsList>(PAYMENT_RECORDS_LIST, {
    variables: {
      policyId: params.policyId,
    },
    fetchPolicy: "no-cache",
    skip: !params.policyId,
  });

  //Functions
  const handleToolClose = (params: ToolOnCloseProps) => {
    policyToolsFieldApi.setValue("");
    window.scrollTo({ top: 0 });

    if (params.errorCode === "NOT_ADMIN") {
      auth0Context.logout();
    }

    if (params.success && params.message) {
      setSuccessMessage(params.message);
    }

    if (params.shouldReload) {
      refetch();
      paymentRecordsRefetch();
    }
  };

  const getDateString = (date: Date) => {
    return new Date(date).toLocaleDateString();
  };

  const getHumanDateString = (date: Date) => {
    return format(new Date(date), "MMMM dd, yyyy");
  };

  const getPaymentColor = (status: string) => {
    switch (status) {
      case "paid":
        return "green";
      case "late":
        return "red";
      case "cancelled":
        return "grey";
      default:
        return "black";
    }
  };

  const getPolicyToolComponent = (tool: PolicyTool) => {
    const SelectedTool = tool.element;

    return (
      <SelectedTool
        identifier={params.policyId ?? ""}
        onClose={handleToolClose}
      />
    );
  };

  const [toggleRenewal] = useMutation(TOGGLE_RENEWAL);

  const handleToggleRenewal = useCallback(async () => {
    try {
      const response = await toggleRenewal({
        variables: {
          policyId: policy?.policyId,
          allowPolicyRenewal: !policy?.renewal?.almiApproved,
        },
      });

      if (response?.data?.toggleAlmiAllowRenewal) {
        window.location.reload();
      } else {
        throw new Error("Failed to toggle almi renewal status.");
      }
    } catch (error) {
      throw new Error("Failed to toggle almi renewal status.");
    }
  }, [policy, toggleRenewal]);

  const toggleRenewalButton = useMemo(() => {
    return (
      <Button type="button" text variant="text" onClick={handleToggleRenewal}>
        {policy?.renewal?.almiApproved ? "Deny" : "Approve"}
      </Button>
    );
  }, [policy, handleToggleRenewal]);

  //Memos
  const policyTypeTitle = React.useMemo(() => {
    if (!policy?.coverageType) {
      return "";
    }

    const coverage = getTypeOfCoverage(policy.coverageType);
    return coverage.title;
  }, [policy]);

  const policyTypeSubTitle = React.useMemo(() => {
    if (!policy?.coverageType) {
      return "";
    }

    const coverage = getTypeOfCoverage(policy.coverageType);
    return coverage.subTitle;
  }, [policy]);

  const policyImageLink = React.useMemo(() => {
    if (policy) {
      if (policy.policyType === "auto") {
        switch (policy.vehicle.type) {
          case "Car":
            return CarImg;
          case "Truck":
            return TruckImg;
          case "Motorcycle":
            return BikeImg;
          default:
            return "";
        }
      } else {
        return HomeImg;
      }
    }

    return "";
  }, [policy]);

  const policyToolsMap = React.useMemo(() => {
    const roles = auth0Context.getRolesFromToken();

    return policyTools.map((tool) => {
      if (
        tool.allowedRoles.filter((roleName) => {
          return roles.includes(roleName);
        }).length > 0
      ) {
        return (
          <Option key={tool.displayName} value={tool.displayName}>
            {tool.displayName}
          </Option>
        );
      } else {
        return <React.Fragment key={tool.displayName}></React.Fragment>;
      }
    });
  }, [auth0Context, policyTools]);

  const paymentScheduleMap = useCallback(
    (policy: PolicyDetails | RenewalHistory) => {
      return !policy ? (
        <></>
      ) : (
        policy.scheduledPayments.map((payment, index) => {
          return (
            <div
              className={styles.ScheduledPayment}
              key={`scheduled-payment-${index}`}
            >
              <H5>
                {getDateString(payment.paymentDate)} (
                {getHumanDateString(payment.paymentDate)})
              </H5>

              <Text>
                <span style={{ color: getPaymentColor(payment.status) }}>
                  {formatCurrency(Number(payment.amount), false)}
                </span>
              </Text>

              <Text>
                <span style={{ color: getPaymentColor(payment.status) }}>
                  {payment.status}
                </span>
              </Text>
            </div>
          );
        })
      );
    },
    []
  );

  const renewalHistoryMap = React.useMemo(() => {
    if (!policy || !policy.renewalHistory) {
      return [];
    }

    return policy.renewalHistory.map((renewal, index) => {
      return (
        <div className={styles.ScheduledPayment} key={`renewal-${index}`}>
          <H5>
            Start: {getDateString(renewal.startDate)} (
            {getHumanDateString(renewal.startDate)})
          </H5>

          <H5>
            End: {getDateString(renewal.endDate)} (
            {getHumanDateString(renewal.endDate)})
          </H5>

          <Text>
            <span>{formatCurrency(Number(renewal.premium), false)}</span>
          </Text>
          <H5>Scheduled Payments: </H5>
          <div style={{ paddingLeft: "10px" }}>
            {paymentScheduleMap(renewal)}
          </div>
        </div>
      );
    });
  }, [policy, paymentScheduleMap]);

  const paymentRecordsMap = React.useMemo(() => {
    return !paymentRecords ? (
      <></>
    ) : (
      paymentRecords.records.map((payment, index) => {
        return (
          <div
            className={styles.ScheduledPayment}
            key={`payment-record${index}`}
          >
            {index > 0 && <br />}
            <H5>Payment ID</H5>
            <Text>{payment.externalId}</Text>
            <H5>Amount</H5>
            <Text>{formatCurrency(Number(payment.amount), false)}</Text>

            <H5>Status</H5>
            <Text>{payment.status}</Text>

            {payment.receiptNumber && (
              <>
                <H5>Reciept Number </H5>
                <Text>{payment.receiptNumber}</Text>
              </>
            )}
          </div>
        );
      })
    );
  }, [paymentRecords]);

  //UseEffects
  React.useEffect(() => {
    setSelectedTool(
      policyTools.find((tool) => {
        return tool.displayName === policyToolsFieldState.value;
      })
    );
    //Including policyTools in the deps causes a looping issue
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyToolsFieldState.value]);

  React.useEffect(() => {
    if (data && data.adminGetPolicyDetails) {
      setPolicy(data.adminGetPolicyDetails);
    }
  }, [data]);

  React.useEffect(() => {
    if (
      paymentRecordsData &&
      paymentRecordsData.adminPaymentRecordListView !== undefined
    ) {
      setPaymentRecords(paymentRecordsData.adminPaymentRecordListView);
    }
  }, [paymentRecordsData]);

  const motorChecklist = policy?.portalChecklist?.motor;

  return (
    <>
      {loading && <LoadingWrapper loading />}

      {error && (
        <div className={styles.Content}>
          <Text className={styles.ErrorMessage}>
            Policy could not be retreived.
          </Text>

          <Button
            className={styles.GoBackButton}
            onClick={() => {
              history.goBack();
            }}
          >
            Go Back
          </Button>
        </div>
      )}

      {policy && (
        <div className={styles.Content}>
          <div className={styles.ScreenHeader}>
            <div className={styles.PolicyIDInfo}>
              <img
                className={styles.PolicyImage}
                src={policyImageLink}
                alt={`Icon Policy`}
                aria-hidden
              />

              <Status status={policy.policyStatus} />

              <H3 className={styles.PolicyType}>
                {policy.policyType === "auto" ? "Motor" : "Home"} Policy{" "}
              </H3>

              <H4 className={styles.PolicyID}>{policy.policyId}</H4>
              {policy.webstarId && <H4>Webstar ID - {policy.webstarId}</H4>}

              <Link to={`${ADMIN_CUSTOMER_DETAILS_URL}/${policy.customerId}`}>
                Customer {policy.customerId}
              </Link>
            </div>

            <div>
              <div className={styles.ToolboxContainer}>
                <H4>Policy Toolbox</H4>

                <Select field={policyToolsFieldName}>
                  <Option value="" disabled>
                    Select Tool
                  </Option>

                  {policyToolsMap}
                </Select>
              </div>

              <div className={styles.PolicyInfoSection}>
                <div className={styles.PolicyInfoSubSection}>
                  <H4>Expiry Date</H4>
                  <Text>{getDateString(policy.coverageEndDate)}</Text>
                </div>

                <div className={styles.PolicyInfoSubSection}>
                  <H4>Annual Premium</H4>

                  <Text>
                    {formatCurrency(Number(policy.annualPremium), false)}
                  </Text>
                </div>
              </div>
            </div>

            {successMessage && (
              <H4 className={styles.SuccessMessage}>{successMessage}</H4>
            )}
          </div>

          <div className={styles.CardsContainer}>
            <div className={styles.DetailsCard}>
              <div
                className={styles.CardHeader}
                title="Click to expand/hide"
                onClick={() => {
                  setShowPolicyDetailsCard((prev) => !prev);
                }}
              >
                <H5>{policyTypeSubTitle}</H5>
                <H3>{policyTypeTitle}</H3>
                {showPolicyDetailsCard ? <ArrowUp /> : <ArrowDown />}
              </div>

              {showPolicyDetailsCard && (
                <>
                  <div>
                    <H5>Producer</H5>
                    <Text>{policy.policyProducer}</Text>
                  </div>

                  {policy.renewal && (
                    <>
                      <div>
                        <H5>Underwriter Approved Renewal</H5>

                        <Text>
                          {getBooleanString(policy.renewal.underwriterApproved)}
                        </Text>
                      </div>

                      <div>
                        <H5>Almi Approved Renewal - {toggleRenewalButton}</H5>
                        <Text>
                          {getBooleanString(policy.renewal.almiApproved)}
                        </Text>
                      </div>

                      <div>
                        <H5>Client Approved Renewal</H5>

                        <Text>
                          {getBooleanString(policy.renewal.clientApproved)}
                        </Text>
                      </div>

                      <div>
                        <H5>Renewal Premium</H5>
                        <Text>
                          {policy.renewal?.premium
                            ? formatCurrency(policy.renewal.premium, false)
                            : "N/A"}
                        </Text>
                      </div>
                    </>
                  )}

                  {!policy.renewal && (
                    <div>
                      <H5>No Renewal Data Found</H5>
                    </div>
                  )}

                  <div>
                    <H5>Coverage Period</H5>
                    <Text>{`${getDateString(
                      policy.coverageStartDate
                    )} to ${getDateString(policy.coverageEndDate)}`}</Text>
                  </div>

                  <div>
                    <H5>Original Inception</H5>
                    <Text>{getDateString(policy.originalInception)}</Text>
                  </div>

                  {policy.policyType === "auto" && (
                    <>
                      <div>
                        <H5>Vehicle Value Insured</H5>

                        <Text>
                          {formatCurrency(Number(policy.vehicle.value), false)}
                        </Text>
                      </div>

                      <div>
                        <H5>Vehicle Make/Model/Year</H5>
                        <Text>{`${policy.vehicle.make} / ${policy.vehicle.model} / ${policy.vehicle.year}`}</Text>
                      </div>
                    </>
                  )}

                  {policy.policyType === "home" && (
                    <>
                      <div>
                        <H5>Address</H5>
                        <Text>{policy.home.address}</Text>
                      </div>

                      <div>
                        <H5>Building Type</H5>
                        <Text>{policy.home.buildingType}</Text>
                      </div>

                      <div>
                        <H5>Construction Type</H5>
                        <Text>{policy.home.constructionType}</Text>
                      </div>

                      <div>
                        <H5>Home Value</H5>
                        <Text>{formatCurrency(policy.home.value, false)}</Text>
                      </div>

                      {!!policy.home.contentsValue && (
                        <div>
                          <H5>Contents Value</H5>

                          <Text>
                            {formatCurrency(policy.home.contentsValue, false)}
                          </Text>
                        </div>
                      )}

                      {!!policy.home.allRiskValue && (
                        <>
                          <div>
                            <H5>All Risk Value</H5>
                            <Text>
                              {formatCurrency(policy.home.allRiskValue, false)}
                            </Text>
                          </div>

                          <div>
                            <H5>Is All Risk Worldwide?</H5>

                            <Text>
                              {policy.home.isAllRiskWorldwide ? "Yes" : "No"}
                            </Text>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>

            <div className={styles.DetailsCard}>
              <div
                className={styles.CardHeader}
                title="Click to expand/hide"
                onClick={() => {
                  setShowDocumentsCard((prev) => !prev);
                }}
              >
                <H5>Documentation</H5>
                <H3>Policy Documents</H3>
                {showDocumentsCard ? <ArrowUp /> : <ArrowDown />}
              </div>

              {showDocumentsCard && (
                <>
                  {policy.policyType === "auto" && (
                    <>
                      <AdminDownloadDocument
                        policyId={params.policyId ?? ""}
                        documentType="SIGNED_PROPOSAL"
                        title="Signed Proposal"
                        subTitle="Your signed proposal form"
                      />

                      <AdminDownloadDocument
                        policyId={params.policyId ?? ""}
                        documentType="POLICY_SCHEDULE"
                        title="Policy Schedule"
                        subTitle="Specific terms of coverage"
                      />

                      <AdminDownloadDocument
                        policyId={params.policyId ?? ""}
                        documentType="COVER_NOTE"
                        title="Cover Note"
                        subTitle="Your proof of coverage"
                      />
                    </>
                  )}

                  {policy.policyType === "home" && (
                    <H6>No Documents Available</H6>
                  )}
                </>
              )}
            </div>

            <div className={styles.DetailsCard}>
              <div
                className={styles.CardHeader}
                title="Click to expand/hide"
                onClick={() => {
                  setShowPaymentsCard((prev) => !prev);
                }}
              >
                <H5>Payments Breakdown</H5>
                <H3>Scheduled Payments</H3>
                {showPaymentsCard ? <ArrowUp /> : <ArrowDown />}
              </div>

              {showPaymentsCard && (
                <div className={styles.ScheduledPaymentsBreakdown}>
                  {paymentScheduleMap(policy)}
                </div>
              )}
            </div>

            <div className={styles.DetailsCard}>
              <div
                className={styles.CardHeader}
                title="Click to show/hide"
                onClick={() => {
                  setShowCardNumberCard((prev) => !prev);
                }}
              >
                <H5>Payment Methods</H5>
                <H3>Card Details</H3>
                {showCardNumberCard ? <ArrowUp /> : <ArrowDown />}
              </div>

              {showCardNumberCard && (
                <div>
                  <H5>Card Number</H5>

                  <Text>
                    ・・・・ ・・・・ ・・・・ {policy.finalCardNumbers}
                  </Text>
                </div>
              )}
            </div>

            <div className={styles.DetailsCard}>
              <div
                className={styles.CardHeader}
                title="Click to show/hide"
                onClick={() => {
                  setShowPortalChecklistCard((prev) => !prev);
                }}
              >
                <H5>Post-Payment Information</H5>
                <H3>Portal Checklist</H3>
                {showPortalChecklistCard ? <ArrowUp /> : <ArrowDown />}
              </div>

              {showPortalChecklistCard && (
                <>
                  {policy.portalChecklist ? (
                    <>
                      <div>
                        <H5>Submitted</H5>

                        <Text>
                          {getBooleanString(policy.portalChecklist.submitted)}
                        </Text>
                      </div>

                      <div>
                        <H5>Agreed To Declaration</H5>

                        <Text>
                          {getBooleanString(
                            policy.portalChecklist.agreedToDeclaration
                          )}
                        </Text>
                      </div>

                      <div>
                        <H5>Documents</H5>

                        {policy.portalChecklist.documents.map((document) => {
                          return (
                            <Text
                              key={`${document.documentType}-${document.context}`}
                            >
                              {document.documentType}
                              {document.context ? ` ${document.context}` : ""}
                            </Text>
                          );
                        })}
                        {policy.portalChecklist.documents.length === 0 && (
                          <Text>No Documents</Text>
                        )}
                      </div>

                      {!!motorChecklist && (
                        <>
                          <div>
                            <H5>Additional Drivers</H5>

                            {(motorChecklist.additionalDrivers ?? []).map(
                              (driver, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <H6>Name</H6>
                                    <Text>{driver.name}</Text>

                                    <H6>Age</H6>
                                    <Text>{driver.age}</Text>

                                    <H6>Years of Driving Experience</H6>
                                    <Text>{driver.yearsDrivingExperience}</Text>

                                    <H6>Occupation</H6>
                                    <Text>{driver.occupation}</Text>

                                    <H6>License Number</H6>
                                    <Text>{driver.licenseNumber}</Text>

                                    <H6>License Date of Issue</H6>
                                    <Text>{driver.licenseDateOfIssue}</Text>

                                    <H6>License Type</H6>
                                    <Text>{driver.licenseType}</Text>

                                    <hr style={{ maxWidth: "50px" }} />
                                  </React.Fragment>
                                );
                              }
                            )}
                          </div>

                          <div>
                            <H5>Health Conditions</H5>

                            {(motorChecklist.healthConditions ?? ["None"]).map(
                              (condition) => {
                                return <Text key={condition}>{condition}</Text>;
                              }
                            )}
                          </div>

                          <div>
                            <H5>Prosecutions In Past Five Years</H5>

                            <Text>
                              {getBooleanString(
                                motorChecklist.prosecutionsPastFiveYears
                              )}
                            </Text>
                          </div>

                          <div>
                            <H5>Refused Insurance</H5>

                            <Text>
                              {getBooleanString(
                                motorChecklist.refusedInsurance
                              )}
                            </Text>
                          </div>

                          <div>
                            <H5>Employer Name</H5>

                            <Text>
                              {getNullableString(motorChecklist.employerName)}
                            </Text>
                          </div>

                          <div>
                            <H5>Employer Address</H5>

                            <Text>
                              {getNullableString(
                                motorChecklist.employerAddress
                              )}
                            </Text>
                          </div>

                          <div>
                            <H5>Employer Phone</H5>

                            <Text>
                              {motorChecklist.employerCountryCode}{" "}
                              {motorChecklist.employerPhone}
                            </Text>
                          </div>

                          <div>
                            <H5>Previous Provider</H5>

                            <Text>
                              {getNullableString(
                                motorChecklist.previousProvider
                              )}
                            </Text>
                          </div>

                          <div>
                            <H5>Is Financed</H5>

                            <Text>
                              {getBooleanString(motorChecklist.isFinanced)}
                            </Text>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <div>
                      <H5>No Portal Checklist Data</H5>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className={styles.DetailsCard}>
              <div
                className={styles.CardHeader}
                title="Click to show/hide"
                onClick={() => {
                  setShowPaymentRecordsCard((prev) => !prev);
                }}
              >
                <H5>Transaction History</H5>
                <H3>Payment Records</H3>
                {showPaymentRecordsCard ? <ArrowUp /> : <ArrowDown />}
              </div>

              {showPaymentRecordsCard && <>{paymentRecordsMap}</>}
            </div>

            <div className={styles.DetailsCard}>
              <div
                className={styles.CardHeader}
                title="Click to show/hide"
                onClick={() => {
                  setShowRenewalHistoryCard((prev) => !prev);
                }}
              >
                <H5>Renewal History</H5>
                <H3>Renewal Records</H3>
                {showRenewalHistoryCard ? <ArrowUp /> : <ArrowDown />}
              </div>

              {showRenewalHistoryCard && <>{renewalHistoryMap}</>}
            </div>
          </div>

          <Modal
            title={selectedTool?.displayName}
            isOpen={selectedTool !== undefined}
            shouldOverlayCloseOnClick
            onClose={() => {
              policyToolsFieldApi.setValue("");
            }}
            id="policy-tool-modal"
          >
            {selectedTool !== undefined && getPolicyToolComponent(selectedTool)}
          </Modal>
        </div>
      )}
    </>
  );
};

export default AdminPolicyDetailView;
