import React, { useState, useEffect, useMemo } from "react";
import classnames from "classnames";

import styles from "./index.module.scss";

import { H5, TextSmall } from "~/components/Typography";
import ButtonIcon from "~/components/ButtonIcon";
import Icon from "~/components/Icon";

import { AvailableIcons } from "~/components/Icon/index.types";
import { useAuth0Context, UserRoleType } from "~/contexts/Auth0Context";
import { useMotorContext } from "~/contexts/MotorProvider";
import { useHomeContext } from "~/contexts/HomeProvider";
import { useUserContext } from "~/contexts/UserProvider";
import { useOnboardContext } from "~/contexts/OnboardProvider";
import { Link } from "react-router-dom";
import { ADMIN_DASHBOARD_URL } from "~/routes/index.constant";

type PropsType = {
  className?: string;
  iconLetter: string | undefined;
};

export default function SideBarNavigation({
  className,
  iconLetter = "",
}: PropsType) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const auth0 = useAuth0Context();
  const homeCtx = useHomeContext();
  const motorCtx = useMotorContext();
  const userCtx = useUserContext();
  const onboardCtx = useOnboardContext();

  const linkParams = {
    className: styles.ListItemLink,
    onClick: () => {
      setToggleMenu(!toggleMenu);
    },
  };

  const handleLogout = () => {
    homeCtx.removeStore();
    motorCtx.vehicleInfo.removeStore();
    motorCtx.savingsInfo.removeStore();
    motorCtx.policyInfo.removeStore();
    userCtx.removeStore();
    onboardCtx.removeStore();
  };

  useEffect(() => {
    const handleEscPress = (event: KeyboardEvent) => {
      if (event.key === "Escape" && toggleMenu) {
        setToggleMenu(false);
      }
    };

    const handleClick = (event: MouseEvent) => {
      if (
        !window.document
          .querySelector(
            "#sidebar-wrapper [role='menuitem'], #profile-icon-btn"
          )
          ?.contains(event.target as Node)
      ) {
        setToggleMenu(false);
      }
    };

    window.document.addEventListener("keydown", handleEscPress);
    window.document.addEventListener("click", handleClick);

    return () => {
      window.document.removeEventListener("keydown", handleEscPress);
      window.document.removeEventListener("click", handleClick);
    };
  }, [toggleMenu]);

  const isAdmin = useMemo(() => {
    return auth0.hasPermission([
      UserRoleType.admin,
      UserRoleType.cxo,
      UserRoleType.readonly,
    ]);
  }, [auth0]);

  const isRestrictedAdmin = useMemo(() => {
    return auth0.hasPermission([UserRoleType.admin]);
  }, [auth0]);

  const showAdmin =
    window.location.href.includes(ADMIN_DASHBOARD_URL) && isAdmin;

  const showRestrictedAdmin =
    window.location.href.includes(ADMIN_DASHBOARD_URL) && isRestrictedAdmin;

  return (
    <div className={className}>
      <button
        id="profile-icon-btn"
        className={styles.ProfileIcon}
        type="button"
        onClick={() => setToggleMenu(!toggleMenu)}
        aria-label="profile"
        aria-expanded={toggleMenu}
        aria-controls="profile-menu-list"
        aria-haspopup
        tabIndex={-1}
      >
        {showAdmin ? (
          <Icon name="Menu" backgroundColor="#F6B600" width={25} height={25} />
        ) : (
          <TextSmall>{iconLetter}</TextSmall>
        )}
      </button>

      <div
        id="sidebar-wrapper"
        className={classnames(styles.SidebarNavigationWrapper, {
          [styles.ToggleMenu]: toggleMenu,
        })}
      >
        <ButtonIcon
          iconName="Close"
          className={styles.CloseButton}
          iconBackgroundColor="#F6B600"
          width={32}
          height={32}
          onClick={() => setToggleMenu(!toggleMenu)}
        />

        <ul
          className={styles.List}
          id="profile-menu-list"
          hidden={!toggleMenu}
          role="menu"
        >
          {!showAdmin && (
            <>
              <li className={styles.ListItem} role="menuitem">
                <Link
                  {...linkParams}
                  to="/portal"
                  className={styles.ListItemLink}
                >
                  <Icon name={"House" as AvailableIcons} />
                  <H5 className={styles.ListItemLinkLabel}>Dashboard</H5>
                </Link>
              </li>

              <li className={styles.ListItem} role="menuitem">
                <Link
                  {...linkParams}
                  to="/portal/account"
                  className={styles.ListItemLink}
                >
                  <Icon name={"User" as AvailableIcons} />
                  <H5 className={styles.ListItemLinkLabel}>My Account</H5>
                </Link>
              </li>

              {isAdmin && (
                <li className={styles.ListItem} role="menuitem">
                  <Link
                    {...linkParams}
                    to="/portal/admin"
                    className={styles.ListItemLink}
                  >
                    <Icon
                      name={"Info" as AvailableIcons}
                      className={styles.AdminLinkIcon}
                    />

                    <H5 className={styles.ListItemLinkLabel}>
                      Admin Dashboard
                    </H5>
                  </Link>
                </li>
              )}

              <li className={styles.ListItem} role="menuitem">
                <a
                  {...linkParams}
                  href="https://almi.bb/faq/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.ListItemLink}
                >
                  <Icon name={"Question" as AvailableIcons} />
                  <H5 className={styles.ListItemLinkLabel}>FAQ</H5>
                </a>
              </li>
            </>
          )}

          {showAdmin && (
            <>
              <li className={styles.ListItem} role="menuitem">
                <Link
                  {...linkParams}
                  to={ADMIN_DASHBOARD_URL}
                  className={styles.ListItemLink}
                >
                  <Icon name={"MagnifyingGlass" as AvailableIcons} />
                  <H5 className={styles.ListItemLinkLabel}>Search</H5>
                </Link>
              </li>

              <li className={styles.ListItem} role="menuitem">
                <Link
                  {...linkParams}
                  to={`${ADMIN_DASHBOARD_URL}/policies`}
                  className={styles.ListItemLink}
                >
                  <Icon
                    name={"ArrowRight" as AvailableIcons}
                    width={37}
                    height={37}
                  />

                  <H5 className={styles.ListItemLinkLabel}>Policy Portfolio</H5>
                </Link>
              </li>

              <li className={styles.ListItem} role="menuitem">
                <Link
                  {...linkParams}
                  to={`${ADMIN_DASHBOARD_URL}/customers`}
                  className={styles.ListItemLink}
                >
                  <Icon
                    name={"ArrowRight" as AvailableIcons}
                    width={37}
                    height={37}
                  />

                  <H5 className={styles.ListItemLinkLabel}>
                    Customer Portfolio
                  </H5>
                </Link>
              </li>

              <li className={styles.ListItem} role="menuitem">
                <Link
                  {...linkParams}
                  to={`${ADMIN_DASHBOARD_URL}/add-vehicle`}
                  className={styles.ListItemLink}
                >
                  <Icon name={"Plus" as AvailableIcons} />
                  <H5 className={styles.ListItemLinkLabel}>Add Vehicle</H5>
                </Link>
              </li>

              {showRestrictedAdmin && (
                <li className={styles.ListItem} role="menuitem">
                  <Link
                    {...linkParams}
                    to={`${ADMIN_DASHBOARD_URL}/actions`}
                    className={styles.ListItemLink}
                  >
                    <Icon name={"Info" as AvailableIcons} />
                    <H5 className={styles.ListItemLinkLabel}>View Actions</H5>
                  </Link>
                </li>
              )}
            </>
          )}

          <li className={styles.ListItem} role="menuitem">
            <a
              {...linkParams}
              onClick={() => {
                linkParams.onClick();
                handleLogout();
                auth0.logout();
              }}
              href="https://almi.bb/"
              className={styles.ListItemLink}
              role="button"
              onKeyPress={() => {}}
              tabIndex={-1}
            >
              <Icon name={"LogOut" as AvailableIcons} />
              <H5 className={styles.ListItemLinkLabel}>Log Out</H5>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
