import { useFieldState } from "informed";
import React from "react";
import Button from "~/components/Button";
import TextInput from "~/components/TextInput";
import cleanNumberInput from "~/helpers/formatters/cleanNumberInput";
import styles from "./index.module.scss";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import hasErrorCode from "~/helpers/hasErrorCode";
import Checkbox from "~/components/Checkbox";
import { ToolProps } from "../..";

enum Field {
  AnnualPremium = "AdminUpdatePolicyPremium-AnnualPremium",
  BccEmail = "AdminUpdatePolicyPremium-BccEmail",
  Passcode = "AdminUpdatePolicyPremium-Passcode",
}

const UPDATE_POLICY_PREMIUM = gql`
  mutation adminUpdatePolicyPremium(
    $policyId: String!
    $annualPremium: Float!
    $sendBccEmail: Boolean!
    $prorate: Boolean!
    $passcode: String!
  ) {
    adminUpdatePolicyPremium(
      policyId: $policyId
      annualPremium: $annualPremium
      sendBccEmail: $sendBccEmail
      prorate: $prorate
      passcode: $passcode
    ) {
      refundAmount
      singleChargeAmount
      newMonthlyAmount
      remainingPaymentCount
    }
  }
`;

const AdminUpdatePolicyPremium = ({ identifier, onClose }: ToolProps) => {
  const annualPremiumState = useFieldState(Field.AnnualPremium);
  const bccEmailState = useFieldState(Field.BccEmail);
  const passcodeState = useFieldState(Field.Passcode);

  const [processing, setProcessing] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );

  const [updatePolicyPremium] = useMutation(UPDATE_POLICY_PREMIUM);

  const handleSubmit = async () => {
    setProcessing(true);

    try {
      const response = await updatePolicyPremium({
        variables: {
          policyId: identifier,
          annualPremium: cleanNumberInput(annualPremiumState.value, true),
          sendBccEmail: !!bccEmailState.value,
          prorate: false,
          passcode: String(passcodeState.value),
        },
      });

      setProcessing(false);

      const data = response?.data?.adminUpdatePolicyPremium;
      let successMessage;

      if (data.refundAmount) {
        successMessage = `Please issue user a refund for $${data.refundAmount}.`;
      } else if (data.singleChargeAmount) {
        successMessage = `User has been charged the amount of $${data.singleChargeAmount}.`;
      } else if (data.newMonthlyAmount) {
        successMessage = `User's monthly payment has been updated to $${data.newMonthlyAmount}.`;
      } else {
        successMessage = "Operation Successful!";
      }

      onClose({
        manuallyClosed: false,
        success: true,
        shouldReload: true,
        message: successMessage,
      });
    } catch (error) {
      if (error instanceof Error) {
        if (hasErrorCode(error, "NOT_ADMIN")) {
          onClose({
            manuallyClosed: false,
            success: false,
            errorCode: "NOT_ADMIN",
          });
        }

        setErrorMessage(error.message);
      }

      setProcessing(false);
    }
  };

  return (
    <div className={styles.Content}>
      {errorMessage && <div className={styles.Error}>{errorMessage}</div>}

      <TextInput
        type="text"
        label="New Annual Premium"
        placeholder="0.00"
        field={Field.AnnualPremium}
        inputMode="decimal"
      />

      <Checkbox
        className={styles.SmallMarginTop}
        field={Field.BccEmail}
        label="Send Me A Copy of The Change Email"
        initialValue
      />

      <TextInput
        type="text"
        label="Passcode"
        placeholder="Passcode"
        field={Field.Passcode}
      />

      <Button
        disabled={
          !annualPremiumState.value || !passcodeState.value || processing
        }
        onClick={handleSubmit}
      >
        {processing ? "Processing..." : "Process"}
      </Button>
    </div>
  );
};

export default AdminUpdatePolicyPremium;
