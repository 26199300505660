import React, { useState } from "react";
import { Bold, H4, Text, TextSmall } from "~/components/Typography";
import Button from "~/components/Button";
import AlertImage from "~/assets/images/alert.png";
import styles from "./index.module.scss";
import TextInput from "~/components/TextInput";
import { Form, FormValues } from "informed";

const NoAccess = (props: {
  handleAccess?: (access: boolean) => void;
  isOffline: boolean;
}) => {
  const [hasError, setHasError] = useState(false);

  const handleSubmit = (values: FormValues) => {
    if (values.accessKey === process.env.REACT_APP_ACCESS_KEY) {
      localStorage.setItem("accessKey", process.env.REACT_APP_ACCESS_KEY);

      if (props.handleAccess) {
        props.handleAccess(true);
      }
    }
    setHasError(true);
  };

  return (
    <div className={styles.Wrapper}>
      <img className={styles.Alert} src={AlertImage} alt="Alert" />

      {props.isOffline && (
        <>
          <H4 className={styles.Heading}>Almi Motor Is Offline</H4>

          <Text className={styles.Text}>
            Almi is currently not accepting new clients, however, coverage on
            existing policies remains unchanged. Existing customers can continue
            to access their accounts via the Almi Portal at
            https://app.almi.com/portal/. Almi apologizes for any inconvenience
            caused. Please contact us at almi.bb/support if you have any
            questions.
          </Text>
        </>
      )}

      {!props.isOffline && (
        <>
          <H4 className={styles.Heading}>Access Denied</H4>

          <Text className={styles.Text}>
            Unfortunately you do not have access to this link. Please enter the
            almi-passphrase to continue, or contact us at{" "}
            <Bold>almi.bb/support</Bold> for access.
          </Text>

          <Form className={styles.Container} onSubmit={handleSubmit}>
            <TextInput
              className={styles.Password}
              field="accessKey"
              placeholder="almi-passphrase"
              type="password"
              autoComplete="current-password"
            />

            {hasError && (
              <TextSmall className={styles.StatusError}>
                Incorrect passphrase.
              </TextSmall>
            )}

            <Button
              id={"NoAccess-ContinueButton"}
              className={styles.ContinueButton}
              type="submit"
            >
              Continue
            </Button>
          </Form>
        </>
      )}
    </div>
  );
};

export default NoAccess;
