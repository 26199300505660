import React, { useCallback, useMemo } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import Button from "../Button";
import { H4, TextSmall, Text, Bold } from "../Typography";
import { format, isAfter } from "date-fns";
import { localDateFromIso, localDateToday } from "~/helpers/dates";

import styles from "./index.module.scss";

type Props = {
  policyId: string;
  clientApprovedRenewal: boolean;
  companyApprovedRenewal: boolean;
  expiryDate: string;
};

const TOGGLE_RENEWAL = gql`
  mutation toggleClientAllowRenewal(
    $policyId: String!
    $allowPolicyRenewal: Boolean!
  ) {
    toggleClientAllowRenewal(
      policyId: $policyId
      allowPolicyRenewal: $allowPolicyRenewal
    )
  }
`;

const PolicyRenewalCard = ({
  policyId,
  clientApprovedRenewal,
  companyApprovedRenewal,
  expiryDate,
}: Props) => {
  const [toggleRenewal] = useMutation(TOGGLE_RENEWAL);

  const expiryDatePassed = useMemo(() => {
    return isAfter(localDateToday(), localDateFromIso(expiryDate));
  }, [expiryDate]);

  const expiryDateText = useMemo(() => {
    return <Bold>{format(localDateFromIso(expiryDate), "MMM dd, yyyy")}</Bold>;
  }, [expiryDate]);

  const renewalHeaderText = useMemo(() => {
    if (!companyApprovedRenewal) {
      return "Renewal Declined";
    } else if (!clientApprovedRenewal) {
      return "Renewal Not Enabled";
    } else {
      return "Renewal Enabled";
    }
  }, [clientApprovedRenewal, companyApprovedRenewal]);

  const handleToggleRenewal = useCallback(async () => {
    try {
      const response = await toggleRenewal({
        variables: {
          policyId,
          allowPolicyRenewal: !clientApprovedRenewal,
        },
      });

      if (response?.data?.toggleClientAllowRenewal) {
        window.location.reload();
      } else {
        throw new Error("Failed to toggle client renewal status.");
      }
    } catch (error) {
      throw new Error("Failed to toggle client renewal status.");
    }
  }, [clientApprovedRenewal, policyId, toggleRenewal]);

  const toggleRenewalButton = useMemo(() => {
    return (
      <Button type="button" text variant="text" onClick={handleToggleRenewal}>
        click here
      </Button>
    );
  }, [handleToggleRenewal]);

  const content = useMemo(() => {
    if (expiryDatePassed) {
      return (
        <Text>
          This policy has expired. If you require a renewal of the policy,
          please contact our support team.
        </Text>
      );
    } else if (!companyApprovedRenewal) {
      return (
        <Text>
          Renewal for your policy has unfortunately been denied.
          <br />
          This policy will permanently expire on {expiryDateText}.
          <br />
          If you have any questions, please contact our support team.
        </Text>
      );
    } else {
      if (clientApprovedRenewal) {
        return (
          <Text>
            This policy&apos;s renewal is scheduled for {expiryDateText}.
            <br />
            If you would like to disable this policy&apos;s renewal, please{" "}
            {toggleRenewalButton}.
          </Text>
        );
      } else {
        return (
          <Text>
            This policy will permanently expire on {expiryDateText}.
            <br />
            Please {toggleRenewalButton} to enable this policy&apos;s renewal.
          </Text>
        );
      }
    }
  }, [
    clientApprovedRenewal,
    companyApprovedRenewal,
    expiryDatePassed,
    expiryDateText,
    toggleRenewalButton,
  ]);

  return (
    <div
      className={`${styles.RenewalCard} ${
        clientApprovedRenewal && companyApprovedRenewal ? styles.Approved : ""
      }`}
    >
      <div className={styles.Heading}>
        <TextSmall>Renewal Status</TextSmall>
        <H4>{renewalHeaderText}</H4>
      </div>

      <div className={styles.Content}>{content}</div>
    </div>
  );
};

export default PolicyRenewalCard;
