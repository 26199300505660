export const PAYMENT_RESULT_STATUS = {
  success: "success",
  failure: "failure",
};

export const NUMBER_STRING = {
  "12": "twelve",
};

export const CONTRACT_STATUS = {
  completed: "completed",
};

export const FILE_TYPES: {
  [key: string]: { accept: string; extension: string };
} = {
  jpg: {
    accept: "image/jpg",
    extension: ".jpg",
  },
  jpeg: {
    accept: "image/jpeg",
    extension: ".jpeg",
  },
  png: {
    accept: "image/png",
    extension: ".png",
  },
  pdf: {
    accept: "application/pdf",
    extension: ".pdf",
  },
};

export const DOCUMENT_TYPES = {
  //Verifiable Documents
  driversLicense: "DRIVERS_LICENSE",
  idCard: "ID_CARD",
  passport: "PASSPORT",
  face: "FACE",
  //Non-verifiable Documents
  licenseSlip: "LICENSE_SLIP",
  //Checklist Documents
  purchaseLetter: "PURCHASE_LETTER",
  registrationCard: "REGISTRATION_CARD",
  assignmentLetter: "ASSIGNMENT_LETTER",
  valuationCertificate: "VALUATION_CERTIFICATE",
  noClaimsDiscountLetter: "NO_CLAIMS_DISCOUNT_LETTER",
  letterOfSettlement: "LETTER_OF_SETTLEMENT",
  proofAddress: "PROOF_ADDRESS",
  roadworthyCertificate: "ROADWORTHY_CERTIFICATE",
};

export const DOCUMENTS_NAMES = {
  DRIVERS_LICENSE: "Driver License",
  ID_CARD: "National ID",
  LICENSE_SLIP: "Driver License Slip",
  PASSPORT: "Passport",
  FACE: "Selfie",
};

export const VERIFIABLE_DOCUMENTS_TYPES = [
  DOCUMENT_TYPES.passport,
  DOCUMENT_TYPES.idCard,
  DOCUMENT_TYPES.face,
];

export const VERIFICATION_STATUS = {
  started: "started",
  approved: "approved",
  resubmissionRequested: "resubmission_requested",
  timeout: "timeout",
  declined: "declined",
  expired: "expired",
  abandoned: "abandoned",
};

export const POLICY_QUOTE_STATUS = {
  quote: "quote",
  pendingEmailVerification: "pending_email_verification",
  pendingEngineChassisNum: "pending_engchasnum",
  pendingOccupation: "pending_occupation",
  pendingDocuments: "pending_documents",
  pendingSigning: "pending_signing",
  pendingPayment: "pending_payment",
};

export const POLICY_COMPLETE_STATUS = {
  pendingSecondaryDocuments: "pending_secondary_documents",
  pendingNewPayment: "pending_new_payment",
  pendingApproval: "pending_approval",
  active: "active",
  cancelled: "cancelled",
  expiringSoon: "expiring_soon",
  expired: "expired",
};

export const POLICY_STATUS = {
  ...POLICY_QUOTE_STATUS,
  ...POLICY_COMPLETE_STATUS,
};

export const PAYMENT_STATUS = {
  registered: "registered",
  approved: "approved",
  declined: "declined",
  error: "error",
  failedRecurrence: "failed_recurrence",
  paymentCancelledPolicyPendingNewPayment:
    "payment_canceled_policy_pending_new_payment",
  paymentCancelledPolicySoonToBeCancelled:
    "payment_canceled_policy_soon_to_be_cancelled",
  cancelledPolicy: "cancelled_policy",
  cancelled: "cancelled",
  completed: "completed",
  noPaymentFound: "no_payment_found",
};

export const UPDATABLE_PAYMENT_STATUS = [
  POLICY_COMPLETE_STATUS.pendingApproval,
  POLICY_COMPLETE_STATUS.active,
  POLICY_COMPLETE_STATUS.expiringSoon,
  POLICY_COMPLETE_STATUS.pendingSecondaryDocuments,
  POLICY_COMPLETE_STATUS.pendingNewPayment,
];

export const LOCAL_PHONE_CODES = ["BRB", "GUY"];

export const CARIB_PHONE_CODES = [
  "AIA",
  "BHS",
  "ABW",
  "CUW",
  "SXM",
  "MAF",
  "BLM",
  "DOM",
  "HTI",
  "PRI",
  "VGB",
  "VIR",
  "CYM",
  "TCA",
  "CUB",
  "DMA",
  "JAM",
  "TTO",
  "KNA",
  "LCA",
  "VCT",
];

export const PARISHES = [
  {
    label: "Christ Church",
    value: "CHRIST CHURCH",
  },
  {
    label: "St. James",
    value: "ST. JAMES",
  },
  {
    label: "St. Lucy",
    value: "ST. LUCY",
  },
  {
    label: "St. Michael",
    value: "ST. MICHAEL",
  },
  {
    label: "St. Peter,",
    value: "ST. PETER",
  },
  {
    label: "St. Thomas",
    value: "ST. THOMAS",
  },
  {
    label: "St. Andrew",
    value: "ST. ANDREW",
  },
  {
    label: "St. George",
    value: "ST. GEORGE",
  },
  {
    label: "St. John",
    value: "ST. JOHN",
  },
  {
    label: "St. Joseph",
    value: "ST. JOSEPH",
  },
  {
    label: "St. Philip",
    value: "ST. PHILIP",
  },
];

export const STREETINDEX = [
  {
    stind: "ABBE03",
    pstcd: "BDCHRCHR",
    strloc: "ABBEY GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ACCO02",
    pstcd: "BDMICBUS",
    strloc: "ACCOMODATION ROAD, BUSH HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ACRE01",
    pstcd: "BDLUCLUC",
    strloc: "ACRE HOUSE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "ADAM02",
    pstcd: "BDMICMIC",
    strloc: "ADAMS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ADAM02",
    pstcd: "BDMICPIN",
    strloc: "ADAMS ROAD, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ADAM03",
    pstcd: "BDCHRCHR",
    strloc: "ADAMS CASTLE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ADAM04",
    pstcd: "BDMICPIN",
    strloc: "ADAMS AVENUE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ADVE03",
    pstcd: "BDMICBAN",
    strloc: "ADVENT AVENUE, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ADVE04",
    pstcd: "BDMICBAN",
    strloc: "ADVENTIST GAP, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "AGAT02",
    pstcd: "BDCHRCHR",
    strloc: "AGATE LANE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "AIRL01",
    pstcd: "BDJAMJAM",
    strloc: "AIRLIE TENANTRY ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "AIRL02",
    pstcd: "BDJAMJAM",
    strloc: "AIRLIE TENANTRY #1, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "AIRL03",
    pstcd: "BDJAMJAM",
    strloc: "AIRLIE TENANTRY #2, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "AIRV01",
    pstcd: "BDLUCLUC",
    strloc: "AIR VIEW, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "AIRY01",
    pstcd: "BDGEOGEO",
    strloc: "AIRY HILL, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "AIRY01",
    pstcd: "BDJOHJOH",
    strloc: "AIRY HILL, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "AIRY01",
    pstcd: "BDJOSJOS",
    strloc: "AIRY HILL, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "AIRY01",
    pstcd: "BDPHIPHI",
    strloc: "AIRY HILL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "AIRY02",
    pstcd: "BDJOSJOS",
    strloc: "AIRY HILL TENANTRY, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "AIRY03",
    pstcd: "BDTHOTHO",
    strloc: "AIRY COT, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "AIRY04",
    pstcd: "BDPHIPHI",
    strloc: "AIRY HALL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "AIRY05",
    pstcd: "BDMICBLA",
    strloc: "AIRY HALL LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "AIRY06",
    pstcd: "BDMICMIC",
    strloc: "AIRY HALL LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ALAM01",
    pstcd: "BDJAMWES",
    strloc: "ALAMANDER RON DRIVE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "ALEX10",
    pstcd: "BDLUCLUC",
    strloc: "ALEXANDRA, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "ALEX11",
    pstcd: "BDLUCLUC",
    strloc: "ALEXANDRIA VILLAGE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "ALKI01",
    pstcd: "BDMICMIC",
    strloc: "ALKINS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ALKI02",
    pstcd: "BDMICMIC",
    strloc: "ALKINS TENANTRY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ALKI03",
    pstcd: "BDMICMIC",
    strloc: "ALKINS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ALLA11",
    pstcd: "BDMICMIC",
    strloc: "ALLAMBY GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ALLE08",
    pstcd: "BDMICBLA",
    strloc: "ALLEYNE'S LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ALLE08",
    pstcd: "BDMICBUS",
    strloc: "ALLEYNE'S LAND, BUSH HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ALLE08",
    pstcd: "BDMICMIC",
    strloc: "ALLEYNE'S LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ALLE09",
    pstcd: "BDMICMIC",
    strloc: "ALLEYNES AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ALLE10",
    pstcd: "BDJOSJOS",
    strloc: "ALLEY ROAD, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "ALLE11",
    pstcd: "BDTHOTHO",
    strloc: "ALLEN VIEW, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "ALLE12",
    pstcd: "BDANDAND",
    strloc: "ALLEYNE SCHOOL GAP, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "ALLE13",
    pstcd: "BDLUCLUC",
    strloc: "ALLEYNEDALE VILLAGE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "ALLE14",
    pstcd: "BDPETPET",
    strloc: "ALLEYNEDALE PLANTATION, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ALLE15",
    pstcd: "BDPETPET",
    strloc: "ALLEYNEDALE TENANTRY, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ALLE16",
    pstcd: "BDMICBUS",
    strloc: "ALLENS LAND, BUSH HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ALLE17",
    pstcd: "BDMICMIC",
    strloc: "ALLEYNES LAND 1ST AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ALLE18",
    pstcd: "BDMICMIC",
    strloc: "ALLEYNES LAND 2ND AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ALLE19",
    pstcd: "BDMICMIC",
    strloc: "ALLEYNES LAND 3RD AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ALLE20",
    pstcd: "BDLUCLUC",
    strloc: "ALLEYNEDALE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "ALLM01",
    pstcd: "BDLUCLUC",
    strloc: "ALLMOND ROAD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "ALLM02",
    pstcd: "BDLUCLUC",
    strloc: "ALLMANS, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "ALLS02",
    pstcd: "BDCHRCHR",
    strloc: "ALLSOP LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ALMO06",
    pstcd: "BDLUCLUC",
    strloc: "ALMOND COT, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "ALMO07",
    pstcd: "BDCHRCHR",
    strloc: "ALMOND TREE ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ALMO08",
    pstcd: "BDCHRCHR",
    strloc: "ALMOND ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ALMO09",
    pstcd: "BDJAMJAM",
    strloc: "ALMOND ROW, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "ALMS01",
    pstcd: "BDPETPET",
    strloc: "ALMSHOUSE GAP, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ALMS01",
    pstcd: "BDPETSPE",
    strloc: "ALMSHOUSE GAP, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ALTO02",
    pstcd: "BDMICPIN",
    strloc: "ALTOS PATH, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "AMBE02",
    pstcd: "BDCHRCHR",
    strloc: "AMBER LANE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "AMEN01",
    pstcd: "BDBGTBGT",
    strloc: "AMENS ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "AMEN01",
    pstcd: "BDBGTBGT",
    strloc: "AMENS ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "AMER02",
    pstcd: "BDJAMSUN",
    strloc: "AMERELLIS ROW, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "AMIT01",
    pstcd: "BDCHRCHR",
    strloc: "AMITY LODGE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ANDR09",
    pstcd: "BDJOSJOS",
    strloc: "ANDREWS TENANTRY, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "ANGE03",
    pstcd: "BDJAMSUN",
    strloc: "ANGELICA AVENUE, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "ANST01",
    pstcd: "BDMICMIC",
    strloc: "ANSTEL LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ANST01",
    pstcd: "BDMICPIN",
    strloc: "ANSTEL LANE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ANTE01",
    pstcd: "BDMICMIC",
    strloc: "ANTEM GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "APES01",
    pstcd: "BDANDAND",
    strloc: "APES HILL, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "APES02",
    pstcd: "BDJAMJAM",
    strloc: "APES HILL TEN PLTN, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "APES03",
    pstcd: "BDJAMJAM",
    strloc: "APES HILL HSG AREA DEVELOPMENT, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "APES04",
    pstcd: "BDJAMJAM",
    strloc: "APES HILL, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "APPL02",
    pstcd: "BDCHRCHR",
    strloc: "APPLEGROVE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "APPL02",
    pstcd: "BDMICBLA",
    strloc: "APPLEGROVE, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "APPL02",
    pstcd: "BDPETPET",
    strloc: "APPLEGROVE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "APPL03",
    pstcd: "BDGEOGEO",
    strloc: "APPLEWHAITES, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "APPL03",
    pstcd: "BDTHOTHO",
    strloc: "APPLEWHAITES, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "APPL04",
    pstcd: "BDTHOTHO",
    strloc: "APPLEWHAITES ESTATE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "APPL05",
    pstcd: "BDGEOGEO",
    strloc: "APPLEWHAITES TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "APPL06",
    pstcd: "BDCHRCHR",
    strloc: "APPLEGROVE PARK, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "APPL07",
    pstcd: "BDPHIPHI",
    strloc: "APPLE HALL DEVELOPMENT, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "APPL08",
    pstcd: "BDPHIPHI",
    strloc: "APPLE HALL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "APPL09",
    pstcd: "BDPHIPHI",
    strloc: "NR. APPLE HALL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "APPL10",
    pstcd: "BDJAMJAM",
    strloc: "APPLEBY ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "APPL11",
    pstcd: "BDJAMJAM",
    strloc: "APPLEBY GARDENS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "APPL12",
    pstcd: "BDJAMHUS",
    strloc: "APPLE WALK, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "APPL13",
    pstcd: "BDPHIPHI",
    strloc: "APPLE HALL TERRACE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "APPL18",
    pstcd: "BDTHOTHO",
    strloc: "APPLEGROVE ESTATE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "AQUA03",
    pstcd: "BDMICMIC",
    strloc: "AQUATIC GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ARAW01",
    pstcd: "BDCHRCHR",
    strloc: "ARAWAK ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ARCH03",
    pstcd: "BDLUCLUC",
    strloc: "ARCHER ROAD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "ARCH09",
    pstcd: "BDMICMIC",
    strloc: "ARCH COT TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ARCH10",
    pstcd: "BDTHOTHO",
    strloc: "ARCH HALL, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "ARCH11",
    pstcd: "BDLUCLUC",
    strloc: "ARCHERS BAY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "ARCH12",
    pstcd: "BDPETSPE",
    strloc: "ARCHERS LANE, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ARDE02",
    pstcd: "BDCHRCHR",
    strloc: "ARDENES, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ARDE03",
    pstcd: "BDMICPIN",
    strloc: "ARDEN CLOSE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ARIS01",
    pstcd: "BDTHOTHO",
    strloc: "ARISE VILLAGE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "ARMS01",
    pstcd: "BDCHRFRE",
    strloc: "ARMSTRONG DRIVE, FRERE PILGRIM - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "AROU01",
    pstcd: "BDPETSPE",
    strloc: "AROUND THE TOWN, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ARSE01",
    pstcd: "BDTHOTHO",
    strloc: "ARSENAL PLAYING FIELD ROAD, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "ARTH02",
    pstcd: "BDTHOTHO",
    strloc: "ARTHUR STREET, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "ARTH03",
    pstcd: "BDGEOGEO",
    strloc: "ARTHUR ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "ARTH03",
    pstcd: "BDMICMIC",
    strloc: "ARTHUR ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ARTH04",
    pstcd: "BDMICMIC",
    strloc: "ARTHUR LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ARTH05",
    pstcd: "BDMICMIC",
    strloc: "ARTHURS HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ARTH06",
    pstcd: "BDMICMIC",
    strloc: "ARTHURS LAND 1ST AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ARTH07",
    pstcd: "BDMICMIC",
    strloc: "ARTHURS LAND 2ND AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ARTH08",
    pstcd: "BDMICMIC",
    strloc: "ARTHURS LAND 3RD AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ARTH09",
    pstcd: "BDTHOTHO",
    strloc: "ARTHUR SEAT ROAD, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "ARTH10",
    pstcd: "BDMICMIC",
    strloc: "ARTHURS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ASHB03",
    pstcd: "BDGEOGEO",
    strloc: "ASHBURY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "ASHB04",
    pstcd: "BDGEOGEO",
    strloc: "ASHBURY TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "ASHB05",
    pstcd: "BDGEOGEO",
    strloc: "ASHBURY PLANTATION, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "ASHB06",
    pstcd: "BDCHRCHR",
    strloc: "ASHBYS LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ASHD01",
    pstcd: "BDMICBLA",
    strloc: "ASHDEANE VILLAGE, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ASHF02",
    pstcd: "BDJOHJOH",
    strloc: "ASHFORD PLANTATION, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "ASHF03",
    pstcd: "BDJOHJOH",
    strloc: "ASHFORD TENANTRY, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "ASHF03",
    pstcd: "BDTHOTHO",
    strloc: "ASHFORD TENANTRY, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "ASHF04",
    pstcd: "BDTHOTHO",
    strloc: "ASHFORD, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "ASHL03",
    pstcd: "BDJAMJAM",
    strloc: "ASHLEY PARK DEVELOPMENT, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "ASHL04",
    pstcd: "BDJAMJAM",
    strloc: "ASHLEY PARK, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "ASHT01",
    pstcd: "BDPETPET",
    strloc: "ASHTON, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ASHT02",
    pstcd: "BDPETPET",
    strloc: "ASHTON HALL VILLAGE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ASHT03",
    pstcd: "BDPETPET",
    strloc: "ASHTON HALL, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ASTO01",
    pstcd: "BDGEOGEO",
    strloc: "ASTORIA, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "ATLA02",
    pstcd: "BDPHIPHI",
    strloc: "ATLANTIC VIEW, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "ATLA03",
    pstcd: "BDCHRATL",
    strloc: "ATLANTIC SHORES, ATLANTIC SHORES -CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ATLA04",
    pstcd: "BDPHIPHI",
    strloc: "ATLANTIC PARK, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "AUBU01",
    pstcd: "BDJOSJOS",
    strloc: "AUBURN, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "AUST04",
    pstcd: "BDMICMIC",
    strloc: "AUSTIN DRIVE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "AVIS01",
    pstcd: "BDLUCLUC",
    strloc: "AVIS TOWN, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "AVON02",
    pstcd: "BDMICPIN",
    strloc: "AVON LANE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "AYSH01",
    pstcd: "BDTHOTHO",
    strloc: "AYSHFORD, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BABB01",
    pstcd: "BDLUCLUC",
    strloc: "BABBS ROAD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "BABB02",
    pstcd: "BDLUCLUC",
    strloc: "BABBS, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "BABY01",
    pstcd: "BDANDAND",
    strloc: "BABYLON ROAD, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "BACH04",
    pstcd: "BDBGTBGT",
    strloc: "BACHELOR'S LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BACH04",
    pstcd: "BDBGTBGT",
    strloc: "BACHELOR'S LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BACK06",
    pstcd: "BDMICMIC",
    strloc: "BACK IVY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BACK07",
    pstcd: "BDPHIPHI",
    strloc: "BACK VILLAGE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BAGA03",
    pstcd: "BDTHOTHO",
    strloc: "BAGATELLE HOUSING AREA, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BAGA04",
    pstcd: "BDTHOTHO",
    strloc: "BAGATELLE GARDENS, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BAGA05",
    pstcd: "BDJAMJAM",
    strloc: "BAGATELLE PARK, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BAGA06",
    pstcd: "BDJAMJAM",
    strloc: "BAGATELLE PARK 2ND AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BAGA06",
    pstcd: "BDTHOTHO",
    strloc: "BAGATELLE PARK 2ND AVENUE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BAGA07",
    pstcd: "BDJAMJAM",
    strloc: "BAGATELLE PARK 3RD AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BAGA07",
    pstcd: "BDTHOTHO",
    strloc: "BAGATELLE PARK 3RD AVENUE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BAGA08",
    pstcd: "BDJAMJAM",
    strloc: "BAGATELLE TERRACE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BAGA08",
    pstcd: "BDTHOTHO",
    strloc: "BAGATELLE TERRACE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BAGA09",
    pstcd: "BDTHOTHO",
    strloc: "BAGATELLE TERRACE, 1ST AVENUE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BAGA10",
    pstcd: "BDTHOTHO",
    strloc: "BAGATELLE YARD GAP, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BAGA11",
    pstcd: "BDTHOTHO",
    strloc: "BAGATELLE MAIN ROAD, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BAGA12",
    pstcd: "BDTHOTHO",
    strloc: "BAGATELLE TERRACE, 4TH AVENUE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BAGA13",
    pstcd: "BDTHOTHO",
    strloc: "BAGATELLE TERRACE, 5TH AVENUE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BAGA14",
    pstcd: "BDTHOTHO",
    strloc: "BAGATELLE TERRACE, 6TH AVENUE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BAGA15",
    pstcd: "BDTHOTHO",
    strloc: "BAGATELLE TERRACE, 7TH AVENUE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BAGA16",
    pstcd: "BDTHOTHO",
    strloc: "BAGATELLE TERRACE, 8TH AVENUE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BAGA17",
    pstcd: "BDTHOTHO",
    strloc: "BAGATELLE TERRACE, 9TH AVENUE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BAGA18",
    pstcd: "BDTHOTHO",
    strloc: "BAGATELLE TERRACE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BAGA24",
    pstcd: "BDTHOTHO",
    strloc: "BAGATELLE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BAGA25",
    pstcd: "BDJAMJAM",
    strloc: "BAGATELLE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BAIL02",
    pstcd: "BDPETPET",
    strloc: "BAILEY ROAD, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BAIR01",
    pstcd: "BDGEOGEO",
    strloc: "BAIRDS VILLAGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BAIR02",
    pstcd: "BDJAMJAM",
    strloc: "BAIRDS ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BAKE03",
    pstcd: "BDPETPET",
    strloc: "BAKERS (LOWER), ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BAKE04",
    pstcd: "BDPETPET",
    strloc: "BAKERS (UPPER), ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BAKE05",
    pstcd: "BDPETPET",
    strloc: "BAKERS PLANTATION, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BAKE06",
    pstcd: "BDPETPET",
    strloc: "BAKERS UPPER TENANTRY, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BAKE07",
    pstcd: "BDJAMJAM",
    strloc: "BAKER'S DEVELOPMENT, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BAKE07",
    pstcd: "BDPETPET",
    strloc: "BAKER'S DEVELOPMENT, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BAKE07",
    pstcd: "BDPHIPHI",
    strloc: "BAKER'S DEVELOPMENT, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BAKE08",
    pstcd: "BDPETPET",
    strloc: "BAKERS WOODS, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BALC02",
    pstcd: "BDCHRCHR",
    strloc: "BALCONY ROCK, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BALD02",
    pstcd: "BDCHRCHR",
    strloc: "BALDSOM LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BALL08",
    pstcd: "BDCHRCHR",
    strloc: "BALLS LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BALL09",
    pstcd: "BDCHRCHR",
    strloc: "BALLS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BALL10",
    pstcd: "BDCHRCHR",
    strloc: "BALLS PLANTATON, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BALL11",
    pstcd: "BDCHRCHR",
    strloc: "BALLS, SOUTHERN HEIGHTS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BALM03",
    pstcd: "BDCHRCHR",
    strloc: "BALMORAL GAP, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BALT02",
    pstcd: "BDPETPET",
    strloc: "BALTIC, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BAMB08",
    pstcd: "BDCHRCHR",
    strloc: "BAMBOO GAP, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BAMB08",
    pstcd: "BDMICMIC",
    strloc: "BAMBOO GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BAMB09",
    pstcd: "BDMICMIC",
    strloc: "BAMBOO ALLEY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BAMB10",
    pstcd: "BDJAMJAM",
    strloc: "BAMBOO RIDGE DEVELOPMENT, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BAMB11",
    pstcd: "BDJAMJAM",
    strloc: "BAMBOO RIDGE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BANA02",
    pstcd: "BDPHIPHI",
    strloc: "BANANA HILL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BANK02",
    pstcd: "BDMICBAN",
    strloc: "BANK HALL CROSS ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BANK03",
    pstcd: "BDMICPIN",
    strloc: "BANK SIDE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BANK04",
    pstcd: "BDMICBAN",
    strloc: "BANK HALL AVENUE 1, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BANK05",
    pstcd: "BDMICBAN",
    strloc: "BANK HALL AVENUE 2, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BANK06",
    pstcd: "BDMICBAN",
    strloc: "BANK HALL MAIN ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BANK07",
    pstcd: "BDMICBAN",
    strloc: "BANK HALL, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BANN02",
    pstcd: "BDCHRCHR",
    strloc: "BANNATYNE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BANN03",
    pstcd: "BDCHRCHR",
    strloc: "BANNATYNE GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BANN04",
    pstcd: "BDMICMIC",
    strloc: "BANNISTER LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BARB03",
    pstcd: "BDMICMIC",
    strloc: "BARBAREES HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BARC02",
    pstcd: "BDMICMIC",
    strloc: "BARCLAYS TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BARC03",
    pstcd: "BDANDAND",
    strloc: "BARCLAYS PARK, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "BARK04",
    pstcd: "BDTHOTHO",
    strloc: "BARKERS, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BARK05",
    pstcd: "BDJAMJAM",
    strloc: "BARKERS GAP, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BARK05",
    pstcd: "BDMICMIC",
    strloc: "BARKERS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BARK06",
    pstcd: "BDMICMIC",
    strloc: "BARKERS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BARR05",
    pstcd: "BDMICBAN",
    strloc: "BARRACKS ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BARR06",
    pstcd: "BDLUCLUC",
    strloc: "BARROWS ROAD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "BARR07",
    pstcd: "BDLUCLUC",
    strloc: "BARROWS, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "BARR08",
    pstcd: "BDCHRCHR",
    strloc: "BARRACK HILL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BART05",
    pstcd: "BDCHRCHR",
    strloc: "BARTLETTS TENANTRY, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BASC02",
    pstcd: "BDJOHJOH",
    strloc: "BASCOM, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "BASC03",
    pstcd: "BDPHIPHI",
    strloc: "BASCID, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BAST02",
    pstcd: "BDPETSPE",
    strloc: "BASTARD ALLEY, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BATH02",
    pstcd: "BDJOHJOH",
    strloc: "BATH HILL, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "BATH03",
    pstcd: "BDJOHJOH",
    strloc: "BATH LAND, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "BATH04",
    pstcd: "BDJOHJOH",
    strloc: "BATH BEACH, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "BATH05",
    pstcd: "BDJOSJOS",
    strloc: "BATHSHEBA, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "BATH06",
    pstcd: "BDCHRCHR",
    strloc: "BATH VILLAGE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BATH07",
    pstcd: "BDJOHJOH",
    strloc: "BATH, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "BATT07",
    pstcd: "BDPETSPE",
    strloc: "BATTLEYS PLANTATION, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BATT08",
    pstcd: "BDPETPET",
    strloc: "BATTALEYS TENANTRY, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BATT09",
    pstcd: "BDPETPET",
    strloc: "BATTALEYS, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BATT09",
    pstcd: "BDPETSPE",
    strloc: "BATTALEYS, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BATT10",
    pstcd: "BDJAMJAM",
    strloc: "BATTS ROCK, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BAWD01",
    pstcd: "BDANDAND",
    strloc: "BAWDENS, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "BAXT02",
    pstcd: "BDANDAND",
    strloc: "BAXTERS ROAD, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "BAXT02",
    pstcd: "BDBGTBGT",
    strloc: "BAXTERS ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BAXT02",
    pstcd: "BDBGTBGT",
    strloc: "BAXTERS ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BAXT02",
    pstcd: "BDBGTBGT",
    strloc: "BAXTERS ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BAXT03",
    pstcd: "BDANDAND",
    strloc: "BAXTERS HILL, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "BAXT04",
    pstcd: "BDANDAND",
    strloc: "BAXTERS PLANTATION, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "BAXT05",
    pstcd: "BDANDAND",
    strloc: "BAXTERS, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "BAY 04",
    pstcd: "BDMICMIC",
    strloc: "BAY GARDENS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BAY 05",
    pstcd: "BDBGTBGT",
    strloc: "BAY STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BAY 05",
    pstcd: "BDBGTBGT",
    strloc: "BAY STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BAY 05",
    pstcd: "BDBGTBGT",
    strloc: "BAY STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BAY 05",
    pstcd: "BDBGTBGT",
    strloc: "BAY STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BAY 05",
    pstcd: "BDBGTBGT",
    strloc: "BAY STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BAY 05",
    pstcd: "BDBGTBGT",
    strloc: "BAY STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BAY 05",
    pstcd: "BDMICMIC",
    strloc: "BAY STREET, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BAYA02",
    pstcd: "BDJAMSUN",
    strloc: "BAYAN ROW, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BAYC01",
    pstcd: "BDMICMIC",
    strloc: "BAYCROFT ALLEY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BAYC02",
    pstcd: "BDMICMIC",
    strloc: "BAYCROFT NEW ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BAYC03",
    pstcd: "BDMICMIC",
    strloc: "BAYCROFT ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BAYF01",
    pstcd: "BDPHIPHI",
    strloc: "BAYFIELD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BAYL01",
    pstcd: "BDPHIPHI",
    strloc: "BAYLEYS ROAD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BAYL02",
    pstcd: "BDPHIPHI",
    strloc: "BAYLEYS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BAYL03",
    pstcd: "BDPHIPHI",
    strloc: "BAYLEYS PLANTATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BAYN01",
    pstcd: "BDMICMIC",
    strloc: "BAYNES GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BAYS01",
    pstcd: "BDMICMIC",
    strloc: "BAYS WATER GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BAYV01",
    pstcd: "BDMICMIC",
    strloc: "BAYVIEW AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BAYV02",
    pstcd: "BDMICMIC",
    strloc: "BAYVILLE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BAYW01",
    pstcd: "BDJAMJAM",
    strloc: "BAYWOOD BOTTOM, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BAYW02",
    pstcd: "BDJAMJAM",
    strloc: "BAYWOOD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BDOS01",
    pstcd: "BDBGTBGT",
    strloc: "BDOS TEST STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BEAC04",
    pstcd: "BDJOSJOS",
    strloc: "BEACHMONT, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "BEAC05",
    pstcd: "BDPHIPHI",
    strloc: "BEACHY HEAD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BEAC06",
    pstcd: "BDCHRCHR",
    strloc: "BEACON, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BEAC07",
    pstcd: "BDMICMIC",
    strloc: "BEACH PATH, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BEAC07",
    pstcd: "BDMICMIC",
    strloc: "BEACH PATH, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BEAC08",
    pstcd: "BDCHRCHR",
    strloc: "BEACH COURT AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BEAC09",
    pstcd: "BDMICMIC",
    strloc: "BEACON HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BEAC10",
    pstcd: "BDPETPET",
    strloc: "BEACON HILL, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BEAL01",
    pstcd: "BDMICMIC",
    strloc: "BEALES GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BECK05",
    pstcd: "BDJAMJAM",
    strloc: "BECKLES ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BECK05",
    pstcd: "BDMICMIC",
    strloc: "BECKLES ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BECK06",
    pstcd: "BDMICMIC",
    strloc: "BECKLES HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BECK07",
    pstcd: "BDMICMIC",
    strloc: "BECKLES HILL 1ST AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BECK08",
    pstcd: "BDMICMIC",
    strloc: "BECKLES HILL 2ND AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BECK09",
    pstcd: "BDMICMIC",
    strloc: "BECKLES LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BECK10",
    pstcd: "BDJAMJAM",
    strloc: "BECKLES GAP, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BECK11",
    pstcd: "BDMICMIC",
    strloc: "BECKWITH PLACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BEDF01",
    pstcd: "BDMICMIC",
    strloc: "BEDFORD LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BEDF02",
    pstcd: "BDMICMIC",
    strloc: "BEDFORD AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BEDF03",
    pstcd: "BDMICMIC",
    strloc: "BEDFORD LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BEGO03",
    pstcd: "BDJAMJAM",
    strloc: "BEGONIA DRIVE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BELA02",
    pstcd: "BDGEOGEO",
    strloc: "BELAIR, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BELA02",
    pstcd: "BDPHIPHI",
    strloc: "BELAIR, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BELA03",
    pstcd: "BDMICMIC",
    strloc: "BELAIR LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELA04",
    pstcd: "BDPHIPHI",
    strloc: "BELAIR TERRACE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BELF01",
    pstcd: "BDMICMIC",
    strloc: "BELFIELD LANDING SCHEME, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELF02",
    pstcd: "BDMICMIC",
    strloc: "BELFIELD LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELG03",
    pstcd: "BDMICMIC",
    strloc: "BELGRAVE LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELL13",
    pstcd: "BDMICBEL",
    strloc: "BELLEVILLE, BELLEVILLE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELL14",
    pstcd: "BDMICBEL",
    strloc: "BELLEVILLE AVENUE FIRST, BELLEVILLE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELL15",
    pstcd: "BDMICBEL",
    strloc: "BELLEVILLE AVENUE SECOND, BELLEVILLE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELL16",
    pstcd: "BDMICBEL",
    strloc: "BELLEVILLE AVENUE THIRD, BELLEVILLE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELL17",
    pstcd: "BDMICBEL",
    strloc: "BELLEVILLE AVENUE FOURTH, BELLEVILLE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELL18",
    pstcd: "BDMICBEL",
    strloc: "BELLEVILLE AVENUE FIFTH, BELLEVILLE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELL19",
    pstcd: "BDMICBEL",
    strloc: "BELLEVILLE AVENUE SIXTH, BELLEVILLE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELL20",
    pstcd: "BDMICBEL",
    strloc: "BELLEVILLE AVENUE SEVENTH, BELLEVILLE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELL21",
    pstcd: "BDMICBEL",
    strloc: "BELLEVILLE AVENUE EIGHTH, BELLEVILLE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELL22",
    pstcd: "BDMICBEL",
    strloc: "BELLEVILLE AVENUE NINTH, BELLEVILLE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELL23",
    pstcd: "BDMICBEL",
    strloc: "BELLEVILLE AVENUE TENTH, BELLEVILLE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELL24",
    pstcd: "BDMICBEL",
    strloc: "BELLEVILLE AVENUE ELEVENTH, BELLEVILLE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELL25",
    pstcd: "BDJOHJOH",
    strloc: "BELLE HILL, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "BELL26",
    pstcd: "BDJOHJOH",
    strloc: "BELLE FARMS, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "BELL27",
    pstcd: "BDANDAND",
    strloc: "BELLEPLAINE, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "BELL28",
    pstcd: "BDANDAND",
    strloc: "BELLEPLAINE EAST, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "BELL29",
    pstcd: "BDANDAND",
    strloc: "BELLEPLAINE HOUSING AREA, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "BELL30",
    pstcd: "BDGEOGEO",
    strloc: "BELLAMY ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BELL31",
    pstcd: "BDMICMIC",
    strloc: "BELLE ROAD NO. 1, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELL32",
    pstcd: "BDMICMIC",
    strloc: "BELLE ROAD NO. 2, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELL33",
    pstcd: "BDMICMIC",
    strloc: "BELLE LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELL34",
    pstcd: "BDMICMIC",
    strloc: "BELLE TENANTRY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELL35",
    pstcd: "BDMICMIC",
    strloc: "BELLE ROAD NO. 3, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELL36",
    pstcd: "BDJAMJAM",
    strloc: "BELLAIRS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BELL37",
    pstcd: "BDMICMIC",
    strloc: "BELLE PLANTATION, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELL38",
    pstcd: "BDMICMIC",
    strloc: "BELLE GULLY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELL39",
    pstcd: "BDMICMIC",
    strloc: "BELLE GULLY ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELM06",
    pstcd: "BDANDAND",
    strloc: "BELMONT ROAD, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "BELM06",
    pstcd: "BDMICMIC",
    strloc: "BELMONT ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BELM07",
    pstcd: "BDJOHJOH",
    strloc: "BELMONT, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "BELM08",
    pstcd: "BDJOHJOH",
    strloc: "BELMONT LAND, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "BELV02",
    pstcd: "BDMICMIC",
    strloc: "BELVIEW LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BEND01",
    pstcd: "BDPETPET",
    strloc: "BENDS HILL, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BENH01",
    pstcd: "BDANDAND",
    strloc: "BEN HILL, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "BENN07",
    pstcd: "BDTHOTHO",
    strloc: "BENNETTS, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BENN08",
    pstcd: "BDTHOTHO",
    strloc: "BENNETTS TENANTRY, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BENN09",
    pstcd: "BDPETPET",
    strloc: "BENN HILL, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BENN10",
    pstcd: "BDPETPET",
    strloc: "BENNY HALL, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BENN11",
    pstcd: "BDPETPET",
    strloc: "BENNY HALL VILLAGE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BENN12",
    pstcd: "BDJAMJAM",
    strloc: "BENNETTS, ST JAMES, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BENT01",
    pstcd: "BDPHIPHI",
    strloc: "BENTLEY TENANTRY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BENT02",
    pstcd: "BDLUCLUC",
    strloc: "BENTHAMS, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "BENT02",
    pstcd: "BDPHIPHI",
    strloc: "BENTHAMS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BENT04",
    pstcd: "BDCHRCHR",
    strloc: "BENTLEY, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BEQU01",
    pstcd: "BDPHIPHI",
    strloc: "BEQUEST, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BERB01",
    pstcd: "BDJAMJAM",
    strloc: "BERBICE GAP, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BERB02",
    pstcd: "BDJAMJAM",
    strloc: "BERBICE ROAD NO1, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BERB03",
    pstcd: "BDJAMJAM",
    strloc: "BERBICE ROAD NO2, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BERL01",
    pstcd: "BDCHRCHR",
    strloc: "BERLIN ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BERL01",
    pstcd: "BDPHIPHI",
    strloc: "BERLIN ROAD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BERR02",
    pstcd: "BDPHIPHI",
    strloc: "BERRY HILL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BERR03",
    pstcd: "BDPHIPHI",
    strloc: "BERRY GARDENS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BERY02",
    pstcd: "BDCHRCHR",
    strloc: "BERYL LANE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BEST04",
    pstcd: "BDCHRCHR",
    strloc: "BEST ALLEY, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BETH02",
    pstcd: "BDMICMIC",
    strloc: "BETHESADA, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BETH03",
    pstcd: "BDCHRCHR",
    strloc: "BETHANY GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BEUL02",
    pstcd: "BDPHIPHI",
    strloc: "BEULAH, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BIBB01",
    pstcd: "BDMICMIC",
    strloc: "BIBBYS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BIBB02",
    pstcd: "BDMICMIC",
    strloc: "BIBBYS LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BILF01",
    pstcd: "BDJAMJAM",
    strloc: "BILFORMIA ROW, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BILF01",
    pstcd: "BDJAMSUN",
    strloc: "BILFORMIA ROW, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BIRC02",
    pstcd: "BDMICPIN",
    strloc: "BIRCH PATH, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BIRD03",
    pstcd: "BDMICMIC",
    strloc: "BIRDS RIVER ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BIRD04",
    pstcd: "BDMICMIC",
    strloc: "BIRDS RIVER, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BIRD05",
    pstcd: "BDMICMIC",
    strloc: "BIRD HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BISH03",
    pstcd: "BDMICMIC",
    strloc: "BISHOP'S COURT HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BISH04",
    pstcd: "BDLUCLUC",
    strloc: "BISHOP ROAD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "BISH05",
    pstcd: "BDMICMIC",
    strloc: "BISHOP GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BISH06",
    pstcd: "BDLUCLUC",
    strloc: "BISHOP'S TENENATRY ROAD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "BISS04",
    pstcd: "BDJOSJOS",
    strloc: "BISSEX, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "BISS05",
    pstcd: "BDJOSJOS",
    strloc: "BISSEX HILL, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "BJER01",
    pstcd: "BDLUCLUC",
    strloc: "BJERKMANN, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "BLAC11",
    pstcd: "BDJOSJOS",
    strloc: "BLACKMAN, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "BLAC12",
    pstcd: "BDJOSJOS",
    strloc: "BLACKMANS TENANTRY, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "BLAC13",
    pstcd: "BDLUCLUC",
    strloc: "BLACK SAGE ALLEY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "BLAC14",
    pstcd: "BDMICPIN",
    strloc: "BLACK BESS PLANTATION, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BLAC14",
    pstcd: "BDPETPET",
    strloc: "BLACK BESS PLANTATION, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BLAC15",
    pstcd: "BDPETPET",
    strloc: "BLACK BESS, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BLAC16",
    pstcd: "BDPETPET",
    strloc: "BLACK BESS TENANTRY, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BLAC17",
    pstcd: "BDMICBLA",
    strloc: "BLACK ROCK, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BLAC18",
    pstcd: "BDMICMIC",
    strloc: "BLACK SHADDOW LAWN, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BLAC19",
    pstcd: "BDMICMIC",
    strloc: "BLACKMAN LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BLAC20",
    pstcd: "BDMICMIC",
    strloc: "BLACKMAN ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BLAC21",
    pstcd: "BDMICMIC",
    strloc: "BLACKMAN GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BLAD01",
    pstcd: "BDPHIPHI",
    strloc: "BLADES HILL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BLAN05",
    pstcd: "BDMICMIC",
    strloc: "BLANCH GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BLEN02",
    pstcd: "BDGEOGEO",
    strloc: "BLENMAN ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BLEN03",
    pstcd: "BDJAMJAM",
    strloc: "BLENMAN GAP, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BLOO01",
    pstcd: "BDTHOTHO",
    strloc: "BLOOMSBURY DEVELOPMENT, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BLOO02",
    pstcd: "BDTHOTHO",
    strloc: "BLOOMSBURY, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BLOW01",
    pstcd: "BDJAMJAM",
    strloc: "BLOWERS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BLOW02",
    pstcd: "BDJAMJAM",
    strloc: "BLOWERS PLANTATION, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BLUE07",
    pstcd: "BDJAMJAM",
    strloc: "BLUE BELL DRIVE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BLUE11",
    pstcd: "BDCHRCHR",
    strloc: "BLUE WATERS TERRACE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BLUN02",
    pstcd: "BDTHOTHO",
    strloc: "BLUNTS, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BLYT01",
    pstcd: "BDTHOTHO",
    strloc: "BLYTH HILL, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BOAR01",
    pstcd: "BDANDAND",
    strloc: "BOARDED HALL, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "BOAR01",
    pstcd: "BDCHRCHR",
    strloc: "BOARDED HALL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BOAR01",
    pstcd: "BDGEOGEO",
    strloc: "BOARDED HALL, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BOAR02",
    pstcd: "BDCHRCHR",
    strloc: "BOARDED HALL TENANTRY, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BOLT01",
    pstcd: "BDBGTBGT",
    strloc: "BOLTON LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BOLT01",
    pstcd: "BDBGTBGT",
    strloc: "BOLTON LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BONA09",
    pstcd: "BDPHIPHI",
    strloc: "BONAS LAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BONA10",
    pstcd: "BDMICCAV",
    strloc: "BONAIRE LANE, CAVE HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BONH01",
    pstcd: "BDANDAND",
    strloc: "BON HILL, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "BONN04",
    pstcd: "BDMICBRI",
    strloc: "BONNETTS LAND, BRITTONS HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BONN05",
    pstcd: "BDMICBRI",
    strloc: "BONNETTS HOUSING AREA, BRITTONS HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BONN06",
    pstcd: "BDMICBRI",
    strloc: "BONNETTS ROAD, BRITTONS HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BONN07",
    pstcd: "BDMICBRI",
    strloc: "BONNETTS MAIN ROAD, BRITTONS HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BONN08",
    pstcd: "BDMICBRI",
    strloc: "BONNETTS AVENUE, BRITTONS HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BONN09",
    pstcd: "BDMICMIC",
    strloc: "BONNETTS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BONN10",
    pstcd: "BDMICMIC",
    strloc: "BONNETTS PASTURE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BONW01",
    pstcd: "BDJOSJOS",
    strloc: "BONWELL, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "BOSC01",
    pstcd: "BDANDAND",
    strloc: "BOSCOBEL, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "BOSC01",
    pstcd: "BDPETPET",
    strloc: "BOSCOBEL, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BOSC02",
    pstcd: "BDPETPET",
    strloc: "BOSCOBEL VILLAGE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BOSV01",
    pstcd: "BDMICMIC",
    strloc: "BOSVIGO GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BOTT02",
    pstcd: "BDPHIPHI",
    strloc: "BOTTOM BAY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BOTT03",
    pstcd: "BDMICMIC",
    strloc: "BOTTOM CLOSE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BOTT09",
    pstcd: "BDPHIPHI",
    strloc: "Bottom Drive, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BOUG03",
    pstcd: "BDJAMJAM",
    strloc: "BOUGAINVILLAEA ROW, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BOUN03",
    pstcd: "BDMICMIC",
    strloc: "BOUNDARY ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BOUR03",
    pstcd: "BDGEOGEO",
    strloc: "BOURNES VILLAGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BOUR04",
    pstcd: "BDLUCLUC",
    strloc: "BOURBON TENANTRY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "BOUR05",
    pstcd: "BDLUCLUC",
    strloc: "BOURBON VILLAGE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "BOUR06",
    pstcd: "BDLUCLUC",
    strloc: "BOURBON PLANTATION, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "BOUR07",
    pstcd: "BDCHRCHR",
    strloc: "BOURNES LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BOVE02",
    pstcd: "BDPETSPE",
    strloc: "BOVELLS LANE, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BOVE03",
    pstcd: "BDPETSPE",
    strloc: "BOVELL LAND, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BOWE03",
    pstcd: "BDMICMIC",
    strloc: "BOWEN LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BOWL01",
    pstcd: "BDJOSJOS",
    strloc: "BOWLING GREEN, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "BOWL02",
    pstcd: "BDPETSPE",
    strloc: "BOWLING ALLEY, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BOWM01",
    pstcd: "BDJOHJOH",
    strloc: "BOWMANSTON PLANTATION, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "BOWM02",
    pstcd: "BDJOHJOH",
    strloc: "BOWMANSTON TENANTRY, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "BOWR01",
    pstcd: "BDMICMIC",
    strloc: "BOW ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BOYC02",
    pstcd: "BDMICMIC",
    strloc: "BOYCE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BOYS03",
    pstcd: "BDANDAND",
    strloc: "BOY'S SCHOOL GAP, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "BRAG01",
    pstcd: "BDJOSJOS",
    strloc: "BRAGGS HILL, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "BRAG02",
    pstcd: "BDJOSJOS",
    strloc: "BRAGGS BOTTOM, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "BRAN05",
    pstcd: "BDMICMIC",
    strloc: "BRANDONS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRAN05",
    pstcd: "BDPHIPHI",
    strloc: "BRANDONS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BRAN06",
    pstcd: "BDJOSJOS",
    strloc: "BRANCHBURY, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "BRAN07",
    pstcd: "BDMICMIC",
    strloc: "BRANKERS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRAT05",
    pstcd: "BDMICBAN",
    strloc: "BRATHWAITES GAP, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRAT05",
    pstcd: "BDMICMIC",
    strloc: "BRATHWAITES GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRAT06",
    pstcd: "BDGEOGEO",
    strloc: "BRATHWAITES ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BRAT06",
    pstcd: "BDJAMJAM",
    strloc: "BRATHWAITES ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BRAT07",
    pstcd: "BDMICMIC",
    strloc: "BRATHWAITES LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BREA02",
    pstcd: "BDMICMIC",
    strloc: "BREACON ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BREA03",
    pstcd: "BDMICMIC",
    strloc: "BREADFRUIT ALLEY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BREE03",
    pstcd: "BDCHRCHR",
    strloc: "BREEDYS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BREE04",
    pstcd: "BDCHRCHR",
    strloc: "BREEDYS LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BREE06",
    pstcd: "BDPHIPHI",
    strloc: "BREEZY HILL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BRER01",
    pstcd: "BDPHIPHI",
    strloc: "BRERETON VILLAGE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BRER02",
    pstcd: "BDPHIPHI",
    strloc: "BRERETON, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BREW02",
    pstcd: "BDBGTBGT",
    strloc: "BREWSTERS LAND, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BREW02",
    pstcd: "BDMICMIC",
    strloc: "BREWSTERS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BREW03",
    pstcd: "BDCHRCHR",
    strloc: "BREWSTER ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BRIA01",
    pstcd: "BDCHRCHR",
    strloc: "BRIAR HALL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BRID02",
    pstcd: "BDMICMIC",
    strloc: "BRIDGE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRID04",
    pstcd: "BDMICBLA",
    strloc: "BRIDLE ROAD, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRID05",
    pstcd: "BDBGTBGT",
    strloc: "BRIDGE STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BRID05",
    pstcd: "BDBGTBGT",
    strloc: "BRIDGE STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BRID06",
    pstcd: "BDTHOTHO",
    strloc: "BRIDGE FIELD, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BRID07",
    pstcd: "BDGEOGEO",
    strloc: "BRIDGE COT TERRACE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BRID08",
    pstcd: "BDGEOGEO",
    strloc: "BRIDGE COT, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BRID09",
    pstcd: "BDMICBLA",
    strloc: "BRIDGE GAP, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRID09",
    pstcd: "BDMICCAV",
    strloc: "BRIDGE GAP, CAVE HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRID10",
    pstcd: "BDMICBLA",
    strloc: "BRIDGE GAP DEVELOPMENT, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRID10",
    pstcd: "BDMICMIC",
    strloc: "BRIDGE GAP DEVELOPMENT, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRIG03",
    pstcd: "BDMICBLA",
    strloc: "BRIGHTON, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRIG03",
    pstcd: "BDMICMIC",
    strloc: "BRIGHTON, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRIG05",
    pstcd: "BDGEOGEO",
    strloc: "BRIGHTON TERRACE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BRIG05",
    pstcd: "BDMICMIC",
    strloc: "BRIGHTON TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRIG07",
    pstcd: "BDGEOGEO",
    strloc: "BRIGHTON TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BRIG08",
    pstcd: "BDGEOGEO",
    strloc: "BRIGGS HILL, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BRIG09",
    pstcd: "BDLUCLUC",
    strloc: "BRIGHT HALL, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "BRIG10",
    pstcd: "BDGEOGEO",
    strloc: "BRIGHTON HEIGHTS, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BRIG11",
    pstcd: "BDGEOGEO",
    strloc: "BRIGHTON LAND, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BRIG12",
    pstcd: "BDGEOGEO",
    strloc: "BRIGHTON PLANTATION, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BRIG14",
    pstcd: "BDMICMIC",
    strloc: "BRIGHTON ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRIG15",
    pstcd: "BDCHRCHR",
    strloc: "BRIGHT HILL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BRIG16",
    pstcd: "BDMICMIC",
    strloc: "BRIGHTON BEACH ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRIG17",
    pstcd: "BDMICMIC",
    strloc: "BRIGHTON CRESCENT, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRIG18",
    pstcd: "BDMICMIC",
    strloc: "BRIGHTON INDUSTRIAL ESTATE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRIM01",
    pstcd: "BDANDAND",
    strloc: "BRIMSTONE TENANTRY, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "BRIS01",
    pstcd: "BDMICMIC",
    strloc: "BRISTOL ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRIT03",
    pstcd: "BDMICMIC",
    strloc: "BRITTONS CROSS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRIT03",
    pstcd: "BDMICMIC",
    strloc: "BRITTONS CROSS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRIT04",
    pstcd: "BDMICMIC",
    strloc: "BRITTONS HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRIT05",
    pstcd: "BDMICMIC",
    strloc: "BRITTONS PARK, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRIT06",
    pstcd: "BDMICMIC",
    strloc: "BRITTONS NEW ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BROA02",
    pstcd: "BDBGTBGT",
    strloc: "BROAD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BROA02",
    pstcd: "BDBGTBGT",
    strloc: "BROAD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BROA02",
    pstcd: "BDBGTBGT",
    strloc: "BROAD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BROA02",
    pstcd: "BDBGTBGT",
    strloc: "BROAD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BROA02",
    pstcd: "BDBGTBGT",
    strloc: "BROAD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BROA02",
    pstcd: "BDBGTBGT",
    strloc: "BROAD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BROA02",
    pstcd: "BDBGTBGT",
    strloc: "BROAD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BROA02",
    pstcd: "BDBGTBGT",
    strloc: "BROAD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BROA02",
    pstcd: "BDBGTBGT",
    strloc: "BROAD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BROA02",
    pstcd: "BDBGTBGT",
    strloc: "BROAD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BROA02",
    pstcd: "BDBGTBGT",
    strloc: "BROAD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BROA02",
    pstcd: "BDBGTBGT",
    strloc: "BROAD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BROA02",
    pstcd: "BDBGTBGT",
    strloc: "BROAD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BROA02",
    pstcd: "BDBGTBGT",
    strloc: "BROAD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BROA02",
    pstcd: "BDBGTBGT",
    strloc: "BROAD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BROA02",
    pstcd: "BDBGTBGT",
    strloc: "BROAD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BROA02",
    pstcd: "BDBGTBGT",
    strloc: "BROAD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BROA02",
    pstcd: "BDBGTBGT",
    strloc: "BROAD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BROA02",
    pstcd: "BDBGTBGT",
    strloc: "BROAD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BROA02",
    pstcd: "BDBGTBGT",
    strloc: "BROAD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BROA02",
    pstcd: "BDMICMIC",
    strloc: "BROAD STREET, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BROM01",
    pstcd: "BDLUCLUC",
    strloc: "BROMEFIELD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "BROM02",
    pstcd: "BDLUCLUC",
    strloc: "BROMEFIELD PLANTATION, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "BROM03",
    pstcd: "BDLUCLUC",
    strloc: "BROMEFIELD TENANTRY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "BROM04",
    pstcd: "BDLUCLUC",
    strloc: "BROMEFIELD VILLAGE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "BROO06",
    pstcd: "BDTHOTHO",
    strloc: "BROOMES GAP, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BROO07",
    pstcd: "BDJAMJAM",
    strloc: "BROOMES TERRACE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BROW04",
    pstcd: "BDMICMIC",
    strloc: "BROWNE'S AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BROW05",
    pstcd: "BDMICMIC",
    strloc: "BROWNE'S LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BROW06",
    pstcd: "BDCHRCHR",
    strloc: "BROWNES GAP, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BROW06",
    pstcd: "BDMICBLA",
    strloc: "BROWNES GAP, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BROW06",
    pstcd: "BDMICMIC",
    strloc: "BROWNES GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BROW06",
    pstcd: "BDTHOTHO",
    strloc: "BROWNES GAP, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BRUC04",
    pstcd: "BDBGTBGT",
    strloc: "BRUCE ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BRUC04",
    pstcd: "BDBGTBGT",
    strloc: "BRUCE ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BRUC04",
    pstcd: "BDBGTBGT",
    strloc: "BRUCE ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BRUC05",
    pstcd: "BDANDAND",
    strloc: "BRUCE VALE, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "BRUC06",
    pstcd: "BDANDAND",
    strloc: "BRUCE VALE PLANTATION, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "BRYA03",
    pstcd: "BDTHOTHO",
    strloc: "BRYAN TENANTRY, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BRYA04",
    pstcd: "BDTHOTHO",
    strloc: "BRYAN ROAD, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BRYD01",
    pstcd: "BDMICMIC",
    strloc: "BRYDENS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BRYD02",
    pstcd: "BDMICMIC",
    strloc: "BRYDENS AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BUCC07",
    pstcd: "BDJAMJAM",
    strloc: "BUCCANEER TERRACE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "BUCK01",
    pstcd: "BDTHOTHO",
    strloc: "BUCKS, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BUCK02",
    pstcd: "BDJOSJOS",
    strloc: "BUCKDEN, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "BUCK03",
    pstcd: "BDMICBAN",
    strloc: "BUCKINGHAM ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BULK01",
    pstcd: "BDGEOGEO",
    strloc: "BULKELEY TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BULK02",
    pstcd: "BDGEOGEO",
    strloc: "BULKELEY HILL, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BULK03",
    pstcd: "BDGEOGEO",
    strloc: "BULKELEY TERRACE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BULL05",
    pstcd: "BDMICMIC",
    strloc: "BULLENS AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BULL07",
    pstcd: "BDBGTBGT",
    strloc: "BULLS ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BURK02",
    pstcd: "BDMICMIC",
    strloc: "BURKE'S LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BURK03",
    pstcd: "BDMICMIC",
    strloc: "BURKES BEACH, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BURK04",
    pstcd: "BDJOSJOS",
    strloc: "BURKES VILLAGE, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "BURM02",
    pstcd: "BDMICMIC",
    strloc: "BURMA ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BURM02",
    pstcd: "BDPETSPE",
    strloc: "BURMA ROAD, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "BURM03",
    pstcd: "BDMICMIC",
    strloc: "BURMA ROAD, GRAZETTES, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BURN04",
    pstcd: "BDANDAND",
    strloc: "BURNHAM, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "BURR01",
    pstcd: "BDMICMIC",
    strloc: "BURROWES GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BUSB03",
    pstcd: "BDBGTBGT",
    strloc: "BUSBEY ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BUSB03",
    pstcd: "BDBGTBGT",
    strloc: "BUSBEY ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BUSB03",
    pstcd: "BDBGTBGT",
    strloc: "BUSBEY ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BUSB03",
    pstcd: "BDBGTBGT",
    strloc: "BUSBEY ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "BUSH03",
    pstcd: "BDMICMIC",
    strloc: "BUSH HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BUSH04",
    pstcd: "BDMICBUS",
    strloc: "BUSH HALL YARD GAP, BUSH HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BUSH05",
    pstcd: "BDTHOTHO",
    strloc: "BUSHY PARK PLANTATION, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "BUSH06",
    pstcd: "BDPHIPHI",
    strloc: "BUSHY PARK, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "BUSH07",
    pstcd: "BDMICBUS",
    strloc: "BUSH HALL MAIN ROAD, BUSH HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BUTC01",
    pstcd: "BDGEOGEO",
    strloc: "BUTCHERS ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BUTC02",
    pstcd: "BDLUCLUC",
    strloc: "BUTCHERS, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "BUTL01",
    pstcd: "BDCHRCHR",
    strloc: "BUTLERS AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BUTL01",
    pstcd: "BDMICMIC",
    strloc: "BUTLERS AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "BUTT03",
    pstcd: "BDGEOGEO",
    strloc: "BUTTALS TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BUTT04",
    pstcd: "BDGEOGEO",
    strloc: "BUTTALS, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BYDE01",
    pstcd: "BDGEOGEO",
    strloc: "BYDE MILL TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "BYNO02",
    pstcd: "BDCHRROC",
    strloc: "BYNOE ROAD, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "BYRE01",
    pstcd: "BDGEOGEO",
    strloc: "BYRE ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "CABL01",
    pstcd: "BDCHRCHR",
    strloc: "CABLE ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CADO02",
    pstcd: "BDMICMIC",
    strloc: "CADOGAN ROAD, GRAZETTES, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CALL04",
    pstcd: "BDCHRCHR",
    strloc: "CALLENDERS DEVELOPMENT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CALL05",
    pstcd: "BDCHRCHR",
    strloc: "CALLENDERS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CALL06",
    pstcd: "BDCHRCHR",
    strloc: "CALLENDERS CRESCENT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CAMB01",
    pstcd: "BDJOSJOS",
    strloc: "CAMBRIDGE TENANTRY, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "CAMB02",
    pstcd: "BDANDAND",
    strloc: "CAMBRIDGE, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "CAMB02",
    pstcd: "BDJOSJOS",
    strloc: "CAMBRIDGE, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "CAMP11",
    pstcd: "BDGEOGEO",
    strloc: "CAMPAIGN CASTLE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "CAMP12",
    pstcd: "BDMICMIC",
    strloc: "CAMPAIGN LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CAMP13",
    pstcd: "BDMICMIC",
    strloc: "CAMPAIGN LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CANB01",
    pstcd: "BDJOSJOS",
    strloc: "CANBAR, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "CANE19",
    pstcd: "BDANDAND",
    strloc: "CANE GARDENS, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "CANE19",
    pstcd: "BDTHOTHO",
    strloc: "CANE GARDENS, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CANE21",
    pstcd: "BDTHOTHO",
    strloc: "CANE GARDEN PLANTATION, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CANE22",
    pstcd: "BDPHIPHI",
    strloc: "CANE GARDEN DEVELOPMENT, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CANE22",
    pstcd: "BDTHOTHO",
    strloc: "CANE GARDEN DEVELOPMENT, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CANE23",
    pstcd: "BDTHOTHO",
    strloc: "CANEFIELD TENANTRY, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CANE24",
    pstcd: "BDTHOTHO",
    strloc: "CANEFIELD PLANTATION, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CANE25",
    pstcd: "BDTHOTHO",
    strloc: "CANEFIELD, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CANE26",
    pstcd: "BDGEOGEO",
    strloc: "CANE HILL, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "CANE27",
    pstcd: "BDLUCLUC",
    strloc: "CANE GARDEN, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CANE27",
    pstcd: "BDPHIPHI",
    strloc: "CANE GARDEN, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CANE27",
    pstcd: "BDTHOTHO",
    strloc: "CANE GARDEN, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CANE28",
    pstcd: "BDPHIPHI",
    strloc: "CANE GARDEN TENANTRY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CANE28",
    pstcd: "BDTHOTHO",
    strloc: "CANE GARDEN TENANTRY, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CANE29",
    pstcd: "BDCHRCHR",
    strloc: "CANE VALE NEW ROADCANE30, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CANE29",
    pstcd: "BDTHOTHO",
    strloc: "CANE VALE NEW ROADCANE30, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CANE30",
    pstcd: "BDCHRCHR",
    strloc: "CANE VALE DEVELOPMENT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CANE31",
    pstcd: "BDCHRCHR",
    strloc: "CANE VALE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CANE32",
    pstcd: "BDCHRCHR",
    strloc: "CANE VALE VALLEY, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CANE33",
    pstcd: "BDCHRCHR",
    strloc: "CANE VALE TERRACE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CANE34",
    pstcd: "BDCHRCHR",
    strloc: "CANE VALE CRESCENT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CANE35",
    pstcd: "BDCHRCHR",
    strloc: "CANE VALE GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CANE36",
    pstcd: "BDCHRCHR",
    strloc: "CANE VALE HILL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CANE37",
    pstcd: "BDMICMIC",
    strloc: "CANE WOOD ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CANE40",
    pstcd: "BDTHOTHO",
    strloc: "CANEWOOD PARK, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CAPR02",
    pstcd: "BDMICMIC",
    strloc: "CAPRI DRIVE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CARI06",
    pstcd: "BDPHIPHI",
    strloc: "CARIBBEAN HATCHERIES, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CARL12",
    pstcd: "BDJAMJAM",
    strloc: "CARLTON, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CARL12",
    pstcd: "BDMICBLA",
    strloc: "CARLTON, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CARL13",
    pstcd: "BDMICMIC",
    strloc: "CARLTON TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CARL14",
    pstcd: "BDMICMIC",
    strloc: "CARLINGTON TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CARL15",
    pstcd: "BDJAMJAM",
    strloc: "CARLTON ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CARL16",
    pstcd: "BDJAMJAM",
    strloc: "CARLTON 2ND AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CARL17",
    pstcd: "BDJAMJAM",
    strloc: "CARLTON 3RD AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CARL18",
    pstcd: "BDJAMJAM",
    strloc: "CARLTON 1ST AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CARL19",
    pstcd: "BDJAMJAM",
    strloc: "CARLTON 4TH AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CARL20",
    pstcd: "BDJAMJAM",
    strloc: "CARLTON (LOWER), ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CARL21",
    pstcd: "BDJAMJAM",
    strloc: "CARLTON (UPPER), ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CARL22",
    pstcd: "BDMICMIC",
    strloc: "CARLISLE BAY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CARM04",
    pstcd: "BDGEOGEO",
    strloc: "CARMICHAEL, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "CARM05",
    pstcd: "BDGEOGEO",
    strloc: "CARMICHAEL TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "CARM06",
    pstcd: "BDGEOGEO",
    strloc: "CARMICHAEL HEIGHTS, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "CARN02",
    pstcd: "BDJAMJAM",
    strloc: "CARNATION DRIVE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CARN03",
    pstcd: "BDJAMSUN",
    strloc: "CARNATION ROW, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CARR12",
    pstcd: "BDMICMIC",
    strloc: "CARRINGTON VILLAGE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CARR12",
    pstcd: "BDTHOTHO",
    strloc: "CARRINGTON VILLAGE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CARR13",
    pstcd: "BDPHIPHI",
    strloc: "CARRINGTON TENANTRY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CARR14",
    pstcd: "BDPHIPHI",
    strloc: "CARRINGTONS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CART04",
    pstcd: "BDJOHJOH",
    strloc: "CARTERS, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "CART05",
    pstcd: "BDPHIPHI",
    strloc: "CARTERS TENANTRY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CART06",
    pstcd: "BDCHRCHR",
    strloc: "CARTERS GAP, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CART06",
    pstcd: "BDMICBLA",
    strloc: "CARTERS GAP, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CART07",
    pstcd: "BDMICBLA",
    strloc: "CARTERS GAP 1ST AVENUE, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CART08",
    pstcd: "BDMICBLA",
    strloc: "CARTERS GAP 2ND AVENUE, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CASA02",
    pstcd: "BDJOHJOH",
    strloc: "CASABELLA, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "CASH05",
    pstcd: "BDJOHJOH",
    strloc: "CASHEL, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "CASH06",
    pstcd: "BDCHRCHR",
    strloc: "CASHELL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CASH07",
    pstcd: "BDJAMSUN",
    strloc: "CASHEW ROW, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CASS03",
    pstcd: "BDJAMHUS",
    strloc: "CASSIA DRIVE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CASS03",
    pstcd: "BDJAMJAM",
    strloc: "CASSIA DRIVE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CASS32",
    pstcd: "BDJAMSUN",
    strloc: "CASSIA ROW, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CAST03",
    pstcd: "BDJOSJOS",
    strloc: "CASTLE GRANT, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "CAST04",
    pstcd: "BDPETPET",
    strloc: "CASTLE TENANTRY, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "CAST05",
    pstcd: "BDPETPET",
    strloc: "CASTLE ROAD, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "CAST06",
    pstcd: "BDPETPET",
    strloc: "CASTLE PLANTATION, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "CAST07",
    pstcd: "BDPHIPHI",
    strloc: "CASTLE TERRACE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CAST08",
    pstcd: "BDPHIPHI",
    strloc: "CASTLE HEIGHTS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CAST09",
    pstcd: "BDMICCAV",
    strloc: "CASTRIES ROAD, CAVE HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CASU06",
    pstcd: "BDPHIPHI",
    strloc: "CASUARINA ESTATE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CATS01",
    pstcd: "BDBGTBGT",
    strloc: "CATS CASTLE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CATT01",
    pstcd: "BDJOSJOS",
    strloc: "CATTLEWASH, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "CAVA02",
    pstcd: "BDBGTBGT",
    strloc: "CAVANS LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CAVA02",
    pstcd: "BDBGTBGT",
    strloc: "CAVANS LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CAVE02",
    pstcd: "BDLUCLUC",
    strloc: "CAVE HILL DRIVE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CAVE03",
    pstcd: "BDCHRCHR",
    strloc: "CAVE HILL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CAVE03",
    pstcd: "BDGEOGEO",
    strloc: "CAVE HILL, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "CAVE03",
    pstcd: "BDLUCLUC",
    strloc: "CAVE HILL, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CAVE03",
    pstcd: "BDMICCAV",
    strloc: "CAVE HILL, CAVE HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CAVE04",
    pstcd: "BDLUCLUC",
    strloc: "CAVE GAP, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CAVE05",
    pstcd: "BDPHIPHI",
    strloc: "CAVE LAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CAVE06",
    pstcd: "BDMICMIC",
    strloc: "CAVELL AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CAVE07",
    pstcd: "BDMICMIC",
    strloc: "CAVE WOOD TENANTRY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CAVE08",
    pstcd: "BDMICCAV",
    strloc: "CAVE HILL HOUSING AREA, CAVE HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CAVE09",
    pstcd: "BDMICMIC",
    strloc: "CAVE WOOD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CEDA10",
    pstcd: "BDTHOTHO",
    strloc: "CEDAR LANE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CEDA11",
    pstcd: "BDMICPIN",
    strloc: "CEDAR HILL, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CEME06",
    pstcd: "BDPETPET",
    strloc: "CEMETERY LANE 1, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "CEME07",
    pstcd: "BDPETPET",
    strloc: "CEMETERY LANE 2, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "CENT10",
    pstcd: "BDJAMJAM",
    strloc: "CENTRAL CLOSE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CENT11",
    pstcd: "BDJAMJAM",
    strloc: "CENTRAL DRIVE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CHAD06",
    pstcd: "BDMICMIC",
    strloc: "CHADDERTON ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CHAL01",
    pstcd: "BDANDAND",
    strloc: "CHALKY MOUNT, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "CHAM05",
    pstcd: "BDBGTBGT",
    strloc: "CHAMBERLAIN BRIDGE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CHAN01",
    pstcd: "BDCHRCHA",
    strloc: "CHANCERY LANE, CHANCERY LANE - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CHAN01",
    pstcd: "BDCHRCHR",
    strloc: "CHANCERY LANE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CHAN02",
    pstcd: "BDCHRCHA",
    strloc: "CHANDRA STREET, CHANCERY LANE - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CHAN02",
    pstcd: "BDCHRCHR",
    strloc: "CHANDRA STREET, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CHAN13",
    pstcd: "BDLUCLUC",
    strloc: "CHANCE HALL, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CHAN14",
    pstcd: "BDJAMJAM",
    strloc: "CHANDLERS HILL, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CHAP01",
    pstcd: "BDBGTBGT",
    strloc: "CHAPEL STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CHAP01",
    pstcd: "BDBGTBGT",
    strloc: "CHAPEL STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CHAP01",
    pstcd: "BDBGTBGT",
    strloc: "CHAPEL STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CHAP01",
    pstcd: "BDBGTBGT",
    strloc: "CHAPEL STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CHAP01",
    pstcd: "BDBGTBGT",
    strloc: "CHAPEL STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CHAP01",
    pstcd: "BDPETSPE",
    strloc: "CHAPEL STREET, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "CHAP03",
    pstcd: "BDBGTBGT",
    strloc: "CHAPMAN LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CHAP03",
    pstcd: "BDMICMIC",
    strloc: "CHAPMAN LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CHAP04",
    pstcd: "BDMICMIC",
    strloc: "CHAPEL PLACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CHAP05",
    pstcd: "BDBGTBGT",
    strloc: "CHAPEL LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CHAP05",
    pstcd: "BDBGTBGT",
    strloc: "CHAPEL LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CHAP06",
    pstcd: "BDANDAND",
    strloc: "CHAPMANS VILLAGE, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "CHAP06",
    pstcd: "BDJOSJOS",
    strloc: "CHAPMANS VILLAGE, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "CHAP06",
    pstcd: "BDTHOTHO",
    strloc: "CHAPMANS VILLAGE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CHAP07",
    pstcd: "BDPHIPHI",
    strloc: "CHAPEL PLANTATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CHAP08",
    pstcd: "BDPHIPHI",
    strloc: "CHAPEL QUARRY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CHAP09",
    pstcd: "BDPHIPHI",
    strloc: "CHAPEL TENANTRY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CHAP10",
    pstcd: "BDMICMIC",
    strloc: "CHAPEL LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CHAP10",
    pstcd: "BDPHIPHI",
    strloc: "CHAPEL LAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CHAP11",
    pstcd: "BDJAMJAM",
    strloc: "CHAPEL GAP, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CHAP11",
    pstcd: "BDMICMIC",
    strloc: "CHAPEL GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CHAP11",
    pstcd: "BDMICMIC",
    strloc: "CHAPEL GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CHAP12",
    pstcd: "BDMICMIC",
    strloc: "CHAPMAN STREET, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CHAP13",
    pstcd: "BDJAMJAM",
    strloc: "CHAPEL GAP NO 1, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CHAP14",
    pstcd: "BDJAMJAM",
    strloc: "CHAPEL GAP NO 2, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CHAR18",
    pstcd: "BDGEOGEO",
    strloc: "CHARLES ROW BRIDGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "CHAR18",
    pstcd: "BDJOHJOH",
    strloc: "CHARLES ROW BRIDGE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "CHAR19",
    pstcd: "BDPHIPHI",
    strloc: "CHARITY HALL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CHAR20",
    pstcd: "BDCHRCHR",
    strloc: "CHARNOCKS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CHAR21",
    pstcd: "BDJAMJAM",
    strloc: "CHARLEY, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CHAS02",
    pstcd: "BDCHRCHR",
    strloc: "CHASE ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CHEA01",
    pstcd: "BDBGTBGT",
    strloc: "CHEAPSIDE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CHEA01",
    pstcd: "BDBGTBGT",
    strloc: "CHEAPSIDE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CHEA01",
    pstcd: "BDBGTBGT",
    strloc: "CHEAPSIDE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CHEA01",
    pstcd: "BDBGTBGT",
    strloc: "CHEAPSIDE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CHEA01",
    pstcd: "BDBGTBGT",
    strloc: "CHEAPSIDE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CHEA01",
    pstcd: "BDBGTBGT",
    strloc: "CHEAPSIDE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CHEA02",
    pstcd: "BDMICMIC",
    strloc: "CHEAPSIDE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CHEC01",
    pstcd: "BDLUCLUC",
    strloc: "CHECKER HALL MAIN ROAD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CHEC02",
    pstcd: "BDLUCLUC",
    strloc: "CHECKER HALL TENANTRY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CHEC03",
    pstcd: "BDLUCLUC",
    strloc: "CHECKER HALL N-S ROAD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CHEC04",
    pstcd: "BDLUCLUC",
    strloc: "CHECKER HALL CROSS ROAD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CHEC05",
    pstcd: "BDLUCLUC",
    strloc: "CHECKER HALL TEN ROAD NO1    ., ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CHEC06",
    pstcd: "BDLUCLUC",
    strloc: "CHECKER HALL ROAD NO2, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CHEC07",
    pstcd: "BDLUCLUC",
    strloc: "CHECKER HALL E-W ROAD NO2, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CHEC08",
    pstcd: "BDLUCLUC",
    strloc: "CHECKER HALL LOWER, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CHEC09",
    pstcd: "BDLUCLUC",
    strloc: "CHECKER HALL DEVELOPMENT, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CHEL02",
    pstcd: "BDMICMIC",
    strloc: "CHELSEA ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CHEL03",
    pstcd: "BDMICMIC",
    strloc: "CHELSTON AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CHEL04",
    pstcd: "BDMICMIC",
    strloc: "CHELSEA GARDENS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CHEL06",
    pstcd: "BDMICMIC",
    strloc: "CHELSTON ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CHEL07",
    pstcd: "BDMICMIC",
    strloc: "CHELSTON GARDENS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CHEL08",
    pstcd: "BDMICCOL",
    strloc: "CHELSTON PARK, COLLYMORE ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CHEP02",
    pstcd: "BDMICMIC",
    strloc: "CHEPSTOW, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CHER04",
    pstcd: "BDJOHJOH",
    strloc: "CHERRY GROVE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "CHER05",
    pstcd: "BDJAMHUS",
    strloc: "CHERRY DRIVE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CHER05",
    pstcd: "BDJAMOXN",
    strloc: "CHERRY DRIVE, OXNARDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CHER06",
    pstcd: "BDJAMWES",
    strloc: "CHERFLEURS AVENUE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CHER07",
    pstcd: "BDJAMSUN",
    strloc: "CHERRY AVENUE, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CHES03",
    pstcd: "BDJOHJOH",
    strloc: "CHESHIRE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "CHIM01",
    pstcd: "BDJOSJOS",
    strloc: "CHIMBORAZO, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "CHRI13",
    pstcd: "BDTHOTHO",
    strloc: "CHRISTIE VILLAGE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CHRI14",
    pstcd: "BDJAMJAM",
    strloc: "CHRISTIAN MISSION GAP, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CHUR01",
    pstcd: "BDBGTBGT",
    strloc: "CHURCH STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CHUR01",
    pstcd: "BDPETSPE",
    strloc: "CHURCH STREET, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "CHUR05",
    pstcd: "BDCHRCHR",
    strloc: "CHURCH ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CHUR13",
    pstcd: "BDBGTBGT",
    strloc: "CHURCH VILLAGE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CHUR13",
    pstcd: "BDJOSJOS",
    strloc: "CHURCH VILLAGE, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "CHUR13",
    pstcd: "BDPHIPHI",
    strloc: "CHURCH VILLAGE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CHUR14",
    pstcd: "BDJOHJOH",
    strloc: "CHURCH VIEW, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "CHUR15",
    pstcd: "BDCHRCHR",
    strloc: "CHURCH HILL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CHUR15",
    pstcd: "BDLUCLUC",
    strloc: "CHURCH HILL, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CHUR16",
    pstcd: "BDANDAND",
    strloc: "CHURCH GAP, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "CHUR16",
    pstcd: "BDMICMIC",
    strloc: "CHURCH GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CHUR17",
    pstcd: "BDPETPET",
    strloc: "CHURCH HILL ROAD, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "CHUR18",
    pstcd: "BDPETPET",
    strloc: "CHURCH HILL TENANTRY ROAD, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "CHUR19",
    pstcd: "BDPETSPE",
    strloc: "CHURCH STREET GARDENS, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "CHUR20",
    pstcd: "BDCHRCHR",
    strloc: "CHURCH ROAD DEVELOPMENT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CHUR21",
    pstcd: "BDMICMIC",
    strloc: "CHURCH LAND DRIVE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CHUR22",
    pstcd: "BDJAMJAM",
    strloc: "CHURCH POINT, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CHUR23",
    pstcd: "BDTHOTHO",
    strloc: "CHURCH OF GOD GAP, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CIVI01",
    pstcd: "BDMICBUS",
    strloc: "CIVILIAN ROAD, BUSH HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CIVI01",
    pstcd: "BDMICMIC",
    strloc: "CIVILIAN ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLAP01",
    pstcd: "BDMICMIC",
    strloc: "CLAPHAM PARK, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLAP02",
    pstcd: "BDMICMIC",
    strloc: "CLAPHAM HEIGHTS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLAP03",
    pstcd: "BDCHRCHR",
    strloc: "CLAPHAM NEW ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CLAP03",
    pstcd: "BDMICMIC",
    strloc: "CLAPHAM NEW ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLAP04",
    pstcd: "BDMICMIC",
    strloc: "CLAPHAM MAIN ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLAP05",
    pstcd: "BDMICMIC",
    strloc: "CLAPHAM UPPER, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLAP06",
    pstcd: "BDMICMIC",
    strloc: "CLAPHAM ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLAP07",
    pstcd: "BDMICMIC",
    strloc: "CLAPHAM LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLAR03",
    pstcd: "BDMICMIC",
    strloc: "CLARKE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLAR10",
    pstcd: "BDMICMIC",
    strloc: "CLARKE'S AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLAR11",
    pstcd: "BDJAMJAM",
    strloc: "CLARKES ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLAR11",
    pstcd: "BDMICBLA",
    strloc: "CLARKES ROAD, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLAR11",
    pstcd: "BDMICMIC",
    strloc: "CLARKES ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLAR11",
    pstcd: "BDPETPET",
    strloc: "CLARKES ROAD, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "CLAR12",
    pstcd: "BDPETPET",
    strloc: "CLARKS GAP, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "CLAR13",
    pstcd: "BDPHIPHI",
    strloc: "CLARKES HILL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CLAR14",
    pstcd: "BDPHIPHI",
    strloc: "CLARKES VILLAGE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CLAR15",
    pstcd: "BDMICBLA",
    strloc: "CLARKES LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLAR16",
    pstcd: "BDJAMJAM",
    strloc: "CLARKES GAP, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLAR16",
    pstcd: "BDMICMIC",
    strloc: "CLARKES GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLAR17",
    pstcd: "BDJAMJAM",
    strloc: "CLARKES ROAD NO1, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLAR18",
    pstcd: "BDJAMJAM",
    strloc: "CLARKES GAP NO2, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLAR19",
    pstcd: "BDJAMJAM",
    strloc: "CLARKE'S ROAD NO2, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLAR20",
    pstcd: "BDJAMJAM",
    strloc: "CLARKE'S ROAD NO1, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLAY01",
    pstcd: "BDJOHJOH",
    strloc: "CLAYBURY, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "CLAY02",
    pstcd: "BDJOHJOH",
    strloc: "CLAYBURY PLANTATION, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "CLAY03",
    pstcd: "BDJOHJOH",
    strloc: "CLAYBURY TENANTRY, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "CLEA03",
    pstcd: "BDJOSJOS",
    strloc: "CLEAVERS HILL, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "CLEA04",
    pstcd: "BDJAMJAM",
    strloc: "CLEARVIEW HEIGHTS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLEA05",
    pstcd: "BDJAMJAM",
    strloc: "CLEARVIEW HEIGHTS STAGE 1, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLEA06",
    pstcd: "BDJAMJAM",
    strloc: "CLEARVIEW DRIVE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLEA07",
    pstcd: "BDJAMJAM",
    strloc: "CLEARVIEW HEIGHTS STAGE 2, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLEL01",
    pstcd: "BDANDAND",
    strloc: "CLELAND, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "CLEM03",
    pstcd: "BDJOSJOS",
    strloc: "CLEMENT ROCK, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "CLEM04",
    pstcd: "BDMICMIC",
    strloc: "CLEMENTS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLER01",
    pstcd: "BDJAMJAM",
    strloc: "CLERMONT, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLER01",
    pstcd: "BDMICMIC",
    strloc: "CLERMONT, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLER01",
    pstcd: "BDTHOTHO",
    strloc: "CLERMONT, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CLER02",
    pstcd: "BDJAMJAM",
    strloc: "CLERMONT GARDENS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLER03",
    pstcd: "BDJAMJAM",
    strloc: "CLERVIEW HEIGHTS STAGE 3, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLER04",
    pstcd: "BDJAMJAM",
    strloc: "CLERVIEW HEIGHTS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLER05",
    pstcd: "BDJAMJAM",
    strloc: "CLERVIEW HEIGHTS STAGE 1, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLER06",
    pstcd: "BDJAMJAM",
    strloc: "CLERVIEW, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLER07",
    pstcd: "BDJAMJAM",
    strloc: "CLERMONT TERRACE - WEST ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLER08",
    pstcd: "BDJAMJAM",
    strloc: "CLERMONT TERRACE-SOUTH, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLER08",
    pstcd: "BDMICMIC",
    strloc: "CLERMONT TERRACE-SOUTH, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLER09",
    pstcd: "BDJAMJAM",
    strloc: "CLERPARK 1ST AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLER10",
    pstcd: "BDJAMJAM",
    strloc: "CLERPARK 2ND AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLER11",
    pstcd: "BDJAMJAM",
    strloc: "CLERPARK 3RD AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLER12",
    pstcd: "BDJAMJAM",
    strloc: "CLERMONT-WEST RD 1ST AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLER13",
    pstcd: "BDJAMJAM",
    strloc: "CLERMONT-WEST RD 2ND AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLER14",
    pstcd: "BDJAMJAM",
    strloc: "CLERMONT CLOSE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLER15",
    pstcd: "BDJAMJAM",
    strloc: "CLERMONT-NORTH, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLER16",
    pstcd: "BDJAMJAM",
    strloc: "CLERMONT TERRACE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLER16",
    pstcd: "BDMICMIC",
    strloc: "CLERMONT TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLER17",
    pstcd: "BDJAMJAM",
    strloc: "CLERVIEW HEIGHTS STAGE 2, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLER18",
    pstcd: "BDMICMIC",
    strloc: "CLERPARK, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLER19",
    pstcd: "BDMICMIC",
    strloc: "CLERVIEW HEIGHTS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLER20",
    pstcd: "BDJAMJAM",
    strloc: "CLERMONT GREEN, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CLEV01",
    pstcd: "BDMICBLA",
    strloc: "CLEVEDALE HOUSING AREA, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLEV02",
    pstcd: "BDMICBLA",
    strloc: "CLEVEDALE ROAD, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLIF02",
    pstcd: "BDTHOTHO",
    strloc: "CLIFTON HILL, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CLIF06",
    pstcd: "BDMICMIC",
    strloc: "CLIFTON TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLIF07",
    pstcd: "BDJOHJOH",
    strloc: "CLIFF COTTAGE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "CLIF08",
    pstcd: "BDJOHJOH",
    strloc: "CLIFF TENANTRY, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "CLIF09",
    pstcd: "BDJOHJOH",
    strloc: "CLIFF PLANTATION, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "CLIF10",
    pstcd: "BDJOHJOH",
    strloc: "CLIFTON HALL PLANTATION, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "CLIF11",
    pstcd: "BDJOHJOH",
    strloc: "CLIFTON HALL TENANTRY, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "CLIF12",
    pstcd: "BDPHIPHI",
    strloc: "CLIFDEN, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CLIF12",
    pstcd: "BDTHOTHO",
    strloc: "CLIFDEN, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CLIF13",
    pstcd: "BDTHOTHO",
    strloc: "CLIFTON PLANTATION, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CLIF14",
    pstcd: "BDTHOTHO",
    strloc: "CLIFTON TENANTRY, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CLIN02",
    pstcd: "BDLUCLUC",
    strloc: "CLINKETTS GARDENS, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CLIN03",
    pstcd: "BDLUCLUC",
    strloc: "CLINKETTS HOUSING AREA, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CLOM01",
    pstcd: "BDMICMIC",
    strloc: "CLOMEL ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLUB01",
    pstcd: "BDMICMIC",
    strloc: "CLUB MORGAN TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLUB02",
    pstcd: "BDCHRCHR",
    strloc: "CLUB MORGAN, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CLUB02",
    pstcd: "BDMICMIC",
    strloc: "CLUB MORGAN, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CLUF01",
    pstcd: "BDLUCLUC",
    strloc: "CLUFFS, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CLUF02",
    pstcd: "BDLUCLUC",
    strloc: "CLUFFS PLANTATION, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CLUF03",
    pstcd: "BDLUCLUC",
    strloc: "CLUFFS TENANTRY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CLUS01",
    pstcd: "BDMICMIC",
    strloc: "CLUSTER BLOCK, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "COAC01",
    pstcd: "BDJOHJOH",
    strloc: "COACH HILL, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "COCO13",
    pstcd: "BDLUCLUC",
    strloc: "COCONUT HALL, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CODR02",
    pstcd: "BDMICMIC",
    strloc: "CODRINGTON HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CODR03",
    pstcd: "BDJOHJOH",
    strloc: "CODRINGTON ROAD, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "CODR03",
    pstcd: "BDMICMIC",
    strloc: "CODRINGTON ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CODR04",
    pstcd: "BDMICMIC",
    strloc: "CODRINGTON HILL LOWER, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "COFF02",
    pstcd: "BDJOSJOS",
    strloc: "COFFEE GULLY, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "COGG01",
    pstcd: "BDANDAND",
    strloc: "COGGINS HILL, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "COLE02",
    pstcd: "BDBGTBGT",
    strloc: "COLERIDGE STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "COLE02",
    pstcd: "BDBGTBGT",
    strloc: "COLERIDGE STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "COLE02",
    pstcd: "BDBGTBGT",
    strloc: "COLERIDGE STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "COLE03",
    pstcd: "BDTHOTHO",
    strloc: "Kaya Coleta V. Ostiana, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "COLE04",
    pstcd: "BDGEOGEO",
    strloc: "COLES ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "COLE04",
    pstcd: "BDMICMIC",
    strloc: "COLES ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "COLE05",
    pstcd: "BDLUCLUC",
    strloc: "COLES CAVE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "COLE06",
    pstcd: "BDPETPET",
    strloc: "COLERIDGE PARRY SCHOOL ROAD, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "COLE07",
    pstcd: "BDPHIPHI",
    strloc: "COLES, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "COLE08",
    pstcd: "BDPHIPHI",
    strloc: "COLES TERRACE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "COLE09",
    pstcd: "BDPHIPHI",
    strloc: "COLES DEVELOPMENT, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "COLE10",
    pstcd: "BDTHOTHO",
    strloc: "COLEMAN GAP, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "COLE11",
    pstcd: "BDTHOTHO",
    strloc: "COLEMANS ROAD, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "COLL06",
    pstcd: "BDMICCOL",
    strloc: "COLLYMORE ROCK, COLLYMORE ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "COLL06",
    pstcd: "BDMICMIC",
    strloc: "COLLYMORE ROCK, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "COLL07",
    pstcd: "BDJOHJOH",
    strloc: "COLLETON TENANTRY, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "COLL08",
    pstcd: "BDJOHJOH",
    strloc: "COLLETON TENANTRY ROAD, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "COLL09",
    pstcd: "BDJOHJOH",
    strloc: "COLLEGE PLANTATION, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "COLL10",
    pstcd: "BDJOHJOH",
    strloc: "COLLEGE LAND, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "COLL11",
    pstcd: "BDJOHJOH",
    strloc: "COLLEGE TENANTRY, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "COLL12",
    pstcd: "BDJOHJOH",
    strloc: "COLLEGE SAVANNAH, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "COLL13",
    pstcd: "BDJOHJOH",
    strloc: "COLLETON PLANTATION, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "COLL15",
    pstcd: "BDJOHJOH",
    strloc: "COLLETON COTTAGE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "COLL16",
    pstcd: "BDLUCLUC",
    strloc: "COLLETON VILLAGE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "COLL17",
    pstcd: "BDPETPET",
    strloc: "COLLETON, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "COLL18",
    pstcd: "BDPETPET",
    strloc: "COLLINS VILLAGE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "COLL19",
    pstcd: "BDPETPET",
    strloc: "COLLINS, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "COLL20",
    pstcd: "BDPHIPHI",
    strloc: "COLLINGTON DRIVE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "COMR01",
    pstcd: "BDMICMIC",
    strloc: "COMRIE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "COND02",
    pstcd: "BDMICMIC",
    strloc: "CONDEMN ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CONG02",
    pstcd: "BDPHIPHI",
    strloc: "CONGO ROAD TENANTRY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CONG03",
    pstcd: "BDPHIPHI",
    strloc: "CONGO ROAD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CONG04",
    pstcd: "BDPHIPHI",
    strloc: "CONGO ROAD PLTN./QTRS., ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CONK01",
    pstcd: "BDBGTBGT",
    strloc: "CONKS ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CONN04",
    pstcd: "BDLUCLUC",
    strloc: "CONNELL TOWN, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CONN05",
    pstcd: "BDGEOGEO",
    strloc: "CONNELS ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "CONN06",
    pstcd: "BDMICMIC",
    strloc: "CONNOUGHT PATH, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CONS08",
    pstcd: "BDBGTBGT",
    strloc: "CONSTITUTION ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CONS08",
    pstcd: "BDBGTBGT",
    strloc: "CONSTITUTION ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CONS08",
    pstcd: "BDBGTBGT",
    strloc: "CONSTITUTION ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CONS09",
    pstcd: "BDJOHJOH",
    strloc: "CONSET BAY, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "CONS10",
    pstcd: "BDGEOGEO",
    strloc: "CONSTANT PLANTATION, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "CONS11",
    pstcd: "BDGEOGEO",
    strloc: "CONSTANT LAND, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "CONS12",
    pstcd: "BDGEOGEO",
    strloc: "CONSTANT TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "CONT06",
    pstcd: "BDTHOTHO",
    strloc: "CONTENT TENANTRY, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CONT07",
    pstcd: "BDLUCLUC",
    strloc: "CONTENT VILLAGE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CONT07",
    pstcd: "BDTHOTHO",
    strloc: "CONTENT VILLAGE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CONT08",
    pstcd: "BDTHOTHO",
    strloc: "CONTENT PLANTATION, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "CONT09",
    pstcd: "BDLUCLUC",
    strloc: "CONTENTS, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CONT10",
    pstcd: "BDPHIPHI",
    strloc: "CONTENT COT, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CONT11",
    pstcd: "BDMICCAV",
    strloc: "CONTINENTAL DRIVE, CAVE HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "COOK02",
    pstcd: "BDBGTBGT",
    strloc: "COOKS ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "COOP05",
    pstcd: "BDJAMSUN",
    strloc: "COOPER HILL ROAD, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "COPP02",
    pstcd: "BDJAMJAM",
    strloc: "COPPER HILL, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "COPP02",
    pstcd: "BDJAMSUN",
    strloc: "COPPER HILL, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CORA08",
    pstcd: "BDPETPET",
    strloc: "CORAL CLIFF, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "CORA09",
    pstcd: "BDCHRCHR",
    strloc: "CORAL AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CORB03",
    pstcd: "BDANDAND",
    strloc: "CORBIN'S VILLAGE, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "CORB04",
    pstcd: "BDMICMIC",
    strloc: "CORBINS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CORD03",
    pstcd: "BDMICMIC",
    strloc: "CORDAY AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CORD04",
    pstcd: "BDJAMSUN",
    strloc: "CORDIA AVENUE, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CORD05",
    pstcd: "BDJAMSUN",
    strloc: "CORDILITA AVENUE, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CORE01",
    pstcd: "BDJAMSUN",
    strloc: "CORELIA AVENUE, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CORN08",
    pstcd: "BDJOSJOS",
    strloc: "Cornelisbaai, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "CORN09",
    pstcd: "BDMICMIC",
    strloc: "CORNER GRAZETTES ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CORP02",
    pstcd: "BDMICMIC",
    strloc: "CORPS CORNER, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "COST01",
    pstcd: "BDMICPIN",
    strloc: "COSTAIN DRIVE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "COT 01",
    pstcd: "BDCHRCHR",
    strloc: "COT ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "COTS01",
    pstcd: "BDCHRCHR",
    strloc: "COTSWOLD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "COTT04",
    pstcd: "BDJOSJOS",
    strloc: "COTTON TOWER, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "COTT05",
    pstcd: "BDLUCLUC",
    strloc: "COTTAGE VILLAGE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "COTT06",
    pstcd: "BDLUCLUC",
    strloc: "COTTAGE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "COTT07",
    pstcd: "BDPHIPHI",
    strloc: "COTTAGE VALE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "COTT08",
    pstcd: "BDPHIPHI",
    strloc: "COTTON HOUSE LINE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "COTT09",
    pstcd: "BDGEOGEO",
    strloc: "COTTAGE TERRACE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "COTT10",
    pstcd: "BDGEOGEO",
    strloc: "COTTAGE PLANTATION, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "COTT12",
    pstcd: "BDGEOGEO",
    strloc: "COTTAGE TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "COTT14",
    pstcd: "BDGEOGEO",
    strloc: "COTTAGE CRESCENT, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "COUN03",
    pstcd: "BDBGTBGT",
    strloc: "COUNTRY ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "COUN03",
    pstcd: "BDBGTBGT",
    strloc: "COUNTRY ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "COUN03",
    pstcd: "BDBGTBGT",
    strloc: "COUNTRY ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "COUN03",
    pstcd: "BDBGTBGT",
    strloc: "COUNTRY ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "COUN03",
    pstcd: "BDMICBAN",
    strloc: "COUNTRY ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "COUR04",
    pstcd: "BDCHRCHR",
    strloc: "COURTVILLE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "COVE01",
    pstcd: "BDLUCLUC",
    strloc: "COVE PLANTATION, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "COVE02",
    pstcd: "BDLUCLUC",
    strloc: "COVE TENANTRY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "COVE03",
    pstcd: "BDLUCLUC",
    strloc: "COVE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "COVE04",
    pstcd: "BDLUCLUC",
    strloc: "COVE BAY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "COVE05",
    pstcd: "BDCHRCHR",
    strloc: "COVERLEY, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "COVE06",
    pstcd: "BDMICMIC",
    strloc: "COVERLEY LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "COVE07",
    pstcd: "BDCHRCHR",
    strloc: "COVERLEY TERRACE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "COVE08",
    pstcd: "BDCHRCHR",
    strloc: "COVERLEY PLANTATION, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "COWA01",
    pstcd: "BDJOSJOS",
    strloc: "COWARD ROAD, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "COWE01",
    pstcd: "BDBGTBGT",
    strloc: "COWELL STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "COWE01",
    pstcd: "BDBGTBGT",
    strloc: "COWELL STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "COWE01",
    pstcd: "BDBGTBGT",
    strloc: "COWELL STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "COWE01",
    pstcd: "BDBGTBGT",
    strloc: "COWELL STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "COWE01",
    pstcd: "BDBGTBGT",
    strloc: "COWELL STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "COWE01",
    pstcd: "BDBGTBGT",
    strloc: "COWELL STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "COWE01",
    pstcd: "BDMICMIC",
    strloc: "COWELL STREET, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "COX 03",
    pstcd: "BDPHIPHI",
    strloc: "COX HILL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "COX 04",
    pstcd: "BDCHRCHR",
    strloc: "COX ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "COX 05",
    pstcd: "BDPHIPHI",
    strloc: "COX HALL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CRAB01",
    pstcd: "BDLUCLUC",
    strloc: "CRAB HILL VILLAGE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CRAB02",
    pstcd: "BDLUCLUC",
    strloc: "CRAB HILL, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CRAB03",
    pstcd: "BDLUCLUC",
    strloc: "CRAB HILL TENANTRY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CRAB04",
    pstcd: "BDMICMIC",
    strloc: "CRAB LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CRAI05",
    pstcd: "BDCHRCHR",
    strloc: "CRAIGGS ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CRAN01",
    pstcd: "BDPHIPHI",
    strloc: "CRANE BEACH, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CRAN02",
    pstcd: "BDPHIPHI",
    strloc: "CRANE PARK, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CRAN03",
    pstcd: "BDPHIPHI",
    strloc: "CRANE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CRAN04",
    pstcd: "BDPHIPHI",
    strloc: "CRANE HAVEN DEV., ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CRAN05",
    pstcd: "BDPHIPHI",
    strloc: "CRANE GARDENS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CRAN10",
    pstcd: "BDPHIPHI",
    strloc: "CRANE DEVELOPMENT, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "CRAW04",
    pstcd: "BDMICBLA",
    strloc: "CRAWFORD LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CRES08",
    pstcd: "BDMICCAV",
    strloc: "CRESCENT VIEW TERRACE, CAVE HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CRIC02",
    pstcd: "BDMICMIC",
    strloc: "CRICHLOW ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CRIC03",
    pstcd: "BDBGTBGT",
    strloc: "CRICHLOW'S ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CRIC03",
    pstcd: "BDBGTBGT",
    strloc: "CRICHLOW'S ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CRIC03",
    pstcd: "BDBGTBGT",
    strloc: "CRICHLOW'S ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CRIC03",
    pstcd: "BDMICMIC",
    strloc: "CRICHLOW'S ALLEY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CRIC04",
    pstcd: "BDMICBLA",
    strloc: "CRICHLOWS LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CRIC05",
    pstcd: "BDJAMJAM",
    strloc: "CRICK HILL, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CROM01",
    pstcd: "BDMICMIC",
    strloc: "CROMWELL ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CROT02",
    pstcd: "BDJAMHUS",
    strloc: "CROTON DRIVE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CROT03",
    pstcd: "BDMICPIN",
    strloc: "CROTON AVENUE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CRUM01",
    pstcd: "BDBGTBGT",
    strloc: "CRUMPTON STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CRUM01",
    pstcd: "BDBGTBGT",
    strloc: "CRUMPTON STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CRUS02",
    pstcd: "BDJAMJAM",
    strloc: "CRUSHER SITE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CRUS03",
    pstcd: "BDJAMJAM",
    strloc: "CRUSHER SITE ROAD 1ST AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CRUS04",
    pstcd: "BDJAMJAM",
    strloc: "CRUSHER SITE ROAD 2ND AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CRUS05",
    pstcd: "BDJAMJAM",
    strloc: "CRUSHER SITE ROAD 3RD AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CRUS06",
    pstcd: "BDJAMJAM",
    strloc: "CRUSHER SITE ROAD 4TH AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CRUS07",
    pstcd: "BDJAMJAM",
    strloc: "CRUSHER SITE ROAD 5TH AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CRYS02",
    pstcd: "BDCHRCHR",
    strloc: "CRYSTAL WATER GAP, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CRYS03",
    pstcd: "BDJAMJAM",
    strloc: "CRYSTAL HEIGHTS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CRYS04",
    pstcd: "BDCHRCHR",
    strloc: "CRYSTAL LANE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CRYS05",
    pstcd: "BDCHRCHR",
    strloc: "CRYSTAL AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CRYS06",
    pstcd: "BDCHRCHR",
    strloc: "CRYSTAL CRESCENT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "CULL01",
    pstcd: "BDMICMIC",
    strloc: "CULLODEN ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CULV01",
    pstcd: "BDJAMJAM",
    strloc: "CULVERS TERRACE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CUMB01",
    pstcd: "BDBGTBGT",
    strloc: "CUMBERBATCH LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CUMB03",
    pstcd: "BDBGTBGT",
    strloc: "CUMBERLAND STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CUMB03",
    pstcd: "BDBGTBGT",
    strloc: "CUMBERLAND STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "CUMB03",
    pstcd: "BDMICMIC",
    strloc: "CUMBERLAND STREET, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CUMB04",
    pstcd: "BDMICMIC",
    strloc: "CUMBERBATCH LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CUMM03",
    pstcd: "BDMICMIC",
    strloc: "CUMMINS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CURI01",
    pstcd: "BDJAMJAM",
    strloc: "CURIOSITY ROW, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "CURW01",
    pstcd: "BDMICMIC",
    strloc: "CURWENS AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CUTR01",
    pstcd: "BDLUCLUC",
    strloc: "CUT ROCK, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "CUTT01",
    pstcd: "BDMICMIC",
    strloc: "CUTTING GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "CUTT02",
    pstcd: "BDMICMIC",
    strloc: "CUTTING ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DACO01",
    pstcd: "BDMICMIC",
    strloc: "DACOSTA DRIVE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DAHL04",
    pstcd: "BDJAMOXN",
    strloc: "DAHLIA WALK, OXNARDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DAIR02",
    pstcd: "BDJAMJAM",
    strloc: "DAIRY MEADOW, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DAIS04",
    pstcd: "BDJAMJAM",
    strloc: "DAISY DRIVE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DALK01",
    pstcd: "BDMICMIC",
    strloc: "DALKEITH ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DALK02",
    pstcd: "BDMICMIC",
    strloc: "DALKEITH VILLAGE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DALK03",
    pstcd: "BDMICMIC",
    strloc: "DALKEITH HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DALK04",
    pstcd: "BDMICMIC",
    strloc: "DALKEITH RIDGE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DALK05",
    pstcd: "BDMICMIC",
    strloc: "DALKEITH, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DANE01",
    pstcd: "BDMICBLA",
    strloc: "DANESBURY, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DANI09",
    pstcd: "BDGEOGEO",
    strloc: "DANIELS LAND, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "DASH01",
    pstcd: "BDCHRCHR",
    strloc: "DASH STREET, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "DASH02",
    pstcd: "BDMICBAN",
    strloc: "DASH ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DASH03",
    pstcd: "BDGEOGEO",
    strloc: "DASH VALLEY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "DASH04",
    pstcd: "BDMICMIC",
    strloc: "DASH GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DASH05",
    pstcd: "BDMICBAN",
    strloc: "DASH GAP AVENUE 1, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DASH06",
    pstcd: "BDMICBAN",
    strloc: "DASH GAP AVENUE 2, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DASH07",
    pstcd: "BDMICBAN",
    strloc: "DASH GAP AVENUE 3, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DATE01",
    pstcd: "BDPETPET",
    strloc: "DATE TREE HILL VILLAGE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "DATE02",
    pstcd: "BDPETPET",
    strloc: "DATE TREE HILL, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "DAYR01",
    pstcd: "BDMICMIC",
    strloc: "DAYRELLS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DAYR02",
    pstcd: "BDMICMIC",
    strloc: "DAYRELLS ROAD 1ST AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DAYR03",
    pstcd: "BDCHRCHR",
    strloc: "DAYRELLS HILL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "DAYR04",
    pstcd: "BDMICMIC",
    strloc: "DAYRELLS HEIGHTS 1ST AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DAYR05",
    pstcd: "BDCHRCHR",
    strloc: "DAYRELLS ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "DEAC01",
    pstcd: "BDMICMIC",
    strloc: "DEACONS FARM, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DEAC02",
    pstcd: "BDMICMIC",
    strloc: "DEACONS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DEAC03",
    pstcd: "BDMICMIC",
    strloc: "DEACONS HOUSING AREA, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DEAL01",
    pstcd: "BDCHRCHR",
    strloc: "DEAL GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "DEAN03",
    pstcd: "BDJAMJAM",
    strloc: "DEANS VILLAGE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DEAN03",
    pstcd: "BDMICMIC",
    strloc: "DEANS VILLAGE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DEAN03",
    pstcd: "BDTHOTHO",
    strloc: "DEANS VILLAGE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "DEAN04",
    pstcd: "BDCHRCHR",
    strloc: "DEAN, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "DEAN05",
    pstcd: "BDMICMIC",
    strloc: "DEANS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DEAR01",
    pstcd: "BDMICBLA",
    strloc: "DEARS LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DEIG01",
    pstcd: "BDMICMIC",
    strloc: "DEIGHTON VILLAGE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DEIG02",
    pstcd: "BDMICMIC",
    strloc: "DEIGHTON ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DEIG04",
    pstcd: "BDMICMIC",
    strloc: "DEIGHTONS ROAD, 1ST AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DEIG05",
    pstcd: "BDMICMIC",
    strloc: "DEIGHTONS ROAD, 2ND AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DELA16",
    pstcd: "BDMICMIC",
    strloc: "DELAWARE LANDS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DENN04",
    pstcd: "BDJAMJAM",
    strloc: "DENNY ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DENN04",
    pstcd: "BDPETPET",
    strloc: "DENNY ROAD, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "DENN05",
    pstcd: "BDJAMJAM",
    strloc: "DENNYS ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DENT01",
    pstcd: "BDMICMIC",
    strloc: "DENTON ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DERI01",
    pstcd: "BDMICMIC",
    strloc: "DERISTON TERR, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DERR05",
    pstcd: "BDJAMJAM",
    strloc: "DERRICKS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DEVI01",
    pstcd: "BDMICMIC",
    strloc: "DEVISTON ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DIAM05",
    pstcd: "BDPHIPHI",
    strloc: "DIAMOND LAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "DIAM06",
    pstcd: "BDPETPET",
    strloc: "DIAMOND CORNER, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "DIAM07",
    pstcd: "BDPETSPE",
    strloc: "DIAMOND VALLEY, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "DIAM07",
    pstcd: "BDPHIPHI",
    strloc: "DIAMOND VALLEY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "DIAM08",
    pstcd: "BDPETSPE",
    strloc: "DIAMOND ROCK CORNER, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "DIAM09",
    pstcd: "BDCHRCHR",
    strloc: "DIAMOND CLOSE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "DISC01",
    pstcd: "BDJAMJAM",
    strloc: "DISCOVERY, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DIVI01",
    pstcd: "BDMICMIC",
    strloc: "DIVISION DRIVE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DODD01",
    pstcd: "BDPHIPHI",
    strloc: "DODDS LAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "DODD02",
    pstcd: "BDPHIPHI",
    strloc: "DODDS PLANTATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "DODS01",
    pstcd: "BDCHRCHR",
    strloc: "DODSON'S LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "DODS01",
    pstcd: "BDMICBLA",
    strloc: "DODSON'S LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DOLP02",
    pstcd: "BDMICMIC",
    strloc: "DOLPHIN COURTS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DOOL04",
    pstcd: "BDLUCLUC",
    strloc: "DOOLEYS, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "DOTT02",
    pstcd: "BDBGTBGT",
    strloc: "DOTTINS, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "DOTT02",
    pstcd: "BDBGTBGT",
    strloc: "DOTTINS, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "DOTT02",
    pstcd: "BDBGTBGT",
    strloc: "DOTTINS, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "DOTT02",
    pstcd: "BDBGTBGT",
    strloc: "DOTTINS, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "DOUG07",
    pstcd: "BDANDAND",
    strloc: "DOUGLINS VILLAGE, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "DOUG08",
    pstcd: "BDPETPET",
    strloc: "DOUGLAS DEVELOPMENT, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "DOUG09",
    pstcd: "BDJAMJAM",
    strloc: "DOUGHLIN GAP, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DOUG10",
    pstcd: "BDJAMJAM",
    strloc: "DOUGLINS ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DOVE05",
    pstcd: "BDCHRCHR",
    strloc: "DOVER TERRACE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "DOVE06",
    pstcd: "BDCHRCHR",
    strloc: "DOVER GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "DOVE07",
    pstcd: "BDCHRCHR",
    strloc: "DOVER, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "DOVE08",
    pstcd: "BDMICCAV",
    strloc: "DOVER CRESCENT, CAVE HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DOWN02",
    pstcd: "BDTHOTHO",
    strloc: "DOWNES GAP, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "DOWN03",
    pstcd: "BDMICBAN",
    strloc: "DOWNEY GAP, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DOWN04",
    pstcd: "BDMICMIC",
    strloc: "DOWN HILL DRIVE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DRAK01",
    pstcd: "BDPHIPHI",
    strloc: "DRAKES LAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "DRAK02",
    pstcd: "BDPHIPHI",
    strloc: "DRAKES, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "DRAX02",
    pstcd: "BDGEOGEO",
    strloc: "DRAX HALL HOPE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "DRAX03",
    pstcd: "BDGEOGEO",
    strloc: "DRAX HALL TERRACE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "DRAX04",
    pstcd: "BDGEOGEO",
    strloc: "DRAX HALL TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "DRAX05",
    pstcd: "BDGEOGEO",
    strloc: "DRAX HALL WOODS, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "DRAX06",
    pstcd: "BDGEOGEO",
    strloc: "DRAX HALL JUMP, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "DRCL01",
    pstcd: "BDMICMIC",
    strloc: "DR CLARKES GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DROM01",
    pstcd: "BDBGTBGT",
    strloc: "DROMEDARY LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "DROM01",
    pstcd: "BDBGTBGT",
    strloc: "DROMEDARY LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "DRUM01",
    pstcd: "BDMICMIC",
    strloc: "DRUMORE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DUKE03",
    pstcd: "BDTHOTHO",
    strloc: "DUKES TENANTRY, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "DUKE04",
    pstcd: "BDTHOTHO",
    strloc: "DUKES PLANTATION, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "DUKE05",
    pstcd: "BDTHOTHO",
    strloc: "DUKES, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "DUNC04",
    pstcd: "BDPHIPHI",
    strloc: "DUNCANS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "DUNC05",
    pstcd: "BDTHOTHO",
    strloc: "DUNCOMB, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "DUNL02",
    pstcd: "BDMICMIC",
    strloc: "DUNLOW LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "DUNS01",
    pstcd: "BDTHOTHO",
    strloc: "DUNSCOMBE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "DUNS02",
    pstcd: "BDTHOTHO",
    strloc: "DUNSCOMBE PLANTATION, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "DURA03",
    pstcd: "BDCHRCHR",
    strloc: "DURANCT TERRACE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "DURA04",
    pstcd: "BDCHRCHR",
    strloc: "DURANTS ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "DURA05",
    pstcd: "BDCHRCHR",
    strloc: "DURANTS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "DURA05",
    pstcd: "BDJAMJAM",
    strloc: "DURANTS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DURA06",
    pstcd: "BDCHRCHR",
    strloc: "DURANTS DEVELOPMENT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "DURA07",
    pstcd: "BDJAMJAM",
    strloc: "DURANTS VILLAGE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DURA08",
    pstcd: "BDJAMJAM",
    strloc: "DURANTS DEVELOPMENT AREA, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DURA09",
    pstcd: "BDJAMJAM",
    strloc: "DURANTS ROAD 4TH AVE., ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DURA10",
    pstcd: "BDJAMJAM",
    strloc: "DURANTS ROAD 1ST AVE N/S, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DURA11",
    pstcd: "BDJAMJAM",
    strloc: "DURANTS ROAD 2ND AVE N/S, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DURA12",
    pstcd: "BDJAMJAM",
    strloc: "DURANTS ROAD 3RD AVE N/S, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DURA13",
    pstcd: "BDJAMJAM",
    strloc: "DURANTS ROAD 4TH AVE N/S, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DURA14",
    pstcd: "BDJAMJAM",
    strloc: "DURANTS ROAD 5TH AVE N/S, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DURA15",
    pstcd: "BDJAMJAM",
    strloc: "DURANTS VILLAGE NO 2, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DURA16",
    pstcd: "BDJAMJAM",
    strloc: "DURANTS VILLAGE NO 1, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DURA17",
    pstcd: "BDJAMJAM",
    strloc: "DURANTS ROAD 2ND AVE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DURA18",
    pstcd: "BDJAMJAM",
    strloc: "DURANTS ROAD 3RD AVE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DURA19",
    pstcd: "BDJAMJAM",
    strloc: "DURANTS VILLAGE NO 3, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DURA20",
    pstcd: "BDJAMJAM",
    strloc: "DURANT'S RIDGE ROAD NO 3, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "DURE01",
    pstcd: "BDPHIPHI",
    strloc: "DURETTE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "DURH02",
    pstcd: "BDLUCLUC",
    strloc: "DURHAMS, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "DURH03",
    pstcd: "BDMICMIC",
    strloc: "DURHAM CLOSE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "EAGL05",
    pstcd: "BDMICMIC",
    strloc: "EAGLE HALL ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "EAGL06",
    pstcd: "BDMICMIC",
    strloc: "EAGLE HALL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "EALI01",
    pstcd: "BDMICMIC",
    strloc: "EALING ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "EALI02",
    pstcd: "BDCHRCHR",
    strloc: "EALING PARK STAGE 1-5, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "EALI03",
    pstcd: "BDCHRCHR",
    strloc: "EALING GROVE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "EALI04",
    pstcd: "BDCHRCHR",
    strloc: "EALING PARK, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "EAST11",
    pstcd: "BDJOHJOH",
    strloc: "EASTMONT, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "EAST12",
    pstcd: "BDANDAND",
    strloc: "EAST COAST ROAD, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "EAST13",
    pstcd: "BDGEOGEO",
    strloc: "EASTLYN, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "EAST14",
    pstcd: "BDPETPET",
    strloc: "EASTRY GARDENS, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "EAST15",
    pstcd: "BDPHIPHI",
    strloc: "EASTPOINT PLANTATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "EAST16",
    pstcd: "BDPHIPHI",
    strloc: "EASTPOINT, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "EAST17",
    pstcd: "BDPHIPHI",
    strloc: "EASTBOURNE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "EAST18",
    pstcd: "BDMICPIN",
    strloc: "EAST TERRACE ROAD 5TH AVENUE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "EAST19",
    pstcd: "BDMICPIN",
    strloc: "EAST TERRACE ROAD 6TH AVENUE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "EAST20",
    pstcd: "BDMICMIC",
    strloc: "EASTMOND ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "EAST21",
    pstcd: "BDMICMIC",
    strloc: "EAST TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "EAST22",
    pstcd: "BDMICMIC",
    strloc: "EAST TERRACE ROAD 3RD AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "EAST23",
    pstcd: "BDMICMIC",
    strloc: "EAST TERRACE ROAD 4TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "EAST24",
    pstcd: "BDJAMJAM",
    strloc: "EAST ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "EASY02",
    pstcd: "BDJOHJOH",
    strloc: "EASY HALL TENANTRY, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "EASY02",
    pstcd: "BDJOSJOS",
    strloc: "EASY HALL TENANTRY, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "EASY03",
    pstcd: "BDJOSJOS",
    strloc: "EASY HALL, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "EBEN01",
    pstcd: "BDPHIPHI",
    strloc: "EBENEZER, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "EBSW01",
    pstcd: "BDPETPET",
    strloc: "EBSWORTH TENANTRY, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ECKS01",
    pstcd: "BDMICMIC",
    strloc: "ECKSTEIN VILLAGE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "EDEN05",
    pstcd: "BDMICMIC",
    strloc: "EDEN LODGE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "EDEN06",
    pstcd: "BDMICMIC",
    strloc: "EDEN LODGE HOUSING AREA, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "EDEY01",
    pstcd: "BDCHRCHR",
    strloc: "EDEYS VILLAGE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "EDEY01",
    pstcd: "BDJOHJOH",
    strloc: "EDEYS VILLAGE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "EDEY02",
    pstcd: "BDMICMIC",
    strloc: "EDEY'S LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "EDGE01",
    pstcd: "BDJOHJOH",
    strloc: "EDGECLIFF, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "EDGE01",
    pstcd: "BDTHOTHO",
    strloc: "EDGECLIFF, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "EDGE03",
    pstcd: "BDTHOTHO",
    strloc: "EDGEHILL INDUSTRIAL ESTATE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "EDGE04",
    pstcd: "BDTHOTHO",
    strloc: "EDGEHILL DEVELOPMENT, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "EDGE05",
    pstcd: "BDTHOTHO",
    strloc: "EDGEHILL HEIGHTS, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "EDGE06",
    pstcd: "BDTHOTHO",
    strloc: "EDGEHILL, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "EDGE07",
    pstcd: "BDJOSJOS",
    strloc: "EDGEWATER, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "EDGE08",
    pstcd: "BDPHIPHI",
    strloc: "EDGECOMBE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "EDGE09",
    pstcd: "BDPHIPHI",
    strloc: "EDGECOMBE TENANTRY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "EDGE10",
    pstcd: "BDGEOGEO",
    strloc: "EDGERTON PLANTATION, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "EDGE11",
    pstcd: "BDMICPIN",
    strloc: "EDGECLIFF AVENUE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "EDWA06",
    pstcd: "BDCHRCHR",
    strloc: "EDWARD GAP, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ELIZ06",
    pstcd: "BDCHRCHR",
    strloc: "ELIZABETH PARK, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ELIZ07",
    pstcd: "BDMICPIN",
    strloc: "ELIZABETH DRIVE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ELLE02",
    pstcd: "BDGEOGEO",
    strloc: "ELLERTON, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "ELLE06",
    pstcd: "BDGEOGEO",
    strloc: "ELLERTON HOUSING AREA, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "ELLE07",
    pstcd: "BDGEOGEO",
    strloc: "ELLERTON PARK, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "ELLE08",
    pstcd: "BDGEOGEO",
    strloc: "ELLESMERE PLANTATION, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "ELLE09",
    pstcd: "BDMICMIC",
    strloc: "ELLERSLIE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ELLE10",
    pstcd: "BDMICMIC",
    strloc: "ELLERSLIE GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ELLI05",
    pstcd: "BDLUCLUC",
    strloc: "ELLIS TENANTRY ROAD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "ELLI06",
    pstcd: "BDMICMIC",
    strloc: "ELLIS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ELLI06",
    pstcd: "BDMICPIN",
    strloc: "ELLIS ROAD, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ELLI07",
    pstcd: "BDMICBLA",
    strloc: "ELLIS DEVELOPMENT, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "EMER04",
    pstcd: "BDPETPET",
    strloc: "EMERALD RIDGE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "EMER05",
    pstcd: "BDCHRCHR",
    strloc: "EMERALD LANE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "EMER06",
    pstcd: "BDCHRCHR",
    strloc: "EMERALD AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "EMER13",
    pstcd: "BDPHIPHI",
    strloc: "EMERALD PARK, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "EMME01",
    pstcd: "BDMICMIC",
    strloc: "EMMERTON LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ENDE03",
    pstcd: "BDJAMJAM",
    strloc: "ENDEAVOUR, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "ENDE03",
    pstcd: "BDJOHJOH",
    strloc: "ENDEAVOUR, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "ENDE03",
    pstcd: "BDTHOTHO",
    strloc: "ENDEAVOUR, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "ENGI01",
    pstcd: "BDMICMIC",
    strloc: "ENGINEER PIER, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ENMO01",
    pstcd: "BDMICMIC",
    strloc: "ENMORE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ENTE02",
    pstcd: "BDCHRCHR",
    strloc: "ENTERPRISE MAIN ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ENTE03",
    pstcd: "BDCHRCHR",
    strloc: "ENTERPRISE DEVELOPMENT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ENTE04",
    pstcd: "BDCHRCHR",
    strloc: "ENTERPRISE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ENTE05",
    pstcd: "BDCHRCHR",
    strloc: "ENTERPRISE GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ENTE06",
    pstcd: "BDCHRCHR",
    strloc: "ENTERPRISE COAST ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ERDI01",
    pstcd: "BDMICPIN",
    strloc: "ERDISTON DRIVE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ERDI02",
    pstcd: "BDMICPIN",
    strloc: "ERDISTON HILL, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ESPL01",
    pstcd: "BDPETPET",
    strloc: "ESPLANADE GARDENS, GIBBS, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "EURO02",
    pstcd: "BDJAMSUN",
    strloc: "EUROPA, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "EVEL04",
    pstcd: "BDMICMIC",
    strloc: "EVELYN'S AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "EVEL05",
    pstcd: "BDPHIPHI",
    strloc: "EVELYN TENANTRY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "EVEL06",
    pstcd: "BDCHRCHR",
    strloc: "EVELYN LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "EVEL07",
    pstcd: "BDCHRROC",
    strloc: "EVELYN'S TERRACE, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "EVER04",
    pstcd: "BDMICMIC",
    strloc: "EVERSLEY ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "EVER05",
    pstcd: "BDMICMIC",
    strloc: "EVERSLEY GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "EVER06",
    pstcd: "BDCHRCHR",
    strloc: "EVERGREEN ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "EVER07",
    pstcd: "BDCHRCHR",
    strloc: "EVERGREEN GROVE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "EXCH03",
    pstcd: "BDGEOGEO",
    strloc: "EXCHANGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "EXCH03",
    pstcd: "BDLUCLUC",
    strloc: "EXCHANGE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "EXCH03",
    pstcd: "BDTHOTHO",
    strloc: "EXCHANGE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "EXCH04",
    pstcd: "BDTHOTHO",
    strloc: "EXCHANGE PLANTATION, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "EXCH05",
    pstcd: "BDMICMIC",
    strloc: "EXCHANGE HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "EXET01",
    pstcd: "BDCHRCHR",
    strloc: "EXETER ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "EXET01",
    pstcd: "BDMICPIN",
    strloc: "EXETER ROAD, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "EXMO01",
    pstcd: "BDMICMIC",
    strloc: "EXMOUTH GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FACT02",
    pstcd: "BDMICMIC",
    strloc: "FACTORY AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FAIR09",
    pstcd: "BDMICBLA",
    strloc: "FAIRHOLME, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FAIR10",
    pstcd: "BDMICMIC",
    strloc: "FAIRVIEW PLACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FAIR11",
    pstcd: "BDMICMIC",
    strloc: "FAIRFIELD CROSS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FAIR12",
    pstcd: "BDBGTBGT",
    strloc: "FAIRCHILD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "FAIR12",
    pstcd: "BDBGTBGT",
    strloc: "FAIRCHILD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "FAIR12",
    pstcd: "BDBGTBGT",
    strloc: "FAIRCHILD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "FAIR12",
    pstcd: "BDBGTBGT",
    strloc: "FAIRCHILD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "FAIR12",
    pstcd: "BDBGTBGT",
    strloc: "FAIRCHILD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "FAIR13",
    pstcd: "BDGEOGEO",
    strloc: "FAIR COTTAGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "FAIR14",
    pstcd: "BDGEOGEO",
    strloc: "FAIRVIEW HEIGHTS, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "FAIR15",
    pstcd: "BDGEOGEO",
    strloc: "FAIRVIEW TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "FAIR16",
    pstcd: "BDCHRCHR",
    strloc: "FAIRVIEW, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "FAIR16",
    pstcd: "BDGEOGEO",
    strloc: "FAIRVIEW, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "FAIR16",
    pstcd: "BDLUCLUC",
    strloc: "FAIRVIEW, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "FAIR17",
    pstcd: "BDGEOGEO",
    strloc: "FAIRVIEW PLANTATION, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "FAIR18",
    pstcd: "BDLUCLUC",
    strloc: "FAIRFIELD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "FAIR18",
    pstcd: "BDMICMIC",
    strloc: "FAIRFIELD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FAIR19",
    pstcd: "BDCHRCHR",
    strloc: "FAIRHOLME GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "FAIR20",
    pstcd: "BDCHRCHR",
    strloc: "FAIRWAYS GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "FAIR21",
    pstcd: "BDCHRCHR",
    strloc: "FAIRY VALLEY LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "FAIR22",
    pstcd: "BDMICMIC",
    strloc: "FAIRFIELD MAIN ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FAIR23",
    pstcd: "BDMICMIC",
    strloc: "FAIRFIELD DEVELOPMENT, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FAIR24",
    pstcd: "BDMICMIC",
    strloc: "FAIRFIELD 1ST AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FAIR25",
    pstcd: "BDMICMIC",
    strloc: "FAIRFIELD 2ND AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FAIR26",
    pstcd: "BDPHIPHI",
    strloc: "FAIRFIELD TENANTRY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "FAIR27",
    pstcd: "BDCHRCHR",
    strloc: "FAIRY VALLEY PLANTATION, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "FAIR49",
    pstcd: "BDCHRCHR",
    strloc: "FAIRVIEW GLADES, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "FALM01",
    pstcd: "BDPHIPHI",
    strloc: "FALMOUTH DEVELOPMENT, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "FARL01",
    pstcd: "BDPETPET",
    strloc: "FARLEY HILL, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "FARM01",
    pstcd: "BDGEOGEO",
    strloc: "FARM ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "FARM01",
    pstcd: "BDMICMIC",
    strloc: "FARM ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FARM01",
    pstcd: "BDPETPET",
    strloc: "FARM ROAD, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "FARM01",
    pstcd: "BDPETSPE",
    strloc: "FARM ROAD, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "FARM01",
    pstcd: "BDPHIPHI",
    strloc: "FARM ROAD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "FARM05",
    pstcd: "BDTHOTHO",
    strloc: "FARM PARK, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "FARM06",
    pstcd: "BDTHOTHO",
    strloc: "FARMERS HOUSING AREA, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "FARM07",
    pstcd: "BDTHOTHO",
    strloc: "FARMERS, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "FARM08",
    pstcd: "BDTHOTHO",
    strloc: "FARMERS PLANTATION, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "FARM09",
    pstcd: "BDGEOGEO",
    strloc: "FARM DEVELOPMENT, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "FARM10",
    pstcd: "BDPETSPE",
    strloc: "FARM TENANTRY, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "FARM11",
    pstcd: "BDPETPET",
    strloc: "FARM PLANTATION, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "FARM12",
    pstcd: "BDPHIPHI",
    strloc: "FARMER BIM, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "FARM13",
    pstcd: "BDPHIPHI",
    strloc: "FARM COTTAGE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "FARM15",
    pstcd: "BDPHIPHI",
    strloc: "FARM GARDENS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "FARM16",
    pstcd: "BDMICMIC",
    strloc: "FARM HOUSING AREA, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FARM17",
    pstcd: "BDMICMIC",
    strloc: "FARM GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FARN01",
    pstcd: "BDBGTBGT",
    strloc: "FARNELLS ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "FARN02",
    pstcd: "BDMICMIC",
    strloc: "FARNUMS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FARR02",
    pstcd: "BDANDAND",
    strloc: "FARREL ROAD, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "FEAT01",
    pstcd: "BDJOHJOH",
    strloc: "FEATHERBED LANE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "FERN05",
    pstcd: "BDMICMIC",
    strloc: "FERNIHURST DEVELOPMENT, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FERR05",
    pstcd: "BDANDAND",
    strloc: "FERRY PEN, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "FIEL05",
    pstcd: "BDMICMIC",
    strloc: "FIELD PLACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FIEL06",
    pstcd: "BDMICMIC",
    strloc: "FIELDS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FIEL07",
    pstcd: "BDMICMIC",
    strloc: "FIELDS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FIRS10",
    pstcd: "BDGEOGEO",
    strloc: "FIRST STEP, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "FISH04",
    pstcd: "BDGEOGEO",
    strloc: "FISHERPOND, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "FISH04",
    pstcd: "BDJOSJOS",
    strloc: "FISHERPOND, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "FISH04",
    pstcd: "BDTHOTHO",
    strloc: "FISHERPOND, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "FISH05",
    pstcd: "BDTHOTHO",
    strloc: "FISHERPOND TENANTRY, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "FISH06",
    pstcd: "BDTHOTHO",
    strloc: "FISHERPOND PLANTATION, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "FITT03",
    pstcd: "BDJAMJAM",
    strloc: "FITTS VILLAGE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "FITT04",
    pstcd: "BDJAMJAM",
    strloc: "FITTS VILLAGE MAIN ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "FITT05",
    pstcd: "BDMICMIC",
    strloc: "FITTS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FLAG03",
    pstcd: "BDMICMIC",
    strloc: "FLAGSTAFF, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FLAT01",
    pstcd: "BDGEOGEO",
    strloc: "FLAT ROCK, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "FLAT02",
    pstcd: "BDLUCLUC",
    strloc: "FLATFIELD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "FLEE01",
    pstcd: "BDGEOGEO",
    strloc: "FLEET WOOD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "FLIN01",
    pstcd: "BDMICMIC",
    strloc: "FLINT HALL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FOLK01",
    pstcd: "BDJAMJAM",
    strloc: "FOLKSTONE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "FONT01",
    pstcd: "BDMICFON",
    strloc: "FONTABELE, FONTABELLE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FORD03",
    pstcd: "BDMICBAN",
    strloc: "FORDE'S ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FORD03",
    pstcd: "BDMICMIC",
    strloc: "FORDE'S ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FORD03",
    pstcd: "BDMICMIC",
    strloc: "FORDE'S ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FORD04",
    pstcd: "BDMICMIC",
    strloc: "FORDES ROAD 2ND AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FORD05",
    pstcd: "BDMICMIC",
    strloc: "FORDES ROAD 3RD AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FORD06",
    pstcd: "BDMICMIC",
    strloc: "FORDES ROAD 4TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FORD07",
    pstcd: "BDMICMIC",
    strloc: "FORDS ROAD 5TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FORD08",
    pstcd: "BDMICMIC",
    strloc: "FORDES ROAD 6TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FORD09",
    pstcd: "BDMICMIC",
    strloc: "FORDES ROAD 8TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FORD10",
    pstcd: "BDCHRCHR",
    strloc: "FORDES LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "FORD10",
    pstcd: "BDMICMIC",
    strloc: "FORDES LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FORD11",
    pstcd: "BDMICMIC",
    strloc: "FORDES TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FORT06",
    pstcd: "BDMICMIC",
    strloc: "FORT GEORGE HEIGHTS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FORT07",
    pstcd: "BDTHOTHO",
    strloc: "FORTRESS HILL, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "FORT08",
    pstcd: "BDTHOTHO",
    strloc: "FORTY ACRES, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "FORT09",
    pstcd: "BDPHIPHI",
    strloc: "FORTESQUE HILL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "FORT10",
    pstcd: "BDPHIPHI",
    strloc: "FORTESQUE DEVELOPMENT, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "FORT11",
    pstcd: "BDPHIPHI",
    strloc: "FORTESQUE PLANTATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "FORT12",
    pstcd: "BDPHIPHI",
    strloc: "FORTESQUE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "FOST01",
    pstcd: "BDLUCLUC",
    strloc: "FOSTER ROAD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "FOST04",
    pstcd: "BDJOHJOH",
    strloc: "FOSTER HALL, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "FOST05",
    pstcd: "BDJOSJOS",
    strloc: "FOSTER HALL TENANTRY, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "FOST06",
    pstcd: "BDGEOGEO",
    strloc: "FOSTER LODGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "FOUL01",
    pstcd: "BDPHIPHI",
    strloc: "FOUL BAY NEW ROAD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "FOUL02",
    pstcd: "BDPHIPHI",
    strloc: "FOUL BAY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "FOUL03",
    pstcd: "BDPHIPHI",
    strloc: "FOUL BAY TENANTRY ROAD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "FOUN01",
    pstcd: "BDMICMIC",
    strloc: "FOUNTAIN ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FOUR03",
    pstcd: "BDJOHJOH",
    strloc: "FOUR ROADS, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "FOUR03",
    pstcd: "BDPHIPHI",
    strloc: "FOUR ROADS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "FOUR05",
    pstcd: "BDJOHJOH",
    strloc: "FOURTH DRIVE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "FOUR13",
    pstcd: "BDPETPET",
    strloc: "FOUR HILL PLANTATION, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "FOUR14",
    pstcd: "BDPETPET",
    strloc: "FOUR HILL TENANTRY, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "FOUR15",
    pstcd: "BDPHIPHI",
    strloc: "FOURSQUARE PLANTATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "FOUR16",
    pstcd: "BDPHIPHI",
    strloc: "FOURSQUARE FACTORY & QUARTERS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "FOUR17",
    pstcd: "BDPHIPHI",
    strloc: "FOURSQUARE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "FOUR18",
    pstcd: "BDPHIPHI",
    strloc: "FOURSQUARE VALLEY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "FOUR19",
    pstcd: "BDMICMIC",
    strloc: "FOUR AVENUES PARK ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FOXC01",
    pstcd: "BDJAMJAM",
    strloc: "FOX CLUB GAP, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "FRAN04",
    pstcd: "BDANDAND",
    strloc: "FRANKLYN ROAD, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "FRAN19",
    pstcd: "BDPHIPHI",
    strloc: "FRANKLYN DEVELOPMENT, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "FRAN20",
    pstcd: "BDJAMJAM",
    strloc: "FRANOIPIA ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "FRED07",
    pstcd: "BDMICMIC",
    strloc: "FREDERICKS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FREE09",
    pstcd: "BDGEOGEO",
    strloc: "FREE HILL, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "FREE09",
    pstcd: "BDLUCLUC",
    strloc: "FREE HILL, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "FREE09",
    pstcd: "BDMICBLA",
    strloc: "FREE HILL, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FREE09",
    pstcd: "BDPHIPHI",
    strloc: "FREE HILL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "FREE10",
    pstcd: "BDMICBLA",
    strloc: "FREE HILL DEVELOPMENT, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FREE11",
    pstcd: "BDMICBLA",
    strloc: "FREE HILL CRESCENT, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FREE12",
    pstcd: "BDMICMIC",
    strloc: "FREEMANS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FREN02",
    pstcd: "BDGEOGEO",
    strloc: "FRENCHES TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "FREN03",
    pstcd: "BDPETPET",
    strloc: "FRENCH VILLAGE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "FREN05",
    pstcd: "BDGEOGEO",
    strloc: "Frenches Gardens, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "FRER01",
    pstcd: "BDCHRFRE",
    strloc: "FRERE PILGRIM, FRERE PILGRIM - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "FRER02",
    pstcd: "BDCHRFRE",
    strloc: "FRERE PILGRIM DEVELOPMENT, FRERE PILGRIM - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "FRES01",
    pstcd: "BDMICMIC",
    strloc: "FRESHWATER BAY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FREY01",
    pstcd: "BDLUCLUC",
    strloc: "FREYERS WELL, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "FRIE03",
    pstcd: "BDLUCLUC",
    strloc: "FRIENDLY HALL, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "FRIE03",
    pstcd: "BDMICMIC",
    strloc: "FRIENDLY HALL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FRIE04",
    pstcd: "BDMICMIC",
    strloc: "FRIENDSHIP TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FRIE05",
    pstcd: "BDLUCLUC",
    strloc: "FRIENDSHIP TENANTRY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "FRIE06",
    pstcd: "BDANDAND",
    strloc: "FRIENDSHIP, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "FRIE06",
    pstcd: "BDMICMIC",
    strloc: "FRIENDSHIP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FRIE07",
    pstcd: "BDMICMIC",
    strloc: "FRIENDSHIP PLANTATION, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FRIE08",
    pstcd: "BDMICMIC",
    strloc: "FRIENDSHIP GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FRIE09",
    pstcd: "BDMICMIC",
    strloc: "FRIENDSHIP HALL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FRIZ01",
    pstcd: "BDJOSJOS",
    strloc: "FRIZERS, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "FRUI01",
    pstcd: "BDJOSJOS",
    strloc: "FRUITFUL HILL, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "FRYI01",
    pstcd: "BDMICMIC",
    strloc: "FRYING PAN ALLEY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "FURY01",
    pstcd: "BDJOSJOS",
    strloc: "FURY, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "FUSI01",
    pstcd: "BDGEOGEO",
    strloc: "FUSILIER ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "FUST01",
    pstcd: "BDLUCLUC",
    strloc: "FUSTIC VILLAGE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "GAGG01",
    pstcd: "BDJOSJOS",
    strloc: "GAGGS HILL, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "GALL04",
    pstcd: "BDCHRCHR",
    strloc: "GALL HILL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GALL04",
    pstcd: "BDJOHJOH",
    strloc: "GALL HILL, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "GALL05",
    pstcd: "BDCHRCHR",
    strloc: "GALL HILL NEW LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GALL06",
    pstcd: "BDCHRCHR",
    strloc: "GALL HILL HOUSING AREA, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GALL07",
    pstcd: "BDMICMIC",
    strloc: "GALLOWAY LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GALW01",
    pstcd: "BDMICMIC",
    strloc: "GALWAY ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GARD07",
    pstcd: "BDMICBAN",
    strloc: "GARDEN ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GARD08",
    pstcd: "BDMICBAN",
    strloc: "GARDEN LAND, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GARD09",
    pstcd: "BDCHRROC",
    strloc: "GARDEN GAP NO. 1, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GARD10",
    pstcd: "BDCHRROC",
    strloc: "GARDEN GAP NO. 2, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GARD11",
    pstcd: "BDJAMSUN",
    strloc: "GARDENIA AVENUE, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "GARD11",
    pstcd: "BDJAMWES",
    strloc: "GARDENIA AVENUE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "GARD12",
    pstcd: "BDJAMJAM",
    strloc: "GARDEN, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "GARL01",
    pstcd: "BDMICMIC",
    strloc: "GARLOW PATH, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GARN05",
    pstcd: "BDPHIPHI",
    strloc: "GARNER'S LAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "GARN06",
    pstcd: "BDPHIPHI",
    strloc: "GARNETT ROAD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "GARR02",
    pstcd: "BDMICMIC",
    strloc: "GARRISON, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GARR03",
    pstcd: "BDMICMIC",
    strloc: "GARRISON HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GASK01",
    pstcd: "BDMICMIC",
    strloc: "GASKIN LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GASK01",
    pstcd: "BDPHIPHI",
    strloc: "GASKIN LAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "GASK02",
    pstcd: "BDPHIPHI",
    strloc: "GASKINS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "GASP05",
    pstcd: "BDMICMIC",
    strloc: "GAS PRODUCT GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GAYS01",
    pstcd: "BDPETPET",
    strloc: "GAYS VILLAGE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "GEMS01",
    pstcd: "BDPHIPHI",
    strloc: "GEMSWICK, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "GEMS02",
    pstcd: "BDPHIPHI",
    strloc: "GEMSWICK INDUSTRIAL PARK, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "GEOR01",
    pstcd: "BDMICBEL",
    strloc: "GEORGE STREET, BELLEVILLE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GERA05",
    pstcd: "BDJAMJAM",
    strloc: "GERANIUM ROW, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "GIBB05",
    pstcd: "BDPETPET",
    strloc: "GIBBS ROAD, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "GIBB06",
    pstcd: "BDJAMJAM",
    strloc: "GIBBS HILL, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "GIBB06",
    pstcd: "BDPETPET",
    strloc: "GIBBS HILL, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "GIBB07",
    pstcd: "BDPETPET",
    strloc: "GIBBS GAP, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "GIBB08",
    pstcd: "BDPETPET",
    strloc: "GIBBS BEACH RESIDENTIAL AREA, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "GIBB09",
    pstcd: "BDPETPET",
    strloc: "GIBBS GLADE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "GIBB10",
    pstcd: "BDCHRCHR",
    strloc: "GIBBONS TERRACE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GIBB11",
    pstcd: "BDCHRCHR",
    strloc: "GIBBONS BROW, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GIBB12",
    pstcd: "BDCHRCHR",
    strloc: "GIBBONS CRESCENT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GIBB13",
    pstcd: "BDCHRCHR",
    strloc: "GIBBONS INDUSTRIAL PARK, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GIBB14",
    pstcd: "BDCHRCHR",
    strloc: "GIBBONS HILL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GIBB15",
    pstcd: "BDCHRCHR",
    strloc: "GIBBONS BOGGS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GIBB16",
    pstcd: "BDPETPET",
    strloc: "GIBBS, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "GIBB18",
    pstcd: "BDCHRCHR",
    strloc: "GIBBONS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GIBS02",
    pstcd: "BDPHIPHI",
    strloc: "GIBSON LAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "GIBS02",
    pstcd: "BDTHOTHO",
    strloc: "GIBSON LAND, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "GILK02",
    pstcd: "BDJAMJAM",
    strloc: "GILKES ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "GILK02",
    pstcd: "BDLUCLUC",
    strloc: "GILKES ROAD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "GILK02",
    pstcd: "BDMICMIC",
    strloc: "GILKES ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GILK03",
    pstcd: "BDJAMJAM",
    strloc: "GILKES VILLAGE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "GILK03",
    pstcd: "BDLUCLUC",
    strloc: "GILKES VILLAGE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "GILK04",
    pstcd: "BDMICMIC",
    strloc: "GILKES LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GILK05",
    pstcd: "BDMICMIC",
    strloc: "GILKES GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GILL05",
    pstcd: "BDMICMIC",
    strloc: "GILLS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GILL06",
    pstcd: "BDBGTBGT",
    strloc: "GILLS ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "GILL06",
    pstcd: "BDBGTBGT",
    strloc: "GILLS ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "GILL06",
    pstcd: "BDBGTBGT",
    strloc: "GILLS ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "GILL06",
    pstcd: "BDBGTBGT",
    strloc: "GILLS ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "GILL06",
    pstcd: "BDBGTBGT",
    strloc: "GILLS ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "GILL06",
    pstcd: "BDBGTBGT",
    strloc: "GILLS ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "GILL06",
    pstcd: "BDBGTBGT",
    strloc: "GILLS ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "GILL06",
    pstcd: "BDMICMIC",
    strloc: "GILLS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GILL07",
    pstcd: "BDLUCLUC",
    strloc: "GILLS TENANTRY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "GILL08",
    pstcd: "BDPETSPE",
    strloc: "GILLS TERRACE, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "GILL09",
    pstcd: "BDJAMJAM",
    strloc: "GILLS LAND, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "GILL09",
    pstcd: "BDMICMIC",
    strloc: "GILLS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GING01",
    pstcd: "BDJOSJOS",
    strloc: "GINGER WORKS, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "GING02",
    pstcd: "BDPHIPHI",
    strloc: "GINGER BAY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "GITT01",
    pstcd: "BDMICMIC",
    strloc: "GITTENS AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GITT03",
    pstcd: "BDMICMIC",
    strloc: "GITTENS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GLAD03",
    pstcd: "BDJAMHUS",
    strloc: "GLADIOLA DRIVE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "GLEB01",
    pstcd: "BDGEOGEO",
    strloc: "GLEBE LAND, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "GLEB01",
    pstcd: "BDJOHJOH",
    strloc: "GLEBE LAND, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "GLEB02",
    pstcd: "BDGEOGEO",
    strloc: "GLEBE MAIN ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "GLEB03",
    pstcd: "BDGEOGEO",
    strloc: "GLEBE GARDEN, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "GLEB07",
    pstcd: "BDPHIPHI",
    strloc: "THE GLEBE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "GLEN08",
    pstcd: "BDJOHJOH",
    strloc: "GLENBURNIE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "GLEN09",
    pstcd: "BDTHOTHO",
    strloc: "GLENDALE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "GLEN10",
    pstcd: "BDLUCLUC",
    strloc: "GLENDELOUGH, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "GLEN11",
    pstcd: "BDMICMIC",
    strloc: "GLENDAIRY ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GLEN12",
    pstcd: "BDMICPIN",
    strloc: "GLENDALE TERRACE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GLEN13",
    pstcd: "BDMICPIN",
    strloc: "GLENDALE CLOSE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GLEN14",
    pstcd: "BDMICMIC",
    strloc: "GLENDAIRY GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GLEN15",
    pstcd: "BDMICCAV",
    strloc: "GLENVILLE WAY, CAVE HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GLEN44",
    pstcd: "BDGEOGEO",
    strloc: "GLEN ACRES, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "GLIT01",
    pstcd: "BDJAMJAM",
    strloc: "GLITTER BAY TERRACE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "GLYD01",
    pstcd: "BDJAMJAM",
    strloc: "GLYDOR GARDENS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "GODD01",
    pstcd: "BDPETSPE",
    strloc: "GODDINGS ALLEY, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "GODD02",
    pstcd: "BDMICMIC",
    strloc: "GODDINGS ROAD 1ST AVE., ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GODD03",
    pstcd: "BDMICMIC",
    strloc: "GODDINGS ROAD 2ND AVE., ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GODD05",
    pstcd: "BDMICMIC",
    strloc: "GODDARDS VILLAGE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GODD06",
    pstcd: "BDMICMIC",
    strloc: "GODDARDS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GOLD05",
    pstcd: "BDJOSJOS",
    strloc: "GOLDEN EGG, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "GOLD06",
    pstcd: "BDGEOGEO",
    strloc: "GOLDEN RIDGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "GOLD07",
    pstcd: "BDGEOGEO",
    strloc: "GOLDEN RIDGE TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "GOLD08",
    pstcd: "BDGEOGEO",
    strloc: "GOLDEN RIDGE PLANTATION, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "GOLD09",
    pstcd: "BDMICMIC",
    strloc: "GOLDEN ACRES, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GOLD09",
    pstcd: "BDPHIPHI",
    strloc: "GOLDEN ACRES, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "GOLD10",
    pstcd: "BDPHIPHI",
    strloc: "GOLDEN GROVE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "GOLD11",
    pstcd: "BDPETSPE",
    strloc: "GOLDEN MILE, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "GOLD12",
    pstcd: "BDMICPIN",
    strloc: "GOLDEN ROCK, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GOLD13",
    pstcd: "BDMICMIC",
    strloc: "GOLDEN ACRE HOUSING AREA, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GOLD14",
    pstcd: "BDJAMSUN",
    strloc: "GOLDEN VIEW, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "GOLD14",
    pstcd: "BDPETPET",
    strloc: "GOLDEN VIEW, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "GOLF02",
    pstcd: "BDCHRCHR",
    strloc: "GOLF CLUB DEVELOPMENT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GOLF03",
    pstcd: "BDCHRCHR",
    strloc: "GOLF CLUB TERRACE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GOLF04",
    pstcd: "BDCHRROC",
    strloc: "GOLF CLUB ROAD, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GOLF05",
    pstcd: "BDCHRROC",
    strloc: "GOLF VIEW TERRACE, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GOLF06",
    pstcd: "BDCHRROC",
    strloc: "GOLF CLUB GARDENS, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GOOD12",
    pstcd: "BDCHRCHR",
    strloc: "GOODLAND GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GOOD13",
    pstcd: "BDMICMIC",
    strloc: "GOODINGS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GOOD14",
    pstcd: "BDMICMIC",
    strloc: "GOODING LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GOOD15",
    pstcd: "BDMICMIC",
    strloc: "GOODLAND MAIN ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GOOD16",
    pstcd: "BDGEOGEO",
    strloc: "GOOD INTENT, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "GOOD18",
    pstcd: "BDPETPET",
    strloc: "GOODRIDGE ROAD, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "GOOD19",
    pstcd: "BDMICMIC",
    strloc: "GOODLAND TENANTRY ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GOOD20",
    pstcd: "BDCHRCHR",
    strloc: "GOODLAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GOOD20",
    pstcd: "BDMICMIC",
    strloc: "GOODLAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GOOD21",
    pstcd: "BDMICMIC",
    strloc: "GOODINGS ROAD, 2ND AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GOOD22",
    pstcd: "BDCHRCHR",
    strloc: "GOODLAND PARK, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GOOD23",
    pstcd: "BDCHRCHR",
    strloc: "GOODWOOD PARK, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GOOD24",
    pstcd: "BDCHRCHR",
    strloc: "GOODRIDGE AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GOOD25",
    pstcd: "BDMICMIC",
    strloc: "GOODHOPE, GREEN HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GORI03",
    pstcd: "BDMICMIC",
    strloc: "GORING LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GOSP02",
    pstcd: "BDMICMIC",
    strloc: "GOSPEL HALL GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GOVE02",
    pstcd: "BDMICMIC",
    strloc: "GOVERNMENT HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GRAE01",
    pstcd: "BDCHRGRA",
    strloc: "GRAEME HALL TERRACE, GRAEME HALL - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GRAE02",
    pstcd: "BDCHRGRA",
    strloc: "GRAEME HALL PARK, GRAEME HALL - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GRAE03",
    pstcd: "BDCHRCHR",
    strloc: "GRAEME HALL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GRAE03",
    pstcd: "BDCHRGRA",
    strloc: "GRAEME HALL, GRAEME HALL - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GRAE04",
    pstcd: "BDCHRCHR",
    strloc: "GRAEME HALL HEIGHTS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GRAE04",
    pstcd: "BDCHRGRA",
    strloc: "GRAEME HALL HEIGHTS, GRAEME HALL - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GRAH04",
    pstcd: "BDMICMIC",
    strloc: "GRAHAM ALLEY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GRAN05",
    pstcd: "BDJOSJOS",
    strloc: "GRANTS HILL, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "GRAN18",
    pstcd: "BDMICMIC",
    strloc: "GRANT'S AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GRAN19",
    pstcd: "BDTHOTHO",
    strloc: "GRAND VIEW ROAD, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "GRAN20",
    pstcd: "BDTHOTHO",
    strloc: "GRAND VIEW HEIGHTS, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "GRAN21",
    pstcd: "BDPHIPHI",
    strloc: "GRAND VIEW, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "GRAN21",
    pstcd: "BDTHOTHO",
    strloc: "GRAND VIEW, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "GRAN22",
    pstcd: "BDMICMIC",
    strloc: "GRANTS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GRAN23",
    pstcd: "BDCHRCHR",
    strloc: "GRAND VIEW HILL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GRAN24",
    pstcd: "BDPHIPHI",
    strloc: "GRANGE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "GRAN25",
    pstcd: "BDMICCAV",
    strloc: "GRANVILLE WAY, CAVE HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GRAN26",
    pstcd: "BDCHRADA",
    strloc: "GRANTLEY ADAMS INT'L PARK, GRANTLEY ADAMS IND ESTATE",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GRAN26",
    pstcd: "BDCHRGRA",
    strloc: "GRANTLEY ADAMS INT'L PARK, GRAEME HALL - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GRAN29",
    pstcd: "BDCHRCHR",
    strloc: "GRANTLEY ADAMS INT'L AIRPORT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GRAP02",
    pstcd: "BDLUCLUC",
    strloc: "GRAPE HALL, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "GRAP03",
    pstcd: "BDLUCLUC",
    strloc: "GRAPE HALL TERRACE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "GRAP04",
    pstcd: "BDJAMJAM",
    strloc: "GRAPE TREE ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "GRAS03",
    pstcd: "BDMICMIC",
    strloc: "GRASSFIELD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GRAS04",
    pstcd: "BDMICMIC",
    strloc: "GRASSFIELD GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GRAV03",
    pstcd: "BDLUCLUC",
    strloc: "GRAVE YARD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "GRAZ01",
    pstcd: "BDMICMIC",
    strloc: "GRAZETTES TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GRAZ01",
    pstcd: "BDMICMIC",
    strloc: "GRAZETTES TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GRAZ02",
    pstcd: "BDMICMIC",
    strloc: "GRAZETTES HOUSING AREA, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GRAZ03",
    pstcd: "BDMICMIC",
    strloc: "GRAZETTES MAIN ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GRAZ04",
    pstcd: "BDMICMIC",
    strloc: "GRAZETTES ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GRAZ05",
    pstcd: "BDMICMIC",
    strloc: "GRAZETTES COURT, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GRAZ06",
    pstcd: "BDMICMIC",
    strloc: "GRAZETTES LANDING SCHEME, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GRAZ07",
    pstcd: "BDMICMIC",
    strloc: "GRAZETTES 4TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GRAZ08",
    pstcd: "BDMICMIC",
    strloc: "GRAZETTES INDUSTRIAL PARK, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GRAZ09",
    pstcd: "BDMICMIC",
    strloc: "GRAZETTES 1ST AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GRAZ10",
    pstcd: "BDMICMIC",
    strloc: "GRAZETTES 2ND AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GRAZ11",
    pstcd: "BDMICMIC",
    strloc: "GRAZETTES, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GRAZ12",
    pstcd: "BDMICMIC",
    strloc: "GRAZETTES GARDENS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GREA03",
    pstcd: "BDJAMJAM",
    strloc: "GREAVES ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "GREA03",
    pstcd: "BDPETPET",
    strloc: "GREAVES ROAD, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "GREA07",
    pstcd: "BDMICMIC",
    strloc: "GREAVES LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GREA08",
    pstcd: "BDPHIPHI",
    strloc: "GREAVES HILL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "GREC01",
    pstcd: "BDANDAND",
    strloc: "GRECIA ROAD, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "GREE02",
    pstcd: "BDJOSJOS",
    strloc: "GREEN ACRES, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "GREE07",
    pstcd: "BDJAMJAM",
    strloc: "GREENIDGE ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "GREE07",
    pstcd: "BDPHIPHI",
    strloc: "GREENIDGE ROAD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "GREE10",
    pstcd: "BDBGTBGT",
    strloc: "GREENFIELD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "GREE10",
    pstcd: "BDBGTBGT",
    strloc: "GREENFIELD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "GREE10",
    pstcd: "BDBGTBGT",
    strloc: "GREENFIELD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "GREE10",
    pstcd: "BDBGTBGT",
    strloc: "GREENFIELD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "GREE10",
    pstcd: "BDMICMIC",
    strloc: "GREENFIELD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GREE11",
    pstcd: "BDMICMIC",
    strloc: "GREEN HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GREE12",
    pstcd: "BDANDAND",
    strloc: "GREENLAND, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "GREE12",
    pstcd: "BDPHIPHI",
    strloc: "GREENLAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "GREE13",
    pstcd: "BDLUCLUC",
    strloc: "GREENIDGES, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "GREE14",
    pstcd: "BDGEOGEO",
    strloc: "GREENS, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "GREE15",
    pstcd: "BDPHIPHI",
    strloc: "GREEN POINT DEVELOPMENT, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "GREE16",
    pstcd: "BDBGTBGT",
    strloc: "GREENIDGE LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "GREE17",
    pstcd: "BDCHRCHR",
    strloc: "GREEN GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GREE18",
    pstcd: "BDCHRCHR",
    strloc: "GREE HILL CLOSE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GREE19",
    pstcd: "BDCHRCHR",
    strloc: "GREEN HILL DRIVE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GREE20",
    pstcd: "BDJAMJAM",
    strloc: "GREENHAM ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "GREE21",
    pstcd: "BDJAMJAM",
    strloc: "GREEN HAM, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "GREE22",
    pstcd: "BDMICMIC",
    strloc: "GREEN PARK LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GREE23",
    pstcd: "BDMICMIC",
    strloc: "GREENIDGE LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GREE24",
    pstcd: "BDPHIPHI",
    strloc: "GREENWAY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "GREE25",
    pstcd: "BDMICMIC",
    strloc: "GREENIDGE TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GREE26",
    pstcd: "BDMICMIC",
    strloc: "GREEN HILL 1ST AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GREE27",
    pstcd: "BDMICMIC",
    strloc: "GREENHILL 2ND AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GREE28",
    pstcd: "BDMICMIC",
    strloc: "GREENHILL 3RD AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GREE29",
    pstcd: "BDMICMIC",
    strloc: "GREENHILL 4TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GREE30",
    pstcd: "BDJAMJAM",
    strloc: "GREENWICH, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "GREE31",
    pstcd: "BDJAMJAM",
    strloc: "GREENWICH VILLAGE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "GREE32",
    pstcd: "BDJAMJAM",
    strloc: "GREENIDGE DRIVE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "GREE33",
    pstcd: "BDGEOGEO",
    strloc: "GREENS IST AVENUE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "GREE34",
    pstcd: "BDGEOGEO",
    strloc: "GREENS 2ND AVENUE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "GREG01",
    pstcd: "BDANDAND",
    strloc: "GREGG FARM, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "GREN03",
    pstcd: "BDMICMIC",
    strloc: "GRENVILLE WAY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GRET01",
    pstcd: "BDPHIPHI",
    strloc: "GRETNA GREEN, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "GREY01",
    pstcd: "BDCHRCHR",
    strloc: "GREYS TENANTRY, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "GRIF03",
    pstcd: "BDMICBLA",
    strloc: "GRIFFITHS LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GROS01",
    pstcd: "BDMICMIC",
    strloc: "GROSVENOR ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GROV02",
    pstcd: "BDGEOGEO",
    strloc: "GROVES TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "GROV03",
    pstcd: "BDPHIPHI",
    strloc: "GROVES PLANTATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "GROV15",
    pstcd: "BDGEOGEO",
    strloc: "GROVES, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "GUIN01",
    pstcd: "BDJOHJOH",
    strloc: "GUINEA PLANTATION, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "GUIN02",
    pstcd: "BDJOHJOH",
    strloc: "GUINEA TENANTRY, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "GULL02",
    pstcd: "BDMICMIC",
    strloc: "GULLY FIELD AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GULL03",
    pstcd: "BDMICMIC",
    strloc: "GULLY HILL ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GUNH01",
    pstcd: "BDGEOGEO",
    strloc: "GUN HILL, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "GUNS01",
    pstcd: "BDMICMIC",
    strloc: "GUNSITE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "GUTT01",
    pstcd: "BDJOSJOS",
    strloc: "GUTTER ROAD, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "HACK02",
    pstcd: "BDJOSJOS",
    strloc: "HACKLETONS CLIFF, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "HAGG02",
    pstcd: "BDANDAND",
    strloc: "HAGGATTS PLANTATION, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "HAGG03",
    pstcd: "BDANDAND",
    strloc: "HAGGATTS TENANTRY, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "HAGG04",
    pstcd: "BDMICMIC",
    strloc: "HAGGATT HALL FLATS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HAGG05",
    pstcd: "BDMICMIC",
    strloc: "HAGGATT HALL 1ST AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HAGG06",
    pstcd: "BDMICMIC",
    strloc: "HAGGATT HALL 2ND AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HAGG07",
    pstcd: "BDMICMIC",
    strloc: "HAGGATT HALL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HAGG08",
    pstcd: "BDMICMIC",
    strloc: "HAGGATT HALL X ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HALC02",
    pstcd: "BDJAMJAM",
    strloc: "HALCYON HEIGTHS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HALC03",
    pstcd: "BDJAMJAM",
    strloc: "HALCYON, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HALF01",
    pstcd: "BDLUCLUC",
    strloc: "HALF MOON FORTE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "HALL05",
    pstcd: "BDJAMJAM",
    strloc: "HALL ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HALL06",
    pstcd: "BDJAMJAM",
    strloc: "HALLS ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HALL07",
    pstcd: "BDJAMJAM",
    strloc: "HALLS VILLAGE NO 1, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HALL08",
    pstcd: "BDJAMJAM",
    strloc: "HALLS VILLAGE NO 2, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HALL09",
    pstcd: "BDJAMJAM",
    strloc: "HALL VILLAGE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HALL10",
    pstcd: "BDJAMJAM",
    strloc: "HALLS TENANTRY ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HALL11",
    pstcd: "BDJAMJAM",
    strloc: "HALLS VILLAGE TENANTRY ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HALL12",
    pstcd: "BDMICBAN",
    strloc: "HALLS LAND, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HALT01",
    pstcd: "BDJOHJOH",
    strloc: "HALTON, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "HALT02",
    pstcd: "BDPHIPHI",
    strloc: "HALTON LAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HALT03",
    pstcd: "BDPHIPHI",
    strloc: "HALTON PLANTATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HALT04",
    pstcd: "BDPHIPHI",
    strloc: "HALTON TERRACE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HAMI06",
    pstcd: "BDPHIPHI",
    strloc: "HAMILTON, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HAML03",
    pstcd: "BDMICMIC",
    strloc: "HAMLETTS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HAMP01",
    pstcd: "BDPHIPHI",
    strloc: "HAMPDEN TENANTRY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HAMP02",
    pstcd: "BDPHIPHI",
    strloc: "HAMPTON PLANTATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HAMP03",
    pstcd: "BDMICMIC",
    strloc: "HAMPTONS COURT, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HAMP04",
    pstcd: "BDPHIPHI",
    strloc: "HAMPTON TERRACE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HAMP05",
    pstcd: "BDCHRCHR",
    strloc: "HAMPTON END, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HAMP05",
    pstcd: "BDPHIPHI",
    strloc: "HAMPTON END, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HAMP06",
    pstcd: "BDPHIPHI",
    strloc: "HAMPTON STATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HANC01",
    pstcd: "BDMICMIC",
    strloc: "Hanchi Amboina, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HANN01",
    pstcd: "BDLUCLUC",
    strloc: "HANNAYS VILLAGE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "HANN02",
    pstcd: "BDLUCLUC",
    strloc: "HANNAYS, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "HANN03",
    pstcd: "BDLUCLUC",
    strloc: "HANNAYS TENANTRY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "HANN04",
    pstcd: "BDCHRCHR",
    strloc: "HANNAYS PLANTATION, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HANN04",
    pstcd: "BDLUCLUC",
    strloc: "HANNAYS PLANTATION, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "HANN05",
    pstcd: "BDCHRCHR",
    strloc: "HANNAYS VALLEY, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HANS01",
    pstcd: "BDGEOGEO",
    strloc: "HANSON, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "HANS02",
    pstcd: "BDGEOGEO",
    strloc: "HANSON PLANTATION, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "HANS03",
    pstcd: "BDGEOGEO",
    strloc: "HANSON HILL, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "HAPP06",
    pstcd: "BDMICBAN",
    strloc: "HAPPY COT LAND, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HARB01",
    pstcd: "BDMICPIN",
    strloc: "HARBOUR VIEW TERRACE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HARB02",
    pstcd: "BDJAMSUN",
    strloc: "HARBIN ALLEYNE ROAD, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HARB03",
    pstcd: "BDMICHAR",
    strloc: "HARBOUR ROAD, HARBOUR IND. PARK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HARB04",
    pstcd: "BDMICMIC",
    strloc: "HARBOUR INDUSTRIAL PARK, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HARD04",
    pstcd: "BDBGTBGT",
    strloc: "HARDWOOD ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HARD04",
    pstcd: "BDBGTBGT",
    strloc: "HARDWOOD ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HARD04",
    pstcd: "BDBGTBGT",
    strloc: "HARDWOOD ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HARD05",
    pstcd: "BDGEOGEO",
    strloc: "HARDING LAND, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "HARE03",
    pstcd: "BDANDAND",
    strloc: "HAREWOOD ROAD, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "HARE04",
    pstcd: "BDMICPIN",
    strloc: "HAREWOOD WALK AVENUE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HARL01",
    pstcd: "BDPHIPHI",
    strloc: "HARLINGTON, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HARL02",
    pstcd: "BDMICMIC",
    strloc: "HARLOW LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HARM03",
    pstcd: "BDBGTBGT",
    strloc: "HARMONY HALL, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HARM03",
    pstcd: "BDCHRCHR",
    strloc: "HARMONY HALL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HARM03",
    pstcd: "BDMICMIC",
    strloc: "HARMONY HALL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HARM03",
    pstcd: "BDPHIPHI",
    strloc: "HARMONY HALL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HARM04",
    pstcd: "BDLUCLUC",
    strloc: "HARMONY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "HARM05",
    pstcd: "BDGEOGEO",
    strloc: "HARMONY COTTAGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "HARM06",
    pstcd: "BDGEOGEO",
    strloc: "HARMONY COT, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "HARM07",
    pstcd: "BDPHIPHI",
    strloc: "HARMONY HALL DEVELOPMENT, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HARM08",
    pstcd: "BDPHIPHI",
    strloc: "HARMONY LODGE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HARM09",
    pstcd: "BDMICMIC",
    strloc: "HARMONY HALL GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HARP05",
    pstcd: "BDCHRCHR",
    strloc: "HARPER LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HARP05",
    pstcd: "BDMICMIC",
    strloc: "HARPER LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HARR18",
    pstcd: "BDGEOGEO",
    strloc: "HARRY COX ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "HARR19",
    pstcd: "BDLUCLUC",
    strloc: "HARRISES, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "HARR20",
    pstcd: "BDLUCLUC",
    strloc: "HARRISON ROAD PLANTATION, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "HARR21",
    pstcd: "BDLUCLUC",
    strloc: "HARRISONS TENANTRY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "HARR22",
    pstcd: "BDLUCLUC",
    strloc: "HARRISONS PLANTAION, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "HARR23",
    pstcd: "BDGEOGEO",
    strloc: "HARRIS ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "HARR24",
    pstcd: "BDPHIPHI",
    strloc: "HARROW VILLE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HARR25",
    pstcd: "BDPHIPHI",
    strloc: "HARRYSMITH, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HARR26",
    pstcd: "BDMICMIC",
    strloc: "HARRISON ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HARR27",
    pstcd: "BDPHIPHI",
    strloc: "HARROW PLANTATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HARR28",
    pstcd: "BDPHIPHI",
    strloc: "HARROW TENANTRY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HARR29",
    pstcd: "BDPHIPHI",
    strloc: "HARROW LAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HART04",
    pstcd: "BDBGTBGT",
    strloc: "HARTS STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HART05",
    pstcd: "BDJAMJAM",
    strloc: "HART LAND ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HART06",
    pstcd: "BDJAMJAM",
    strloc: "HART LAND CLOSE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HART07",
    pstcd: "BDJAMJAM",
    strloc: "HARTLAND HEIGHTS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HART08",
    pstcd: "BDCHRCHR",
    strloc: "HARTS GAP,, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HART09",
    pstcd: "BDCHRCHR",
    strloc: "HARTS GAP 1ST AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HART10",
    pstcd: "BDCHRCHR",
    strloc: "HARTS GAP 2ND AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HART11",
    pstcd: "BDCHRCHR",
    strloc: "HARTS GAP 3RD AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HART12",
    pstcd: "BDCHRCHR",
    strloc: "HARTS GAP 4TH AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HART13",
    pstcd: "BDCHRCHR",
    strloc: "HARTS GAP 5TH AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HART14",
    pstcd: "BDCHRCHR",
    strloc: "HARTS GAP 6TH AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HART15",
    pstcd: "BDCHRCHR",
    strloc: "HARTS GAP 7TH AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HART16",
    pstcd: "BDCHRCHR",
    strloc: "HARTS GAP 8TH AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HAST01",
    pstcd: "BDCHRCHR",
    strloc: "HASTING MAIN ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HAWK01",
    pstcd: "BDMICMIC",
    strloc: "HAWKINS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HAYM01",
    pstcd: "BDPETPET",
    strloc: "HAYMANS, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "HAYN01",
    pstcd: "BDJAMJAM",
    strloc: "HAYNESVILLE #1, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HAYN02",
    pstcd: "BDJAMJAM",
    strloc: "HAYNES VILLE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HAYN03",
    pstcd: "BDJAMJAM",
    strloc: "HAYNES NO.1, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HAYN04",
    pstcd: "BDJOHJOH",
    strloc: "HAYNES HILL, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "HAYN04",
    pstcd: "BDMICMIC",
    strloc: "HAYNES HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HAYN05",
    pstcd: "BDJAMJAM",
    strloc: "HAYNESVILLE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HEAD02",
    pstcd: "BDMICBAN",
    strloc: "HEADLEYS LAND, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HEAD02",
    pstcd: "BDMICMIC",
    strloc: "HEADLEYS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HEAD03",
    pstcd: "BDCHRCHR",
    strloc: "HEADLEYS ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HEAT01",
    pstcd: "BDGEOGEO",
    strloc: "HEATH DEVELOPMENT, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "HEAT02",
    pstcd: "BDJAMJAM",
    strloc: "HEATH LANE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HEDD01",
    pstcd: "BDPHIPHI",
    strloc: "HEDDINGS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HEDD02",
    pstcd: "BDPHIPHI",
    strloc: "HEDDINGS GARDENS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HEDD03",
    pstcd: "BDPHIPHI",
    strloc: "HEDDINGS CROSS ROAD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HENL01",
    pstcd: "BDJOHJOH",
    strloc: "HENLEY LAND, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "HENL02",
    pstcd: "BDJOHJOH",
    strloc: "HENLEY TENANTRY, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "HENL03",
    pstcd: "BDJOHJOH",
    strloc: "HENLEY PLANTATION, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "HENR08",
    pstcd: "BDMICMIC",
    strloc: "HENRY LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HERB03",
    pstcd: "BDMICMIC",
    strloc: "HERBERTS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HERM05",
    pstcd: "BDGEOGEO",
    strloc: "HERMAN CLARKE ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "HERO02",
    pstcd: "BDJAMJAM",
    strloc: "HERON BAY, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HEYW01",
    pstcd: "BDPETPET",
    strloc: "HEYWOODS, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "HIBI01",
    pstcd: "BDJAMHUS",
    strloc: "HIBISCUS DRIVE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HIBI05",
    pstcd: "BDMICCAV",
    strloc: "HIBISCUS WAY, CAVE HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HIBI06",
    pstcd: "BDJAMJAM",
    strloc: "HIBICUS AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HIBI06",
    pstcd: "BDJAMSUN",
    strloc: "HIBICUS AVENUE, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HIGG01",
    pstcd: "BDBGTBGT",
    strloc: "HIGGINSON LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HIGG01",
    pstcd: "BDBGTBGT",
    strloc: "HIGGINSON LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HIGG02",
    pstcd: "BDLUCLUC",
    strloc: "HIGGINSON, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "HIGH01",
    pstcd: "BDBGTBGT",
    strloc: "HIGH STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HIGH01",
    pstcd: "BDBGTBGT",
    strloc: "HIGH STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HIGH01",
    pstcd: "BDBGTBGT",
    strloc: "HIGH STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HIGH03",
    pstcd: "BDTHOTHO",
    strloc: "HIGH HOLDINGS, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "HIGH04",
    pstcd: "BDTHOTHO",
    strloc: "HIGHCLERE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "HIGH05",
    pstcd: "BDPHIPHI",
    strloc: "HIGHLAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HIGH05",
    pstcd: "BDTHOTHO",
    strloc: "HIGHLAND, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "HIGH06",
    pstcd: "BDMICMIC",
    strloc: "HIGHGATE GARDENS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HIGH07",
    pstcd: "BDJAMJAM",
    strloc: "HIGH PARK, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HIGH08",
    pstcd: "BDCHRCHR",
    strloc: "HIGH RIDGE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HIGH08",
    pstcd: "BDJAMJAM",
    strloc: "HIGH RIDGE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HIGH09",
    pstcd: "BDJAMJAM",
    strloc: "HIGH POINT, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HIGH11",
    pstcd: "BDTHOTHO",
    strloc: "High Wood Park, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "HIGH12",
    pstcd: "BDTHOTHO",
    strloc: "Highland  Park, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "HILB01",
    pstcd: "BDGEOGEO",
    strloc: "HILBURY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "HILL05",
    pstcd: "BDMICPIN",
    strloc: "HILLTOP AVENUE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HILL31",
    pstcd: "BDMICMIC",
    strloc: "HILLBURY ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HILL32",
    pstcd: "BDJOHJOH",
    strloc: "HILLBURY, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "HILL33",
    pstcd: "BDTHOTHO",
    strloc: "HILLABY TENANTRY, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "HILL34",
    pstcd: "BDANDAND",
    strloc: "HILLABY, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "HILL34",
    pstcd: "BDTHOTHO",
    strloc: "HILLABY, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "HILL35",
    pstcd: "BDJOSJOS",
    strloc: "HILLSWICK VILLAGE, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "HILL36",
    pstcd: "BDGEOGEO",
    strloc: "HILLSIDE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "HILL37",
    pstcd: "BDCHRCHR",
    strloc: "HILL VIEW, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HILL37",
    pstcd: "BDMICBAN",
    strloc: "HILL VIEW, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HILL37",
    pstcd: "BDPHIPHI",
    strloc: "HILL VIEW, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HILL38",
    pstcd: "BDMICBAN",
    strloc: "HILL ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HILL39",
    pstcd: "BDMICMIC",
    strloc: "HILLSIDE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HILL40",
    pstcd: "BDMICMIC",
    strloc: "HILLSIDE TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HILL41",
    pstcd: "BDMICMIC",
    strloc: "HILL ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HILT01",
    pstcd: "BDJAMJAM",
    strloc: "HILTON ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HILT02",
    pstcd: "BDCHRCHR",
    strloc: "HILTON GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HILT03",
    pstcd: "BDCHRCHR",
    strloc: "HILTON AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HILT04",
    pstcd: "BDMICPIN",
    strloc: "HILTON ROW, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HINC01",
    pstcd: "BDBGTBGT",
    strloc: "HINCKS STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HINC01",
    pstcd: "BDBGTBGT",
    strloc: "HINCKS STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HINC01",
    pstcd: "BDBGTBGT",
    strloc: "HINCKS STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HINC01",
    pstcd: "BDBGTBGT",
    strloc: "HINCKS STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HINC01",
    pstcd: "BDBGTBGT",
    strloc: "HINCKS STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HINC01",
    pstcd: "BDBGTBGT",
    strloc: "HINCKS STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HINC01",
    pstcd: "BDBGTBGT",
    strloc: "HINCKS STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HINC01",
    pstcd: "BDBGTBGT",
    strloc: "HINCKS STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HIND06",
    pstcd: "BDMICCAV",
    strloc: "HINDS HILL, CAVE HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HIND06",
    pstcd: "BDMICMIC",
    strloc: "HINDS HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HIND07",
    pstcd: "BDMICMIC",
    strloc: "HINDSBURY ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HIND08",
    pstcd: "BDMICBLA",
    strloc: "HINDS ROAD, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HINK03",
    pstcd: "BDMICMIC",
    strloc: "HINKSON GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HINK04",
    pstcd: "BDMICMIC",
    strloc: "HINKSON LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HOAD01",
    pstcd: "BDMICMIC",
    strloc: "HOAD LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HOLB01",
    pstcd: "BDMICMIC",
    strloc: "HOLBORN, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HOLD05",
    pstcd: "BDGEOGEO",
    strloc: "HOLDERS VILLAGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "HOLD06",
    pstcd: "BDMICBUS",
    strloc: "HOLDERS LAND, BUSH HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HOLD06",
    pstcd: "BDMICMIC",
    strloc: "HOLDERS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HOLD07",
    pstcd: "BDJAMJAM",
    strloc: "HOLDERS TERRACE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HOLD08",
    pstcd: "BDJAMJAM",
    strloc: "HOLDERS TERRACE 2ND AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HOLD09",
    pstcd: "BDJAMJAM",
    strloc: "HOLDERS TERRACE 1ST AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HOLD10",
    pstcd: "BDJAMJAM",
    strloc: "HOLDERS MAIN ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HOLD11",
    pstcd: "BDJAMJAM",
    strloc: "HOLDERS HILL, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HOLD12",
    pstcd: "BDJAMJAM",
    strloc: "HOLDERS LODGE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HOLD13",
    pstcd: "BDJAMJAM",
    strloc: "HOLDERS DEVELOPMENT AREA, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HOLE02",
    pstcd: "BDHLTHLT",
    strloc: "HOLETOWN, HOLETOWN - ST JAMES",
    parish: "HOLETOWN - ST JAMES",
  },
  {
    stind: "HOLE03",
    pstcd: "BDJAMJAM",
    strloc: "HOLETOWN 2ND STREET, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HOLL07",
    pstcd: "BDMICBAN",
    strloc: "HOLLIGAN ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HOLL08",
    pstcd: "BDCHRCHR",
    strloc: "HOLLYWOOD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HOLY01",
    pstcd: "BDJOHJOH",
    strloc: "HOLY CROSS, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "HOLY02",
    pstcd: "BDPHIPHI",
    strloc: "HOLY TRINITY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HOME04",
    pstcd: "BDPHIPHI",
    strloc: "HOME AGRICULTURAL STATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HOME05",
    pstcd: "BDCHRFRE",
    strloc: "HOMESTEAD DRIVE, FRERE PILGRIM - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HOME06",
    pstcd: "BDCHRFRE",
    strloc: "HOMESTEAD 2ND AVE., FRERE PILGRIM - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HONE03",
    pstcd: "BDJAMJAM",
    strloc: "HONEY SUCKLE ROW, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HOOD01",
    pstcd: "BDCHRCHR",
    strloc: "HOOD ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HOPE01",
    pstcd: "BDJAMJAM",
    strloc: "HOPE ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HOPE01",
    pstcd: "BDLUCLUC",
    strloc: "HOPE ROAD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "HOPE03",
    pstcd: "BDJAMJAM",
    strloc: "HOPE FIRST BRANCH ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HOPE05",
    pstcd: "BDBGTBGT",
    strloc: "HOPES ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HOPE05",
    pstcd: "BDBGTBGT",
    strloc: "HOPES ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HOPE05",
    pstcd: "BDBGTBGT",
    strloc: "HOPES ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HOPE06",
    pstcd: "BDTHOTHO",
    strloc: "HOPEWELL TENANTRY, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "HOPE07",
    pstcd: "BDTHOTHO",
    strloc: "HOPEWELL DEVELOPMENT, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "HOPE08",
    pstcd: "BDTHOTHO",
    strloc: "HOPEWELL COTTAGE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "HOPE09",
    pstcd: "BDTHOTHO",
    strloc: "HOPEWELL PLANTATION, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "HOPE10",
    pstcd: "BDTHOTHO",
    strloc: "HOPE YARD, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "HOPE11",
    pstcd: "BDANDAND",
    strloc: "HOPEWELL, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "HOPE11",
    pstcd: "BDCHRCHR",
    strloc: "HOPEWELL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HOPE11",
    pstcd: "BDTHOTHO",
    strloc: "HOPEWELL, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "HOPE12",
    pstcd: "BDGEOGEO",
    strloc: "HOPE TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "HOPE12",
    pstcd: "BDLUCLUC",
    strloc: "HOPE TENANTRY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "HOPE13",
    pstcd: "BDGEOGEO",
    strloc: "HOPE DEVELOPMENT, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "HOPE14",
    pstcd: "BDGEOGEO",
    strloc: "HOPE PARK, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "HOPE15",
    pstcd: "BDLUCLUC",
    strloc: "HOPE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "HOPE15",
    pstcd: "BDPETPET",
    strloc: "HOPE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "HOPE16",
    pstcd: "BDLUCLUC",
    strloc: "HOPE PLANTATION, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "HOPE17",
    pstcd: "BDLUCLUC",
    strloc: "HOPE BRIDGE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "HOPE18",
    pstcd: "BDGEOGEO",
    strloc: "HOPE HILL, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "HOPE19",
    pstcd: "BDMICMIC",
    strloc: "HOPELAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HOPE19",
    pstcd: "BDPETPET",
    strloc: "HOPELAND, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "HOPE19",
    pstcd: "BDPHIPHI",
    strloc: "HOPELAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "HOPE21",
    pstcd: "BDCHRCHR",
    strloc: "HOPEWELL GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HOPE22",
    pstcd: "BDCHRCHR",
    strloc: "HOPEFIELD DEVELOPMENT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HOPE26",
    pstcd: "BDCHRCHR",
    strloc: "HOPEFIELD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HORS02",
    pstcd: "BDJOSJOS",
    strloc: "HORSE HILL, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "HORT01",
    pstcd: "BDJOSJOS",
    strloc: "HORTONS, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "HORT02",
    pstcd: "BDJOSJOS",
    strloc: "HORTONS VILLAGE, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "HOTH01",
    pstcd: "BDJOHJOH",
    strloc: "HOTHERSAL PLANTATION, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "HOTH02",
    pstcd: "BDJOHJOH",
    strloc: "HOTHERSAL TENANTRY, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "HOTH03",
    pstcd: "BDJOHJOH",
    strloc: "HOTHERSAL, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "HOTH04",
    pstcd: "BDMICMIC",
    strloc: "HOTHERSAL TURNING, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HOWA02",
    pstcd: "BDJOHJOH",
    strloc: "HOWARDS HILL, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "HOWA03",
    pstcd: "BDMICMIC",
    strloc: "HOWARD LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HOWE02",
    pstcd: "BDMICMIC",
    strloc: "HOWELLS CROSS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HOWE03",
    pstcd: "BDJAMJAM",
    strloc: "HOWELLS ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HOYT02",
    pstcd: "BDMICMIC",
    strloc: "HOYTES ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HOYT03",
    pstcd: "BDJAMJAM",
    strloc: "HOYTES, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HOYT04",
    pstcd: "BDJAMJAM",
    strloc: "HOYTES VILLAGE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HOYT05",
    pstcd: "BDJAMJAM",
    strloc: "HOYTES VILLAGE 1ST AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HOYT06",
    pstcd: "BDJAMJAM",
    strloc: "HOYTES VILLAGE 2ND AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HOYT07",
    pstcd: "BDJAMJAM",
    strloc: "HOYTES POINT, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HOYT08",
    pstcd: "BDJAMJAM",
    strloc: "HOYTES TERRACE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUNT02",
    pstcd: "BDMICMIC",
    strloc: "HUNTE STREET, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HUNT05",
    pstcd: "BDMICMIC",
    strloc: "HUNTES ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HURL01",
    pstcd: "BDPETPET",
    strloc: "HURLEYS GAP, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "HUSB01",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS PLANTATION, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB01",
    pstcd: "BDLUCLUC",
    strloc: "HUSBANDS PLANTATION, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "HUSB02",
    pstcd: "BDLUCLUC",
    strloc: "HUSBANDS TENANTRY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "HUSB03",
    pstcd: "BDLUCLUC",
    strloc: "HUSBANDS VILLAGE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "HUSB04",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS DEVELOPMENT PHASE 3, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB05",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS 4TH PELICAN AVENUE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB06",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS 5TH AVENUE EAST, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB07",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS 6TH AVENUE EAST, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB08",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS 1ST PELICAN AVENUE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB09",
    pstcd: "BDJAMJAM",
    strloc: "HUSBANDS DEVELOPMENT, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB09",
    pstcd: "BDMICMIC",
    strloc: "HUSBANDS DEVELOPMENT, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HUSB10",
    pstcd: "BDJAMJAM",
    strloc: "HUSBANDS ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB11",
    pstcd: "BDJAMJAM",
    strloc: "HUSBANDS GAP, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB12",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS 2ND AVE EAST, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB13",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS 4TH AVE EAST, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB14",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS 3RD AVE EAST, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB15",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS 11TH AVE WEST, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB16",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS 8TH AVE WEST, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB18",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS 2ND AVE WEST, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB19",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS 7TH AVE EAST, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB20",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS 8TH AVE EAST, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB21",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS 9TH AVE EAST, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB22",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS 10TH AVE EAST, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB23",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS 12TH AVE EAST, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB24",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS 7TH AVE WEST, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB25",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS 4TH AVE WEST, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB26",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS 5TH AVE WEST, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB27",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS 6TH AVE WEST, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB28",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS 1ST AVE WEST, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB29",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS TERRACE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB30",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS TERRACE 1ST AVE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB31",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS TERRACE 4TH AVE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB32",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS TERRACE, 2ND AVENUE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB33",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS TERRACE, 3RD AVENUE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB34",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS TERRACE, 5TH AVENUE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB35",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS HEIGHTS, 1ST AVENUE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB36",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS HEIGHTS, 2ND AVENUE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB37",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS HEIGHTS, 3RD AVENUE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB38",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS HEIGHTS, 4TH AVENUE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB39",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS HEIGHTS, 5TH AVENUE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB40",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS HEIGHTS, 6TH AVENUE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB41",
    pstcd: "BDJAMJAM",
    strloc: "HUSBANDS NEW DEVELOPMENT, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB42",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS HEIGHTS, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUSB43",
    pstcd: "BDJAMHUS",
    strloc: "HUSBANDS GARDENS, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "HUST01",
    pstcd: "BDMICPIN",
    strloc: "HUSTELL LANE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "HUTC03",
    pstcd: "BDCHRCHR",
    strloc: "HUTCHINSON DEVELOPMENT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HUTS01",
    pstcd: "BDBGTBGT",
    strloc: "HUTSON'S ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "HYGI01",
    pstcd: "BDCHRCHR",
    strloc: "HYGIA, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "HYTH01",
    pstcd: "BDCHRCHR",
    strloc: "HYTHE GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "IFIL05",
    pstcd: "BDJAMJAM",
    strloc: "IFILL ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "INCE01",
    pstcd: "BDCHRCHR",
    strloc: "INCE DRIVE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "INCH01",
    pstcd: "BDPHIPHI",
    strloc: "INCH CAPE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "INCH02",
    pstcd: "BDCHRCHR",
    strloc: "INCH MARLOW, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "INDE01",
    pstcd: "BDBGTBGT",
    strloc: "INDEPENDENCE SQUARE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "INDE04",
    pstcd: "BDJAMHUS",
    strloc: "INDEPENDENCE DRIVE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "INDE05",
    pstcd: "BDJAMHUS",
    strloc: "INDEPENDENCE CLOSE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "INDI03",
    pstcd: "BDJOSJOS",
    strloc: "INDIAN POND, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "INDI04",
    pstcd: "BDPETPET",
    strloc: "INDIAN GROUND VILLAGE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "INDI05",
    pstcd: "BDMICMIC",
    strloc: "INDIAN GROUND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "INDI05",
    pstcd: "BDPETPET",
    strloc: "INDIAN GROUND, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "INDU03",
    pstcd: "BDJOSJOS",
    strloc: "INDUSTRY ROAD, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "INDU03",
    pstcd: "BDMICBUS",
    strloc: "INDUSTRY ROAD, BUSH HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "INDU03",
    pstcd: "BDPHIPHI",
    strloc: "INDUSTRY ROAD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "INDU04",
    pstcd: "BDJOSJOS",
    strloc: "INDUSTRY, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "INDU05",
    pstcd: "BDPHIPHI",
    strloc: "INDUSTRY HALL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "INFI01",
    pstcd: "BDJAMJAM",
    strloc: "INFILL ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "ISOL01",
    pstcd: "BDANDAND",
    strloc: "ISOLATION ROAD, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "IVYH01",
    pstcd: "BDMICMIC",
    strloc: "IVY HOUSING AREA, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "IVYM02",
    pstcd: "BDMICMIC",
    strloc: "IVY MAIN ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "IVYM03",
    pstcd: "BDMICMIC",
    strloc: "IVY MAIN ROAD 1ST AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "IVYM04",
    pstcd: "BDMICMIC",
    strloc: "IVY MAIN ROAD 2ND AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "IVYM05",
    pstcd: "BDMICMIC",
    strloc: "IVY MAIN ROAD 3RD AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "IVYM06",
    pstcd: "BDMICMIC",
    strloc: "IVY MAIN ROAD 4TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "IVYM07",
    pstcd: "BDMICMIC",
    strloc: "IVY MAIN ROAD 5TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "IVYM08",
    pstcd: "BDMICMIC",
    strloc: "IVY MAIN ROAD 6TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "IVYM09",
    pstcd: "BDMICMIC",
    strloc: "IVY MAIN ROAD 7TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "IVYM10",
    pstcd: "BDMICMIC",
    strloc: "IVY MAIN ROAD 8TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "IVYM11",
    pstcd: "BDMICMIC",
    strloc: "IVY MAIN ROAD 9TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "IVYW01",
    pstcd: "BDJAMOXN",
    strloc: "IVY WALK, OXNARDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "JACK01",
    pstcd: "BDTHOTHO",
    strloc: "JACKSON ROAD, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "JACK11",
    pstcd: "BDMICMIC",
    strloc: "JACKIE TRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "JACK15",
    pstcd: "BDMICMIC",
    strloc: "JACKSON, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "JACK15",
    pstcd: "BDTHOTHO",
    strloc: "JACKSON, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "JACK16",
    pstcd: "BDMICBLA",
    strloc: "JACKMANS LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "JACK16",
    pstcd: "BDMICMIC",
    strloc: "JACKMANS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "JACK17",
    pstcd: "BDMICMIC",
    strloc: "JACKMANS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "JACK18",
    pstcd: "BDMICBLA",
    strloc: "JACKMAN ROAD, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "JACK19",
    pstcd: "BDJAMJAM",
    strloc: "JACKMANS ALLEY, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "JACK20",
    pstcd: "BDTHOTHO",
    strloc: "JACKSON TERRACE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "JACK21",
    pstcd: "BDMICMIC",
    strloc: "JACKSON TENANTRY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "JAME02",
    pstcd: "BDBGTBGT",
    strloc: "JAMES STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "JAME02",
    pstcd: "BDBGTBGT",
    strloc: "JAMES STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "JAME02",
    pstcd: "BDBGTBGT",
    strloc: "JAMES STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "JAME02",
    pstcd: "BDBGTBGT",
    strloc: "JAMES STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "JAME02",
    pstcd: "BDBGTBGT",
    strloc: "JAMES STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "JAME02",
    pstcd: "BDBGTBGT",
    strloc: "JAMES STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "JAME06",
    pstcd: "BDJAMJAM",
    strloc: "JAMESTOWN PARK, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "JASP01",
    pstcd: "BDCHRCHR",
    strloc: "JASPER AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "JEMM01",
    pstcd: "BDMICMIC",
    strloc: "JEMMOTTS LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "JEMM02",
    pstcd: "BDLUCLUC",
    strloc: "JEMMOTTS, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "JEMM03",
    pstcd: "BDMICMIC",
    strloc: "JEMMOTTS DEVELOPMENT, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "JERI01",
    pstcd: "BDGEOGEO",
    strloc: "JERICHO, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "JERU01",
    pstcd: "BDPETPET",
    strloc: "JERUSALEM, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "JESS01",
    pstcd: "BDMICMIC",
    strloc: "JESSAMINE AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "JESS02",
    pstcd: "BDBGTBGT",
    strloc: "JESSAIME ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "JESS02",
    pstcd: "BDBGTBGT",
    strloc: "JESSAIME ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "JESS03",
    pstcd: "BDPHIPHI",
    strloc: "JESSAMY LANE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "JEZR01",
    pstcd: "BDPHIPHI",
    strloc: "JEZREEL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "JILL01",
    pstcd: "BDJOHJOH",
    strloc: "JILL POT HILL, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "JOES01",
    pstcd: "BDJOSJOS",
    strloc: "JOES RIVER, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "JOHN34",
    pstcd: "BDJAMJAM",
    strloc: "JOHNSONS ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "JOHN34",
    pstcd: "BDPETPET",
    strloc: "JOHNSONS ROAD, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "JOHN35",
    pstcd: "BDPHIPHI",
    strloc: "JOHNSON DEVELOPMENT, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "JOHN36",
    pstcd: "BDPHIPHI",
    strloc: "JOHNSONS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "JOHN37",
    pstcd: "BDMICMIC",
    strloc: "JOHNSON LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "JOHN38",
    pstcd: "BDJAMJAM",
    strloc: "JOHN'S PLAIN, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "JONE04",
    pstcd: "BDMICBLA",
    strloc: "JONES LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "JONE05",
    pstcd: "BDMICMIC",
    strloc: "JONES GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "JONE06",
    pstcd: "BDMICMIC",
    strloc: "JONES ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "JORD05",
    pstcd: "BDANDAND",
    strloc: "JORDANS ROAD, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "JORD05",
    pstcd: "BDBGTBGT",
    strloc: "JORDANS ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "JORD05",
    pstcd: "BDBGTBGT",
    strloc: "JORDANS ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "JORD05",
    pstcd: "BDJAMJAM",
    strloc: "JORDANS ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "JORD06",
    pstcd: "BDMICMIC",
    strloc: "JORDAN'S LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "JORD07",
    pstcd: "BDGEOGEO",
    strloc: "JORDANS TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "JORD08",
    pstcd: "BDGEOGEO",
    strloc: "JORDANS PLANTATION, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "JORD09",
    pstcd: "BDLUCLUC",
    strloc: "JORDANS, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "JORD13",
    pstcd: "BDGEOGEO",
    strloc: "JORDANS, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "JOSE09",
    pstcd: "BDLUCLUC",
    strloc: "JOSEY HILL, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "JOSE10",
    pstcd: "BDMICMIC",
    strloc: "JOSEPHS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "JOYP01",
    pstcd: "BDCHRFRE",
    strloc: "JOYPA DRIVE, FRERE PILGRIM - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "JUBI03",
    pstcd: "BDMICMIC",
    strloc: "JUBILEE GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KEIZ01",
    pstcd: "BDCHRCHR",
    strloc: "KEIZER HILL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "KELL06",
    pstcd: "BDMICBLA",
    strloc: "KELLMANS LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KEND01",
    pstcd: "BDJOHJOH",
    strloc: "KENDAL TENANTRY, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "KEND02",
    pstcd: "BDJOHJOH",
    strloc: "KENDAL PLANTATION, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "KEND03",
    pstcd: "BDCHRCHR",
    strloc: "KENDAL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "KEND03",
    pstcd: "BDJOHJOH",
    strloc: "KENDAL, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "KEND04",
    pstcd: "BDCHRCHR",
    strloc: "KENDAL HILL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "KEND05",
    pstcd: "BDCHRCHR",
    strloc: "KENDALL HILL TERRACE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "KEND06",
    pstcd: "BDCHRCHR",
    strloc: "KENDAL HILL TENANTRY, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "KEND07",
    pstcd: "BDCHRCHR",
    strloc: "KENDAL HILL DEVELOPMENT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "KENS02",
    pstcd: "BDMICMIC",
    strloc: "KENSINGTON LODGE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KENS03",
    pstcd: "BDMICMIC",
    strloc: "KENSINGTON NEW ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KENS04",
    pstcd: "BDMICMIC",
    strloc: "KENSINGTON, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KENT02",
    pstcd: "BDCHRCHR",
    strloc: "KENT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "KENT03",
    pstcd: "BDCHRCHR",
    strloc: "KENT RIDGE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "KEW 02",
    pstcd: "BDMICBAN",
    strloc: "KEW ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KEWH03",
    pstcd: "BDMICMIC",
    strloc: "KEW HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KEWL02",
    pstcd: "BDMICMIC",
    strloc: "KEW LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KEWL02",
    pstcd: "BDTHOTHO",
    strloc: "KEW LAND, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "KEWR01",
    pstcd: "BDMICMIC",
    strloc: "KEW ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KILL04",
    pstcd: "BDMICMIC",
    strloc: "KILLDARE CLOSE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KINC01",
    pstcd: "BDCHRCHR",
    strloc: "KINCHES AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "KING01",
    pstcd: "BDANDAND",
    strloc: "KING STREET, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "KING01",
    pstcd: "BDMICMIC",
    strloc: "KING STREET, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KING10",
    pstcd: "BDJAMJAM",
    strloc: "KINGS VILLAGE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "KING10",
    pstcd: "BDMICMIC",
    strloc: "KINGS VILLAGE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KING11",
    pstcd: "BDBGTBGT",
    strloc: "KING WILLIAM STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "KING12",
    pstcd: "BDMICBLA",
    strloc: "KINGSLAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KING12",
    pstcd: "BDMICBLA",
    strloc: "KINGSLAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KING13",
    pstcd: "BDCHRKIN",
    strloc: "KINGSLAND NORTH, KINGSLAND - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "KING14",
    pstcd: "BDCHRKIN",
    strloc: "KINGSLAND TERRACE STAGE 1, KINGSLAND - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "KING15",
    pstcd: "BDCHRKIN",
    strloc: "KINGSLAND GARDENS, KINGSLAND - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "KING16",
    pstcd: "BDCHRKIN",
    strloc: "KINGSLAND TERRACE, KINGSLAND - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "KING17",
    pstcd: "BDCHRKIN",
    strloc: "KINGSLAND STAGE 4, KINGSLAND - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "KING18",
    pstcd: "BDCHRKIN",
    strloc: "KINGSLAND PLANTATION, KINGSLAND - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "KING19",
    pstcd: "BDCHRCHR",
    strloc: "KINGS COURT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "KING20",
    pstcd: "BDCHRCHR",
    strloc: "KING GEORGE ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "KING20",
    pstcd: "BDMICBAN",
    strloc: "KING GEORGE ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KING21",
    pstcd: "BDMICBAN",
    strloc: "KING EDWARD ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KING22",
    pstcd: "BDMICMIC",
    strloc: "KINGS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KING23",
    pstcd: "BDMICMIC",
    strloc: "KINGSTON CROSS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KING24",
    pstcd: "BDMICMIC",
    strloc: "KINGSTON GARDENS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KING25",
    pstcd: "BDMICMIC",
    strloc: "KINGSTON TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KING28",
    pstcd: "BDCHRKIN",
    strloc: "KINGSLAND CRESCENT, KINGSLAND - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "KING29",
    pstcd: "BDCHRKIN",
    strloc: "KINGSLAND PARK, KINGSLAND - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "KING32",
    pstcd: "BDCHRKIN",
    strloc: "KINGSLAND HEIGHTS, KINGSLAND - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "KIRT01",
    pstcd: "BDPHIPHI",
    strloc: "KIRTON, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "KIRT02",
    pstcd: "BDPHIPHI",
    strloc: "KIRTONS BOTTOM, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "KIRT03",
    pstcd: "BDMICMIC",
    strloc: "KIRTON ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KIRT04",
    pstcd: "BDPHIPHI",
    strloc: "KIRTONS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "KNIG04",
    pstcd: "BDMICMIC",
    strloc: "KNIGHTS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "KNIG05",
    pstcd: "BDJOHJOH",
    strloc: "KNIGHTS VILLAGE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "KNIG06",
    pstcd: "BDMICMIC",
    strloc: "KNIGHTS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LABO03",
    pstcd: "BDPHIPHI",
    strloc: "LABOUR COLLEGE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "LADY11",
    pstcd: "BDMICMIC",
    strloc: "LADYMEADE GARDENS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LAKE01",
    pstcd: "BDBGTBGT",
    strloc: "LAKES FOLLY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "LAKE01",
    pstcd: "BDBGTBGT",
    strloc: "LAKES FOLLY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "LAKE01",
    pstcd: "BDMICMIC",
    strloc: "LAKES FOLLY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LAKE02",
    pstcd: "BDANDAND",
    strloc: "LAKES VILLAGE, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "LAKE03",
    pstcd: "BDMICMIC",
    strloc: "LAKES CLOSE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LAMB02",
    pstcd: "BDLUCLUC",
    strloc: "LAMBERTS PLANTATION, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "LAMB03",
    pstcd: "BDLUCLUC",
    strloc: "LAMBERTS HILL, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "LAMM04",
    pstcd: "BDJOSJOS",
    strloc: "LAMMINGS HOUSING AREA, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "LAMM05",
    pstcd: "BDJOSJOS",
    strloc: "LAMMINGS, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "LANC03",
    pstcd: "BDBGTBGT",
    strloc: "LANCHESTER LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "LANC04",
    pstcd: "BDJAMJAM",
    strloc: "LANCASTER, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "LANC05",
    pstcd: "BDJAMJAM",
    strloc: "LANCASTER TERRACE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "LANC06",
    pstcd: "BDJAMJAM",
    strloc: "LANNCASTER HEIGHTS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "LANC07",
    pstcd: "BDJAMJAM",
    strloc: "LANCASTER DEVELOPMENT, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "LAND02",
    pstcd: "BDMICMIC",
    strloc: "LANDS END, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LAND03",
    pstcd: "BDCHRCHR",
    strloc: "LANDS DOWN, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "LASC02",
    pstcd: "BDJAMJAM",
    strloc: "LASCELLES TERRACE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "LASC02",
    pstcd: "BDMICPIN",
    strloc: "LASCELLES TERRACE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LASC03",
    pstcd: "BDJAMJAM",
    strloc: "LASCELLS AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "LASC04",
    pstcd: "BDJAMJAM",
    strloc: "LASCELLS HILL, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "LASH02",
    pstcd: "BDPHIPHI",
    strloc: "LASHLEY ROAD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "LASH03",
    pstcd: "BDJAMJAM",
    strloc: "LASHLEY'S ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "LAUN02",
    pstcd: "BDMICBAN",
    strloc: "LAUNDRY ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LAWR06",
    pstcd: "BDMICMIC",
    strloc: "LAWRENCE AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LAYN01",
    pstcd: "BDCHRCHR",
    strloc: "LAYNES ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "LAYN01",
    pstcd: "BDMICMIC",
    strloc: "LAYNES ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LAYN01",
    pstcd: "BDMICMIC",
    strloc: "LAYNES ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LAYN02",
    pstcd: "BDJOSJOS",
    strloc: "LAYNES LAND, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "LAYN03",
    pstcd: "BDMICMIC",
    strloc: "LAYNES AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LAYN04",
    pstcd: "BDJAMJAM",
    strloc: "LAYNES GAP, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "LAYN04",
    pstcd: "BDMICMIC",
    strloc: "LAYNES GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LEAD01",
    pstcd: "BDCHRCHR",
    strloc: "LEAD VALE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "LEAM01",
    pstcd: "BDPETPET",
    strloc: "LEAMINGTON, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "LEAR01",
    pstcd: "BDGEOGEO",
    strloc: "LEARS LAND, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "LEAR02",
    pstcd: "BDMICMIC",
    strloc: "LEARS PLANTATION, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LEAR03",
    pstcd: "BDMICMIC",
    strloc: "LEARS TENANTRY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LEAR04",
    pstcd: "BDMICMIC",
    strloc: "LEARS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LEIG01",
    pstcd: "BDMICMIC",
    strloc: "LEIGH TENANTRY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LEIN01",
    pstcd: "BDMICMIC",
    strloc: "LEINSTER ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LEMO03",
    pstcd: "BDJOHJOH",
    strloc: "LEMON ARBOR, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "LENN04",
    pstcd: "BDMICBLA",
    strloc: "LENNOX AVENUE, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LESB01",
    pstcd: "BDANDAND",
    strloc: "LESBEHOLDEN, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "LESC01",
    pstcd: "BDTHOTHO",
    strloc: "LES CHAMPS, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "LETT01",
    pstcd: "BDMICMIC",
    strloc: "LETTUCE LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LETT02",
    pstcd: "BDMICMIC",
    strloc: "LETTUCE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LEWI07",
    pstcd: "BDTHOTHO",
    strloc: "LEWIS VILLAGE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "LEWI08",
    pstcd: "BDMICMIC",
    strloc: "LEWIS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LEWI09",
    pstcd: "BDMICMIC",
    strloc: "LEWIS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LICO01",
    pstcd: "BDMICMIC",
    strloc: "LICORISH VILLAGE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LIGH03",
    pstcd: "BDBGTBGT",
    strloc: "LIGHTFOOT LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "LIGH03",
    pstcd: "BDBGTBGT",
    strloc: "LIGHTFOOT LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "LIGH03",
    pstcd: "BDMICMIC",
    strloc: "LIGHTFOOT LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LILL04",
    pstcd: "BDJAMJAM",
    strloc: "LILLY DRIVE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "LIME06",
    pstcd: "BDJAMJAM",
    strloc: "LIMEGROVE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "LIME07",
    pstcd: "BDJAMJAM",
    strloc: "LIME GROVE PARK, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "LINK01",
    pstcd: "BDMICBAN",
    strloc: "LINK ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LINK02",
    pstcd: "BDMICMIC",
    strloc: "LINK LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LION03",
    pstcd: "BDTHOTHO",
    strloc: "LION CASTLE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "LION04",
    pstcd: "BDTHOTHO",
    strloc: "LION CASTLE TENANTRY, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "LISB01",
    pstcd: "BDCHRCHR",
    strloc: "LISBON VALE DEVELOPMENT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "LISB02",
    pstcd: "BDCHRCHR",
    strloc: "LISBON VALE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "LITE01",
    pstcd: "BDBGTBGT",
    strloc: "LITERARY ROW, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "LITE01",
    pstcd: "BDBGTBGT",
    strloc: "LITERARY ROW, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "LITT03",
    pstcd: "BDTHOTHO",
    strloc: "LITTLE EDGHILL, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "LITT04",
    pstcd: "BDTHOTHO",
    strloc: "LITTLE SHARON, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "LITT05",
    pstcd: "BDJOSJOS",
    strloc: "LITTLE DIAMOND, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "LITT06",
    pstcd: "BDLUCLUC",
    strloc: "LITTLE BAY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "LITT07",
    pstcd: "BDJAMJAM",
    strloc: "LITTLE RIDGE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "LITT08",
    pstcd: "BDPHIPHI",
    strloc: "LITTLE HOPE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "LIVE01",
    pstcd: "BDBGTBGT",
    strloc: "LIVERPOOL LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "LIVE01",
    pstcd: "BDBGTBGT",
    strloc: "LIVERPOOL LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "LIVE02",
    pstcd: "BDMICMIC",
    strloc: "LIVERPOOL ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LOCU03",
    pstcd: "BDGEOGEO",
    strloc: "LOCUST HALL LAND, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "LOCU04",
    pstcd: "BDGEOGEO",
    strloc: "LOCUST HALL HEIGHTS, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "LOCU05",
    pstcd: "BDGEOGEO",
    strloc: "LOCUST HALL TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "LOCU06",
    pstcd: "BDGEOGEO",
    strloc: "LOCUST HALL DEVELOPMENT, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "LOCU07",
    pstcd: "BDGEOGEO",
    strloc: "LOCUST HALL, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "LODG04",
    pstcd: "BDCHRCHR",
    strloc: "LODGE ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "LODG04",
    pstcd: "BDMICMIC",
    strloc: "LODGE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LODG05",
    pstcd: "BDCHRCHR",
    strloc: "LODGE ROAD B, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "LODG06",
    pstcd: "BDMICMIC",
    strloc: "LODGE GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LODG07",
    pstcd: "BDMICMIC",
    strloc: "LODGE HILL LAND SCHEME 2ND AVE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LODG08",
    pstcd: "BDMICMIC",
    strloc: "LODGE HILL LAND SCHEME 3RD AVE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LODG09",
    pstcd: "BDMICMIC",
    strloc: "LODGE TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LODG10",
    pstcd: "BDMICMIC",
    strloc: "LODGE HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LODG11",
    pstcd: "BDMICMIC",
    strloc: "LODGE HILL HOUSING AREA, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LODG12",
    pstcd: "BDMICCAV",
    strloc: "LODGE HILL LAND SCHEME 1ST AVE, CAVE HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LODG13",
    pstcd: "BDMICMIC",
    strloc: "LODGE CRESCENT, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LOGA01",
    pstcd: "BDGEOGEO",
    strloc: "LOGAN ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "LOND03",
    pstcd: "BDMICMIC",
    strloc: "LONDON ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LONE02",
    pstcd: "BDPETPET",
    strloc: "LONESOME HILL TENANTRY, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "LONG06",
    pstcd: "BDPHIPHI",
    strloc: "LONG BAY DEVELOPMENT, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "LONG07",
    pstcd: "BDPHIPHI",
    strloc: "LONG BAY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "LONG08",
    pstcd: "BDMICMIC",
    strloc: "LONG GAP 1ST AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LONG09",
    pstcd: "BDMICMIC",
    strloc: "LONG GAP HOUSING AREA, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LONG10",
    pstcd: "BDMICMIC",
    strloc: "LONG GAP 2ND AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LONG11",
    pstcd: "BDMICMIC",
    strloc: "LONG GAP 3RD AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LONG12",
    pstcd: "BDMICMIC",
    strloc: "LONG GAP 4TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LONG13",
    pstcd: "BDMICMIC",
    strloc: "LONG GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LONG14",
    pstcd: "BDCHRCHR",
    strloc: "LONG BEACH DEVELOPMENT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "LONG15",
    pstcd: "BDMICMIC",
    strloc: "LONGFORD ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LONG16",
    pstcd: "BDMICMIC",
    strloc: "LONGFORD PLACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LOOP01",
    pstcd: "BDCHRCHR",
    strloc: "LOOP, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "LOVE06",
    pstcd: "BDJOHJOH",
    strloc: "LOVELLS ROAD, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "LOVE07",
    pstcd: "BDMICMIC",
    strloc: "LOVELLS DRIVE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LOWE06",
    pstcd: "BDGEOGEO",
    strloc: "LOWER ESTATE TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "LOWE07",
    pstcd: "BDGEOGEO",
    strloc: "LOWES ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "LOWE08",
    pstcd: "BDCHRCHR",
    strloc: "LOWER GREYS TENANTRY, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "LOWE09",
    pstcd: "BDMICLOW",
    strloc: "LOWER ESTATE GARDENS, LOWER ESTATE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LOWE10",
    pstcd: "BDMICMIC",
    strloc: "LOWER CODRINGTON HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LOWE11",
    pstcd: "BDMICMIC",
    strloc: "LOWER BURNEY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LOWE12",
    pstcd: "BDMICLOW",
    strloc: "LOWER ESTATE HEIGHTS, LOWER ESTATE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LOWE13",
    pstcd: "BDMICLOW",
    strloc: "LOWER ESTATE TERRACE, LOWER ESTATE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LOWE14",
    pstcd: "BDMICLOW",
    strloc: "LOWER ESTATE, LOWER ESTATE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LOWE15",
    pstcd: "BDMICLOW",
    strloc: "LOWER ESTATE PLANTATION, LOWER ESTATE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LOWE16",
    pstcd: "BDMICMIC",
    strloc: "LOWER GREEN, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LOWE39",
    pstcd: "BDGEOGEO",
    strloc: "LOWER ESTATE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "LOWL02",
    pstcd: "BDCHRCHR",
    strloc: "LOWLAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "LOWL02",
    pstcd: "BDGEOGEO",
    strloc: "LOWLAND, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "LOWL02",
    pstcd: "BDLUCLUC",
    strloc: "LOWLAND, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "LOWL03",
    pstcd: "BDCHRCHR",
    strloc: "LOWLAND PLANTATION, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "LOWL03",
    pstcd: "BDLUCLUC",
    strloc: "LOWLAND PLANTATION, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "LOWL04",
    pstcd: "BDCHRCHR",
    strloc: "LOWLAND PARK, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "LOWL05",
    pstcd: "BDCHRCHR",
    strloc: "LOWLAND ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "LOWL06",
    pstcd: "BDMICMIC",
    strloc: "LOWLAND DRIVE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LOWT01",
    pstcd: "BDCHRCHR",
    strloc: "LOWTHERS HILL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "LOWT02",
    pstcd: "BDCHRCHR",
    strloc: "LOWTHERS PLANTATION, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "LOWT04",
    pstcd: "BDCHRCHR",
    strloc: "LOWTHERS PARK, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "LUCA02",
    pstcd: "BDBGTBGT",
    strloc: "LUCAS STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "LUCA02",
    pstcd: "BDBGTBGT",
    strloc: "LUCAS STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "LUCA02",
    pstcd: "BDPHIPHI",
    strloc: "LUCAS STREET, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "LUCO01",
    pstcd: "BDCHRCHR",
    strloc: "LUCOR GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "LUKE03",
    pstcd: "BDMICMIC",
    strloc: "LUKES AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LYDE02",
    pstcd: "BDPHIPHI",
    strloc: "LYDERS HILL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "LYNC05",
    pstcd: "BDMICMIC",
    strloc: "LYNCH ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LYNC06",
    pstcd: "BDPHIPHI",
    strloc: "LYNCHES, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "LYND02",
    pstcd: "BDMICMIC",
    strloc: "LYNDEN GROVE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "LYON01",
    pstcd: "BDMICMIC",
    strloc: "LYONS STREET, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MACA07",
    pstcd: "BDGEOGEO",
    strloc: "MACARONI VILLAGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "MADD01",
    pstcd: "BDMICMIC",
    strloc: "MADDISON TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MAGA01",
    pstcd: "BDBGTBGT",
    strloc: "MAGAZINE LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MAGA01",
    pstcd: "BDBGTBGT",
    strloc: "MAGAZINE LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MAGA01",
    pstcd: "BDBGTBGT",
    strloc: "MAGAZINE LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MAGA01",
    pstcd: "BDBGTBGT",
    strloc: "MAGAZINE LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MAHA21",
    pstcd: "BDMICMIC",
    strloc: "MAHAICA, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MAHO01",
    pstcd: "BDJAMJAM",
    strloc: "MAHOGANY AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MAHO08",
    pstcd: "BDBGTBGT",
    strloc: "MAHONS ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MAHO08",
    pstcd: "BDBGTBGT",
    strloc: "MAHONS ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MAHO09",
    pstcd: "BDBGTBGT",
    strloc: "MAHOGANY LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MAHO09",
    pstcd: "BDBGTBGT",
    strloc: "MAHOGANY LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MAHO09",
    pstcd: "BDMICMIC",
    strloc: "MAHOGANY LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MAID02",
    pstcd: "BDBGTBGT",
    strloc: "MAIDEN'S LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MAID02",
    pstcd: "BDBGTBGT",
    strloc: "MAIDEN'S LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MAID02",
    pstcd: "BDMICMIC",
    strloc: "MAIDEN'S LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MAIL01",
    pstcd: "BDJAMJAM",
    strloc: "MAILAND CLOSE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MAIN08",
    pstcd: "BDMICMIC",
    strloc: "MAINE AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MAIT04",
    pstcd: "BDJAMJAM",
    strloc: "MAITLAND CLOSE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MAJO01",
    pstcd: "BDPETSPE",
    strloc: "MAJOR WALK, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MALL02",
    pstcd: "BDJAMJAM",
    strloc: "MALLOWS LANE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MALL03",
    pstcd: "BDJAMJAM",
    strloc: "MALLOWS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MALV01",
    pstcd: "BDJOHJOH",
    strloc: "MALVERN, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "MALV02",
    pstcd: "BDJOHJOH",
    strloc: "MALVERN TENANTRY, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "MALV03",
    pstcd: "BDJOHJOH",
    strloc: "MALVERN PLANTATION, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "MALV04",
    pstcd: "BDMICMIC",
    strloc: "MALVERN LODGE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MANC01",
    pstcd: "BDBGTBGT",
    strloc: "MANCHESTER STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MANC01",
    pstcd: "BDBGTBGT",
    strloc: "MANCHESTER STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MANC02",
    pstcd: "BDMICMIC",
    strloc: "MANCHESTER ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MAND12",
    pstcd: "BDPHIPHI",
    strloc: "MANDERLY GARDENS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MANG08",
    pstcd: "BDPETSPE",
    strloc: "MANGO LANE, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MANG09",
    pstcd: "BDPETPET",
    strloc: "MANGROVE PLANTATION, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MANG09",
    pstcd: "BDPHIPHI",
    strloc: "MANGROVE PLANTATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MANG10",
    pstcd: "BDPETPET",
    strloc: "MANGROVE TENANTRY, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MANG10",
    pstcd: "BDPHIPHI",
    strloc: "MANGROVE TENANTRY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MANG11",
    pstcd: "BDPHIPHI",
    strloc: "MANGROVE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MANG12",
    pstcd: "BDPHIPHI",
    strloc: "MANGROVE DEVELOPMENT, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MANG13",
    pstcd: "BDPHIPHI",
    strloc: "MANGROVE PARK, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MANG14",
    pstcd: "BDPHIPHI",
    strloc: "MANGROVE GARDENS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MANG15",
    pstcd: "BDPHIPHI",
    strloc: "MANGROVE TERRACE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MANN06",
    pstcd: "BDGEOGEO",
    strloc: "MANNINGS VILLAGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "MANN07",
    pstcd: "BDMICMIC",
    strloc: "MANNS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MANN08",
    pstcd: "BDMICMIC",
    strloc: "MANNING LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MANS03",
    pstcd: "BDMICBAN",
    strloc: "MANSION ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MAPL02",
    pstcd: "BDMICPIN",
    strloc: "MAPLE PATH, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MAPL03",
    pstcd: "BDCHRCHR",
    strloc: "MAPLE COTTAGE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MAPL04",
    pstcd: "BDCHRCHR",
    strloc: "MAPLE GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MAPL05",
    pstcd: "BDCHRCHR",
    strloc: "MAPLE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MAPP01",
    pstcd: "BDPHIPHI",
    strloc: "MAPPS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MAPP02",
    pstcd: "BDPHIPHI",
    strloc: "MAPPS TERRACE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MAPP03",
    pstcd: "BDMICMIC",
    strloc: "MAPP HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MARC15",
    pstcd: "BDPHIPHI",
    strloc: "MARCHFIELD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MARC17",
    pstcd: "BDPHIPHI",
    strloc: "MARCHFIELD VILLAGE ROAD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MARG05",
    pstcd: "BDMICMIC",
    strloc: "MARGATE TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MARG06",
    pstcd: "BDCHRCHR",
    strloc: "MARGATE GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MARH01",
    pstcd: "BDBGTBGT",
    strloc: "MARHILL STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MARH01",
    pstcd: "BDBGTBGT",
    strloc: "MARHILL STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MARI12",
    pstcd: "BDJAMHUS",
    strloc: "MARIGOLD DRIVE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MARI16",
    pstcd: "BDMICMIC",
    strloc: "MARINE SQUARE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MARI17",
    pstcd: "BDCHRCHR",
    strloc: "MARINE GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MARI18",
    pstcd: "BDMICBUS",
    strloc: "MARINE ROAD, BUSH HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MARI18",
    pstcd: "BDMICMIC",
    strloc: "MARINE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MARI19",
    pstcd: "BDJAMSUN",
    strloc: "MARIGOLD ROW, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MARK03",
    pstcd: "BDPHIPHI",
    strloc: "MARKET ROAD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MARK04",
    pstcd: "BDGEOGEO",
    strloc: "MARKET HILL, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "MARL03",
    pstcd: "BDPHIPHI",
    strloc: "MARLEY VALE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MARS01",
    pstcd: "BDMICBLA",
    strloc: "MARSHALL GARDENS, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MARS05",
    pstcd: "BDPHIPHI",
    strloc: "MARSHALL HALL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MARS06",
    pstcd: "BDMICBLA",
    strloc: "MARSHALLS LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MARS06",
    pstcd: "BDMICMIC",
    strloc: "MARSHALLS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MARS07",
    pstcd: "BDMICMIC",
    strloc: "MARSHALLS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MARS08",
    pstcd: "BDMICPIN",
    strloc: "MARSDEN AVENUE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MARS09",
    pstcd: "BDMICPIN",
    strloc: "MARSDEN ROAD, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MARS10",
    pstcd: "BDMICMIC",
    strloc: "MARSHALL PARK, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MARS11",
    pstcd: "BDMICMIC",
    strloc: "MARSHALL & PARKINSON ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MART11",
    pstcd: "BDJOHJOH",
    strloc: "MARTIN'S BAY, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "MART12",
    pstcd: "BDMICMIC",
    strloc: "MARTINDALES ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MART12",
    pstcd: "BDMICPIN",
    strloc: "MARTINDALES ROAD, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MART13",
    pstcd: "BDMICMIC",
    strloc: "MARTINIQUE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MART14",
    pstcd: "BDMICPIN",
    strloc: "MARTINS ROAD, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MASC01",
    pstcd: "BDJOSJOS",
    strloc: "MASCHOLL, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "MASO02",
    pstcd: "BDBGTBGT",
    strloc: "MASONIC ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MASO03",
    pstcd: "BDBGTBGT",
    strloc: "MASON HALL STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MASO03",
    pstcd: "BDBGTBGT",
    strloc: "MASON HALL STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MASS04",
    pstcd: "BDJOHJOH",
    strloc: "MASSIAH STREET, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "MATT03",
    pstcd: "BDJAMJAM",
    strloc: "MATTHEW DRIVE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MAVE01",
    pstcd: "BDJAMSUN",
    strloc: "MAVERICKS ROAD, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MAXW03",
    pstcd: "BDCHRCHR",
    strloc: "MAXWELL VIEW, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MAXW04",
    pstcd: "BDCHRCHR",
    strloc: "MAXWELL ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MAXW05",
    pstcd: "BDCHRCHR",
    strloc: "MAXWELL HEIGHTS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MAXW06",
    pstcd: "BDCHRCHR",
    strloc: "MAXWELL HILL MAIN ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MAXW08",
    pstcd: "BDCHRCHR",
    strloc: "MAXWELL ROAD LOWER, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MAXW09",
    pstcd: "BDCHRCHR",
    strloc: "MAXWELL TERRACE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MAXW10",
    pstcd: "BDCHRCHR",
    strloc: "MAXWELL COAST ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MAXW11",
    pstcd: "BDCHRCHR",
    strloc: "MAXWELL PARK, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MAXW12",
    pstcd: "BDCHRCHR",
    strloc: "MAXWELL PLAINES, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MAYC03",
    pstcd: "BDLUCLUC",
    strloc: "MAYCOCKS VILLAGE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "MAYC04",
    pstcd: "BDLUCLUC",
    strloc: "MAYCOCKS TERRACE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "MAYC05",
    pstcd: "BDLUCLUC",
    strloc: "MAYCOCKS, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "MAYC06",
    pstcd: "BDLUCLUC",
    strloc: "MAYCOCKS ROAD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "MAYC06",
    pstcd: "BDMICMIC",
    strloc: "MAYCOCKS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MAYE02",
    pstcd: "BDJOSJOS",
    strloc: "MAYERS, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "MAYE03",
    pstcd: "BDMICBAN",
    strloc: "MAYERS LAND, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MAYE03",
    pstcd: "BDMICMIC",
    strloc: "MAYERS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MAYE04",
    pstcd: "BDMICBAN",
    strloc: "MAYERS GAP, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MAYE05",
    pstcd: "BDMICMIC",
    strloc: "MAYERS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MAYF04",
    pstcd: "BDGEOGEO",
    strloc: "MAYFIELD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "MAYF12",
    pstcd: "BDCHRCHR",
    strloc: "MAYFAIR TERRACE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MAYH01",
    pstcd: "BDJAMSUN",
    strloc: "MAYHOE AVENUE, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MAYN01",
    pstcd: "BDJOSJOS",
    strloc: "MAYNARDS VILLAGE, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "MAYN02",
    pstcd: "BDPETPET",
    strloc: "MAYNARD DEVELOPMENT, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MAYN03",
    pstcd: "BDPETPET",
    strloc: "MAYNARDS HOUSING AREA, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MAYN04",
    pstcd: "BDPETPET",
    strloc: "MAYNARDS PLANTATION, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MAYN05",
    pstcd: "BDMICMIC",
    strloc: "MAYNARDS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MAYN05",
    pstcd: "BDPETPET",
    strloc: "MAYNARDS LAND, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MAYP01",
    pstcd: "BDMICPIN",
    strloc: "MAYPORT, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MCCA04",
    pstcd: "BDPHIPHI",
    strloc: "McCARTHY LAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MCCL01",
    pstcd: "BDMICMIC",
    strloc: "MCCLEANS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MCDO04",
    pstcd: "BDPHIPHI",
    strloc: "McDONALD FARMS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MCGR01",
    pstcd: "BDBGTBGT",
    strloc: "McGREGOR STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MCGR01",
    pstcd: "BDBGTBGT",
    strloc: "McGREGOR STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MCGR01",
    pstcd: "BDBGTBGT",
    strloc: "McGREGOR STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MEAD03",
    pstcd: "BDMICMIC",
    strloc: "MEADOW ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MEAD04",
    pstcd: "BDJAMJAM",
    strloc: "MEADOWVALE HEIGHTS # 1, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MEAD05",
    pstcd: "BDJAMJAM",
    strloc: "MEADOWVALE HEIGHTS #2, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MEAD06",
    pstcd: "BDJAMJAM",
    strloc: "MEADOWVALE HEIGHTS # 3, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MEDF02",
    pstcd: "BDMICMIC",
    strloc: "MEDFORD LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MEDF03",
    pstcd: "BDMICMIC",
    strloc: "MEDFORD ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MELL01",
    pstcd: "BDJOSJOS",
    strloc: "MELLOWS HILL, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "MELR01",
    pstcd: "BDTHOTHO",
    strloc: "MELROSE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "MELV02",
    pstcd: "BDJOSJOS",
    strloc: "MELVINS HILL, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "MELV03",
    pstcd: "BDGEOGEO",
    strloc: "MELVERTON, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "MELV04",
    pstcd: "BDMICBLA",
    strloc: "MELVIN AVENUE, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MERR02",
    pstcd: "BDPHIPHI",
    strloc: "MERRICKS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MESS01",
    pstcd: "BDGEOGEO",
    strloc: "MESS HOUSE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "MIDD01",
    pstcd: "BDBGTBGT",
    strloc: "MIDDLE STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MIDD01",
    pstcd: "BDBGTBGT",
    strloc: "MIDDLE STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MIDD02",
    pstcd: "BDGEOGEO",
    strloc: "MIDDLETON, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "MIDD03",
    pstcd: "BDMICMIC",
    strloc: "MIDDLE LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MIDL02",
    pstcd: "BDCHRCHR",
    strloc: "MIDLAND AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MIDW04",
    pstcd: "BDMICPIN",
    strloc: "MIDWAY LANE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MIDW05",
    pstcd: "BDCHRCHR",
    strloc: "MIDWAY, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MILE03",
    pstcd: "BDPETPET",
    strloc: "MILE & A QUARTER, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MILI01",
    pstcd: "BDMICBAN",
    strloc: "MILITARY ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MILI01",
    pstcd: "BDMICBUS",
    strloc: "MILITARY ROAD, BUSH HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MILL10",
    pstcd: "BDMICMIC",
    strloc: "MILLYARD ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MILL10",
    pstcd: "BDMICMIC",
    strloc: "MILLYARD ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MILL11",
    pstcd: "BDJOHJOH",
    strloc: "MILLERS, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "MILL12",
    pstcd: "BDMICMIC",
    strloc: "MILLIONAIRE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MILL12",
    pstcd: "BDPETPET",
    strloc: "MILLIONAIRE ROAD, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MILL13",
    pstcd: "BDPETSPE",
    strloc: "MILLERS GAP, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MILL14",
    pstcd: "BDMICMIC",
    strloc: "MILLYARD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MILL15",
    pstcd: "BDJAMJAM",
    strloc: "MILLERS DEVELOPMENT, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MILL15",
    pstcd: "BDMICMIC",
    strloc: "MILLERS DEVELOPMENT, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MILL16",
    pstcd: "BDMICPIN",
    strloc: "MILLHURST LANE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MILL17",
    pstcd: "BDCHRCHR",
    strloc: "MILLWALL AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MILL18",
    pstcd: "BDMICMIC",
    strloc: "MILLARS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MILL19",
    pstcd: "BDMICMIC",
    strloc: "MILLBANK, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MILL20",
    pstcd: "BDMICMIC",
    strloc: "MILLERS TENANTRY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MILL23",
    pstcd: "BDMICMIC",
    strloc: "MILLENIUM HEIGHTS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MILL23",
    pstcd: "BDTHOTHO",
    strloc: "MILLENIUM HEIGHTS, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "MISS03",
    pstcd: "BDMICMIC",
    strloc: "MISSION GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MOLL01",
    pstcd: "BDBGTBGT",
    strloc: "MOLLS ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MOLL01",
    pstcd: "BDBGTBGT",
    strloc: "MOLLS ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "MOLY01",
    pstcd: "BDJAMSUN",
    strloc: "MOLYNEUX ROAD, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MOLY02",
    pstcd: "BDJAMSUN",
    strloc: "MOLYNEUX, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MONC03",
    pstcd: "BDPHIPHI",
    strloc: "MONCRIEFFE TENANTRY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MONR03",
    pstcd: "BDGEOGEO",
    strloc: "MONROE ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "MONR03",
    pstcd: "BDMICMIC",
    strloc: "MONROE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MONR04",
    pstcd: "BDGEOGEO",
    strloc: "MONROE VILLAGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "MONT27",
    pstcd: "BDJOHJOH",
    strloc: "MONTCRIEFFE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "MONT28",
    pstcd: "BDJOHJOH",
    strloc: "MONTCRIEFFE PLANTATION, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "MONT29",
    pstcd: "BDJOHJOH",
    strloc: "MONTCRIEFFE DEVELOPMENT, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "MONT30",
    pstcd: "BDMICMIC",
    strloc: "MONTGOMERY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MONT31",
    pstcd: "BDMICMIC",
    strloc: "MONTEITH GARDENS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MONT32",
    pstcd: "BDCHRCHR",
    strloc: "MONTROSE DEVELOPMENT STAGE 1, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MONT33",
    pstcd: "BDCHRCHR",
    strloc: "MONTROSE DEVELOPMENT STAGE 2, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MONT34",
    pstcd: "BDCHRCHR",
    strloc: "MONTROSE MAIN ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MONT35",
    pstcd: "BDCHRCHR",
    strloc: "MONTROSE GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MONT36",
    pstcd: "BDCHRCHR",
    strloc: "MONTROSE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MOON09",
    pstcd: "BDGEOGEO",
    strloc: "MOONSHINE TERRACE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "MOON10",
    pstcd: "BDGEOGEO",
    strloc: "MOONSHINE PLANTATION, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "MOON11",
    pstcd: "BDGEOGEO",
    strloc: "MOONSHINE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "MOOR04",
    pstcd: "BDJOHJOH",
    strloc: "MOORES LAND, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "MOOR04",
    pstcd: "BDMICMIC",
    strloc: "MOORES LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MOOR05",
    pstcd: "BDPETPET",
    strloc: "MOOR HILL VILLAGE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MOOR06",
    pstcd: "BDPETPET",
    strloc: "MOOR HILL, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MOOR07",
    pstcd: "BDJAMJAM",
    strloc: "MOORINGS LAND, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MORG03",
    pstcd: "BDANDAND",
    strloc: "MORGAN LEWIS, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "MORR08",
    pstcd: "BDMICMIC",
    strloc: "MORRIS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MOSC01",
    pstcd: "BDGEOGEO",
    strloc: "MOSCOW TERRACE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "MOSC02",
    pstcd: "BDGEOGEO",
    strloc: "MOSCOW, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "MOSC03",
    pstcd: "BDMICBLA",
    strloc: "MOSCLIFFE, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MOSE05",
    pstcd: "BDANDAND",
    strloc: "MOSE BOTTOM, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "MOTT02",
    pstcd: "BDMICBAN",
    strloc: "MOTTLEY LAND, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MOUN13",
    pstcd: "BDJOHJOH",
    strloc: "MOUNT TABOR, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "MOUN14",
    pstcd: "BDANDAND",
    strloc: "MOUNT HILLABY, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "MOUN14",
    pstcd: "BDTHOTHO",
    strloc: "MOUNT HILLABY, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "MOUN15",
    pstcd: "BDTHOTHO",
    strloc: "MOUNT MISER, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "MOUN16",
    pstcd: "BDTHOTHO",
    strloc: "MOUNT WILTON, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "MOUN17",
    pstcd: "BDJOSJOS",
    strloc: "MOUNT DACRES, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "MOUN18",
    pstcd: "BDLUCLUC",
    strloc: "MOUNT VIEW, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "MOUN19",
    pstcd: "BDLUCLUC",
    strloc: "MOUNT GAY PLANTATION, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "MOUN20",
    pstcd: "BDLUCLUC",
    strloc: "MOUNT GAY TENANTRY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "MOUN21",
    pstcd: "BDANDAND",
    strloc: "MOUNT STEHNEY, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "MOUN22",
    pstcd: "BDLUCLUC",
    strloc: "MOUNT POYER, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "MOUN23",
    pstcd: "BDANDAND",
    strloc: "MOUNT ALL, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "MOUN24",
    pstcd: "BDGEOGEO",
    strloc: "MOUNT TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "MOUN25",
    pstcd: "BDGEOGEO",
    strloc: "MOUNT HILL, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "MOUN26",
    pstcd: "BDGEOGEO",
    strloc: "MOUNT DEVELOPMENT, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "MOUN27",
    pstcd: "BDPETPET",
    strloc: "MOUNT BREVITOR TENANTRY, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MOUN28",
    pstcd: "BDPETPET",
    strloc: "MOUNT BREVITOR, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MOUN29",
    pstcd: "BDPETPET",
    strloc: "MOUNT BREVITOR PLANTATION, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MOUN30",
    pstcd: "BDPETPET",
    strloc: "MOUNT VILLAGE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MOUN31",
    pstcd: "BDPHIPHI",
    strloc: "MOUNT VIEW VILLAGE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MOUN32",
    pstcd: "BDMICMIC",
    strloc: "MOUNT CLAPHAM, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MOUN33",
    pstcd: "BDMICPIN",
    strloc: "MOUNT JOY AVENUE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MOUN34",
    pstcd: "BDMICPIN",
    strloc: "MOUNT CLOSE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MOUN35",
    pstcd: "BDPHIPHI",
    strloc: "MOUNT PLEASANT GARDENS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MOUN36",
    pstcd: "BDPHIPHI",
    strloc: "MOUNT PLEASANT HEIGHTS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MOUN37",
    pstcd: "BDPHIPHI",
    strloc: "MOUNT PLEASANT PLANTATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "MOUN38",
    pstcd: "BDJAMJAM",
    strloc: "MOUNT STANDFAST LOWER, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MOUN39",
    pstcd: "BDJAMJAM",
    strloc: "MOUNT STANDFAST DEVELOPMENT, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MOUN40",
    pstcd: "BDJAMJAM",
    strloc: "MOUNT STANDFAST, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MOUN41",
    pstcd: "BDJAMJAM",
    strloc: "MOUNT STANDFAST TENANTRY ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MOUN42",
    pstcd: "BDMICMIC",
    strloc: "MOUNT FRIENDSHIP ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MOUN43",
    pstcd: "BDPETPET",
    strloc: "MOUNT NO 2, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MOUN65",
    pstcd: "BDPETPET",
    strloc: "MOUNT PROSPECT, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MOUR01",
    pstcd: "BDJAMJAM",
    strloc: "MOURNE CLOSE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "MULL01",
    pstcd: "BDPETPET",
    strloc: "MULLINS ROAD, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MULL02",
    pstcd: "BDPETPET",
    strloc: "MULLINS BAY TERRACE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MULL03",
    pstcd: "BDPETPET",
    strloc: "MULLINS DEVELOPMENT, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "MURP02",
    pstcd: "BDMICMIC",
    strloc: "MURPHY LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MURR05",
    pstcd: "BDMICMIC",
    strloc: "MURRAY'S GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MURR06",
    pstcd: "BDMICMIC",
    strloc: "MURRELL ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MURR07",
    pstcd: "BDCHRCHR",
    strloc: "MURRAY AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "MURR08",
    pstcd: "BDMICBLA",
    strloc: "MURRAY LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MUST01",
    pstcd: "BDMICMIC",
    strloc: "MUSTOR LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "MYLO01",
    pstcd: "BDMICMIC",
    strloc: "MY LORDS HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NAVY01",
    pstcd: "BDCHRCHR",
    strloc: "NAVY GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "NAZA01",
    pstcd: "BDMICMIC",
    strloc: "NAZARENE GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NAZA01",
    pstcd: "BDTHOTHO",
    strloc: "NAZARENE GAP, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "NEED01",
    pstcd: "BDMICMIC",
    strloc: "NEEDHAM'S POINT, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NEIL04",
    pstcd: "BDGEOGEO",
    strloc: "NEILS GAP, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "NEIL05",
    pstcd: "BDMICMIC",
    strloc: "NEILS TENANTRY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NEIL06",
    pstcd: "BDMICMIC",
    strloc: "NEILS PLANTATION, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NELS01",
    pstcd: "BDBGTBGT",
    strloc: "NELSON STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "NELS03",
    pstcd: "BDCHRCHR",
    strloc: "NELSON ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "NEPT01",
    pstcd: "BDMICMIC",
    strloc: "NEPTUNE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NESF01",
    pstcd: "BDLUCLUC",
    strloc: "NESFIELD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "NEST01",
    pstcd: "BDLUCLUC",
    strloc: "NESTFIELD ROAD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "NEW 05",
    pstcd: "BDPHIPHI",
    strloc: "NEW LAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "NEWB03",
    pstcd: "BDGEOGEO",
    strloc: "NEWBURY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "NEWB04",
    pstcd: "BDGEOGEO",
    strloc: "NEWBURY DEVELOPMENT, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "NEWB05",
    pstcd: "BDCHRCHR",
    strloc: "NEW BARRACKS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "NEWC03",
    pstcd: "BDJOHJOH",
    strloc: "NEWCASTLE HOUSE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "NEWC04",
    pstcd: "BDJOHJOH",
    strloc: "NEWCASTLE PLANTATION, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "NEWC05",
    pstcd: "BDJOHJOH",
    strloc: "NEWCASTLE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "NEWG02",
    pstcd: "BDLUCLUC",
    strloc: "NEW GATE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "NEWH02",
    pstcd: "BDPETPET",
    strloc: "NEW HAVEN, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "NEWH02",
    pstcd: "BDPHIPHI",
    strloc: "NEW HAVEN, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "NEWH03",
    pstcd: "BDPHIPHI",
    strloc: "NEW HAVEN PLANTATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "NEWM02",
    pstcd: "BDGEOGEO",
    strloc: "NEW MARKET, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "NEWO01",
    pstcd: "BDMICMIC",
    strloc: "NEW ORLEANS 1ST AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NEWO02",
    pstcd: "BDMICMIC",
    strloc: "NEW ORLEANS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NEWO03",
    pstcd: "BDMICMIC",
    strloc: "NEW ORLEANS 2ND AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NEWO04",
    pstcd: "BDMICMIC",
    strloc: "NEW ORLEANS 3RD AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NEWO05",
    pstcd: "BDMICMIC",
    strloc: "NEW ORLEANS 4TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NEWO06",
    pstcd: "BDMICMIC",
    strloc: "NEW ORLEANS 5TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NEWO07",
    pstcd: "BDMICMIC",
    strloc: "NEW ORLEANS 6TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NEWO08",
    pstcd: "BDMICMIC",
    strloc: "NEW ORLEANS 7TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NEWO09",
    pstcd: "BDMICMIC",
    strloc: "NEW ORLEANS 8TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NEWO10",
    pstcd: "BDMICMIC",
    strloc: "NEW ORLEANS 9TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NEWO11",
    pstcd: "BDMICMIC",
    strloc: "NEW ORLEANS 10TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NEWS01",
    pstcd: "BDPETPET",
    strloc: "NEW STEAD, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "NEWT03",
    pstcd: "BDJOSJOS",
    strloc: "NEWTONS ROAD, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "NEWT04",
    pstcd: "BDJOSJOS",
    strloc: "NEWTON CRESCENT, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "NEWT04",
    pstcd: "BDMICPIN",
    strloc: "NEWTON CRESCENT, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NEWT05",
    pstcd: "BDCHRNEW",
    strloc: "NEWTON INDUSTRIAL PARK SOUTH, NEWTON - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "NEWT06",
    pstcd: "BDCHRCHR",
    strloc: "NEWTON PLANTATION, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "NEWT07",
    pstcd: "BDCHRCHR",
    strloc: "NEWTON TERRACE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "NEWT08",
    pstcd: "BDCHRCHR",
    strloc: "NEWTON TENANTRY, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "NEWT14",
    pstcd: "BDCHRNEW",
    strloc: "NEWTON INDUSTRIAL PARK NORTH, NEWTON - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "NEWT16",
    pstcd: "BDCHRNEW",
    strloc: "NEWTON INDUSTRIAL ESTATE, NEWTON - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "NICH03",
    pstcd: "BDPETPET",
    strloc: "NICHOLAS PLANTATION, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "NICH04",
    pstcd: "BDJAMJAM",
    strloc: "NICHOLLS VILLAGE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "NILE02",
    pstcd: "BDBGTBGT",
    strloc: "NILE STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "NILE02",
    pstcd: "BDBGTBGT",
    strloc: "NILE STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "NILE03",
    pstcd: "BDGEOGEO",
    strloc: "NILES ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "NILE04",
    pstcd: "BDPETSPE",
    strloc: "NILES GAP, SPEIGHSTOWN, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "NILE05",
    pstcd: "BDCHRCHR",
    strloc: "NILES CORNER, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "NORT01",
    pstcd: "BDMICMIC",
    strloc: "NORTH ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NORT14",
    pstcd: "BDLUCLUC",
    strloc: "NORTH POINT, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "NORT15",
    pstcd: "BDLUCLUC",
    strloc: "NORTHUMBERLAND, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "NORT16",
    pstcd: "BDMICPIN",
    strloc: "NORTH CLOSE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NORT17",
    pstcd: "BDMICMIC",
    strloc: "NORTH FRIENDSHIP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NORT18",
    pstcd: "BDMICMIC",
    strloc: "NORTH LOWLAND DRIVE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NORT19",
    pstcd: "BDMICMIC",
    strloc: "NORTH WILDEY TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NORW01",
    pstcd: "BDJAMJAM",
    strloc: "NORWOOD ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "NURS04",
    pstcd: "BDMICBAN",
    strloc: "NURSE TERRACE, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NURS05",
    pstcd: "BDMICMIC",
    strloc: "NURSE LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NURS06",
    pstcd: "BDMICMIC",
    strloc: "NURSE GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NURS07",
    pstcd: "BDMICMIC",
    strloc: "NURSERY CLOSE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NURS08",
    pstcd: "BDMICMIC",
    strloc: "NURSE LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "NUTM03",
    pstcd: "BDJAMSUN",
    strloc: "NUTMEG AVENUE, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "NUTM04",
    pstcd: "BDJAMSUN",
    strloc: "NUTMEG ROW, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "OAKW01",
    pstcd: "BDMICPIN",
    strloc: "OAKWAY, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "OBSE02",
    pstcd: "BDMICMIC",
    strloc: "OBSERVATORY ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "OBSE03",
    pstcd: "BDMICMIC",
    strloc: "OBSERVATORY LOTS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "OCEA04",
    pstcd: "BDPHIPHI",
    strloc: "OCEAN CITY DEVELOPMENT, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "OCEA05",
    pstcd: "BDMICMIC",
    strloc: "OCEAN ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "OCEA06",
    pstcd: "BDMICBUS",
    strloc: "OCEAN VIEW ROAD, BUSH HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ODEL01",
    pstcd: "BDMICMIC",
    strloc: "ODELS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ODEL02",
    pstcd: "BDMICBLA",
    strloc: "ODEL ROAD, WAVELL AVENUE, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "OIST01",
    pstcd: "BDOISOIS",
    strloc: "OISTINS, OISTINS - CHRIST CHURCH",
    parish: "OISTINS - CHRIST CHURCH",
  },
  {
    stind: "OIST02",
    pstcd: "BDOISOIS",
    strloc: "OISTINS HILL, OISTINS - CHRIST CHURCH",
    parish: "OISTINS - CHRIST CHURCH",
  },
  {
    stind: "OLDB01",
    pstcd: "BDPHIPHI",
    strloc: "OLDBURY PLANTATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "OLDB02",
    pstcd: "BDPHIPHI",
    strloc: "OLDBURY TENANTRY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "OLDB03",
    pstcd: "BDPHIPHI",
    strloc: "OLDBURY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "OLDB05",
    pstcd: "BDPHIPHI",
    strloc: "OLDBURY TERRACE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "OLDN01",
    pstcd: "BDCHRCHR",
    strloc: "OLD NAVY ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "OLDP03",
    pstcd: "BDCHRCHR",
    strloc: "OLD POST OFFICE GAP, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "OLDP04",
    pstcd: "BDTHOTHO",
    strloc: "OLD PLOW ROAD, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "OLDQ02",
    pstcd: "BDMICMIC",
    strloc: "OLD QUARRY AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "OLDT03",
    pstcd: "BDMICMIC",
    strloc: "OLD TRAIN LINE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "OLEA04",
    pstcd: "BDJAMWES",
    strloc: "OLEANDA AVENUE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "OLIV04",
    pstcd: "BDJAMHUS",
    strloc: "OLIVE DRIVE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "OLIV07",
    pstcd: "BDMICMIC",
    strloc: "OLIVIES ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "OLIV08",
    pstcd: "BDMICMIC",
    strloc: "OLIVIES GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "OLIV09",
    pstcd: "BDJAMJAM",
    strloc: "OLIVE LODGE ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "OLTO01",
    pstcd: "BDMICMIC",
    strloc: "OLTON ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "OLTO03",
    pstcd: "BDPETPET",
    strloc: "OLTON ALLEY, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ONEA01",
    pstcd: "BDMICMIC",
    strloc: "ONEAL LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ONTA01",
    pstcd: "BDCHRPLA",
    strloc: "ONTARIO CLOSE, PLATINIUM HGTS - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "OPAL03",
    pstcd: "BDCHRCHR",
    strloc: "OPAL ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ORAN06",
    pstcd: "BDJAMJAM",
    strloc: "ORANGE HILL, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "ORAN06",
    pstcd: "BDJOSJOS",
    strloc: "ORANGE HILL, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "ORAN07",
    pstcd: "BDJOSJOS",
    strloc: "ORANGE COTTAGE, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "ORAN08",
    pstcd: "BDJOSJOS",
    strloc: "ORANGE GROVE, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "ORAN09",
    pstcd: "BDPETPET",
    strloc: "ORANGE HILL TENANTRY, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ORAN10",
    pstcd: "BDPETPET",
    strloc: "ORANGE HILL PLANTATION, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ORAN11",
    pstcd: "BDPETSPE",
    strloc: "ORANGE STREET, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ORAN12",
    pstcd: "BDJAMJAM",
    strloc: "ORANGE HILL DEVELOPMENT, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "ORAN25",
    pstcd: "BDGEOGEO",
    strloc: "ORANGE ESTATES, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "ORCH01",
    pstcd: "BDJAMHUS",
    strloc: "ORCHID DRIVE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "ORCH02",
    pstcd: "BDJAMWES",
    strloc: "ORCHID AVENUE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "OUGH01",
    pstcd: "BDPHIPHI",
    strloc: "OUGHTERSON, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "OUGH03",
    pstcd: "BDPHIPHI",
    strloc: "OUGHTLANDS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "OUGH04",
    pstcd: "BDPHIPHI",
    strloc: "OUGHTERSON LAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "OUGH05",
    pstcd: "BDPHIPHI",
    strloc: "OUGHTERSON TENANTRY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "OVER02",
    pstcd: "BDMICMIC",
    strloc: "OVERDENE GARDENS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "OXFO02",
    pstcd: "BDPETPET",
    strloc: "OXFORD, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "OXFO03",
    pstcd: "BDPETPET",
    strloc: "OXFORD PLANTATION, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "OXFO04",
    pstcd: "BDPETPET",
    strloc: "OXFORD TENANTRY, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "OXLE01",
    pstcd: "BDMICMIC",
    strloc: "OXLEY ROAD, CLEVEDALE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "OXNA01",
    pstcd: "BDJAMOXN",
    strloc: "OXNARDS CRESCENT, OXNARDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "OXNA02",
    pstcd: "BDJAMOXN",
    strloc: "OXNARDS ROAD, OXNARDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "OXNA03",
    pstcd: "BDJAMOXN",
    strloc: "OXNARDS, OXNARDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "OXNA04",
    pstcd: "BDJAMHUS",
    strloc: "OXNARD HEIGHTS, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "OXNA04",
    pstcd: "BDJAMOXN",
    strloc: "OXNARD HEIGHTS, OXNARDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PACK01",
    pstcd: "BDCHRCHR",
    strloc: "PACKERS VALLEY, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PACK02",
    pstcd: "BDCHRCHR",
    strloc: "PACKERS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PADD01",
    pstcd: "BDMICMIC",
    strloc: "PADDOCK ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PADD02",
    pstcd: "BDMICMIC",
    strloc: "PADDOCK ROAD, 1ST AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PADD03",
    pstcd: "BDMICMIC",
    strloc: "PADDOCK ROAD, 2ND AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PADM02",
    pstcd: "BDPHIPHI",
    strloc: "PADMORE VILLAGE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "PADM02",
    pstcd: "BDTHOTHO",
    strloc: "PADMORE VILLAGE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "PADM03",
    pstcd: "BDPHIPHI",
    strloc: "PADMORE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "PALM02",
    pstcd: "BDJAMSUN",
    strloc: "PALM AVENUE, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PALM08",
    pstcd: "BDMICMIC",
    strloc: "PALM ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PALM16",
    pstcd: "BDMICMIC",
    strloc: "PALM TREE PLACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PALM18",
    pstcd: "BDBGTBGT",
    strloc: "PALMETTO STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PALM18",
    pstcd: "BDBGTBGT",
    strloc: "PALMETTO STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PALM18",
    pstcd: "BDBGTBGT",
    strloc: "PALMETTO STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PALM19",
    pstcd: "BDJOHJOH",
    strloc: "PALMERS, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "PALM20",
    pstcd: "BDPHIPHI",
    strloc: "PALM SPRING, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "PALM21",
    pstcd: "BDPHIPHI",
    strloc: "PALM COURT, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "PALM22",
    pstcd: "BDPHIPHI",
    strloc: "PALMERS PLANTATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "PALM23",
    pstcd: "BDCHRCHR",
    strloc: "PALM BEACH, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PARA09",
    pstcd: "BDMICBLA",
    strloc: "PARADISE DRIVE, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PARA12",
    pstcd: "BDGEOGEO",
    strloc: "PARADISE ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "PARA12",
    pstcd: "BDMICBLA",
    strloc: "PARADISE ROAD, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PARA12",
    pstcd: "BDMICBUS",
    strloc: "PARADISE ROAD, BUSH HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PARA13",
    pstcd: "BDGEOGEO",
    strloc: "PARADISE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "PARA14",
    pstcd: "BDMICBLA",
    strloc: "PARADISE BEACH DRIVE, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PARA15",
    pstcd: "BDCHRCHR",
    strloc: "PARADISE VILLAGE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PARA16",
    pstcd: "BDMICBUS",
    strloc: "PARADE ROAD, BUSH HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PARA17",
    pstcd: "BDMICMIC",
    strloc: "PARADISE GAP, SPOONERS HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PARA18",
    pstcd: "BDJAMJAM",
    strloc: "PARADISE HEIGHTS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PARA32",
    pstcd: "BDCHRCHR",
    strloc: "PARAGON, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PARF02",
    pstcd: "BDBGTBGT",
    strloc: "PARFITTS, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PARF02",
    pstcd: "BDBGTBGT",
    strloc: "PARFITTS, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PARI07",
    pstcd: "BDCHRCHR",
    strloc: "PARISH LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PARI07",
    pstcd: "BDGEOGEO",
    strloc: "PARISH LAND, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "PARI07",
    pstcd: "BDPHIPHI",
    strloc: "PARISH LAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "PARK03",
    pstcd: "BDCHRCHR",
    strloc: "PARK AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PARK06",
    pstcd: "BDCHRCHR",
    strloc: "PARK ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PARK06",
    pstcd: "BDMICBUS",
    strloc: "PARK ROAD, BUSH HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PARK06",
    pstcd: "BDMICMIC",
    strloc: "PARK ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PARK08",
    pstcd: "BDJOSJOS",
    strloc: "PARKS, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "PARK09",
    pstcd: "BDJOSJOS",
    strloc: "PARKS ROAD, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "PARK10",
    pstcd: "BDMICPIN",
    strloc: "PARKINSON FIELD HOUSING AREA, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PARK11",
    pstcd: "BDMICPIN",
    strloc: "PARKINSON FIELD, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PARK12",
    pstcd: "BDCHRCHR",
    strloc: "PARKSIDE DRIVE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PARK13",
    pstcd: "BDCHRCHR",
    strloc: "PARKWAY DRIVE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PARK14",
    pstcd: "BDMICMIC",
    strloc: "PARKINSON GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PARK15",
    pstcd: "BDMICMIC",
    strloc: "PARKINSON ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PARR01",
    pstcd: "BDBGTBGT",
    strloc: "PARRY STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PARR01",
    pstcd: "BDBGTBGT",
    strloc: "PARRY STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PARR01",
    pstcd: "BDBGTBGT",
    strloc: "PARRY STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PARR01",
    pstcd: "BDBGTBGT",
    strloc: "PARRY STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PARR07",
    pstcd: "BDGEOGEO",
    strloc: "PARRIS HILL, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "PARR07",
    pstcd: "BDJOSJOS",
    strloc: "PARRIS HILL, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "PARR08",
    pstcd: "BDMICBLA",
    strloc: "PARRIS LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PARR09",
    pstcd: "BDMICMIC",
    strloc: "PARRIS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PASS03",
    pstcd: "BDMICMIC",
    strloc: "PASSAGE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PASS04",
    pstcd: "BDMICMIC",
    strloc: "PASSAGE GARDENS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PAST01",
    pstcd: "BDMICBAN",
    strloc: "PASTURE ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PAST01",
    pstcd: "BDMICMIC",
    strloc: "PASTURE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PAST02",
    pstcd: "BDMICMIC",
    strloc: "PASTURE ROAD (UPPER), ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PATI04",
    pstcd: "BDMICMIC",
    strloc: "PATIENCE DRIVE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PATL01",
    pstcd: "BDMICMIC",
    strloc: "PAT LYNCH VILLAGE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PATR03",
    pstcd: "BDPETPET",
    strloc: "PATRICIA GARDENS, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "PAVI01",
    pstcd: "BDMICBAN",
    strloc: "PAVILLION ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PAVI02",
    pstcd: "BDMICMIC",
    strloc: "PAVILION TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PAVI03",
    pstcd: "BDMICMIC",
    strloc: "PAVILION FARM, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PAVI04",
    pstcd: "BDCHRCHR",
    strloc: "PAVILION COURT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PAX 01",
    pstcd: "BDMICMIC",
    strloc: "PAX HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PAYN03",
    pstcd: "BDMICMIC",
    strloc: "PAYNES ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PAYN04",
    pstcd: "BDJAMJAM",
    strloc: "PAYNES BAY, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PEAR01",
    pstcd: "BDCHRCHR",
    strloc: "PEARL AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PECO03",
    pstcd: "BDMICPIN",
    strloc: "PECOS PATH, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PEGW01",
    pstcd: "BDCHRCHR",
    strloc: "PEGWELL BOGGS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PEGW02",
    pstcd: "BDCHRCHR",
    strloc: "PEGWELL GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PEGW03",
    pstcd: "BDCHRCHR",
    strloc: "PEGWELL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PELI08",
    pstcd: "BDBGTBGT",
    strloc: "Pelican Village, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PEMB03",
    pstcd: "BDMICMIC",
    strloc: "PEMBROKE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PEMB04",
    pstcd: "BDMICMIC",
    strloc: "PEMBROOK, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PERF01",
    pstcd: "BDMICBAN",
    strloc: "PERFECTION ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PERF01",
    pstcd: "BDMICBUS",
    strloc: "PERFECTION ROAD, BUSH HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PERK02",
    pstcd: "BDMICMIC",
    strloc: "PERKIN'S ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PERK03",
    pstcd: "BDMICMIC",
    strloc: "PERKINS LAND 1ST AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PERK04",
    pstcd: "BDMICBAN",
    strloc: "PERKINS LAND, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PERK05",
    pstcd: "BDMICMIC",
    strloc: "PERKINS LAND 2ND AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PERO01",
    pstcd: "BDGEOGEO",
    strloc: "PEROGATIVE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "PERO02",
    pstcd: "BDCHRROC",
    strloc: "PERONNE GAP, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PERR08",
    pstcd: "BDBGTBGT",
    strloc: "PERRY'S GAP, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PERR08",
    pstcd: "BDBGTBGT",
    strloc: "PERRY'S GAP, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PERR09",
    pstcd: "BDPHIPHI",
    strloc: "PERRY TENANTRY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "PERS07",
    pstcd: "BDMICMIC",
    strloc: "PERSEVERANCE DRIVE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PETE15",
    pstcd: "BDMICBAN",
    strloc: "PETERKIN ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PETE15",
    pstcd: "BDMICMIC",
    strloc: "PETERKIN ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PETE16",
    pstcd: "BDMICMIC",
    strloc: "PETERKIN GARDENS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PETE17",
    pstcd: "BDMICMIC",
    strloc: "PETERKIN LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PETU05",
    pstcd: "BDJAMJAM",
    strloc: "PETUNIA ROW, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PHIL09",
    pstcd: "BDBGTBGT",
    strloc: "PHILADELPHIA LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PHIL09",
    pstcd: "BDBGTBGT",
    strloc: "PHILADELPHIA LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PHIL09",
    pstcd: "BDBGTBGT",
    strloc: "PHILADELPHIA LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PHIL10",
    pstcd: "BDJOSJOS",
    strloc: "PHILLIPS ROAD, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "PHIL10",
    pstcd: "BDMICMIC",
    strloc: "PHILLIPS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PHIL10",
    pstcd: "BDMICPIN",
    strloc: "PHILLIPS ROAD, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PHIL11",
    pstcd: "BDMICMIC",
    strloc: "PHILLIPS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PHIL12",
    pstcd: "BDMICMIC",
    strloc: "PHILLIPS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PHIL13",
    pstcd: "BDMICPIN",
    strloc: "PHILLIPS DRIVE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PHIN01",
    pstcd: "BDPHIPHI",
    strloc: "PHINNEYS HILL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "PHIN02",
    pstcd: "BDPHIPHI",
    strloc: "PHINNEYS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "PICK01",
    pstcd: "BDLUCLUC",
    strloc: "PICKERING, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "PICK02",
    pstcd: "BDLUCLUC",
    strloc: "PICKERINGS PLANTATION, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "PICK03",
    pstcd: "BDLUCLUC",
    strloc: "PICKERINGS TENANTRY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "PICK04",
    pstcd: "BDMICMIC",
    strloc: "PICKWICK GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PIEC01",
    pstcd: "BDLUCLUC",
    strloc: "PIE CORNER, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "PIED01",
    pstcd: "BDMICMIC",
    strloc: "PIEDMONT PARK, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PIER09",
    pstcd: "BDBGTBGT",
    strloc: "PIERHEAD LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PIER10",
    pstcd: "BDBGTBGT",
    strloc: "PIERHEAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PIGG01",
    pstcd: "BDJAMJAM",
    strloc: "PIGGOTTS ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PILE01",
    pstcd: "BDMICMIC",
    strloc: "PILE BAY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PILG02",
    pstcd: "BDCHRCHR",
    strloc: "PILGRIM PLACE A, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PILG03",
    pstcd: "BDCHRCHR",
    strloc: "PILGRIM LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PILG04",
    pstcd: "BDCHRCHR",
    strloc: "PILGRIM PLACE LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PILG05",
    pstcd: "BDCHRCHR",
    strloc: "PILGRIM ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PILG06",
    pstcd: "BDCHRCHR",
    strloc: "PILGRIM PLACE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PILL02",
    pstcd: "BDMICMIC",
    strloc: "PILLERSDORF ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PILL03",
    pstcd: "BDCHRCHR",
    strloc: "PILLERSDORF DEVELOPMENT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PILL03",
    pstcd: "BDMICMIC",
    strloc: "PILLERSDORF DEVELOPMENT, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PILL04",
    pstcd: "BDMICMIC",
    strloc: "PILLERSDORF, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PIND03",
    pstcd: "BDMICMIC",
    strloc: "PINDER AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PIND04",
    pstcd: "BDMICMIC",
    strloc: "PINDER GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PINE12",
    pstcd: "BDMICMIC",
    strloc: "PINE INDUSTRIAL PARK, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PINE13",
    pstcd: "BDMICBEL",
    strloc: "PINE ROAD, BELLEVILLE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PINE13",
    pstcd: "BDMICPIN",
    strloc: "PINE ROAD, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PINE14",
    pstcd: "BDMICPIN",
    strloc: "PINE GARDENS, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PINE15",
    pstcd: "BDMICPIN",
    strloc: "PINE HILL, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PINE16",
    pstcd: "BDMICPIN",
    strloc: "PINE PLANTATION ROAD, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PINE19",
    pstcd: "BDMICPIN",
    strloc: "PINE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PINE20",
    pstcd: "BDMICPIN",
    strloc: "PINELANDS, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PINF01",
    pstcd: "BDBGTBGT",
    strloc: "PINFOLD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PINF01",
    pstcd: "BDBGTBGT",
    strloc: "PINFOLD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PINF01",
    pstcd: "BDBGTBGT",
    strloc: "PINFOLD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PINF01",
    pstcd: "BDBGTBGT",
    strloc: "PINFOLD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PINF01",
    pstcd: "BDBGTBGT",
    strloc: "PINFOLD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PINF01",
    pstcd: "BDBGTBGT",
    strloc: "PINFOLD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PINF01",
    pstcd: "BDBGTBGT",
    strloc: "PINFOLD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PION03",
    pstcd: "BDMICBUS",
    strloc: "PIONEER ROAD, BUSH HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PION03",
    pstcd: "BDMICMIC",
    strloc: "PIONEER ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PIPE03",
    pstcd: "BDMICMIC",
    strloc: "PIPERS AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PIPE04",
    pstcd: "BDJAMJAM",
    strloc: "PIPERS ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PITT01",
    pstcd: "BDJAMJAM",
    strloc: "PITTONS ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PLAN02",
    pstcd: "BDMICMIC",
    strloc: "PLANTAIN WALK, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PLAN03",
    pstcd: "BDMICPIN",
    strloc: "PLANTATION CLOSE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PLEA05",
    pstcd: "BDCHRCHR",
    strloc: "PLEASANT HALL LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PLEA05",
    pstcd: "BDMICMIC",
    strloc: "PLEASANT HALL LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PLEA07",
    pstcd: "BDTHOTHO",
    strloc: "PLEASANT VALE PLANTATION, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "PLEA08",
    pstcd: "BDPETPET",
    strloc: "PLEASANT HALL PLANTATION, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "PLEA09",
    pstcd: "BDPETPET",
    strloc: "PLEASANT HALL TENANTRY, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "PLEA10",
    pstcd: "BDCHRCHR",
    strloc: "PLEASANT HALL HOUSING AREA, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PLEA11",
    pstcd: "BDCHRCHR",
    strloc: "PLEASANT HALL LANDING SCHEME, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PLEA12",
    pstcd: "BDMICCAV",
    strloc: "PLEASANT VIEW, CAVE HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PLEA13",
    pstcd: "BDPETPET",
    strloc: "PLEASANT HALL, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "PLUM08",
    pstcd: "BDTHOTHO",
    strloc: "PLUM TREE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "PLUM09",
    pstcd: "BDCHRCHR",
    strloc: "PLUMGROVE GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PLUM10",
    pstcd: "BDJAMJAM",
    strloc: "PLUMTREE HILL, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PLUM11",
    pstcd: "BDJAMJAM",
    strloc: "PLUMAGE AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PLUM12",
    pstcd: "BDMICMIC",
    strloc: "PLUM TREE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PLYM01",
    pstcd: "BDMICCAV",
    strloc: "PLYMOUTH SQUARE, CAVE HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "POIN15",
    pstcd: "BDMICCAV",
    strloc: "POINSETTIA WAY, CAVE HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "POIN16",
    pstcd: "BDJAMSUN",
    strloc: "POINSETTIA AVENUE, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "POIN17",
    pstcd: "BDJAMWES",
    strloc: "POINSETTIA DRIVE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "POLL03",
    pstcd: "BDPHIPHI",
    strloc: "POLLARD'S PLANTATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "POLY01",
    pstcd: "BDBGTBGT",
    strloc: "POLY GREEN ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "POLY01",
    pstcd: "BDBGTBGT",
    strloc: "POLY GREEN ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "POLY02",
    pstcd: "BDPETPET",
    strloc: "POLYMAR GARDENS, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "POND06",
    pstcd: "BDMICMIC",
    strloc: "PONDSIDE & WATERLOO ALLEY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "POND07",
    pstcd: "BDMICMIC",
    strloc: "POND BOTTOM, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "POOL04",
    pstcd: "BDJOHJOH",
    strloc: "POOL RISK, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "POOL05",
    pstcd: "BDJOHJOH",
    strloc: "POOL, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "POOL06",
    pstcd: "BDJOHJOH",
    strloc: "POOL TENANTRY, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "POOL07",
    pstcd: "BDJOHJOH",
    strloc: "POOL PLANTATION, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "POOL08",
    pstcd: "BDPHIPHI",
    strloc: "POOLERS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "POOL09",
    pstcd: "BDJOHJOH",
    strloc: "POOL LAND, ST JOHN, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "PORE01",
    pstcd: "BDTHOTHO",
    strloc: "POREY SPRING, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "PORT03",
    pstcd: "BDPETSPE",
    strloc: "PORTERS LANE, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "PORT05",
    pstcd: "BDMICCAV",
    strloc: "PORTSMOUTH LANE, CAVE HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PORT06",
    pstcd: "BDJAMJAM",
    strloc: "PORTERS TENANTRY, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PORT07",
    pstcd: "BDJAMJAM",
    strloc: "PORTERS ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PORT08",
    pstcd: "BDJAMJAM",
    strloc: "PORTERS HILL, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "POST03",
    pstcd: "BDJOSJOS",
    strloc: "POST OFFICE GAP, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "POTH01",
    pstcd: "BDJOHJOH",
    strloc: "POT HOUSE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "POUI02",
    pstcd: "BDJAMSUN",
    strloc: "POUI AVENUE, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "POUN01",
    pstcd: "BDPHIPHI",
    strloc: "POUNDERS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "POUN02",
    pstcd: "BDMICMIC",
    strloc: "POUNDERS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "POWD02",
    pstcd: "BDMICMIC",
    strloc: "POWDER ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "POWE02",
    pstcd: "BDJOSJOS",
    strloc: "POWELL SPRING, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "POWE03",
    pstcd: "BDMICMIC",
    strloc: "POWERS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PRES04",
    pstcd: "BDMICMIC",
    strloc: "PRESIDENT KENNEDY DRIVE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PRES05",
    pstcd: "BDMICBAN",
    strloc: "PRESCOD LAND, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PRES05",
    pstcd: "BDMICMIC",
    strloc: "PRESCOD LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PRES06",
    pstcd: "BDJAMJAM",
    strloc: "PRESCOD ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PRES07",
    pstcd: "BDMICMIC",
    strloc: "PRESIDENT KENNEDY ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PRIC01",
    pstcd: "BDGEOGEO",
    strloc: "PRICE LAND, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "PRIC02",
    pstcd: "BDCHRCHR",
    strloc: "PRICE GAP, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PRIC03",
    pstcd: "BDCHRCHR",
    strloc: "PRICE HILL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PRIN12",
    pstcd: "BDBGTBGT",
    strloc: "PRINCE WILLIAM HENRY STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PRIN12",
    pstcd: "BDBGTBGT",
    strloc: "PRINCE WILLIAM HENRY STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PRIN12",
    pstcd: "BDBGTBGT",
    strloc: "PRINCE WILLIAM HENRY STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PRIN12",
    pstcd: "BDBGTBGT",
    strloc: "PRINCE WILLIAM HENRY STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PRIN13",
    pstcd: "BDBGTBGT",
    strloc: "PRINCE ALFRED STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PRIN13",
    pstcd: "BDBGTBGT",
    strloc: "PRINCE ALFRED STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PRIN13",
    pstcd: "BDBGTBGT",
    strloc: "PRINCE ALFRED STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PRIN13",
    pstcd: "BDBGTBGT",
    strloc: "PRINCE ALFRED STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PRIN13",
    pstcd: "BDBGTBGT",
    strloc: "PRINCE ALFRED STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PRIN13",
    pstcd: "BDBGTBGT",
    strloc: "PRINCE ALFRED STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PRIN13",
    pstcd: "BDBGTBGT",
    strloc: "PRINCE ALFRED STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PRIN13",
    pstcd: "BDBGTBGT",
    strloc: "PRINCE ALFRED STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PRIN14",
    pstcd: "BDBGTBGT",
    strloc: "PRINCESS ALICE HIGHWAY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PRIN14",
    pstcd: "BDBGTBGT",
    strloc: "PRINCESS ALICE HIGHWAY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PRIN15",
    pstcd: "BDMICBAN",
    strloc: "PRINCE OF WALES ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PRIN16",
    pstcd: "BDMICPIN",
    strloc: "PRINCESS MARY ROAD, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PRIN17",
    pstcd: "BDMICPIN",
    strloc: "PRINCESS ROYAL AVENUE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PRIN18",
    pstcd: "BDMICPIN",
    strloc: "PRINCE GAP, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PRIO01",
    pstcd: "BDJAMPRI",
    strloc: "PRIOR PARK HEIGHTS, PRIOR PARK - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PRIO02",
    pstcd: "BDJAMPRI",
    strloc: "PRIOR PARK GARDENS, PRIOR PARK - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PRIO03",
    pstcd: "BDJAMPRI",
    strloc: "PRIOR PARK, PRIOR PARK - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PRIO04",
    pstcd: "BDJAMPRI",
    strloc: "PRIOR PARK GARDENS STAGE 2, PRIOR PARK - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PRIO05",
    pstcd: "BDJAMPRI",
    strloc: "PRIOR PARK TERRACE 2ND AVENUE, PRIOR PARK - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PRIO06",
    pstcd: "BDJAMPRI",
    strloc: "PRIOR PARK TERRACE 3RD AVENUE, PRIOR PARK - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PRIO07",
    pstcd: "BDJAMPRI",
    strloc: "PRIOR PARK TERRACE 4TH AVENUE, PRIOR PARK - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PRIO08",
    pstcd: "BDJAMPRI",
    strloc: "PRIOR PARK TERRACE 5TH AVENUE, PRIOR PARK - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PRIO09",
    pstcd: "BDJAMPRI",
    strloc: "PRIOR PARK TERRACE 6TH AVENUE, PRIOR PARK - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PRIO10",
    pstcd: "BDJAMPRI",
    strloc: "PRIOR PARK TERRACE 7TH AVENUE, PRIOR PARK - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PRIO11",
    pstcd: "BDJAMPRI",
    strloc: "PRIOR PARK TERRACE 8TH AVENUE, PRIOR PARK - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PRIO12",
    pstcd: "BDJAMJAM",
    strloc: "PRIOR VIEW HEIGHTS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PRIO13",
    pstcd: "BDJAMJAM",
    strloc: "PRIOR HILL, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PRIO14",
    pstcd: "BDJAMPRI",
    strloc: "PRIOR PARK CRESCENT, PRIOR PARK - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PRIT02",
    pstcd: "BDCHRCHR",
    strloc: "PRITCHARD HILL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PROB01",
    pstcd: "BDBGTBGT",
    strloc: "PROBYN STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PROB01",
    pstcd: "BDBGTBGT",
    strloc: "PROBYN STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PROB01",
    pstcd: "BDBGTBGT",
    strloc: "PROBYN STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "PROG02",
    pstcd: "BDMICBAN",
    strloc: "PROGRESSIVE ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PROM01",
    pstcd: "BDMICMIC",
    strloc: "PROMENADE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PROM02",
    pstcd: "BDMICMIC",
    strloc: "PROMENADE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PROS02",
    pstcd: "BDJAMJAM",
    strloc: "PROSPECT, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PROS02",
    pstcd: "BDPETPET",
    strloc: "PROSPECT, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "PROS03",
    pstcd: "BDPETPET",
    strloc: "PROSPECT PLANTATION, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "PROS04",
    pstcd: "BDPETPET",
    strloc: "PROSPECT TENANTRY, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "PROS05",
    pstcd: "BDCHRCHR",
    strloc: "PROSPECT DRIVE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PROS06",
    pstcd: "BDJAMJAM",
    strloc: "PROSPECT MAIN ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PROS07",
    pstcd: "BDJAMJAM",
    strloc: "PROSPECT SIDE ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "PROU01",
    pstcd: "BDGEOGEO",
    strloc: "PROUTE VILLAGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "PROU01",
    pstcd: "BDTHOTHO",
    strloc: "PROUTE VILLAGE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "PROU02",
    pstcd: "BDTHOTHO",
    strloc: "PROUTE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "PROU03",
    pstcd: "BDMICMIC",
    strloc: "PROUTE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PROU04",
    pstcd: "BDTHOTHO",
    strloc: "PROUTE HILL, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "PROV04",
    pstcd: "BDCHRCHR",
    strloc: "PROVIDENCE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "PRUN01",
    pstcd: "BDMICBLA",
    strloc: "PRUNETTE LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "PUCK01",
    pstcd: "BDJOHJOH",
    strloc: "PUCKERING LAND, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "QUAK01",
    pstcd: "BDPHIPHI",
    strloc: "QUAKERS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "QUAK02",
    pstcd: "BDMICMIC",
    strloc: "QUAKERS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "QUAR02",
    pstcd: "BDMICBAN",
    strloc: "QUARRY ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "QUAR02",
    pstcd: "BDTHOTHO",
    strloc: "QUARRY ROAD, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "QUEE01",
    pstcd: "BDMICMIC",
    strloc: "QUEEN STREET, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "QUEE01",
    pstcd: "BDPETSPE",
    strloc: "QUEEN STREET, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "QUEE07",
    pstcd: "BDBGTBGT",
    strloc: "QUEEN'S PARK, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "QUEE08",
    pstcd: "BDCHRCHR",
    strloc: "QUEENSWAY, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "QUEE09",
    pstcd: "BDCHRCHR",
    strloc: "QUEEN WAY, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "QUEE10",
    pstcd: "BDJAMJAM",
    strloc: "QUEEN FORT, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "QUEE11",
    pstcd: "BDMICBAN",
    strloc: "QUEEN VICTORIA ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "QUEE12",
    pstcd: "BDMICBAN",
    strloc: "QUEEN MARY ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "QUIC01",
    pstcd: "BDMICMIC",
    strloc: "QUICK LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "QUIN06",
    pstcd: "BDJOHJOH",
    strloc: "QUINTYNE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "RADC02",
    pstcd: "BDMICMIC",
    strloc: "RADCLIFFE GARDENS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "RAGG01",
    pstcd: "BDPHIPHI",
    strloc: "RAGGED POINT, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "RAIL03",
    pstcd: "BDMICMIC",
    strloc: "RAILWAY ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "RAIL11",
    pstcd: "BDMICMIC",
    strloc: "RAILWAY GARDENS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "RAMG06",
    pstcd: "BDPHIPHI",
    strloc: "RAM GOAT HILL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "RAMS18",
    pstcd: "BDMICMIC",
    strloc: "RAMSGATE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "RECT01",
    pstcd: "BDPETPET",
    strloc: "RECTORY HILL, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "REDL01",
    pstcd: "BDGEOGEO",
    strloc: "REDLAND PLANTATION, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "REDL02",
    pstcd: "BDGEOGEO",
    strloc: "REDLAND TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "REDM02",
    pstcd: "BDTHOTHO",
    strloc: "REDMANS VILLAGE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "REDM03",
    pstcd: "BDANDAND",
    strloc: "REDMANS ROAD, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "REDM04",
    pstcd: "BDMICBLA",
    strloc: "REDMANS GAP, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "REDM04",
    pstcd: "BDMICMIC",
    strloc: "REDMANS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "REDM05",
    pstcd: "BDMICMIC",
    strloc: "REDMANS GAP 2ND AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "REEC02",
    pstcd: "BDMICMIC",
    strloc: "REECE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "REEC02",
    pstcd: "BDTHOTHO",
    strloc: "REECE ROAD, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "REEC03",
    pstcd: "BDMICBUS",
    strloc: "REECE LAND, BUSH HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "REEC03",
    pstcd: "BDMICMIC",
    strloc: "REECE LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "REED01",
    pstcd: "BDBGTBGT",
    strloc: "REED STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "REED01",
    pstcd: "BDBGTBGT",
    strloc: "REED STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "REEF01",
    pstcd: "BDBGTBGT",
    strloc: "REEF ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "REEV01",
    pstcd: "BDTHOTHO",
    strloc: "REEVES HILL, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "REGA02",
    pstcd: "BDMICMIC",
    strloc: "REGAN LODGE, GREEN HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "REGE07",
    pstcd: "BDCHRCHR",
    strloc: "REGENCY PARK, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "REGE08",
    pstcd: "BDMICPIN",
    strloc: "REGENT HILL, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "REGE09",
    pstcd: "BDJAMJAM",
    strloc: "REGENCY DRIVE-WARRENS PARK, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "REGE09",
    pstcd: "BDMICWAR",
    strloc: "REGENCY DRIVE-WARRENS PARK, WARRENS - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "REGI01",
    pstcd: "BDCHRCHR",
    strloc: "REGINA COURT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "REID02",
    pstcd: "BDJAMJAM",
    strloc: "REID ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "REID06",
    pstcd: "BDJAMJAM",
    strloc: "REID LAND, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "REID06",
    pstcd: "BDMICMIC",
    strloc: "REID LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "REID07",
    pstcd: "BDJAMJAM",
    strloc: "REID GAP, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "REIF01",
    pstcd: "BDPHIPHI",
    strloc: "REIFER LAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "RELI01",
    pstcd: "BDJOSJOS",
    strloc: "RELIANCE ROAD, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "REND01",
    pstcd: "BDCHRCHR",
    strloc: "RENDEZVOUS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "REND01",
    pstcd: "BDMICMIC",
    strloc: "RENDEZVOUS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "REND02",
    pstcd: "BDCHRCHR",
    strloc: "RENDEZVOUS WEST, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "REND03",
    pstcd: "BDCHRCHR",
    strloc: "RENDEZVOUS GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "REND04",
    pstcd: "BDCHRCHR",
    strloc: "RENDEZVOUS HILL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "REND05",
    pstcd: "BDCHRCHR",
    strloc: "RENDEZVOUS TERRACE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "REND06",
    pstcd: "BDCHRCHR",
    strloc: "RENDEZVOUS RIDGE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "REND07",
    pstcd: "BDCHRCHR",
    strloc: "RENDEZVOUS ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "RESE01",
    pstcd: "BDJAMJAM",
    strloc: "RESERVOIR ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "RESE01",
    pstcd: "BDMICMIC",
    strloc: "RESERVOIR ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "RESE01",
    pstcd: "BDPETPET",
    strloc: "RESERVOIR ROAD, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "RETR01",
    pstcd: "BDGEOGEO",
    strloc: "RETREAT, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "RETR01",
    pstcd: "BDJOSJOS",
    strloc: "RETREAT, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "RETR01",
    pstcd: "BDLUCLUC",
    strloc: "RETREAT, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "RETR01",
    pstcd: "BDMICBLA",
    strloc: "RETREAT, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "RETR01",
    pstcd: "BDPETPET",
    strloc: "RETREAT, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "RETR02",
    pstcd: "BDGEOGEO",
    strloc: "RETREAT HILL, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "RETR03",
    pstcd: "BDGEOGEO",
    strloc: "RETREAT TERRACE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "RETR03",
    pstcd: "BDMICBLA",
    strloc: "RETREAT TERRACE, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "RETR04",
    pstcd: "BDGEOGEO",
    strloc: "RETREAT VILLAGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "RETR04",
    pstcd: "BDPETPET",
    strloc: "RETREAT VILLAGE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "RETR05",
    pstcd: "BDGEOGEO",
    strloc: "RETREAT WOODS, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "RETR06",
    pstcd: "BDMICBLA",
    strloc: "RETREAT ROAD, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "RETR07",
    pstcd: "BDMICBLA",
    strloc: "RETREAT LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "REVI01",
    pstcd: "BDMICBUS",
    strloc: "REVIEW ROAD, BUSH HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "RHYS01",
    pstcd: "BDCHRROC",
    strloc: "RHYSTONE GARDENS, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "RICE02",
    pstcd: "BDPHIPHI",
    strloc: "RICES, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "RICH12",
    pstcd: "BDJOSJOS",
    strloc: "RICHMOND, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "RICH13",
    pstcd: "BDPETPET",
    strloc: "RICHMOND HILL PLANTATION, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "RICH14",
    pstcd: "BDMICMIC",
    strloc: "RICHMOND GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "RICK03",
    pstcd: "BDBGTBGT",
    strloc: "RICKETT STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "RICK04",
    pstcd: "BDJAMJAM",
    strloc: "RICKS LAND, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "RICK05",
    pstcd: "BDJAMJAM",
    strloc: "RICKS ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "RIDG02",
    pstcd: "BDJOSJOS",
    strloc: "RIDGE ROAD, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "RIDG05",
    pstcd: "BDJAMJAM",
    strloc: "RIDGEWAY, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "RIDG05",
    pstcd: "BDMICMIC",
    strloc: "RIDGEWAY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "RIDG05",
    pstcd: "BDTHOTHO",
    strloc: "RIDGEWAY, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "RIDG06",
    pstcd: "BDCHRCHR",
    strloc: "RIDGE PLANTATION, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "RIDG07",
    pstcd: "BDCHRCHR",
    strloc: "RIDGE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "RIDG08",
    pstcd: "BDMICMIC",
    strloc: "RIDGE ROAD, EDEN LODGE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "RIDG10",
    pstcd: "BDCHRCHR",
    strloc: "RIDGE VIEW, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "RISK01",
    pstcd: "BDLUCLUC",
    strloc: "RISK VILLAGE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "RISK01",
    pstcd: "BDPETPET",
    strloc: "RISK VILLAGE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "RISK02",
    pstcd: "BDJAMJAM",
    strloc: "RISK ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "RISK04",
    pstcd: "BDJAMJAM",
    strloc: "RISK ROAD 1ST AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "RISK05",
    pstcd: "BDJAMJAM",
    strloc: "RISK ROAD 2ND AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "RISK06",
    pstcd: "BDJAMJAM",
    strloc: "RISK ROAD 3RD AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "RISK07",
    pstcd: "BDJAMJAM",
    strloc: "RISK ROAD 4TH AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "RIVE03",
    pstcd: "BDBGTBGT",
    strloc: "RIVER ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "RIVE03",
    pstcd: "BDBGTBGT",
    strloc: "RIVER ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "RIVE03",
    pstcd: "BDMICMIC",
    strloc: "RIVER ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "RIVE18",
    pstcd: "BDANDAND",
    strloc: "RIVER SIDE, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "RIVE19",
    pstcd: "BDANDAND",
    strloc: "RIVER PLANTATION, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "RIVE20",
    pstcd: "BDLUCLUC",
    strloc: "RIVER BAY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "RIVE21",
    pstcd: "BDPHIPHI",
    strloc: "RIVER TENANTRY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "RIVE27",
    pstcd: "BDPHIPHI",
    strloc: "RIVER LAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "ROAC01",
    pstcd: "BDGEOGEO",
    strloc: "ROACH VILLAGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "ROAC02",
    pstcd: "BDLUCLUC",
    strloc: "ROACHES, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "ROAC03",
    pstcd: "BDMICMIC",
    strloc: "ROACH LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROAD03",
    pstcd: "BDPETPET",
    strloc: "ROAD VIEW, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ROBE04",
    pstcd: "BDMICMIC",
    strloc: "ROBERTS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROBE12",
    pstcd: "BDMICMIC",
    strloc: "ROBERTS TENANTRY ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROBI09",
    pstcd: "BDPHIPHI",
    strloc: "ROBINSON LAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "ROBI10",
    pstcd: "BDPHIPHI",
    strloc: "ROBINSONS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "ROCH03",
    pstcd: "BDMICMIC",
    strloc: "ROCHEDALE CLOSE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROCK04",
    pstcd: "BDANDAND",
    strloc: "ROCK HALL, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "ROCK04",
    pstcd: "BDGEOGEO",
    strloc: "ROCK HALL, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "ROCK04",
    pstcd: "BDLUCLUC",
    strloc: "ROCK HALL, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "ROCK04",
    pstcd: "BDPETPET",
    strloc: "ROCK HALL, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ROCK04",
    pstcd: "BDPHIPHI",
    strloc: "ROCK HALL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "ROCK04",
    pstcd: "BDTHOTHO",
    strloc: "ROCK HALL, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "ROCK05",
    pstcd: "BDLUCLUC",
    strloc: "ROCK FIELD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "ROCK06",
    pstcd: "BDPETPET",
    strloc: "ROCK DUNDO VILLAGE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ROCK07",
    pstcd: "BDPETPET",
    strloc: "ROCK PLANTATION, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ROCK08",
    pstcd: "BDPETPET",
    strloc: "ROCK DEVELOPMENT, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ROCK09",
    pstcd: "BDPETPET",
    strloc: "ROCK HALL PLANTATION, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ROCK10",
    pstcd: "BDPETPET",
    strloc: "ROCK HALL TENANTRY, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ROCK11",
    pstcd: "BDMICBLA",
    strloc: "ROCK LAND 1ST AVENUE, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROCK12",
    pstcd: "BDMICMIC",
    strloc: "ROCK GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROCK13",
    pstcd: "BDCHRROC",
    strloc: "ROCKLEY, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ROCK13",
    pstcd: "BDPHIPHI",
    strloc: "ROCKLEY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "ROCK14",
    pstcd: "BDCHRROC",
    strloc: "ROCKLEY MEADOWS, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ROCK15",
    pstcd: "BDCHRROC",
    strloc: "ROCKLEY GOLF CLUB & DEV, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ROCK16",
    pstcd: "BDCHRROC",
    strloc: "ROCKLEY GOLF CLUB GARDENS, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ROCK17",
    pstcd: "BDCHRROC",
    strloc: "ROCKLEY TERRACE, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ROCK18",
    pstcd: "BDCHRROC",
    strloc: "ROCKLEY NEW ROAD, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ROCK18",
    pstcd: "BDMICMIC",
    strloc: "ROCKLEY NEW ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROCK19",
    pstcd: "BDCHRROC",
    strloc: "ROCKLEY ROAD, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ROCK20",
    pstcd: "BDCHRROC",
    strloc: "ROCKLEY VILLAGE ROAD, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ROCK21",
    pstcd: "BDMICMIC",
    strloc: "ROCKHAMPTON ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROCK22",
    pstcd: "BDMICMIC",
    strloc: "ROCKHAMPTON GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROCK23",
    pstcd: "BDMICMIC",
    strloc: "ROCK LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROCK24",
    pstcd: "BDMICMIC",
    strloc: "ROCK DUNDO HEIGHTS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROCK25",
    pstcd: "BDMICMIC",
    strloc: "ROCK LAND 2ND AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROCK26",
    pstcd: "BDMICMIC",
    strloc: "ROCK DUNDO PARK, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROCK27",
    pstcd: "BDJAMJAM",
    strloc: "ROCK DUNDO, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "ROCK28",
    pstcd: "BDJAMJAM",
    strloc: "ROCK DUNDO NO1 AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "ROCK29",
    pstcd: "BDJAMJAM",
    strloc: "ROCK DUNDO (LOWER), ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "ROCK30",
    pstcd: "BDJAMJAM",
    strloc: "ROCK DUNDO (UPPER), ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "ROCK31",
    pstcd: "BDMICMIC",
    strloc: "ROCK CLOSE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROCK32",
    pstcd: "BDMICMIC",
    strloc: "ROCK AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROCK40",
    pstcd: "BDLUCLUC",
    strloc: "ROCKFIELD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "RODN04",
    pstcd: "BDCHRCHR",
    strloc: "RODNEY ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ROEB01",
    pstcd: "BDBGTBGT",
    strloc: "ROEBUCK STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "ROEB01",
    pstcd: "BDBGTBGT",
    strloc: "ROEBUCK STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "ROEB01",
    pstcd: "BDBGTBGT",
    strloc: "ROEBUCK STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "ROEB01",
    pstcd: "BDBGTBGT",
    strloc: "ROEBUCK STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "ROEB01",
    pstcd: "BDBGTBGT",
    strloc: "ROEBUCK STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "ROEB01",
    pstcd: "BDBGTBGT",
    strloc: "ROEBUCK STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "ROEB01",
    pstcd: "BDBGTBGT",
    strloc: "ROEBUCK STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "ROEB01",
    pstcd: "BDBGTBGT",
    strloc: "ROEBUCK STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "ROEB01",
    pstcd: "BDBGTBGT",
    strloc: "ROEBUCK STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "ROEB01",
    pstcd: "BDBGTBGT",
    strloc: "ROEBUCK STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "ROEB01",
    pstcd: "BDBGTBGT",
    strloc: "ROEBUCK STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "ROEB01",
    pstcd: "BDBGTBGT",
    strloc: "ROEBUCK STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "ROEB01",
    pstcd: "BDBGTBGT",
    strloc: "ROEBUCK STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "ROEB01",
    pstcd: "BDBGTBGT",
    strloc: "ROEBUCK STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "ROEB01",
    pstcd: "BDBGTBGT",
    strloc: "ROEBUCK STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "ROEB01",
    pstcd: "BDBGTBGT",
    strloc: "ROEBUCK STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "ROEB01",
    pstcd: "BDBGTBGT",
    strloc: "ROEBUCK STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "ROEB01",
    pstcd: "BDBGTBGT",
    strloc: "ROEBUCK STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "ROEB01",
    pstcd: "BDJOHJOH",
    strloc: "ROEBUCK STREET, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "ROEB02",
    pstcd: "BDPETPET",
    strloc: "ROEBUCK VILLAGE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ROEB03",
    pstcd: "BDPETPET",
    strloc: "ROEBUCK, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ROGE03",
    pstcd: "BDMICMIC",
    strloc: "ROGERS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROGE04",
    pstcd: "BDMICMIC",
    strloc: "ROGERS DEVELOPMENT, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROLL01",
    pstcd: "BDPHIPHI",
    strloc: "ROLLINS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "ROLL02",
    pstcd: "BDCHRCHR",
    strloc: "ROLLINS ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ROLL02",
    pstcd: "BDMICMIC",
    strloc: "ROLLINS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROLL03",
    pstcd: "BDMICMIC",
    strloc: "ROLLOCK GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROLL04",
    pstcd: "BDMICMIC",
    strloc: "ROLLOCK LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROLL08",
    pstcd: "BDGEOGEO",
    strloc: "ROLLING HILLS, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "ROSA08",
    pstcd: "BDGEOGEO",
    strloc: "ROSARY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "ROSE02",
    pstcd: "BDGEOGEO",
    strloc: "ROSE HILL, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "ROSE02",
    pstcd: "BDPETPET",
    strloc: "ROSE HILL, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ROSE05",
    pstcd: "BDJAMJAM",
    strloc: "ROSE DRIVE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "ROSE05",
    pstcd: "BDMICMIC",
    strloc: "ROSE DRIVE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROSE17",
    pstcd: "BDJOHJOH",
    strloc: "ROSEGATE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "ROSE18",
    pstcd: "BDJOHJOH",
    strloc: "ROSEMONT, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "ROSE18",
    pstcd: "BDMICMIC",
    strloc: "ROSEMONT, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROSE19",
    pstcd: "BDGEOGEO",
    strloc: "ROSE HILL TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "ROSE20",
    pstcd: "BDANDAND",
    strloc: "ROSELINE, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "ROSE21",
    pstcd: "BDPETPET",
    strloc: "ROSE HILL VILLAGE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "ROSE22",
    pstcd: "BDCHRROC",
    strloc: "ROSE GARDEN, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ROUE01",
    pstcd: "BDMICMIC",
    strloc: "ROUEN, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROUN01",
    pstcd: "BDJOSJOS",
    strloc: "ROUND HILL, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "ROUN02",
    pstcd: "BDJOSJOS",
    strloc: "ROUND ROAD, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "ROUN03",
    pstcd: "BDLUCLUC",
    strloc: "ROUND THE ROCK, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "ROUN04",
    pstcd: "BDPHIPHI",
    strloc: "ROUND HOUSE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "ROUN05",
    pstcd: "BDCHRCHR",
    strloc: "ROUND ROCK, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ROWA02",
    pstcd: "BDGEOROW",
    strloc: "ROWANS PARK, ROWANS PARK - ST GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "ROWE01",
    pstcd: "BDMICMIC",
    strloc: "ROWEN ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROXY01",
    pstcd: "BDMICBAN",
    strloc: "ROXY AVENUE, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "ROXY02",
    pstcd: "BDMICBAN",
    strloc: "ROXY GAP, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "RUBY05",
    pstcd: "BDTHOTHO",
    strloc: "RUBY, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "RUBY06",
    pstcd: "BDPHIRUB",
    strloc: "RUBY PARK, RUBY PARK - ST PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "RUBY07",
    pstcd: "BDPHIRUB",
    strloc: "RUBY TENANTRY, RUBY PARK - ST PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "RUBY08",
    pstcd: "BDPHIRUB",
    strloc: "RUBY PLANTATION, RUBY PARK - ST PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "RUBY09",
    pstcd: "BDPHIRUB",
    strloc: "RUBY LANDS, RUBY PARK - ST PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "RUGB01",
    pstcd: "BDTHOTHO",
    strloc: "RUGBY, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "RURA01",
    pstcd: "BDGEOGEO",
    strloc: "RURAL COTTAGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "RUSS03",
    pstcd: "BDTHOTHO",
    strloc: "RUSSIA VILLAGE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "RUTH03",
    pstcd: "BDGEOGEO",
    strloc: "RUTH KING ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "RUTH04",
    pstcd: "BDMICPIN",
    strloc: "RUTHLAND CLOSE LANE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SALI03",
    pstcd: "BDGEOGEO",
    strloc: "SALISBURY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "SALM02",
    pstcd: "BDLUCLUC",
    strloc: "SALMONDS, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "SALT02",
    pstcd: "BDJOSJOS",
    strloc: "SALTRAM, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "SALT03",
    pstcd: "BDGEOGEO",
    strloc: "SALTERS, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "SALT04",
    pstcd: "BDJAMSUN",
    strloc: "SALTERS ROAD, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SALT04",
    pstcd: "BDMICMIC",
    strloc: "SALTERS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SALT06",
    pstcd: "BDMICMIC",
    strloc: "SALTERS VALLEY ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SALT07",
    pstcd: "BDMICMIC",
    strloc: "SALTERS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SAML03",
    pstcd: "BDPHIPHI",
    strloc: "SAM LORDS CASTLE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SAMO01",
    pstcd: "BDMICMIC",
    strloc: "SAMON ROW, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SAND10",
    pstcd: "BDMICMIC",
    strloc: "SAND BOX AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SAND11",
    pstcd: "BDPETSPE",
    strloc: "SAND STREET, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "SAND22",
    pstcd: "BDPHIPHI",
    strloc: "SANDY HILL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SAND23",
    pstcd: "BDPHIPHI",
    strloc: "SANDY CREST, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SAND24",
    pstcd: "BDCHRCHR",
    strloc: "SANDY GROUND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SAND25",
    pstcd: "BDJAMJAM",
    strloc: "SANDY LANE DEV. NORTH, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SAND26",
    pstcd: "BDJAMJAM",
    strloc: "SAND PIPER, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SAND27",
    pstcd: "BDJAMJAM",
    strloc: "SANDY LANE ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SAND28",
    pstcd: "BDJAMJAM",
    strloc: "SANDY LANE DEV. AREA SOUTH, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SAND29",
    pstcd: "BDJAMJAM",
    strloc: "SANDY LANE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SANF10",
    pstcd: "BDPHIPHI",
    strloc: "SANFORD DEVELOPMENT, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SANF11",
    pstcd: "BDPHIPHI",
    strloc: "SANFORD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SANT18",
    pstcd: "BDMICMIC",
    strloc: "SANTO DOMINGO ALLEY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SAPP04",
    pstcd: "BDCHRCHR",
    strloc: "SAPPHIRE AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SARG02",
    pstcd: "BDJOHJOH",
    strloc: "SARGEANT STREET, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "SARG04",
    pstcd: "BDGEOGEO",
    strloc: "SARGEANT ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "SARG05",
    pstcd: "BDPHIPHI",
    strloc: "SARGEANTS PARK, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SARG06",
    pstcd: "BDCHRCHR",
    strloc: "SARGEANTS VILLAGE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SARG07",
    pstcd: "BDMICMIC",
    strloc: "SARGEANTS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SAVA06",
    pstcd: "BDANDAND",
    strloc: "SAVANNAH ROAD, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "SAVA06",
    pstcd: "BDMICBUS",
    strloc: "SAVANNAH ROAD, BUSH HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SAVA06",
    pstcd: "BDMICMIC",
    strloc: "SAVANNAH ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SAYE01",
    pstcd: "BDCHRCHR",
    strloc: "SAYERS COURT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SAYE02",
    pstcd: "BDCHRCHR",
    strloc: "SAYERS COURT HOUSING AREA, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SCAR01",
    pstcd: "BDCHRCHR",
    strloc: "SCARBOROUGH, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SCHO04",
    pstcd: "BDMICMIC",
    strloc: "SCHOOL ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SCHO09",
    pstcd: "BDMICMIC",
    strloc: "SCHOOL GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SCOT11",
    pstcd: "BDMICMIC",
    strloc: "SCOTTS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SCOT12",
    pstcd: "BDANDAND",
    strloc: "SCOTLAND FACTORY, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "SCOT13",
    pstcd: "BDMICMIC",
    strloc: "SCOTTS TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SEAL05",
    pstcd: "BDMICMIC",
    strloc: "SEALES AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SEAL06",
    pstcd: "BDJOHJOH",
    strloc: "SEALY HALL, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "SEAL06",
    pstcd: "BDPHIPHI",
    strloc: "SEALY HALL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SEAL07",
    pstcd: "BDMICBAN",
    strloc: "SEALY LAND, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SEAL07",
    pstcd: "BDPHIPHI",
    strloc: "SEALY LAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SEAL08",
    pstcd: "BDPHIPHI",
    strloc: "SEALY HILL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SEAL09",
    pstcd: "BDCHRCHR",
    strloc: "SEALES LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SEAL09",
    pstcd: "BDMICMIC",
    strloc: "SEALES LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SEAL10",
    pstcd: "BDMICBLA",
    strloc: "SEALS LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SEAL11",
    pstcd: "BDMICMIC",
    strloc: "SEALES GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SEAM02",
    pstcd: "BDMICMIC",
    strloc: "SEAMENS VILLAGE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SEAR01",
    pstcd: "BDCHRCHR",
    strloc: "SEARLES TENANTRY, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SEAR02",
    pstcd: "BDCHRCHR",
    strloc: "SEARLES, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SEAR03",
    pstcd: "BDCHRCHR",
    strloc: "SEA ROCKS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SEAR04",
    pstcd: "BDCHRCHR",
    strloc: "SEA ROCKS ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SEAS01",
    pstcd: "BDCHRCHR",
    strloc: "SEASCAPE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SEAS02",
    pstcd: "BDCHRCHR",
    strloc: "SEASPRAY ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SEAT03",
    pstcd: "BDMICMIC",
    strloc: "SEATON, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SEAV04",
    pstcd: "BDLUCLUC",
    strloc: "SEA VIEW, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "SEAV04",
    pstcd: "BDMICBLA",
    strloc: "SEA VIEW, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SEAV07",
    pstcd: "BDPHIPHI",
    strloc: "SEA VIEW PARK, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SEAV08",
    pstcd: "BDPHIPHI",
    strloc: "SEA VIEW ROAD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SEAV09",
    pstcd: "BDCHRCHR",
    strloc: "SEAVIEW, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SEAV09",
    pstcd: "BDJAMJAM",
    strloc: "SEAVIEW, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SEAV09",
    pstcd: "BDPHIPHI",
    strloc: "SEAVIEW, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SEAV09",
    pstcd: "BDTHOTHO",
    strloc: "SEAVIEW, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "SEAV10",
    pstcd: "BDCHRCHR",
    strloc: "SEAVIEW ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SEAV11",
    pstcd: "BDMICMIC",
    strloc: "SEAVIEW LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SEAW03",
    pstcd: "BDCHRCHR",
    strloc: "SEAWELL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SECL01",
    pstcd: "BDMICBLA",
    strloc: "SECLUSION, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SECL02",
    pstcd: "BDMICMIC",
    strloc: "SECLUSION GARDENS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SECL03",
    pstcd: "BDMICMIC",
    strloc: "SECLUSION ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SEDG01",
    pstcd: "BDANDAND",
    strloc: "SEDGE POND, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "SELA01",
    pstcd: "BDLUCLUC",
    strloc: "SELAH, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "SENI02",
    pstcd: "BDANDAND",
    strloc: "SENIORS PLANTATION, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "SEPT01",
    pstcd: "BDCHRCHR",
    strloc: "SEPTEMBER SQUARE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SHAR02",
    pstcd: "BDTHOTHO",
    strloc: "SHARON, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "SHAR03",
    pstcd: "BDTHOTHO",
    strloc: "SHARON HILL, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "SHEL03",
    pstcd: "BDCHRCHR",
    strloc: "SHELBOURNE GAP, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SHEP01",
    pstcd: "BDBGTBGT",
    strloc: "SHEPHERD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SHEP01",
    pstcd: "BDBGTBGT",
    strloc: "SHEPHERD STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SHEP02",
    pstcd: "BDMICPIN",
    strloc: "SHEPTON LANE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SHER03",
    pstcd: "BDJOHJOH",
    strloc: "SHERBOURNE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "SHER04",
    pstcd: "BDLUCLUC",
    strloc: "SHERMANS, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "SHER05",
    pstcd: "BDPETPET",
    strloc: "SHERMAN VILLAGE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "SHER06",
    pstcd: "BDMICPIN",
    strloc: "SHERWOOD LANE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SHER07",
    pstcd: "BDCHRCHR",
    strloc: "SHERINGHAM GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SHER08",
    pstcd: "BDCHRCHR",
    strloc: "SHERATON PARK, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SHOP01",
    pstcd: "BDTHOTHO",
    strloc: "SHOP HILL, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "SHOP02",
    pstcd: "BDTHOTHO",
    strloc: "SHOP HILL NEW ROAD, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "SHOP03",
    pstcd: "BDMICMIC",
    strloc: "SHOP HILL GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SHOR02",
    pstcd: "BDANDAND",
    strloc: "SHOREY TENANTRY ROAD, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "SHOR03",
    pstcd: "BDANDAND",
    strloc: "SHOREY VILLAGE, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "SHOT01",
    pstcd: "BDMICMIC",
    strloc: "SHOT HALL GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SHRE01",
    pstcd: "BDPHIPHI",
    strloc: "SHREWSBURY GARDENS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SHUR01",
    pstcd: "BDBGTBGT",
    strloc: "SHURLANDS ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SILV05",
    pstcd: "BDCHRCHR",
    strloc: "SILVER SANDS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SILV06",
    pstcd: "BDCHRCHR",
    strloc: "SILVER HILL LANDING SCHEME, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SILV07",
    pstcd: "BDCHRCHR",
    strloc: "SILVER HILL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SILV08",
    pstcd: "BDCHRCHR",
    strloc: "SILVER HILL DRIVE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SILV09",
    pstcd: "BDCHRCHR",
    strloc: "SILVER HILL HOUSING AREA, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SIMM01",
    pstcd: "BDGEOGEO",
    strloc: "SIMMONS TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "SIMM02",
    pstcd: "BDCHRCHR",
    strloc: "SIMMONS LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SIMM02",
    pstcd: "BDMICMIC",
    strloc: "SIMMONS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SIMM03",
    pstcd: "BDCHRCHR",
    strloc: "SIMMONS DEVELOPMENT CH.CH, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SIMP03",
    pstcd: "BDMICMIC",
    strloc: "SIMPSON LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SINC02",
    pstcd: "BDMICMIC",
    strloc: "SINCKLER ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SION01",
    pstcd: "BDCHRCHR",
    strloc: "SION HILL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SION01",
    pstcd: "BDJAMJAM",
    strloc: "SION HILL, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SION02",
    pstcd: "BDJAMJAM",
    strloc: "SION HILL PLANTATION, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SION03",
    pstcd: "BDJAMJAM",
    strloc: "SION HILL TENANTRY ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SION04",
    pstcd: "BDJAMJAM",
    strloc: "SION HILL GAP, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SION05",
    pstcd: "BDJAMJAM",
    strloc: "SION HILL TERRACE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SISN01",
    pstcd: "BDMICMIC",
    strloc: "SISNETT ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SIXC01",
    pstcd: "BDPHIPHI",
    strloc: "SIX CROSS ROADS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SIXC02",
    pstcd: "BDPHISIX",
    strloc: "SIX ROADS INDUSTRIAL PARK, SIX ROADS INDUSTRIAL PARK",
    parish: "ST. PHILIP",
  },
  {
    stind: "SIXM01",
    pstcd: "BDPETPET",
    strloc: "SIX MENS VILLAGE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "SIXM02",
    pstcd: "BDPETPET",
    strloc: "SIX MENS ROAD, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "SIXM03",
    pstcd: "BDPETPET",
    strloc: "SIXMENS DEVELOPMENT, ST PETER, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "SKEE02",
    pstcd: "BDMICBAN",
    strloc: "SKEETE ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SKEE02",
    pstcd: "BDMICMIC",
    strloc: "SKEETE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SKEE02",
    pstcd: "BDPETPET",
    strloc: "SKEETE ROAD, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "SKEE03",
    pstcd: "BDPHIPHI",
    strloc: "SKEETES BAY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SKEE04",
    pstcd: "BDMICMIC",
    strloc: "SKEETES LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SKEE05",
    pstcd: "BDCHRROC",
    strloc: "SKEETES HILL, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SKEE06",
    pstcd: "BDMICBAN",
    strloc: "SKEETES ROAD 1ST AVENUE, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SKEE07",
    pstcd: "BDMICBAN",
    strloc: "SKEETES ROAD 2ND AVENUE, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SKEE08",
    pstcd: "BDMICBAN",
    strloc: "SKEETES ROAD 3RD AVENUE, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SKEE09",
    pstcd: "BDMICBAN",
    strloc: "SKEETES ROAD 4TH AVENUE, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SKEE10",
    pstcd: "BDMICBAN",
    strloc: "SKEETES ROAD 5TH AVENUE, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SKEE11",
    pstcd: "BDMICBAN",
    strloc: "SKEETES ROAD 6TH AVENUE, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SKEE12",
    pstcd: "BDMICBAN",
    strloc: "SKEETES ROAD 7TH AVENUE, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SKEE13",
    pstcd: "BDMICBAN",
    strloc: "SKEETES ROAD 8TH AVENUE, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SKEE14",
    pstcd: "BDMICBAN",
    strloc: "SKEETES ROAD 9TH AVENUE, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SMAL03",
    pstcd: "BDJOHJOH",
    strloc: "SMALL HOPE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "SMAL04",
    pstcd: "BDJOHJOH",
    strloc: "SMAL TOWN, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "SMAL05",
    pstcd: "BDPETPET",
    strloc: "SMALL HOPE TENANTRY, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "SMAL06",
    pstcd: "BDCHRCHR",
    strloc: "SMALL LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SMAL06",
    pstcd: "BDMICBLA",
    strloc: "SMALL LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SMAL07",
    pstcd: "BDMICMIC",
    strloc: "Smallesteeg, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SMIT06",
    pstcd: "BDPHIPHI",
    strloc: "SMITH HILL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SMIT07",
    pstcd: "BDCHRCHR",
    strloc: 'SMITTEN"S BOTTOM, CHRIST CHURCH',
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SMIT07",
    pstcd: "BDMICMIC",
    strloc: 'SMITTEN"S BOTTOM, ST. MICHAEL',
    parish: "ST. MICHAEL",
  },
  {
    stind: "SMIT08",
    pstcd: "BDMICPIN",
    strloc: "SMITH ROAD, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SMIT09",
    pstcd: "BDMICPIN",
    strloc: "SMITH AVENUE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SMIT10",
    pstcd: "BDMICMIC",
    strloc: "SMITH LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SMIT11",
    pstcd: "BDMICMIC",
    strloc: "SMITH LAND, FAIRFIELD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SNAG01",
    pstcd: "BDMICMIC",
    strloc: "SNAGGS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SNAL01",
    pstcd: "BDMICMIC",
    strloc: "SNALLS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SOBE01",
    pstcd: "BDBGTBGT",
    strloc: "SOBERS LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SOBE01",
    pstcd: "BDBGTBGT",
    strloc: "SOBERS LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SOBE01",
    pstcd: "BDMICMIC",
    strloc: "SOBERS LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SOBE02",
    pstcd: "BDMICMIC",
    strloc: "SOBERS CROSS LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SOBE03",
    pstcd: "BDMICMIC",
    strloc: "SOBERS ALLEY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SOCI01",
    pstcd: "BDJOHJOH",
    strloc: "SOCIETY HILL, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "SOCI02",
    pstcd: "BDJOHJOH",
    strloc: "SOCIETY PLANTATION, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "SORO01",
    pstcd: "BDMICMIC",
    strloc: "SOROPTOMIST VILLAGE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SORR01",
    pstcd: "BDMICMIC",
    strloc: "SORREL LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SOUT03",
    pstcd: "BDCHRCHR",
    strloc: "SOUTHERN MAIN ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SOUT11",
    pstcd: "BDJAMJAM",
    strloc: "SOUTH ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SOUT13",
    pstcd: "BDMICCAV",
    strloc: "SOUTHHAMPTON ROAD, CAVE HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SOUT14",
    pstcd: "BDTHOTHO",
    strloc: "SOUTHBOROUGH, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "SOUT15",
    pstcd: "BDGEOGEO",
    strloc: "SOUTH DISTRICT, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "SOUT16",
    pstcd: "BDCHRCHR",
    strloc: "SOUTHWAYS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SOUT17",
    pstcd: "BDMICPIN",
    strloc: "SOUTH PORT LANE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SOUT18",
    pstcd: "BDCHRCHR",
    strloc: "SOUTHRIDGE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SOUT19",
    pstcd: "BDMICPIN",
    strloc: "SOUTH BEND LANE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SOUT20",
    pstcd: "BDMICPIN",
    strloc: "SOUTH BEND, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SOUT21",
    pstcd: "BDCHRCHR",
    strloc: "SOUTH END, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SOUT22",
    pstcd: "BDMICMIC",
    strloc: "SOUTH LOWLAND DRIVE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SOUT23",
    pstcd: "BDMICMIC",
    strloc: "SOUTH LOWLAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SOUT24",
    pstcd: "BDPHIPHI",
    strloc: "SOUTHFIELD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SOUT25",
    pstcd: "BDMICMIC",
    strloc: "SOUTH WILDEY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SOUT54",
    pstcd: "BDCHRCHR",
    strloc: "SOUTHERN HEIGHTS, BALLS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SPAH01",
    pstcd: "BDJOSJOS",
    strloc: "SPA HILL, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "SPEI01",
    pstcd: "BDPETSPE",
    strloc: "SPEIGHSTOWN, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "SPEI02",
    pstcd: "BDPETSPE",
    strloc: "SPEIGHSTOWN BY PASS, SPEIGHTSTOWN - ST PETER",
    parish: "ST. PETER",
  },
  {
    stind: "SPEN03",
    pstcd: "BDMICMIC",
    strloc: "SPENCERS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SPOO01",
    pstcd: "BDJOHJOH",
    strloc: "SPOONERS, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "SPOO02",
    pstcd: "BDMICMIC",
    strloc: "SPOONERS ALLEY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SPOO03",
    pstcd: "BDMICMIC",
    strloc: "SPOONERS HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SPRI15",
    pstcd: "BDPHIPHI",
    strloc: "SPRING FARM, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SPRI15",
    pstcd: "BDTHOTHO",
    strloc: "SPRING FARM, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "SPRI16",
    pstcd: "BDJOSJOS",
    strloc: "SPRINGFIELD, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "SPRI17",
    pstcd: "BDGEOGEO",
    strloc: "SPRINGER LAND, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "SPRI17",
    pstcd: "BDMICMIC",
    strloc: "SPRINGER LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SPRI18",
    pstcd: "BDLUCLUC",
    strloc: "SPRING HALL VILLAGE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "SPRI19",
    pstcd: "BDLUCLUC",
    strloc: "SPRING HALL, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "SPRI20",
    pstcd: "BDLUCLUC",
    strloc: "SPRING GARDEN VILLAGE, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "SPRI21",
    pstcd: "BDLUCLUC",
    strloc: "SPRING VILLA, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "SPRI22",
    pstcd: "BDANDAND",
    strloc: "SPRING VALE, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "SPRI23",
    pstcd: "BDMICMIC",
    strloc: "SPRING GARDENS GAP 1ST AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SPRI24",
    pstcd: "BDMICMIC",
    strloc: "SPRING GARDENS GAPS 2ND AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SPRI25",
    pstcd: "BDMICMIC",
    strloc: "SPRING GARDENS GAPS 3RD AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SPRI26",
    pstcd: "BDMICBLA",
    strloc: "SPRING GARDEN,ST MICHAEL, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SPRI26",
    pstcd: "BDMICMIC",
    strloc: "SPRING GARDEN,ST MICHAEL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SPRI27",
    pstcd: "BDMICMIC",
    strloc: "SPRINGER GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SPRI28",
    pstcd: "BDMICMIC",
    strloc: "SPRINGER ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SPRI29",
    pstcd: "BDCHRCHR",
    strloc: "SPRING TERRACE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SPRI30",
    pstcd: "BDJAMJAM",
    strloc: "SPRING HEAD TENNANTRY, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SPRI31",
    pstcd: "BDJAMJAM",
    strloc: "SPRING HEAD PLANTATION, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SPRI32",
    pstcd: "BDJAMJAM",
    strloc: "SPRING HEAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SPRI49",
    pstcd: "BDMICMIC",
    strloc: "SPRING GARDEN ESTATE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SPRU01",
    pstcd: "BDMICMIC",
    strloc: "SPRUCE STREET, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SPRY01",
    pstcd: "BDBGTBGT",
    strloc: "SPRY STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SPRY01",
    pstcd: "BDBGTBGT",
    strloc: "SPRY STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SPRY01",
    pstcd: "BDBGTBGT",
    strloc: "SPRY STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SPRY01",
    pstcd: "BDBGTBGT",
    strloc: "SPRY STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SPRY01",
    pstcd: "BDBGTBGT",
    strloc: "SPRY STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "STAD01",
    pstcd: "BDMICMIC",
    strloc: "STADIUM ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STAL02",
    pstcd: "BDJAMJAM",
    strloc: "ST ALBANS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "STAN26",
    pstcd: "BDMICBLA",
    strloc: "STANMORE CRESCENT, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STAN27",
    pstcd: "BDJOSJOS",
    strloc: "ST ANNS, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "STAN28",
    pstcd: "BDMICBLA",
    strloc: "STANMORE TERRACE, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STAN29",
    pstcd: "BDMICPIN",
    strloc: "ST ANNES ROAD, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STAN30",
    pstcd: "BDMICPIN",
    strloc: "STANLEY TERRACE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STAN31",
    pstcd: "BDCHRCHR",
    strloc: "STANFORD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "STAN32",
    pstcd: "BDCHRCHR",
    strloc: "STANFORD LODGE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "STAN33",
    pstcd: "BDPHIPHI",
    strloc: "STANFORD DEVELOPMENT, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "STAN35",
    pstcd: "BDANDAND",
    strloc: "ST ANDREW'S MAIN ROAD, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "STAP01",
    pstcd: "BDCHRCHR",
    strloc: "STAPLE GROVE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "STAT03",
    pstcd: "BDMICPIN",
    strloc: "STATFORD HILL, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STAT13",
    pstcd: "BDJOSJOS",
    strloc: "STATION HILL, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "STAT13",
    pstcd: "BDMICMIC",
    strloc: "STATION HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STAT13",
    pstcd: "BDPETPET",
    strloc: "STATION HILL, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "STAT13",
    pstcd: "BDPHIPHI",
    strloc: "STATION HILL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "STAU02",
    pstcd: "BDGEOGEO",
    strloc: "ST AUGUSTINE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "STBA02",
    pstcd: "BDMICMIC",
    strloc: "ST BARNABAS HOUSING AREA, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STBA03",
    pstcd: "BDMICMIC",
    strloc: "ST BARNABAS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STBE02",
    pstcd: "BDJOSJOS",
    strloc: "ST BERNARDS VILLAGE, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "STCA01",
    pstcd: "BDPHIPHI",
    strloc: "ST CATHERINES VILLAGE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "STCA02",
    pstcd: "BDJAMJAM",
    strloc: "ST CATHERINE CLOSE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "STCE02",
    pstcd: "BDMICMIC",
    strloc: "ST CECILIA, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STCH02",
    pstcd: "BDCHRCHR",
    strloc: "ST CHRISTOPHER, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "STCL07",
    pstcd: "BDLUCLUC",
    strloc: "ST CLEMENTS, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "STCO01",
    pstcd: "BDMICPIN",
    strloc: "ST COLUMB AVENUE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STDA01",
    pstcd: "BDCHRCHR",
    strloc: "ST DAVIDS VILLAGE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "STEL02",
    pstcd: "BDJOSJOS",
    strloc: "ST ELIZABETH VILLAGE, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "STEP07",
    pstcd: "BDGEOGEO",
    strloc: "STEPNEY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "STEP08",
    pstcd: "BDGEOGEO",
    strloc: "STEPNEY TERRACE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "STEV01",
    pstcd: "BDMICMIC",
    strloc: "STEVENSON ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STEV02",
    pstcd: "BDMICMIC",
    strloc: "STEVENSON LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STEW03",
    pstcd: "BDJOHJOH",
    strloc: "STEWART HILL, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "STGE05",
    pstcd: "BDBGTBGT",
    strloc: "ST GEORGE STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "STGE05",
    pstcd: "BDBGTBGT",
    strloc: "ST GEORGE STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "STHE01",
    pstcd: "BDGEOGEO",
    strloc: "ST HELENS, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "STHE02",
    pstcd: "BDJAMJAM",
    strloc: "ST HELENA, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "STHI02",
    pstcd: "BDMICMIC",
    strloc: "ST HILL ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STHI02",
    pstcd: "BDMICMIC",
    strloc: "ST HILL ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STIR01",
    pstcd: "BDPHIPHI",
    strloc: "STIRLING, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "STIV01",
    pstcd: "BDMICPIN",
    strloc: "ST IVES LANE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STJA03",
    pstcd: "BDMICMIC",
    strloc: "ST JAMES FORT, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STJO13",
    pstcd: "BDJAMJAM",
    strloc: "ST JOHN BAPTIST DRIVE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "STJU02",
    pstcd: "BDGEOGEO",
    strloc: "ST JUDES VILLAGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "STLA01",
    pstcd: "BDCHRCHR",
    strloc: "ST LAWRENCE MAIN ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "STLA02",
    pstcd: "BDCHRCHR",
    strloc: "ST LAWRENCE GAP, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "STLE01",
    pstcd: "BDMICMIC",
    strloc: "ST LEONARDS AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STLE02",
    pstcd: "BDMICMIC",
    strloc: "ST LEONARDS VILLAGE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STLU05",
    pstcd: "BDGEOGEO",
    strloc: "ST LUKES, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "STMA08",
    pstcd: "BDBGTBGT",
    strloc: "ST MARY'S ROW, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "STMA09",
    pstcd: "BDJOHJOH",
    strloc: "ST MARGARET'S GLEBE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "STMA10",
    pstcd: "BDJOHJOH",
    strloc: "ST MARGARETS, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "STMA11",
    pstcd: "BDJOHJOH",
    strloc: "ST MARKS, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "STMA12",
    pstcd: "BDPHIPHI",
    strloc: "ST MARTINS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "STMA13",
    pstcd: "BDPHIPHI",
    strloc: "ST MARTINS RECTORY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "STMA14",
    pstcd: "BDPHIPHI",
    strloc: "ST MARTINS VILLAGE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "STMA15",
    pstcd: "BDCHRCHR",
    strloc: "ST MATTHIAS VILLAGE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "STMA16",
    pstcd: "BDCHRCHR",
    strloc: "ST MATTHIAS 2ND AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "STMA17",
    pstcd: "BDMICMIC",
    strloc: "ST MATTHEWS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STMA19",
    pstcd: "BDCHRCHR",
    strloc: "ST MATTHIAS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "STMI04",
    pstcd: "BDBGTBGT",
    strloc: "ST MICHAEL'S ROW, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "STMI04",
    pstcd: "BDBGTBGT",
    strloc: "ST MICHAEL'S ROW, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "STMI04",
    pstcd: "BDBGTBGT",
    strloc: "ST MICHAEL'S ROW, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "STMI04",
    pstcd: "BDBGTBGT",
    strloc: "ST MICHAEL'S ROW, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "STMI04",
    pstcd: "BDBGTBGT",
    strloc: "ST MICHAEL'S ROW, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "STON04",
    pstcd: "BDPHIPHI",
    strloc: "STONE HALL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "STOR02",
    pstcd: "BDMICMIC",
    strloc: "STOREY GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STOR03",
    pstcd: "BDJAMJAM",
    strloc: "STORE HOUSE GAP, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "STOR04",
    pstcd: "BDJAMJAM",
    strloc: "STORE HOUSE ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "STPA02",
    pstcd: "BDMICMIC",
    strloc: "ST PAUL'S AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STPA03",
    pstcd: "BDCHRCHR",
    strloc: "ST PATRICKS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "STPA04",
    pstcd: "BDCHRCHR",
    strloc: "ST PATRICKS MAIN ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "STPH01",
    pstcd: "BDPETPET",
    strloc: "ST PHILIP THE LESS, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "STRA09",
    pstcd: "BDMICBLA",
    strloc: "STRAKERS LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STRA10",
    pstcd: "BDJOSJOS",
    strloc: "STRAUGHNS VILLAGE, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "STRA11",
    pstcd: "BDMICMIC",
    strloc: "STRAKERS TENANTRY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STRA12",
    pstcd: "BDMICMIC",
    strloc: "STRATHCLYDE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STRE02",
    pstcd: "BDMICMIC",
    strloc: "STREATS ROAD LOWER, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STRE04",
    pstcd: "BDCHRCHR",
    strloc: "STREAM ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "STRO03",
    pstcd: "BDTHOTHO",
    strloc: "STRONG HOPE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "STRO04",
    pstcd: "BDLUCLUC",
    strloc: "STROUDE BAY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "STRO05",
    pstcd: "BDPHIPHI",
    strloc: "STROUDE LAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "STSA01",
    pstcd: "BDANDAND",
    strloc: "ST SAVIOURS, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "STSI01",
    pstcd: "BDANDAND",
    strloc: "ST SIMONS, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "STSI02",
    pstcd: "BDJAMJAM",
    strloc: "ST SILAS HEIGHTS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "STSI03",
    pstcd: "BDJAMJAM",
    strloc: "ST SILAS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "STST01",
    pstcd: "BDMICBLA",
    strloc: "ST STEPHENS ROAD, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STST02",
    pstcd: "BDMICBLA",
    strloc: "ST STEPHENS HILL (LOWER), BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STST03",
    pstcd: "BDMICBLA",
    strloc: "ST STEPHENS SCHEME & WAVELL GD, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STST04",
    pstcd: "BDMICBLA",
    strloc: "ST STEPHENS HOUSING AREA, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STST05",
    pstcd: "BDMICBLA",
    strloc: "ST STEPHENS, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STST06",
    pstcd: "BDMICMIC",
    strloc: "ST STEPHENS HILL (UPPER), ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STSW01",
    pstcd: "BDLUCLUC",
    strloc: "ST SWITHINS, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "STSY01",
    pstcd: "BDJOSJOS",
    strloc: "ST SYLVANS VILLAGE, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "STUA03",
    pstcd: "BDMICMIC",
    strloc: "STUARTS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "STUR01",
    pstcd: "BDTHOTHO",
    strloc: "STURGES, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "STUR02",
    pstcd: "BDTHOTHO",
    strloc: "STURGES DEVELOPMENT, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "STUR03",
    pstcd: "BDTHOTHO",
    strloc: "STURGES PLANTATION, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "SUCK01",
    pstcd: "BDPHIPHI",
    strloc: "SUCK HOLE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SUGA01",
    pstcd: "BDJAMJAM",
    strloc: "SUGAR APPLE CRESCENT, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SUGA03",
    pstcd: "BDJAMHUS",
    strloc: "SUGARCANE AVENUE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SUGA05",
    pstcd: "BDJOSJOS",
    strloc: "SUGAR HILL, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "SUGA06",
    pstcd: "BDGEOGEO",
    strloc: "SUGAR FARMS LAND, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "SUGA09",
    pstcd: "BDJAMJAM",
    strloc: "SUGAR HILL ESTATE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SUMM02",
    pstcd: "BDJOHJOH",
    strloc: "SUMMIT, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "SUMM03",
    pstcd: "BDMICMIC",
    strloc: "SUMMERVALE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SUMM03",
    pstcd: "BDPHIPHI",
    strloc: "SUMMERVALE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SUNB02",
    pstcd: "BDPHIPHI",
    strloc: "SUNBURY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SUNB03",
    pstcd: "BDPHIPHI",
    strloc: "SUNBURY PLANTATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SUNB04",
    pstcd: "BDPHIPHI",
    strloc: "SUNBURY TENANTRY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SUND02",
    pstcd: "BDCHRCHR",
    strloc: "SUNDOWN, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SUNF01",
    pstcd: "BDCHRCHR",
    strloc: "SUNFLOWER DRIVE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SUNF01",
    pstcd: "BDJAMHUS",
    strloc: "SUNFLOWER DRIVE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SUNN01",
    pstcd: "BDPHIPHI",
    strloc: "SUNNY ACRES, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SUNN02",
    pstcd: "BDPHIPHI",
    strloc: "SUNNY MEADOWS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SUNN03",
    pstcd: "BDMICMIC",
    strloc: "SUNNY SIDE COURT, DEACONS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SUNR02",
    pstcd: "BDMICPIN",
    strloc: "SUNRISE DRIVE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SUNR03",
    pstcd: "BDCHRCHR",
    strloc: "SUNRISE ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "SUNS01",
    pstcd: "BDMICPIN",
    strloc: "SUNSET DRIVE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SUNS05",
    pstcd: "BDJAMJAM",
    strloc: "SUNSET RIDGE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SUNS12",
    pstcd: "BDJAMSUN",
    strloc: "SUNSET CREST DEVELOPMENT, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SUNS13",
    pstcd: "BDJAMSUN",
    strloc: "SUNSET CREST, SUNSET CREST - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SUPE03",
    pstcd: "BDGEOGEO",
    strloc: "SUPERLATIVE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "SUPE04",
    pstcd: "BDPHIPHI",
    strloc: "SUPERS LAND, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SUPE05",
    pstcd: "BDPHIPHI",
    strloc: "SUPERS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SUPE06",
    pstcd: "BDJAMJAM",
    strloc: "SUPER POULTRY GAP, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "SURF01",
    pstcd: "BDPHIPHI",
    strloc: "SURF SONG, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SURI01",
    pstcd: "BDJOSJOS",
    strloc: "SURINAM VILLAGE, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "SURI02",
    pstcd: "BDJOSJOS",
    strloc: "SURINAM, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "SUSS01",
    pstcd: "BDPHIPHI",
    strloc: "SUSSEX ROW, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "SUTH01",
    pstcd: "BDLUCLUC",
    strloc: "SUTHERLAND HILL, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "SUTT02",
    pstcd: "BDBGTBGT",
    strloc: "SUTTLE STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SUTT02",
    pstcd: "BDBGTBGT",
    strloc: "SUTTLE STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SUTT02",
    pstcd: "BDBGTBGT",
    strloc: "SUTTLE STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SUTT02",
    pstcd: "BDBGTBGT",
    strloc: "SUTTLE STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SUTT03",
    pstcd: "BDMICMIC",
    strloc: "SUTTON WAY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SWAM03",
    pstcd: "BDLUCLUC",
    strloc: "SWAMPY TOWN, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "SWAN01",
    pstcd: "BDBGTBGT",
    strloc: "SWAN STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SWAN01",
    pstcd: "BDBGTBGT",
    strloc: "SWAN STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SWAN01",
    pstcd: "BDBGTBGT",
    strloc: "SWAN STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SWAN01",
    pstcd: "BDBGTBGT",
    strloc: "SWAN STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SWAN01",
    pstcd: "BDBGTBGT",
    strloc: "SWAN STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SWAN01",
    pstcd: "BDBGTBGT",
    strloc: "SWAN STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SWAN01",
    pstcd: "BDBGTBGT",
    strloc: "SWAN STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SWAN01",
    pstcd: "BDBGTBGT",
    strloc: "SWAN STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SWEE03",
    pstcd: "BDGEOGEO",
    strloc: "SWEET BOTTOM, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "SWEE03",
    pstcd: "BDMICMIC",
    strloc: "SWEET BOTTOM, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "SWEE04",
    pstcd: "BDGEOGEO",
    strloc: "SWEET VALE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "SWEE05",
    pstcd: "BDPETPET",
    strloc: "SWEET HOME GAP, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "SWEE06",
    pstcd: "BDPETPET",
    strloc: "SWEET HOME GARDENS, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "SYNA01",
    pstcd: "BDBGTBGT",
    strloc: "SYNAGOGUE LANE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "SYND01",
    pstcd: "BDMICMIC",
    strloc: "SYNDICATE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "TABE01",
    pstcd: "BDPHIPHI",
    strloc: "TABERNACLE AVENUE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "TAIT04",
    pstcd: "BDGEOGEO",
    strloc: "TAITT HILL, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "TAIT05",
    pstcd: "BDMICMIC",
    strloc: "TAITTS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "TAIT06",
    pstcd: "BDJAMJAM",
    strloc: "TAITTS PLANTATION, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "TAIT07",
    pstcd: "BDJAMJAM",
    strloc: "TAITTS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "TAIT08",
    pstcd: "BDJAMJAM",
    strloc: "TAITTS MARTIN, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "TAMA07",
    pstcd: "BDJAMJAM",
    strloc: "TAMARIND AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "TAMA07",
    pstcd: "BDMICMIC",
    strloc: "TAMARIND AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "TAMA10",
    pstcd: "BDMICBAN",
    strloc: "TAMARIND LANE, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "TAMA10",
    pstcd: "BDMICMIC",
    strloc: "TAMARIND LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "TAPP02",
    pstcd: "BDCHRCHR",
    strloc: "TAPPINS LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "TAYL02",
    pstcd: "BDMICBAN",
    strloc: "TAYLOR ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "TAYL04",
    pstcd: "BDMICBAN",
    strloc: "TAYLORS LAND, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "TAYL05",
    pstcd: "BDJAMJAM",
    strloc: "TAYLORS GAP, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "TAYL05",
    pstcd: "BDMICMIC",
    strloc: "TAYLORS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "TAYL06",
    pstcd: "BDMICMIC",
    strloc: "TAYLORS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "TAYL07",
    pstcd: "BDJAMJAM",
    strloc: "TAYLOR GAP, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "TEMP05",
    pstcd: "BDBGTBGT",
    strloc: "TEMPLE YARD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "TEMP05",
    pstcd: "BDBGTBGT",
    strloc: "TEMPLE YARD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "TENT03",
    pstcd: "BDJOSJOS",
    strloc: "TENT BAY, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "THEA02",
    pstcd: "BDANDAND",
    strloc: "THE ALLEY, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "THEG01",
    pstcd: "BDJOHJOH",
    strloc: "THE GLEN, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "THEG02",
    pstcd: "BDJAMJAM",
    strloc: "THE GARDEN, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "THEI01",
    pstcd: "BDMICMIC",
    strloc: "THE IVY 10TH AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "THEM01",
    pstcd: "BDANDAND",
    strloc: "THE MOUNT, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "THEM03",
    pstcd: "BDGEOGEO",
    strloc: "THE MOUNT, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "THEN01",
    pstcd: "BDPHIPHI",
    strloc: "THE NATIONAL HATCHERIES, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "THER02",
    pstcd: "BDLUCLUC",
    strloc: "THE RISK, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "THER03",
    pstcd: "BDPETPET",
    strloc: "THE RISK VILLAGE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "THER04",
    pstcd: "BDMICMIC",
    strloc: "THE ROCK, SPOONERS HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "THER05",
    pstcd: "BDPETPET",
    strloc: "THE ROCK, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "THER06",
    pstcd: "BDGEOGEO",
    strloc: "THE RIDGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "THES02",
    pstcd: "BDJAMJAM",
    strloc: "THE STABLES, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "THEW01",
    pstcd: "BDPETPET",
    strloc: "THE WHARF, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "THEW02",
    pstcd: "BDPETPET",
    strloc: "THE WHIM, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "THIC01",
    pstcd: "BDPHIPHI",
    strloc: "THICKETTS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "THIC02",
    pstcd: "BDPHIPHI",
    strloc: "THICKETTS HILL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "THIC03",
    pstcd: "BDPHIPHI",
    strloc: "THICKETTS PLANTATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "THIC04",
    pstcd: "BDPHIPHI",
    strloc: "THICKETTS TENANTRY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "THOM19",
    pstcd: "BDMICMIC",
    strloc: "THOMAS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "THOM20",
    pstcd: "BDCHRCHR",
    strloc: "THOMAS LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "THOM20",
    pstcd: "BDMICMIC",
    strloc: "THOMAS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "THOM21",
    pstcd: "BDMICMIC",
    strloc: "THOMPSON ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "THOM22",
    pstcd: "BDMICMIC",
    strloc: "THOMPSONS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "THOR05",
    pstcd: "BDGEOGEO",
    strloc: "THORPES COTTAGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "THOR06",
    pstcd: "BDMICMIC",
    strloc: "THORNES LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "THOR07",
    pstcd: "BDCHRCHR",
    strloc: "THORNBURY HILL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "THOR08",
    pstcd: "BDJAMJAM",
    strloc: "THORPES GARDENS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "THOR09",
    pstcd: "BDJAMJAM",
    strloc: "THORPES HILL 1ST AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "THOR10",
    pstcd: "BDJAMJAM",
    strloc: "THORPES HILL 2ND AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "THOR11",
    pstcd: "BDJAMJAM",
    strloc: "THORPES, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "THOR12",
    pstcd: "BDMICMIC",
    strloc: "THORNES GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "THOR13",
    pstcd: "BDMICMIC",
    strloc: "THORINGTON NEW DEV. LODGE HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "THOR14",
    pstcd: "BDPHIPHI",
    strloc: "THORPES WOOD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "THRE02",
    pstcd: "BDPHIPHI",
    strloc: "THREE HOUSES HILL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "THRE03",
    pstcd: "BDPHIPHI",
    strloc: "THREE HOUSES, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "THYM01",
    pstcd: "BDCHRCHR",
    strloc: "THYME BOTTOM, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "TICH01",
    pstcd: "BDMICMIC",
    strloc: "TICHBOURNE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "TINO01",
    pstcd: "BDCHRCHR",
    strloc: "TINO TERRACE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "TODD03",
    pstcd: "BDJOHJOH",
    strloc: "TODDS ESTATE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "TODD04",
    pstcd: "BDJOHJOH",
    strloc: "TODD PLANTATION, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "TODD05",
    pstcd: "BDGEOGEO",
    strloc: "TODDS TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "TOMB01",
    pstcd: "BDPETPET",
    strloc: "TOM BEN ROAD, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "TOMB02",
    pstcd: "BDPETPET",
    strloc: "TOM BEND GAP, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "TOPA02",
    pstcd: "BDCHRCHR",
    strloc: "TOPAZ AVENUE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "TOPR01",
    pstcd: "BDCHRCHR",
    strloc: "TOP ROCK, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "TORO01",
    pstcd: "BDGEOGEO",
    strloc: "TORONTO ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "TOUR01",
    pstcd: "BDJOSJOS",
    strloc: "TOURVILLE, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "TRAF01",
    pstcd: "BDBGTBGT",
    strloc: "TRAFALGAR SQUARE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "TRAF01",
    pstcd: "BDBGTBGT",
    strloc: "TRAFALGAR SQUARE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "TRAI01",
    pstcd: "BDMICMIC",
    strloc: "TRAIN LINE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "TRAI04",
    pstcd: "BDPHIPHI",
    strloc: "TRAIN ROAD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "TRAN04",
    pstcd: "BDMICMIC",
    strloc: "TRANMORE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "TRAV01",
    pstcd: "BDJAMJAM",
    strloc: "TRAVENA GARDENS ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "TREE01",
    pstcd: "BDJAMJAM",
    strloc: "TREE TOP, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "TREL01",
    pstcd: "BDMICMIC",
    strloc: "TRELLIS WALK, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "TREL02",
    pstcd: "BDMICMIC",
    strloc: "TRELLIS ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "TREN01",
    pstcd: "BDJAMJAM",
    strloc: "TRENTS TENANTRY, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "TREN01",
    pstcd: "BDLUCLUC",
    strloc: "TRENTS TENANTRY, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "TREN02",
    pstcd: "BDJAMJAM",
    strloc: "TRENTS DEVELOPMENT, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "TREN03",
    pstcd: "BDJAMJAM",
    strloc: "TRENTS HILL, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "TRID02",
    pstcd: "BDJAMHUS",
    strloc: "TRIDENT AVE NORTH, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "TRID03",
    pstcd: "BDJAMHUS",
    strloc: "TRIDENT AVE SOUTH, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "TRIO01",
    pstcd: "BDANDAND",
    strloc: "TRIOPATH, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "TRUN01",
    pstcd: "BDJAMJAM",
    strloc: "TRUNK ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "TUDO01",
    pstcd: "BDBGTBGT",
    strloc: "TUDOR STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "TUDO01",
    pstcd: "BDBGTBGT",
    strloc: "TUDOR STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "TUDO01",
    pstcd: "BDBGTBGT",
    strloc: "TUDOR STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "TUDO01",
    pstcd: "BDBGTBGT",
    strloc: "TUDOR STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "TUDO01",
    pstcd: "BDBGTBGT",
    strloc: "TUDOR STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "TUDO01",
    pstcd: "BDBGTBGT",
    strloc: "TUDOR STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "TUDO01",
    pstcd: "BDBGTBGT",
    strloc: "TUDOR STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "TUDO02",
    pstcd: "BDMICMIC",
    strloc: "TUDOR LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "TUDO03",
    pstcd: "BDMICMIC",
    strloc: "TUDOR GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "TUDO04",
    pstcd: "BDMICMIC",
    strloc: "TUDOR BRIDGE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "TULI02",
    pstcd: "BDJAMHUS",
    strloc: "TULIP DRIVE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "TULL02",
    pstcd: "BDMICMIC",
    strloc: "TULL LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "TURN04",
    pstcd: "BDANDAND",
    strloc: "TURNER HALL, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "TURT02",
    pstcd: "BDMICMIC",
    strloc: "TURTON GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "TWEE01",
    pstcd: "BDMICMIC",
    strloc: "TWEEDSIDE ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "TWOG01",
    pstcd: "BDJAMJAM",
    strloc: "TWO GABLES, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "TWOM01",
    pstcd: "BDMICMIC",
    strloc: "TWO MILE HILL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "TWOM02",
    pstcd: "BDMICMIC",
    strloc: "TWO MILE HOUSING AREA, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "UNIO02",
    pstcd: "BDPHIUNI",
    strloc: "UNION ROAD, UNION HALL - ST PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "UNIO08",
    pstcd: "BDJOSJOS",
    strloc: "UNION VILLAGE, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "UNIO09",
    pstcd: "BDJOSJOS",
    strloc: "UNION LAND, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "UNIO10",
    pstcd: "BDCHRCHR",
    strloc: "UNION LODGE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "UNIO10",
    pstcd: "BDGEOGEO",
    strloc: "UNION LODGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "UNIO11",
    pstcd: "BDPHIUNI",
    strloc: "UNION PARK, UNION HALL - ST PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "UNIO12",
    pstcd: "BDPHIUNI",
    strloc: "UNION HALL DEVELOPMENT, UNION HALL - ST PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "UNIT02",
    pstcd: "BDCHRCHR",
    strloc: "UNITY LANE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "UNIV02",
    pstcd: "BDMICMIC",
    strloc: "UNIVERSITY ROW, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "UNIV03",
    pstcd: "BDMICCAV",
    strloc: "UNIVERSITY DRIVE, CAVE HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "UPLA01",
    pstcd: "BDJOHJOH",
    strloc: "UPLAND, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "UPLA02",
    pstcd: "BDJOHJOH",
    strloc: "UPLANDS TENANTRY, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "UPRI01",
    pstcd: "BDGEOGEO",
    strloc: "UPRIGHT STREET, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "UPTO01",
    pstcd: "BDMICMIC",
    strloc: "UPTON, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "URBA02",
    pstcd: "BDMICMIC",
    strloc: "URBAN DEVELOPMENT, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "VALE05",
    pstcd: "BDMICMIC",
    strloc: "VALERY LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "VALE06",
    pstcd: "BDPHIPHI",
    strloc: "VALE VIEW, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "VALE07",
    pstcd: "BDCHRCHR",
    strloc: "VALENCIA, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "VALI01",
    pstcd: "BDMICMIC",
    strloc: "VALIERIE LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "VALL08",
    pstcd: "BDMICMIC",
    strloc: "VALLEY ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "VALL09",
    pstcd: "BDGEOGEO",
    strloc: "VALLEY PLANTATION, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "VALL10",
    pstcd: "BDGEOGEO",
    strloc: "VALLEY TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "VALL11",
    pstcd: "BDGEOGEO",
    strloc: "VALLEY SUB DIVISION NO 1, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "VALL12",
    pstcd: "BDGEOGEO",
    strloc: "VALLEY CLOSE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "VALL13",
    pstcd: "BDGEOGEO",
    strloc: "VALLEY DEVELOPMENT, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "VALL14",
    pstcd: "BDGEOGEO",
    strloc: "VALLEY SUB DIVISION NO 2, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "VALL15",
    pstcd: "BDGEOGEO",
    strloc: "VALLEY VIEW, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "VALL16",
    pstcd: "BDGEOGEO",
    strloc: "VALLEY VIEW DEVELOPMENT, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "VALL17",
    pstcd: "BDCHRCHR",
    strloc: "VALLEY RIDGE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "VALL18",
    pstcd: "BDMICPIN",
    strloc: "VALLEY VIEW TERRACE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "VALL19",
    pstcd: "BDCHRCHR",
    strloc: "VALLEY HILL GAP, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "VAUC01",
    pstcd: "BDTHOTHO",
    strloc: "VAUCLUSE PLANTATION, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "VAUC02",
    pstcd: "BDTHOTHO",
    strloc: "VAUCLUSE TENANTRY, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "VAUG01",
    pstcd: "BDJOSJOS",
    strloc: "VAUGHN LAND, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "VAUG02",
    pstcd: "BDJOSJOS",
    strloc: "VAUGHN ROAD, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "VAUG03",
    pstcd: "BDJOSJOS",
    strloc: "VAUGHNS, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "VAUG04",
    pstcd: "BDJAMJAM",
    strloc: "VAUGHAN'S ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "VAUL01",
    pstcd: "BDTHOTHO",
    strloc: "VAULT ROAD, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "VAUX01",
    pstcd: "BDCHRCHR",
    strloc: "VAUXHALL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "VAUX02",
    pstcd: "BDJAMJAM",
    strloc: "VAUXHALL ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "VAUX03",
    pstcd: "BDCHRCHR",
    strloc: "VAUXHALL GARDEN, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "VENT01",
    pstcd: "BDJOHJOH",
    strloc: "VENTURE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "VENT02",
    pstcd: "BDCHRROC",
    strloc: "VENTNOR GARDENS, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "VENT03",
    pstcd: "BDCHRROC",
    strloc: "VENTNOR GARDENS NO. 1, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "VENT04",
    pstcd: "BDCHRROC",
    strloc: "VENTNOR LAND, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "VENT05",
    pstcd: "BDCHRROC",
    strloc: "VENTNOR GARDENS NO. 2, ROCKLEY - CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "VERB02",
    pstcd: "BDJAMJAM",
    strloc: "VERBENA ROW, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "VICA01",
    pstcd: "BDMICMIC",
    strloc: "VICARAGE LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "VICT03",
    pstcd: "BDBGTBGT",
    strloc: "VICTORIA STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "VICT03",
    pstcd: "BDBGTBGT",
    strloc: "VICTORIA STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "VICT03",
    pstcd: "BDBGTBGT",
    strloc: "VICTORIA STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "VICT03",
    pstcd: "BDBGTBGT",
    strloc: "VICTORIA STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "VICT03",
    pstcd: "BDBGTBGT",
    strloc: "VICTORIA STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "VICT03",
    pstcd: "BDBGTBGT",
    strloc: "VICTORIA STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "VICT13",
    pstcd: "BDJOHJOH",
    strloc: "VICTORIA, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "VICT14",
    pstcd: "BDMICBLA",
    strloc: "VICTORS LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "VICT15",
    pstcd: "BDMICMIC",
    strloc: "VICTUS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "VILL05",
    pstcd: "BDMICMIC",
    strloc: "VILLA ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "VILL06",
    pstcd: "BDMICMIC",
    strloc: "VILLA PARK, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "VILL07",
    pstcd: "BDPHIPHI",
    strloc: "VILLAGE ROAD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "VINE02",
    pstcd: "BDPHIPHI",
    strloc: "VINEYARD, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "VINE03",
    pstcd: "BDMICMIC",
    strloc: "VINE STREET, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "VIOL02",
    pstcd: "BDJAMHUS",
    strloc: "VIOLET CIRCLE, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WAIT01",
    pstcd: "BDMICMIC",
    strloc: "WAITHES LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WAKE01",
    pstcd: "BDJOHJOH",
    strloc: "WAKEFIELD TENANTRY, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "WAKE02",
    pstcd: "BDJOHJOH",
    strloc: "WAKEFIELD PLANTATION, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "WAKE03",
    pstcd: "BDJOHJOH",
    strloc: "WAKEFIELD, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "WALC02",
    pstcd: "BDMICMIC",
    strloc: "WALCOTT'S AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WALC03",
    pstcd: "BDCHRCHR",
    strloc: "WALCOTT LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WALC03",
    pstcd: "BDMICMIC",
    strloc: "WALCOTT LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WALC04",
    pstcd: "BDJAMJAM",
    strloc: "WALCOTT ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WALK07",
    pstcd: "BDTHOTHO",
    strloc: "WALKES SPRING PLANTATION, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WALK08",
    pstcd: "BDTHOTHO",
    strloc: "WALKES SPRING TENANTRY, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WALK09",
    pstcd: "BDGEOGEO",
    strloc: "WALKERS TERRACE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "WALK10",
    pstcd: "BDGEOGEO",
    strloc: "WALKERS CLOSE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "WALK11",
    pstcd: "BDANDAND",
    strloc: "WALKERS BAY, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "WALK12",
    pstcd: "BDANDAND",
    strloc: "WALKERS, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "WALK12",
    pstcd: "BDGEOGEO",
    strloc: "WALKERS, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "WALK13",
    pstcd: "BDGEOGEO",
    strloc: "WALKERS VALLEY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "WALK14",
    pstcd: "BDGEOGEO",
    strloc: "WALKER ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "WALK15",
    pstcd: "BDGEOGEO",
    strloc: "WALKERS TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "WALK16",
    pstcd: "BDMICMIC",
    strloc: "WALK ABOUT PATH, EDEN LODGE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WALL07",
    pstcd: "BDCHRCHR",
    strloc: "WALLS TENANTRY, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WALM01",
    pstcd: "BDMICBLA",
    strloc: "WALMER LODGE, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WALM01",
    pstcd: "BDMICMIC",
    strloc: "WALMER LODGE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WALR01",
    pstcd: "BDBGTBGT",
    strloc: "WALROND STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "WALR01",
    pstcd: "BDBGTBGT",
    strloc: "WALROND STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "WALR01",
    pstcd: "BDBGTBGT",
    strloc: "WALROND STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "WALR02",
    pstcd: "BDCHRCHR",
    strloc: "WALROND VILLAGE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WANS01",
    pstcd: "BDJAMJAM",
    strloc: "WANSTEAD HEIGHTS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WANS02",
    pstcd: "BDJAMJAM",
    strloc: "WANSTEAD GARDENS, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WANS03",
    pstcd: "BDJAMJAM",
    strloc: "WANSTEAD TERRACE, 1ST AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WANS04",
    pstcd: "BDJAMJAM",
    strloc: "WANSTEAD TERRACE, 2ND AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WANS05",
    pstcd: "BDJAMJAM",
    strloc: "WANSTEAD TERRACE, 3RD AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WANS06",
    pstcd: "BDJAMJAM",
    strloc: "WANSTEAD TERRACE, 4TH AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WANS07",
    pstcd: "BDJAMJAM",
    strloc: "WANSTEAD TERRACE, 5TH AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WANS08",
    pstcd: "BDJAMJAM",
    strloc: "WANSTEAD TERRACE, 6TH AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WANS09",
    pstcd: "BDJAMJAM",
    strloc: "WANSTEAD TERRACE, 7TH AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WANS10",
    pstcd: "BDJAMJAM",
    strloc: "WANSTEAD TERRACE, 8TH AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WANS11",
    pstcd: "BDJAMJAM",
    strloc: "WANSTEAD TERRACE, 9TH AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WANS12",
    pstcd: "BDJAMJAM",
    strloc: "WANSTEAD TERRACE, 10TH AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WANS13",
    pstcd: "BDJAMJAM",
    strloc: "WANSTEAD TERRACE, 11TH AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WANS15",
    pstcd: "BDMICMIC",
    strloc: "WANSTEAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WARD06",
    pstcd: "BDMICMIC",
    strloc: "WARD LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WARL01",
    pstcd: "BDPETPET",
    strloc: "WARLEIGH, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "WARL02",
    pstcd: "BDPETPET",
    strloc: "WARLEIGH PLANTATION, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "WARN04",
    pstcd: "BDMICMIC",
    strloc: "WARNERS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WARN05",
    pstcd: "BDCHRCHR",
    strloc: "WARNERS PLANTATION, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WARN06",
    pstcd: "BDCHRCHR",
    strloc: "WARNERS TERRACE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WARN07",
    pstcd: "BDCHRCHR",
    strloc: "WARNERS GARDENS, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WARN08",
    pstcd: "BDCHRCHR",
    strloc: "WARNERS PARK, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WARN09",
    pstcd: "BDCHRCHR",
    strloc: "WARNERS RIDGE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WARR06",
    pstcd: "BDTHOTHO",
    strloc: "WARRENS COMPLEX, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WARR07",
    pstcd: "BDTHOTHO",
    strloc: "WARRENS CRESCENT, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WARR08",
    pstcd: "BDTHOTHO",
    strloc: "WARRENS DEVELOPMENT, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WARR09",
    pstcd: "BDTHOTHO",
    strloc: "WARRENS HILL, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WARR10",
    pstcd: "BDMICWAR",
    strloc: "WARRENS INDUSTRIAL PARK, WARRENS - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WARR10",
    pstcd: "BDTHOTHO",
    strloc: "WARRENS INDUSTRIAL PARK, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WARR11",
    pstcd: "BDMICWAR",
    strloc: "WARRENS, WARRENS - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WARR11",
    pstcd: "BDTHOTHO",
    strloc: "WARRENS, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WARR12",
    pstcd: "BDJAMJAM",
    strloc: "WARRENS PARK SOUTH, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WARR12",
    pstcd: "BDMICWAR",
    strloc: "WARRENS PARK SOUTH, WARRENS - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WARR13",
    pstcd: "BDJAMJAM",
    strloc: "WARRENS PARK 1ST AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WARR13",
    pstcd: "BDMICWAR",
    strloc: "WARRENS PARK 1ST AVENUE, WARRENS - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WARR14",
    pstcd: "BDJAMJAM",
    strloc: "WARRENS PARK 2ND AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WARR14",
    pstcd: "BDMICWAR",
    strloc: "WARRENS PARK 2ND AVENUE, WARRENS - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WARR15",
    pstcd: "BDJAMJAM",
    strloc: "WARRENS PARK 3RD AVENUE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WARR15",
    pstcd: "BDMICWAR",
    strloc: "WARRENS PARK 3RD AVENUE, WARRENS - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WARR16",
    pstcd: "BDMICWAR",
    strloc: "WARRENS HEIGHTS, WARRENS - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WARR17",
    pstcd: "BDMICWAR",
    strloc: "WARRENS TERRACE, WARRENS - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WARR17",
    pstcd: "BDTHOTHO",
    strloc: "WARRENS TERRACE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WARR18",
    pstcd: "BDMICWAR",
    strloc: "WARRENS PARK NORTH, WARRENS - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WARR19",
    pstcd: "BDMICWAR",
    strloc: "WARRENS CRESCENT STAGE 1, WARRENS - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WARR20",
    pstcd: "BDMICWAR",
    strloc: "WARRENS CRESCENT STAGE 2, WARRENS - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WARR21",
    pstcd: "BDTHOTHO",
    strloc: "WARREN HEIGHTS, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WARR22",
    pstcd: "BDMICMIC",
    strloc: "WARRENS PARK WEST, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WARR23",
    pstcd: "BDMICMIC",
    strloc: "WARRENS PARK EAST, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WARW01",
    pstcd: "BDMICPIN",
    strloc: "WARWICK LANE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WARW03",
    pstcd: "BDMICPIN",
    strloc: "WARWICK LAND, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WASH02",
    pstcd: "BDMICBLA",
    strloc: "WASHINGTON AVENUE, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WATE15",
    pstcd: "BDMICMIC",
    strloc: "WATERMILL PLACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WATE16",
    pstcd: "BDJAMJAM",
    strloc: "WATERMANS VILLAGE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WATE17",
    pstcd: "BDMICMIC",
    strloc: "WATERFORD HOUSING AREA, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WATE18",
    pstcd: "BDMICMIC",
    strloc: "WATERFORDS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WATE19",
    pstcd: "BDMICMIC",
    strloc: "WATERFORD PLANTATION, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WATE20",
    pstcd: "BDCHRCHR",
    strloc: "WATER STREET, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WATE21",
    pstcd: "BDJAMJAM",
    strloc: "WATERHALL, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WATE21",
    pstcd: "BDMICBAN",
    strloc: "WATERHALL, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WATE21",
    pstcd: "BDPHIPHI",
    strloc: "WATERHALL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "WATE22",
    pstcd: "BDMICMIC",
    strloc: "WATERLOO GARDENS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WATE23",
    pstcd: "BDMICMIC",
    strloc: "WATERLOO ALLEY, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WATE24",
    pstcd: "BDJAMJAM",
    strloc: "WATER HALL TERRACE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WATE25",
    pstcd: "BDJAMJAM",
    strloc: "WATER HALL PLANTATION, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WATE26",
    pstcd: "BDJAMJAM",
    strloc: "WATER HALL TENANTRY, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WATE27",
    pstcd: "BDMICMIC",
    strloc: "WATERFORD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WATK01",
    pstcd: "BDMICMIC",
    strloc: "WATKINS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WATT07",
    pstcd: "BDGEOGEO",
    strloc: "WATTS VILLAGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "WAVE02",
    pstcd: "BDMICBLA",
    strloc: "WAVELL AVENUE, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WAVE03",
    pstcd: "BDGEOGEO",
    strloc: "WAVERLEY COT, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "WAVE04",
    pstcd: "BDCHRCHR",
    strloc: "WAVERLEY, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WAVE05",
    pstcd: "BDMICBLA",
    strloc: "WAVELL DEVELOPMENT, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WEBB04",
    pstcd: "BDPHIPHI",
    strloc: "WEBB HILL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "WEBS02",
    pstcd: "BDMICWEB",
    strloc: "WEBSTER INDUSTRIAL PARK, WEBSTER IND PARK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WEBS02",
    pstcd: "BDMICWEB",
    strloc: "WEBSTER INDUSTRIAL PARK, WEBSTER IND PARK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WEEK03",
    pstcd: "BDMICBLA",
    strloc: "WEEKES LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WEEK03",
    pstcd: "BDMICMIC",
    strloc: "WEEKES LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WEIR01",
    pstcd: "BDMICBRI",
    strloc: "WEIRS GAP, BRITTONS HILL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WEIR01",
    pstcd: "BDMICMIC",
    strloc: "WEIRS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WEIR01",
    pstcd: "BDMICMIC",
    strloc: "WEIRS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WELC04",
    pstcd: "BDJOHJOH",
    strloc: "WELCH VILLAGE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "WELC05",
    pstcd: "BDTHOWEL",
    strloc: "WELCHES HEIGHTS, WELCHES - ST THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WELC06",
    pstcd: "BDTHOWEL",
    strloc: "WELCHES TERRACE NO. 1, WELCHES - ST THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WELC07",
    pstcd: "BDTHOWEL",
    strloc: "WELCHES LAND, WELCHES - ST THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WELC08",
    pstcd: "BDTHOWEL",
    strloc: "WELCHES TERRACE NORTH NO. 2, WELCHES - ST THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WELC09",
    pstcd: "BDTHOWEL",
    strloc: "WELCHES SHCOOL GAP, WELCHES - ST THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WELC10",
    pstcd: "BDTHOWEL",
    strloc: "WELCHES NORTH NO. 3, WELCHES - ST THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WELC11",
    pstcd: "BDTHOWEL",
    strloc: "WELCHES TERRACE SOUTH  NO. 3, WELCHES - ST THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WELC12",
    pstcd: "BDTHOTHO",
    strloc: "WELCHMAN HALL, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WELC13",
    pstcd: "BDTHOTHO",
    strloc: "WELCHMAN HALL LOWER, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WELC14",
    pstcd: "BDTHOTHO",
    strloc: "WELCHMAN HALL TERRACE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WELC15",
    pstcd: "BDTHOTHO",
    strloc: "WELCHAMN HALL UPPER, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WELC16",
    pstcd: "BDTHOTHO",
    strloc: "WELCHMAN HALL MAIN ROAD, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WELC17",
    pstcd: "BDTHOTHO",
    strloc: "WELCHMAN HALL 1ST AVENUE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WELC18",
    pstcd: "BDTHOTHO",
    strloc: "WELCHMAN HALL 2ND AVENUE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WELC19",
    pstcd: "BDPETPET",
    strloc: "WELCHTOWN, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "WELC20",
    pstcd: "BDPETPET",
    strloc: "WELCHTOWN PLANTATION, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "WELC21",
    pstcd: "BDPETPET",
    strloc: "WELCHTOWN TENANTRY, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "WELC22",
    pstcd: "BDCHRCHR",
    strloc: "WELCHES, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WELC22",
    pstcd: "BDMICMIC",
    strloc: "WELCHES, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WELC22",
    pstcd: "BDTHOWEL",
    strloc: "WELCHES, WELCHES - ST THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WELC23",
    pstcd: "BDMICWEL",
    strloc: "WELCHES ROAD, WELCHES - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WELC24",
    pstcd: "BDMICWEL",
    strloc: "WELCHES TERRACE, WELCHES - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WELC24",
    pstcd: "BDTHOWEL",
    strloc: "WELCHES TERRACE, WELCHES - ST THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WELC25",
    pstcd: "BDMICWEL",
    strloc: "WELCHES GARDENS, WELCHES - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WELC27",
    pstcd: "BDTHOWEL",
    strloc: "WELCHES GROVE, WELCHES - ST THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WELC28",
    pstcd: "BDJAMJAM",
    strloc: "WELCHES ESTATE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WELL05",
    pstcd: "BDLUCLUC",
    strloc: "WELL ROAD, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "WELL05",
    pstcd: "BDMICBLA",
    strloc: "WELL ROAD, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WELL05",
    pstcd: "BDMICMIC",
    strloc: "WELL ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WELL06",
    pstcd: "BDBGTBGT",
    strloc: "WELLINGTON STREET, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "WELL06",
    pstcd: "BDMICMIC",
    strloc: "WELLINGTON STREET, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WELL07",
    pstcd: "BDLUCLUC",
    strloc: "WELL HILL, ST. LUCY",
    parish: "ST. LUCY",
  },
  {
    stind: "WELL08",
    pstcd: "BDPHIPHI",
    strloc: "WELL HOUSE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "WELL09",
    pstcd: "BDPHIPHI",
    strloc: "WELL HOUSE BAY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "WELL10",
    pstcd: "BDCHRCHR",
    strloc: "WELLINGTON DRIVE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WELL11",
    pstcd: "BDMICMIC",
    strloc: "WELL GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WEST17",
    pstcd: "BDCHRCHR",
    strloc: "WEST DRIVE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WEST21",
    pstcd: "BDMICMIC",
    strloc: "WESTRIDGE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WEST22",
    pstcd: "BDJAMWES",
    strloc: "WEST TERRACE STAGE 1, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST23",
    pstcd: "BDJAMWES",
    strloc: "WEST TERRACE STAGE 2, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST24",
    pstcd: "BDJAMWES",
    strloc: "WEST TERRACE AVENUE 13TH, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST25",
    pstcd: "BDMICMIC",
    strloc: "WESTERN TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WEST26",
    pstcd: "BDMICMIC",
    strloc: "WESTON TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WEST27",
    pstcd: "BDMICMIC",
    strloc: "WESTBURY NEW ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WEST28",
    pstcd: "BDCHRCHR",
    strloc: "WEST END DRIVE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WEST29",
    pstcd: "BDMICMIC",
    strloc: "WEST ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WEST30",
    pstcd: "BDJAMJAM",
    strloc: "WESTWAY, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST31",
    pstcd: "BDJAMJAM",
    strloc: "WESTERN WAY, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST32",
    pstcd: "BDJAMJAM",
    strloc: "WESTON, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST33",
    pstcd: "BDJAMJAM",
    strloc: "WEST CLIFF, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST34",
    pstcd: "BDJAMJAM",
    strloc: "WESTMORELAND, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST35",
    pstcd: "BDJAMJAM",
    strloc: "WESTMOUNT, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST36",
    pstcd: "BDJAMJAM",
    strloc: "WEST CLIFF DEVELOPMENT, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST37",
    pstcd: "BDJAMJAM",
    strloc: "WEST RIDGE RD NO1, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST38",
    pstcd: "BDJAMJAM",
    strloc: "WESTMORELAND PLANTATION, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST39",
    pstcd: "BDJAMJAM",
    strloc: "WESTMORELAND RIDGE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST40",
    pstcd: "BDJAMJAM",
    strloc: "WESTMORELAND NO 1, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST41",
    pstcd: "BDJAMJAM",
    strloc: "WEST RIDGE ROAD NO 2, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST42",
    pstcd: "BDJAMJAM",
    strloc: "WESTMORELAND NO 2, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST44",
    pstcd: "BDMICMIC",
    strloc: "WESTBURY ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WEST46",
    pstcd: "BDJAMWES",
    strloc: "WEST TERRACE MAIN ROAD, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST47",
    pstcd: "BDJAMWES",
    strloc: "WEST TERRACE 1ST AVENUE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST48",
    pstcd: "BDJAMWES",
    strloc: "WEST TERRACE 2ND AVENUE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST49",
    pstcd: "BDJAMWES",
    strloc: "WEST TERRACE 3RD AVENUE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST50",
    pstcd: "BDJAMWES",
    strloc: "WEST TERRACE 4TH AVENUE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST51",
    pstcd: "BDJAMWES",
    strloc: "WEST TERRACE 5TH AVENUE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST52",
    pstcd: "BDJAMWES",
    strloc: "WEST TERRACE 6TH AVENUE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST53",
    pstcd: "BDJAMWES",
    strloc: "WEST TERRACE 7TH AVENUE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST54",
    pstcd: "BDJAMWES",
    strloc: "WEST TERRACE 8TH AVENUE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST55",
    pstcd: "BDJAMWES",
    strloc: "WEST TERRACE 9TH AVENUE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST56",
    pstcd: "BDJAMWES",
    strloc: "WEST TERRACE 10TH AVENUE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST57",
    pstcd: "BDJAMWES",
    strloc: "WEST TERRACE 11TH AVENUE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST58",
    pstcd: "BDJAMWES",
    strloc: "WEST TERRACE 12TH AVENUE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST59",
    pstcd: "BDJAMWES",
    strloc: "WEST TERRACE 13TH AVENUE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST60",
    pstcd: "BDJAMWES",
    strloc: "WEST TERRACE 14TH AVENUE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST61",
    pstcd: "BDJAMWES",
    strloc: "WEST TERRACE 15TH AVENUE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST62",
    pstcd: "BDJAMWES",
    strloc: "WEST TERRACE 16TH AVENUE, WEST TERRACE - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST63",
    pstcd: "BDJAMJAM",
    strloc: "WEST RIDGE GAP, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WEST65",
    pstcd: "BDMICMIC",
    strloc: "WEST TERRACE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WEST70",
    pstcd: "BDCHRCHR",
    strloc: "WESTERLEY COT ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WEXF01",
    pstcd: "BDMICMIC",
    strloc: "WEXFORD LANE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WEYM01",
    pstcd: "BDBGTBGT",
    strloc: "WEYMOUTH PASTURE, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "WHAR05",
    pstcd: "BDMICMIC",
    strloc: "WHARTONS GAP, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WHEE02",
    pstcd: "BDPHIPHI",
    strloc: "WHEELERS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "WHIM01",
    pstcd: "BDPETPET",
    strloc: "WHIM VILLAGE, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "WHIT05",
    pstcd: "BDBGTBGT",
    strloc: "WHITES ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "WHIT05",
    pstcd: "BDBGTBGT",
    strloc: "WHITES ALLEY, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "WHIT06",
    pstcd: "BDBGTBGT",
    strloc: "WHITEPARK ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "WHIT06",
    pstcd: "BDBGTBGT",
    strloc: "WHITEPARK ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "WHIT06",
    pstcd: "BDBGTBGT",
    strloc: "WHITEPARK ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "WHIT06",
    pstcd: "BDBGTBGT",
    strloc: "WHITEPARK ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "WHIT06",
    pstcd: "BDBGTBGT",
    strloc: "WHITEPARK ROAD, BRIDGETOWN",
    parish: "BRIDGETOWN",
  },
  {
    stind: "WHIT06",
    pstcd: "BDMICMIC",
    strloc: "WHITEPARK ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WHIT07",
    pstcd: "BDMICMIC",
    strloc: "WHITE HALL, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WHIT07",
    pstcd: "BDPETPET",
    strloc: "WHITE HALL, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "WHIT07",
    pstcd: "BDPHIPHI",
    strloc: "WHITE HALL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "WHIT07",
    pstcd: "BDTHOTHO",
    strloc: "WHITE HALL, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WHIT08",
    pstcd: "BDANDAND",
    strloc: "WHITE HILL, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "WHIT09",
    pstcd: "BDPETPET",
    strloc: "WHITE HALL TENANTRY, ST. PETER",
    parish: "ST. PETER",
  },
  {
    stind: "WHIT10",
    pstcd: "BDPHIPHI",
    strloc: "WHITE HAVEN, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "WHIT11",
    pstcd: "BDMICMIC",
    strloc: "WHITEHALL TENANTRY ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WHIT12",
    pstcd: "BDMICMIC",
    strloc: "WHITEHALL MAIN ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WHIT13",
    pstcd: "BDJAMJAM",
    strloc: "WHITE HOUSE TERRACE, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WHIT14",
    pstcd: "BDMICMIC",
    strloc: "WHITEHALL NO 1, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WHIT15",
    pstcd: "BDMICMIC",
    strloc: "WHITEHALL NO 2, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WHIT16",
    pstcd: "BDMICMIC",
    strloc: "WHITEHALL NO 3, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WHIT40",
    pstcd: "BDTHOTHO",
    strloc: "WHITE HILL TERRACE, ST. THOMAS",
    parish: "ST. THOMAS",
  },
  {
    stind: "WHOP01",
    pstcd: "BDJAMJAM",
    strloc: "WHOPPING, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WHOP02",
    pstcd: "BDJAMJAM",
    strloc: "WHOPING, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "WILC01",
    pstcd: "BDCHRCHR",
    strloc: "WILCOX TERRACE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WILC02",
    pstcd: "BDCHRCHR",
    strloc: "WILCOX LAND, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WILC03",
    pstcd: "BDCHRCHR",
    strloc: "WILCOX, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WILD01",
    pstcd: "BDMICPIN",
    strloc: "WILDEY GARDENS, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WILD02",
    pstcd: "BDMICMIC",
    strloc: "WILDEY PLAZA, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WILD03",
    pstcd: "BDMICMIC",
    strloc: "WILDEY MAIN ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WILD04",
    pstcd: "BDMICMIC",
    strloc: "WILDEY INDUSTRIAL PARK, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WILD04",
    pstcd: "BDMICWIL",
    strloc: "WILDEY INDUSTRIAL PARK, WILDEY IND. PARK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WILD05",
    pstcd: "BDMICMIC",
    strloc: "WILDEY HEIGHTS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WILD06",
    pstcd: "BDMICMIC",
    strloc: "WILDEY COMPLEX, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WILK02",
    pstcd: "BDGEOGEO",
    strloc: "WILKINSON ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "WILK02",
    pstcd: "BDMICMIC",
    strloc: "WILKINSON ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WILL13",
    pstcd: "BDJOHJOH",
    strloc: "WILLOW HILL, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "WILL14",
    pstcd: "BDMICMIC",
    strloc: "WILLIAMS LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WILL15",
    pstcd: "BDCHRCHR",
    strloc: "WILLOW ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WILS05",
    pstcd: "BDCHRCHR",
    strloc: "WILSON DRIVE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WILS06",
    pstcd: "BDJOHJOH",
    strloc: "WILSON HILL, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "WILS07",
    pstcd: "BDCHRCHR",
    strloc: "WILSON VILLAGE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WILS08",
    pstcd: "BDCHRCHR",
    strloc: "WILSON RIDGE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WILT01",
    pstcd: "BDPHIPHI",
    strloc: "WILTSHIRE PLANTATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "WILT02",
    pstcd: "BDPHIPHI",
    strloc: "WILTSHIRE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "WILT03",
    pstcd: "BDPHIPHI",
    strloc: "WILTSHIRES TENANTRY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "WIND05",
    pstcd: "BDCHRCHR",
    strloc: "WINDSOR ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WIND05",
    pstcd: "BDGEOGEO",
    strloc: "WINDSOR ROAD, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "WIND05",
    pstcd: "BDMICBAN",
    strloc: "WINDSOR ROAD, BANK HALL - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WIND07",
    pstcd: "BDCHRCHR",
    strloc: "WINDY RIDGE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WIND10",
    pstcd: "BDJOHJOH",
    strloc: "WINDERMERE, ST. JOHN",
    parish: "ST. JOHN",
  },
  {
    stind: "WIND11",
    pstcd: "BDANDAND",
    strloc: "WINDY HILL, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "WIND12",
    pstcd: "BDGEOGEO",
    strloc: "WINDSOR TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "WIND13",
    pstcd: "BDGEOGEO",
    strloc: "WINDSOR PLANTATION, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "WIND19",
    pstcd: "BDPHIPHI",
    strloc: "WINDWARD GARDENS, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "WIND20",
    pstcd: "BDGEOGEO",
    strloc: "WINDSOR, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "WOBU01",
    pstcd: "BDGEOGEO",
    strloc: "WOBURN, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "WOOD12",
    pstcd: "BDGEOGEO",
    strloc: "WOODS ALLEY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "WOOD19",
    pstcd: "BDGEOGEO",
    strloc: "WOODLAND PLANTATION, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "WOOD20",
    pstcd: "BDGEOGEO",
    strloc: "WOODLAND TENANTRY, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "WOOD21",
    pstcd: "BDPHIPHI",
    strloc: "NR. WOODBOURNE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "WOOD22",
    pstcd: "BDCHRCHR",
    strloc: "WOODBOURNE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WOOD22",
    pstcd: "BDPHIPHI",
    strloc: "WOODBOURNE, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "WOOD23",
    pstcd: "BDPHIPHI",
    strloc: "WOODBOURNE VALLEY, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "WOOD24",
    pstcd: "BDPHIPHI",
    strloc: "WOODBOURNE PLANTATION, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "WOOD25",
    pstcd: "BDPHIPHI",
    strloc: "WOODBOURNE PARK, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "WOOD26",
    pstcd: "BDCHRCHR",
    strloc: "WOODBOURNE TENANTRY, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WOOD27",
    pstcd: "BDMICMIC",
    strloc: "WOOD LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WOOD28",
    pstcd: "BDMICMIC",
    strloc: "WOODSTOCK ROAD, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WOOD29",
    pstcd: "BDMICMIC",
    strloc: "WOODSIDE GARDENS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WORK02",
    pstcd: "BDGEOGEO",
    strloc: "WORKMANS, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "WORK03",
    pstcd: "BDGEOGEO",
    strloc: "WORKMANS VILLAGE, ST. GEORGE",
    parish: "ST. GEORGE",
  },
  {
    stind: "WORK04",
    pstcd: "BDPHIPHI",
    strloc: "WORK HALL, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "WORR02",
    pstcd: "BDANDAND",
    strloc: "WORRELL ROAD, ST. ANDREW",
    parish: "ST. ANDREW",
  },
  {
    stind: "WORR03",
    pstcd: "BDMICMIC",
    strloc: "WORRELL LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "WORT01",
    pstcd: "BDCHRCHR",
    strloc: "WORTHING, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WORT02",
    pstcd: "BDCHRCHR",
    strloc: "WORTHING MAIN ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WORT03",
    pstcd: "BDCHRCHR",
    strloc: "WORTHING VIEW, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WORT04",
    pstcd: "BDCHRCHR",
    strloc: "WORTHING ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WORT05",
    pstcd: "BDCHRCHR",
    strloc: "WORTHING VIEW HILL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WOTT01",
    pstcd: "BDCHRCHR",
    strloc: "WOTTON DEVELOPMENT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WOTT02",
    pstcd: "BDCHRCHR",
    strloc: "WOTTON HOUSING AREA, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "WOTT03",
    pstcd: "BDCHRCHR",
    strloc: "WOTTON PLANTATION, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "YEAR02",
    pstcd: "BDJAMJAM",
    strloc: "YEARWOOD ROAD, ST. JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "YEAR02",
    pstcd: "BDJOSJOS",
    strloc: "YEARWOOD ROAD, ST. JOSEPH",
    parish: "ST. JOSEPH",
  },
  {
    stind: "YEAR02",
    pstcd: "BDMICBLA",
    strloc: "YEARWOOD ROAD, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "YEAR03",
    pstcd: "BDMICBLA",
    strloc: "YEARWOOD LAND, BLACK ROCK - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "YEAR03",
    pstcd: "BDMICMIC",
    strloc: "YEARWOOD LAND, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "YEAR04",
    pstcd: "BDMICMIC",
    strloc: "YEARWOOD LAND 1ST AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "YEAR05",
    pstcd: "BDMICMIC",
    strloc: "YEARWOOD LAND 2ND AVENUE, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "YONK01",
    pstcd: "BDMICMIC",
    strloc: "YONKERS, ST. MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "YORK04",
    pstcd: "BDCHRCHR",
    strloc: "YORKSHIRE TENANTRY, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "YORK05",
    pstcd: "BDCHRCHR",
    strloc: "YORKSHIRE PLANTATION, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "YORK06",
    pstcd: "BDCHRCHR",
    strloc: "YORKSHIRE TERRACE, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "YORK07",
    pstcd: "BDCHRCHR",
    strloc: "YORKSHIRE DEVELOPMENT, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "YORK08",
    pstcd: "BDCHRCHR",
    strloc: "YORKSHIRE HILL, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "YORK09",
    pstcd: "BDMICPIN",
    strloc: "YORK TERRACE AVENUE, PINE - ST MICHAEL",
    parish: "ST. MICHAEL",
  },
  {
    stind: "YORK10",
    pstcd: "BDCHRCHR",
    strloc: "YORK ROAD, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ZALE01",
    pstcd: "BDPHIPHI",
    strloc: "ZALE VIEW, ST. PHILIP",
    parish: "ST. PHILIP",
  },
  {
    stind: "ZINN03",
    pstcd: "BDJAMHUS",
    strloc: "ZINNIA WALK, HUSBANDS - ST JAMES",
    parish: "ST. JAMES",
  },
  {
    stind: "ZIRC02",
    pstcd: "BDCHRCHR",
    strloc: "ZIRCON, CHRIST CHURCH",
    parish: "CHRIST CHURCH",
  },
  {
    stind: "ZOAR01",
    pstcd: "BDJOHJOH",
    strloc: "ZOARS, ST. JOHN",
    parish: "ST. JOHN",
  },
];

export const ENVIRONMENTS = {
  local: "local",
  development: "development",
  staging: "staging",
  production: "production",
  uat: "uat",
};

export const NCD_HISTORY_CAP = 3;

export const MINIMUM_HOME_REPLACEMENT_VALUE = 100000; // one hundred thousand
export const MAXIMUM_HOME_REPLACEMENT_VALUE = 2500000; // two million five hundred thousand

export const HOME_PROPERTY_EXCESS = 1500;

export const HOME_CONTENTS_EXCESS = 500;

export const HOME_ALL_RISK_EXCESS = 500;

export const HOME_TOTAL_EXCESS =
  HOME_PROPERTY_EXCESS + HOME_CONTENTS_EXCESS + HOME_ALL_RISK_EXCESS;

export const HOME_SUM_INSURED = 150000; // one hundred fifty thousand

export const HOME_PUBLIC_LIABILITY = 500000; // five hundred thousand

export const HOME_PERSONAL_LIABILITY = 250000; // two fifty thousand

export const HOME_EMPLOYER_LIABILITY = 2500000; // two point five million

export const DEFAULT_ALL_RISK_TYPE = "local";

export const ENABLE_HOME_FLOW =
  process.env.REACT_APP_ENV !== ENVIRONMENTS.production; // process.env.REACT_APP_HOME_FLOW === "true";
