import React from "react";
import gql from "graphql-tag";
import { useFieldState } from "informed";
import { useMutation } from "@apollo/react-hooks";
import hasErrorCode from "~/helpers/hasErrorCode";
import styles from "./index.module.scss";
import TextInput from "~/components/TextInput";
import Button from "~/components/Button";
import { ToolProps } from "../..";
import { Text } from "~/components/Typography";

enum Field {
  Email = "AdminChangeEmail-Email",
  Passcode = "AdminChangeEmail-Passcode",
}

const CHANGE_EMAIL = gql`
  mutation adminChangeEmail(
    $profileId: String!
    $email: String!
    $passcode: String!
  ) {
    adminChangeEmail(profileId: $profileId, email: $email, passcode: $passcode)
  }
`;

const AdminChangeEmail = ({ identifier, onClose }: ToolProps) => {
  const emailState = useFieldState(Field.Email);
  const passcodeState = useFieldState(Field.Passcode);

  const [processing, setProcessing] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );

  const [changeEmail] = useMutation(CHANGE_EMAIL);

  const handleSubmit = async () => {
    setProcessing(true);

    try {
      const response = await changeEmail({
        variables: {
          profileId: identifier,
          email: String(emailState.value),
          passcode: String(passcodeState.value),
        },
      });

      const data = response?.data?.adminChangeEmail;

      if (!data) {
        throw new Error("Failed to change email.");
      }

      setProcessing(false);

      onClose({
        manuallyClosed: false,
        success: true,
        shouldReload: true,
        message: "Email successfully changed.",
      });
    } catch (error) {
      if (error instanceof Error) {
        if (hasErrorCode(error, "NOT_ADMIN")) {
          onClose({
            manuallyClosed: false,
            success: false,
            errorCode: "NOT_ADMIN",
          });
        }

        setErrorMessage(error.message);
      }

      setProcessing(false);
    }
  };

  return (
    <div className={styles.Content}>
      {errorMessage && <div className={styles.Error}>{errorMessage}</div>}

      <Text>
        NOTE: Ensure the client&apos;s new email is valid and the client owns
        the email. This tool will not confirm.
      </Text>

      <>
        <TextInput
          type="text"
          label="User's New Email"
          placeholder="Email"
          field={Field.Email}
        />

        <TextInput
          type="text"
          label="Passcode"
          placeholder="Passcode"
          field={Field.Passcode}
        />

        <Button
          disabled={!emailState.value || !passcodeState.value || processing}
          onClick={handleSubmit}
        >
          {processing ? "Processing..." : "Process"}
        </Button>
      </>
    </div>
  );
};

export default AdminChangeEmail;
